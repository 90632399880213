import React, { useState, useEffect } from "react";
import { TextField, IconButton, Paper, Checkbox } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  setAvailability,
  getAvailability,
  patchTimings,
} from "../../../../API/AppointmentLayer";
import { ReactComponent as Spinner_2 } from "../../../../Assets/Spinner_2.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Weekdays() {
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [ApiLoading, setApiLoading] = useState(false);
  const [shoCheckMark, setShowCheckMark] = useState(false);
  const [timings, setTimings] = useState(
    daysOfWeek.reduce((key, day) => {
      key[day] = [{ start_time: "", end_time: "" }];
      return key;
    }, {})
  );
  const [unavailableStatus, setUnavailableStatus] = useState(
    daysOfWeek.reduce((status, day) => {
      status[day] = { id: null, unavailable: false }; // Initialize all days as available
      return status;
    }, {})
  );

  const fetchTimingsFromAPI = async () => {
    try {
      const data = await getAvailability(false).then((res) => {
        return res.results;
      });

      const updatedTimings = {};
      daysOfWeek.forEach((day) => {
        const dayData = data.find((item) => item.day_of_week === day);
        updatedTimings[day] = dayData
          ? dayData.timings
          : [{ start_time: "", end_time: "" }];
      });
      setTimings(updatedTimings);

      const updatedUnavailableStatus = {};
      daysOfWeek.forEach((day) => {
        const dayData = data.find((item) => item.day_of_week === day);
        updatedUnavailableStatus[day] = dayData
          ? { id: dayData.id, unavailable: dayData.unavailable }
          : { id: null, unavailable: false };
      });
      setUnavailableStatus(updatedUnavailableStatus);
    } catch (error) {
      console.error("Error fetching timings:", error);
    }
  };

  useEffect(() => {
    fetchTimingsFromAPI();
  }, []);

  const saveDataToServer = async () => {
    setApiLoading(true);
    for (const dayOfWeek of daysOfWeek) {
      const timingsList = timings[dayOfWeek].filter(
        (item) => item.start_time && item.end_time
      );
      if (timingsList.length > 0) {
        const data = {
          day_of_week: dayOfWeek,
          timings: timingsList,
        };

        setAvailability(false, data).then((res) => {
          setApiLoading(false);
          setShowCheckMark(true);
          setTimeout(() => {
            setShowCheckMark(false);
          }, 1000);
        });
      }
    }
  };

  const handleTimingChange = (dayOfWeek, index, field, value) => {
    const updatedTimings = { ...timings };
    updatedTimings[dayOfWeek][index][field] = value;
    setTimings(updatedTimings);
    saveDataToServer();
  };

  const appendTiming = (dayOfWeek) => {
    const newTimings = [
      ...timings[dayOfWeek],
      { start_time: "", end_time: "" },
    ];
    setTimings({ ...timings, [dayOfWeek]: newTimings });
    saveDataToServer();
  };

  const removeTiming = async (dayOfWeek, index) => {
    if (timings[dayOfWeek].length > 1) {
      const updatedTimings = timings[dayOfWeek].filter((_, i) => i !== index);

      const timingsList = updatedTimings.filter(
        (item) => item.start_time && item.end_time
      );

      const data = {
        day_of_week: dayOfWeek,
        timings: timingsList,
      };

      try {
        await setAvailability(false, data);
        setTimings({ ...timings, [dayOfWeek]: updatedTimings });
      } catch (error) {
        console.error("Error removing timing:", error);
      }
    }
  };
  const handleCheckBox = (data, day_of_week) => {
    setApiLoading(true);
    const body = {
      day_of_week: day_of_week,
      unavailable: !data.unavailable,
    };
    // console.log("body", body);
    // console.log("id", data.id);
    patchTimings(data.id, body)
      .then((res) => {
        setApiLoading(false);
        setShowCheckMark(true);
        setTimeout(() => {
          setShowCheckMark(false);
        }, 1000);
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <Paper
      elevation={2}
      style={{ padding: "0 15px", overflowX: "auto", maxWidth: "100%" }}
    >
      <h3 style={{ margin: "15px" }}>Set your weekly hours</h3>
      {daysOfWeek.map((dayOfWeek) => (
        <div
          key={dayOfWeek}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "500px",
            borderBottom: "1px solid #b5b5b5",
            padding: "10px 0",
            alignItems: "flex-start",
          }}
        >
          <Checkbox
            checked={!unavailableStatus[dayOfWeek].unavailable}
            onChange={(e) => {
              setUnavailableStatus({
                ...unavailableStatus,
                [dayOfWeek]: {
                  ...unavailableStatus[dayOfWeek],
                  unavailable: !e.target.checked,
                },
              });
              handleCheckBox(unavailableStatus[dayOfWeek], dayOfWeek);
            }}
          />
          <h3
            style={{
              margin: "15px 5px 0 0",
              textTransform: "capitalize",
              width: "50px",
            }}
          >
            {dayOfWeek.substring(0, 3)}
          </h3>
          {unavailableStatus[dayOfWeek].unavailable ? (
            <p
              style={{
                margin: "15px 5px 0 0",
                textTransform: "capitalize",
                width: "370px",
                height: "45px",
              }}
            >
              unavailable
            </p>
          ) : (
            <div>
              {timings[dayOfWeek].map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "5px",
                    margin: "10px 0",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="outlined"
                    type="time"
                    fullWidth
                    size="small"
                    value={item.start_time}
                    onChange={(e) =>
                      handleTimingChange(
                        dayOfWeek,
                        index,
                        "start_time",
                        e.target.value
                      )
                    }
                  />
                  <span>-</span>
                  <TextField
                    variant="outlined"
                    type="time"
                    fullWidth
                    size="small"
                    value={item.end_time}
                    onChange={(e) =>
                      handleTimingChange(
                        dayOfWeek,
                        index,
                        "end_time",
                        e.target.value
                      )
                    }
                  />

                  <IconButton
                    aria-label="delete"
                    onClick={() => removeTiming(dayOfWeek, index)}
                    disabled={timings[dayOfWeek].length < 2}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="add"
                    onClick={() => appendTiming(dayOfWeek)}
                    color="primary"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      {ApiLoading && (
        <div style={{ position: "absolute", top: "160px", right: "800px" }}>
          <Spinner_2 style={{ width: "40px" }} />
        </div>
      )}

      {shoCheckMark && (
        <div style={{ position: "absolute", top: "248px", right: "800px" }}>
          <CheckCircleIcon style={{ width: "40px", color: "#46954a" }} />
        </div>
      )}
    </Paper>
  );
}
export default Weekdays;
