import { apiFetch } from "./CommonApi";

export const loadProducts = (param) => {
  const url = param ? `service/products/?${param}` : "service/products/?page=1";
  return apiFetch("GET", url, null, true).then((res) => {
    // console.log(res.result);
    if (res.ok) {
      return res.json();
    } else {
      // navigate("/login");
    }
  });
};

export const createProduct = (data) => {
  const formData = new FormData();

  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);

        formData.append("name", data.name);
        formData.append("image", file);
        formData.append("imageName", data.fileName);
        // console.log(Object.fromEntries(formData));
        return apiFetch("POST", "service/products/", formData, true);
      });
  } else {
    formData.append("name", data.name);
    return apiFetch("POST", "service/products/", formData, true);
  }
};

// export const editProduct = (editId, data) => {
//   const body = {
//     id: editId,
//     name: data.name.toLowerCase().replace(/ /g, "_"),
//   };

//   return apiFetch("PUT", `/service/products/${editId}/`, body, true);
// };

export const editProduct = (editId, data) => {
  const formData = new FormData();
  formData.append("id", editId);
  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);

        formData.append("name", data.name);
        formData.append("image", file);
        formData.append("imageName", data.fileName);
        // console.log("Edit Product formData",Object.fromEntries(formData));
        return apiFetch("PUT", `service/products/${editId}/`, formData, true);
      });
  } else {
    formData.append("name", data.name);
    return apiFetch("PUT", `service/products/${editId}/`, formData, true);
  }
};

export const delProduct = (productId) => {
  return apiFetch("DELETE", `service/products/${productId}/`, null, true).then(
    (res) => {
      if (res.ok) {
        return true;
      }
    }
  );
};
