import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../Context/Auth";
import { useIntegrationsLayer } from "../../../API/useIntegrationsLayer";
const RedirectScreen = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userDetails, user } = useAuth();
  const { getZohoHookUrl } = useIntegrationsLayer();
  useEffect(() => {
    const code = searchParams.get("code");
    const location = searchParams.get("location");
    const accountsServer = searchParams.get("accounts-server");
    const body = {
      code: code,
      email: userDetails?.email,
    };
    getZohoHookUrl(body);
    navigate("/integrations/zohobooks");
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 1300, // This ensures it is on top of most elements
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <p>Loading...</p>
    </Box>
  );
};

export default RedirectScreen;
