import React, { useEffect } from "react";
import { Link, Outlet, NavLink, useNavigate } from "react-router-dom";

function UserManagement() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/UserManagement/roles");
  }, []);

  return (
    <>
      <div className="CampaignNav">
        <nav>
          <NavLink to="roles">Roles</NavLink>
          <NavLink to="users"> Users </NavLink>
          <NavLink to="team">Teams</NavLink>
        </nav>
      </div>
      <Outlet />
    </>
  );
}
export default UserManagement;
