import React, { useEffect, useRef, useState } from "react";
import Conversation from "./Conversation";
import { useAuth } from "../../../../Context/Auth";
import { updateMessageStatus } from "../../../../API/ChatLayer";
import InfiniteScroll from "react-infinite-scroll-component";
import { useChatLayer } from "../../../../API/useChatLayer";

const Conversations = React.memo(() => {
  const {
    wpDetails,
    setChatData,
    setSelectedChat,
    setConversationspage,
    conversations,
    moreConversations,
    selectedChat,
    setConversations,
  } = useAuth();
  const { getContactChatList } = useChatLayer();

  const scrollableDivRef = useRef(null);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const clickHandler = (data) => {
    if (data?.total_unread_messages !== 0) {
      data.total_unread_messages = 0;
      setSelectedChat(data);
      updateMessageStatus(wpDetails, data)
        .then((res) => {
          console.log("Status Update");
        })
        .catch((error) => {
          console.log("Failed to update status", error);
        });
    }
    setSelectedChat(data);
    setConversationspage("chatContent");
    setChatData(null);
  };

  const scrollHandler = () => {
    getContactChatList(null, true);
  };

  // Track scroll position before update
  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        setSavedScrollPosition(scrollableDivRef.current.scrollTop);
      }
    };

    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // Preserve scroll position after updating conversations
  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) {
      // Use requestAnimationFrame to ensure DOM updates before setting scroll position
      requestAnimationFrame(() => {
        div.scrollTop = savedScrollPosition;
      });
    }
  }, [conversations]);

  return (
    <div className="chatContact" id="scrollableDiv" ref={scrollableDivRef}>
      <InfiniteScroll
        dataLength={conversations?.length || 10}
        next={scrollHandler}
        style={{ display: "flex", flexDirection: "column" }}
        hasMore={conversations?.length < moreConversations?.count}
        loader={
          <h4 style={{ textAlign: "center", marginTop: "100px" }}>
            Loading...
          </h4>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>--*--</b>
          </p>
        }
        scrollableTarget="scrollableDiv"
      >
        {conversations?.map((data) => (
          <p
            key={data.id}
            onClick={() => {
              clickHandler(data);
            }}
            style={{
              background: data.id === selectedChat?.id ? "#f2f2f2" : "",
            }}
          >
            <Conversation data={data} />
          </p>
        ))}
      </InfiniteScroll>
    </div>
  );
});

export default Conversations;
