import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import dayjs from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, choosebtn, TextfieldCss } from "../../Styles";
import { notify } from "../Common";
import { apiFetch } from "../../API/CommonApi";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { ClsBtn } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  createAppointment as apiCreateAppointment,
  editAppointment as apiEditAppointment,
} from "../../API/AppointmentLayer";
import { loadContacts as apiLoadContacts } from "../../API/ContactLayer";
import CustomSwitch from "../CustomSwitch/CustomSwitch";
function AddAppointment({
  handleClose,
  open,
  loadAppointments,
  editData,
  handleAddorEdit,
}) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const watchAllFields = watch();
  const [editId, setEditID] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);

  const HandleOnClose = () => {
    reset();
    setImage(null);
    handleClose();
  };
  const loadContacts = (text) => {
    setLoadingContacts(true);
    apiLoadContacts(text)
      .then((res) => {
        setContacts(res.results);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingContacts(false);
      });
  };
  const debouncedContacts = debounce((searchKey) => {
    if (searchKey) {
      loadContacts(searchKey);
    } else {
      loadContacts();
    }
  }, 600);
  useEffect(() => {
    reset();
  }, [open]);

  const onSubmit = (data) => {
    SetApiLoading(true);
    const body = {
      name: data.name,
      ...(data?.full_day_event
        ? { full_day_event: data?.full_day_event }
        : { end_time: data?.end_time }),
      contact: data?.contact?.id,
      start_time: data?.start_time,
      notes: data?.notes,
      appointment_type: appointmentType,
    };
    const body2 = {
      name: data.name,
      contact: data?.contact?.id,
      start_time: data?.start_time,
      notes: data?.notes,
      appointment_type: appointmentType,
    };
    if (editData) {
      apiEditAppointment(editData.id, appointmentType === "task" ? body2 : body)
        .then((res) => {
          if (res.ok) {
            handleClose();
            loadAppointments(null);
            reset();
            SetApiLoading(false);
          } else {
            res?.text().then((text) => {
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    } else {
      apiCreateAppointment(appointmentType === "task" ? body2 : body)
        .then((res) => {
          if (res.ok) {
            handleClose();
            loadAppointments(null);

            reset();
            SetApiLoading(false);
          } else {
            res?.text().then((text) => {
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (editData) {
      setAppointmentType(editData.appointment_type);
      setValue("start_time", editData.start_time);
      setValue("end_time", editData.end_time);
      setValue("name", editData.name);
      setValue("notes", editData.notes);
      setValue("contact", editData.contact);
      setValue("full_day_event", editData.full_day_event);
    } else {
      setAppointmentType("event");
      setValue("start_time", "");
      setValue("end_time", "");
    }
  }, [open, editData]);
  const handleTabChange = (selectedTab) => {
    setAppointmentType(selectedTab);
  };

  return (
    <>
      <div style={{ position: "absolute", right: "20px", top: "80px" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleAddorEdit(null);
          }}
          sx={btnCss}
          style={{ width: "auto" }}
        >
          <AddIcon className="Icon3" />
          <strong>Create</strong>
        </Button>
      </div>
      <Modal
        open={open}
        onClose={HandleOnClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="mCtr"
      >
        <Paper elevation={2} className="modal">
          <FormProvider {...methods} enctype="multipart/form-data">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <h2>Add Appointment</h2> */}
              <CustomSwitch
                tabs={["event", "task"]}
                defaultTab={
                  editData?.appointment_type
                    ? editData?.appointment_type
                    : appointmentType
                }
                onTabChange={handleTabChange}
              />
              <CloseIcon
                style={ClsBtn}
                className="Icon2"
                onClick={handleClose}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={12}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Name
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        sx={{ maxWidth: "412px", marginBottom: "10px" }}
                        error={Boolean(errors.name)}
                        helperText={errors?.name && "Name is required"}
                      />
                    )}
                    rules={{ required: true }}
                    name="name"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    {appointmentType === "event" ? "Start Time" : "Due at"}
                  </InputLabel>
                  <Controller
                    name="start_time"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          // label="Controlled picker"
                          value={value}
                          onChange={(newValue) => onChange(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              fullWidth
                              error={Boolean(errors.start_time)}
                              helperText={
                                errors.start_time && "Start Time is required"
                              }
                              placeholder="Select Date & time"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                {appointmentType === "event" && (
                  <>
                    <Grid item xs={12}>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        End Time
                      </InputLabel>
                      <Controller
                        name="end_time"
                        control={control}
                        //   rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              // label="Controlled picker"
                              disabled={
                                watchAllFields.full_day_event ? true : false
                              }
                              value={value}
                              onChange={(newValue) => onChange(newValue)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  fullWidth
                                  error={Boolean(errors.end_time)}
                                  helperText={
                                    errors.end_time && "end Time is required"
                                  }
                                  placeholder="Select Date & time"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid>
                      <Controller
                        name="full_day_event"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={onChange}
                                name="Checkbox"
                              />
                            }
                            label="Full day event"
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Contact
                  </InputLabel>
                  <Controller
                    name="contact"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={value}
                        options={contacts ? contacts : []}
                        loading={loadingContacts}
                        getOptionLabel={(option) => option?.name}
                        onInputChange={(e, newInputValue) => {
                          debouncedContacts(newInputValue);
                        }}
                        onChange={(e, data) => {
                          onChange(data);
                        }}
                        loadingText="Loading..."
                        noOptionsText="No options"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            error={Boolean(errors.contact)}
                            onFocus={() => loadContacts()}
                            helperText={
                              errors.contact && " Contact is required"
                            }
                            placeholder="Select Contact"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Notes
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        multiline
                        minRows={3}
                        fullWidth
                        sx={{ maxWidth: "412px", marginBottom: "10px" }}
                        error={Boolean(errors.notes)}
                        helperText={errors?.notes && "notes is required"}
                      />
                    )}
                    //   rules={{ required: true }}
                    name="notes"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    type="submit"
                  >
                    <strong>{editData ? "Update" : "Create"}</strong>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
          {ApiLoading && (
            <div className="overlay">
              <Spinner_2 style={{ width: "80px" }} />
            </div>
          )}
        </Paper>
      </Modal>
    </>
  );
}

export default AddAppointment;
