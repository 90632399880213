import React, { useEffect } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";

import { ReactComponent as FreeTrail } from "../../Assets/FreeTrail.svg";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useLocation } from "react-router-dom";
import "./SignUp.css";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import UseWindowSize from "../UseWindowSize";
import Form from "./Form";
import { useAuth } from "../../Context/Auth";
import { isMobile } from "../Common";
const outerCtr = {
  height: "auto",
  width: "50%",
  backgroundColor: "rgba(253, 200, 134, 0.1)",
  paddingTop: "12%",
};
const outerCtr2 = {
  width: "50%",
  height: "auto",
  backgroundColor: "#ffff",
  paddingTop: "4%",
};
const BoxCss = {
  display: "flex",
  minHeight: "80vh",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "50px",
  padding: "0 10px",
};
const miniFooterCss = {
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  marginTop: "2%",
};
export default function FirstTime() {
  const auth = useAuth();
  const [screenWidth, screenHeight] = UseWindowSize();
  const location = useLocation();
  // console.log(location.state.contactNo);
  // const phNo = location.state.contactNo;
  const navigate = useNavigate();
  useEffect(() => {
    auth.RedirectBasedOnDomain();
  }, []);
  return (
    <>
      <Grid container style={{ flexWrap: "wrap-reverse" }}>
        <Grid item sx={outerCtr} xs={12} md={6}>
          <Box sx={BoxCss}>
            <div style={{ padding: isMobile() ? "10px" : "0" }}>
              <FreeTrail style={{ margin: "auto" }} />
              <p className="heading">
                Discover how Pinaippu can boost your sales!
              </p>
              <p className="Fpara">
                All you need is one active WhatsApp number to get started with
                Pinaippu’s exciting free trial. Explore new ways to create long
                term values with Pinaippu! Let’s see what all it has to offer
                you.
              </p>
              <ul className="bullets">
                <li>Free WhatsApp Business API verification</li>
                <li>Send trial messages to your registered number</li>
                <li>360° contact management view</li>
                <li>
                  Experiment with broadcast campaigns in real-time (limited).
                </li>
              </ul>
            </div>
          </Box>
        </Grid>
        <Grid item sx={outerCtr2} xs={12} md={6}>
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              style={{ margin: "0 15px 5% 0", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              « Back
            </p>
          </div> */}
          <Box sx={BoxCss}>
            <Grid item xs={12}>
              <p
                style={{
                  color: "#464646",
                  fontSize: "28px",
                  fontWeight: 700,
                  margin: "8px 0",
                }}
              >
                Start your 14-day free trial now
              </p>
              <p style={{ fontSize: "18px", margin: "8px 0 38px 0" }}>
                Hey, Enter your details to get sign up to your account
              </p>

              <Form />
            </Grid>
          </Box>
          <div style={miniFooterCss}>
            <p>©2022 Pinaippu</p>
            <Link className="no-underline" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
