import react, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { notify, parseFormattedText } from "../Common";
import CallIcon from "@mui/icons-material/Call";
import LaunchIcon from "@mui/icons-material/Launch";
import { useAuth } from "../../Context/Auth";
import { debounce, set } from "lodash";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { loadTemplates as apiAllTemplates } from "../../API/TemplateLayer";
import { loadTemplates as apiPublishedTemplates } from "../../API/CampaignLayer";
import { templatePreview as apiTemplatePreview } from "../../API/TemplateLayer";
import { SendTemplates as apiSendTemplates } from "../../API/ChatLayer";

function SendMessageTemplate({
  rowData,
  isOpen,
  closeDialog,
  fetchMSGData,
  selectedChat,
  isSendDisabled,
}) {
  const methods = useForm({ mode: "onBlur" });
  const { control, formState, watch } = methods;
  const { errors } = formState;
  const watchAllFields = watch();
  const auth = useAuth();
  const wpDetails = auth.wpDetails?.[0];
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [fileName, setFileName] = useState("");

  const resetTemplate = () => {
    setTemplate(null);
    methods.reset();
  };

  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    if (!isSendDisabled) {
      apiPublishedTemplates(text)
        .then((res) => {
          setTemplates(res.data);
        })
        .catch((error) => {
          notify("error", "Something went wrong with templates");
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    } else {
      apiAllTemplates(text)
        .then((res) => {
          let templates = res.results;
          setTemplates(templates);
          for (var i = 2; i <= res.total_pages; i++) {
            apiAllTemplates("page=" + String(i))
              .then((res) => {
                setTemplates(templates.concat(res.results));
              })
              .catch((error) => {
                notify("error", "Something went wrong with templates");
              })
              .finally(() => {
                setLoadingTemplates(false);
              });
          }
        })
        .catch((error) => {
          notify("error", "Something went wrong with templates");
        })
        .finally(() => {
          setLoadingTemplates(false);
        });
    }
  };

  const handleSendTemplate = () => {
    if (template) {
      closeDialog();
      if (typeof selectedChat === "object") {
        selectedChat = selectedChat.contact?.phone_number;
      }
      return apiSendTemplates(selectedChat, wpDetails, template).then((res) => {
        if (fetchMSGData) {
          return fetchMSGData()
            .then((res) => {
              notify("success", "Message sent successfully");
              resetTemplate();
            })
            .catch((error) => {
              notify("error", "Something went wrong with sending message");
              resetTemplate();
            });
        } else {
          notify("success", "Message sent successfully");
          resetTemplate();
        }
      });
    }
  };

  useEffect(() => {
    if (preview?.header) {
      var link = preview.header.split("?")[0];
      var file = link.split("/").pop();
      setFileName(file);
    }
  }, [preview]);

  const ifCallTOAction = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type === "URL" || obj.type === "PHONE_NUMBER")
    ) {
      return (
        <div style={{ borderTop: "1px solid #dadde1" }}>
          {buttons?.map((item) => (
            <p
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "5px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                fontWeight: "normal",
                padding: "10px 15px",
              }}
            >
              {item.type == "PHONE_NUMBER" ? (
                <CallIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              ) : (
                <LaunchIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              )}
              <a
                href={item.url}
                style={{ color: "#33B7F6", textDecoration: "none" }}
                target="_blank"
              >
                {item.text}
              </a>
            </p>
          ))}
        </div>
      );
    }
  };
  const ifQuickreply = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type !== "URL" && obj.type !== "PHONE_NUMBER")
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "295px",
            justifyContent: "center",
          }}
        >
          {buttons?.map((item) => (
            <p
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "2px",
                background: "#ffffff",
                minWidth: "100px",
                cursor: "pointer",
                padding: "10px 15px",
                borderRadius: "10px",
                flexGrow: 1,
              }}
            >
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };
  const templatePreview = (body) => {
    if (body.template_name) {
      apiTemplatePreview(body)
        .then((res) => {
          setPreview(res);
          setPreviewLoading(false);
        })
        .catch((error) => {
          console.error("Error", error);
          notify("error", "Something went wrong with Preview");
        });
    }
  };

  useEffect(() => {
    if (watchAllFields?.template?.name) {
      templatePreview({ template_name: watchAllFields?.template?.name });
    }
  }, [watchAllFields.template]);

  const debouncedTemplates = debounce((searchKey) => {
    loadTemplates(searchKey);
  }, 600);

  useEffect(() => {
    if (isOpen) {
      loadTemplates();
    }
  }, [isOpen]);

  useEffect(() => {
    if (watchAllFields?.template) {
      setTemplate(watchAllFields?.template);
    }
  }, [watchAllFields]);
  return (
    <>
      <Dialog open={isOpen} onClose={closeDialog}>
        <DialogTitle>Send Template</DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "500px",
              height: "450px",
            }}
          >
            <Controller
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  value={value}
                  options={templates ? templates : []}
                  loading={loadingTemplates}
                  getOptionLabel={(option) => option?.name}
                  onInputChange={(e, newInputValue) => {
                    debouncedTemplates(newInputValue);
                  }}
                  onChange={(e, data) => {
                    onChange(data);
                  }}
                  loadingText="Loading..."
                  noOptionsText="No options"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={Boolean(errors.template)}
                      helperText={errors.template && "Template is required"}
                      placeholder="Select From the List"
                    />
                  )}
                />
              )}
              rules={{ required: "Template is required" }}
              name="template"
              control={control}
            />
            <div className="templateReview">
              {watchAllFields?.template?.name ? (
                <>
                  {preview ? (
                    <>
                      <div className="preview">
                        <div className="previewMCtr">
                          {preview.header &&
                          preview.header.includes("http") &&
                          preview.header.includes(".pdf") ? (
                            <>
                              <a
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "5px",
                                  paddingTop: "5px",
                                  borderTop: "1px solid #f2f2f2",
                                }}
                                href={preview.header}
                                target="_blank"
                                name="header"
                              >
                                <PictureAsPdfIcon />
                                {fileName}
                              </a>
                            </>
                          ) : preview.header &&
                            preview.header.includes("http") ? (
                            <Avatar
                              src={preview.header}
                              alt="Image Preview"
                              style={{ marginTop: "10px" }}
                              name="header"
                            />
                          ) : (
                            <p>{preview.header}</p>
                          )}
                          <p style={{ whiteSpace: "pre-wrap" }}>
                            {parseFormattedText(preview?.body)}
                          </p>
                          <p>{preview.footer}</p>
                          {ifCallTOAction(preview.buttons)}
                        </div>
                      </div>
                      {ifQuickreply(preview.buttons)}
                    </>
                  ) : (
                    <p>Loading...</p>
                  )}
                </>
              ) : (
                <p style={{ textAlign: "center" }}>
                  Select Template to preview
                </p>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "0 20px 20px 0" }}>
            <Button
              disabled={!watchAllFields.template}
              onClick={handleSendTemplate}
            >
              Send
            </Button>
            <Button onClick={closeDialog}>Cancel</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default SendMessageTemplate;
