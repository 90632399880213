import React from "react";
import SetAvailability from "./SetAvailability";
import AppointmentMsgs from "./AppointmentMsgs";

const AppointmentSettings = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <SetAvailability />
      <AppointmentMsgs />
    </div>
  );
};

export default AppointmentSettings;
