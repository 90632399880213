import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { ReactComponent as SigninSVG } from "../../Assets/SigninSVG.svg";
import { useAuth } from "../../Context/Auth";

import LoginUForm from "./LoginForm";
import { useNavigate, Link } from "react-router-dom";
import "./Login.css";
import { Padding } from "@mui/icons-material";
import UseWindowSize from "../UseWindowSize";
import { isMobile } from "../Common";
const outerCtr = {
  height: "auto",
  width: "50%",
  backgroundColor: "rgba(253, 200, 134, 0.1)",
  paddingTop: "12%",
  minWidth: "300px",
  flexGrow: 1,
};
const outerCtr2 = {
  width: "50%",
  height: "auto",
  backgroundColor: "#ffff",
  paddingTop: "4%",
  minWidth: "300px",
  flexGrow: 1,
  justifyContent: "center",
};
const BoxCss = {
  display: "flex",
  minHeight: "80vh",
  alignItems: "center",
  flexDirection: "column",
  flexGrow: 1,
  margin: "20px 0",
};
const miniFooterCss = {
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
};
export default function Login() {
  const auth = useAuth();
  const [width, height] = UseWindowSize();
  useEffect(() => {
    auth.RedirectBasedOnDomain();
  }, []);
  return (
    <>
      <Grid container style={{ display: "flex", flexWrap: "wrap-reverse" }}>
        <Grid item sx={outerCtr}>
          <Box sx={BoxCss}>
            <SigninSVG style={{ maxWidth: "100%", height: "auto" }} />
            <div style={{ padding: isMobile() ? "10px" : "0" }}>
              <p
                className="heading"
                style={{ textAlign: "center", marginBottom: "13px" }}
              >
                One-stop shop for Customer Communication
              </p>
              <p className="Fpara" style={{ textAlign: "center" }}>
                Build your customer relationship at ease with our end-to-end
                automated platform.
              </p>
            </div>
          </Box>
        </Grid>
        <Grid item sx={outerCtr2}>
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              style={{ margin: "0 15px 5% 0", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              « Back
            </p>
          </div> */}
          <div style={BoxCss}>
            <LoginUForm />
          </div>
          {!isMobile() && (
            <div style={miniFooterCss}>
              <p>©2023 Pinaippu</p>
              <Link className="no-underline" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
}
