import React, { useEffect, useState } from "react";

import {
  Button,
  Paper,
  Menu,
  TablePagination,
  IconButton,
  Checkbox,
} from "@mui/material";
import { btnCss } from "../../../../Styles";
import DateModel from "./DateModel";
import {
  setAvailability,
  getAvailability,
  delTimings as apiDelTimings,
  patchTimings,
} from "../../../../API/AppointmentLayer";
import { formatDateOnly, formatTime, notify } from "../../../Common";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ReactComponent as Spinner_2 } from "../../../../Assets/Spinner_2.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DatesGroup = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(null);
  const [page, setPage] = useState(1);
  const [ApiLoading, setApiLoading] = useState(false);
  const [shoCheckMark, setShowCheckMark] = useState(false);
  const [tableData, setTabledata] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [deleteData, setDeleteData] = useState(null);
  //   const [selectedRow, setSelectedRow] = useState(null);
  const handleAddorEdit = (data) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setSelectedRow(null);
  };

  const loadTimings = () => {
    getAvailability(true).then((res) => {
      setCount(res.count);
      setTabledata(res.results);
    });
  };
  const delTimings = (rowData) => {
    const body = {
      date: rowData.date,
      timings: rowData.timings,
    };
    apiDelTimings(rowData.id, body).then(() => {
      setAnchorEl(null);
      setDeleteData(null);
      loadTimings(null);
      notify("warn", "timings Deleted");
    });
  };

  const handleChexkbox = (e, data) => {
    setApiLoading(true);
    patchTimings(data.id, {
      unavailable: !data.unavailable,
      date: data.date,
    }).then((res) => {
      setApiLoading(false);
      setShowCheckMark(true);
      setTimeout(() => {
        setShowCheckMark(false);
      }, 1000);
      loadTimings();
    });
  };
  //columns
  const columns = [
    {
      title: "Checkbox",
      field: "Checkbox",
      type: "date",
      render: (x) => (
        <p className="column-4" style={{ width: "20px" }}>
          <Checkbox
            checked={!x.unavailable}
            onChange={(e) => handleChexkbox(e, x)}
          />
        </p>
      ),
    },
    {
      title: "Date",
      field: "date",
      render: (x) => (
        <p className="column-4" style={{ width: "100px" }}>
          {formatDateOnly(x.date)}
        </p>
      ),
    },

    {
      title: "Timings",
      field: "timings",
      render: (x) => (
        <p className="column-4" style={{ width: "210px" }}>
          {x.unavailable
            ? "Unavailable"
            : x.timings.map((x) => (
                <p>
                  {formatTime(x.start_time)} - {formatTime(x.end_time)}
                </p>
              ))}
        </p>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          {/* <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p> */}
          {/* <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p> */}
          <IconButton
            aria-label="delete"
            onClick={(event) => {
              setDeleteData(rowData);
              setAnchorEl(event.currentTarget);
            }}
            // style={{ marginLeft: "10px" }}
            // disabled={watchAllFields.timings?.length < 2}
          >
            <DeleteOutlineOutlinedIcon color="primary" />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delTimings(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setDeleteData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  useEffect(() => {
    loadTimings();
  }, []);
  return (
    <Paper elevation={2} style={{ overflowX: "auto", maxWidth: "100%" }}>
      <div style={{ margin: "15px" }}>
        <div
          style={{
            marginTop: "10px ",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <h3>Add date overrides</h3>

          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleAddorEdit(null);
            }}
            sx={btnCss}
          >
            <span style={{ textTransform: "none" }}>Add a date override</span>
          </Button>
        </div>
        {count < 1 ? (
          <p>Add dates when your availability changes from your weekly hours</p>
        ) : (
          ""
        )}
      </div>
      <div>
        {/* {tableData?.map((x) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 05px",
              margin: "10px 0",
              background: "#f3f3f3",
              borderRadius: "4px",
            }}
          >
            <p>{formatDateOnly(x.date)} </p>
            <div>
              {x.timings?.map((x, index) => (
                <p>
                  {formatTime(x.start_time)} - {formatTime(x.end_time)}
                </p>
              ))}
            </div>
          </div>
        ))} */}

        <MaterialTable
          sx={{
            border: "1px solid red",
            borderRadius: "10px",
          }}
          columns={columns}
          data={tableData ? tableData : []}
          components={{
            Toolbar: () => <></>,
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                count={count ? count : 0}
                page={page - 1}
                onPageChange={(e, page) => setPage(page + 1)}
                labelDisplayedRows={() => null}
              />
            ),
          }}
          //   onRowClick={(evt, selectedRow) => {
          //     setSelectedRow(selectedRow.tableData.id);
          //     handleAddorEdit(null);
          //     console.log("selectedRow", selectedRow);
          //   }}
          options={{
            search: false,
            toolbar: false,
            showTitle: false,
            paging: true,
            header: false,
            showTitle: false,
            pageSize: 10,
            sorting: true,

            headerStyle: {
              border: "1px solid #EDDCDC",
              maxHeight: "50px0",
              zIndex: 2,
              backgroundColor: "#FBFBFB",
            },
            // rowStyle: (rowData) => ({
            //   backgroundColor:
            //     selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            // }),
          }}
        />
      </div>
      <DateModel
        handleClose={handleClose}
        open={open}
        loadTimings={loadTimings}
      />
      {ApiLoading && (
        <div style={{ position: "absolute", top: "160px", right: "250px" }}>
          <Spinner_2 style={{ width: "40px" }} />
        </div>
      )}

      {shoCheckMark && (
        <div style={{ position: "absolute", top: "248px", right: "250px" }}>
          <CheckCircleIcon style={{ width: "40px", color: "#46954a" }} />
        </div>
      )}
    </Paper>
  );
};

export default DatesGroup;
