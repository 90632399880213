import React from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { deleteElements } = useReactFlow();
  const deleteEdge = (Eid) => {
    deleteElements({ edges: [{ id: Eid }] });
  };
  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: "all",
          }}
        >
          <IconButton
            onClick={() => deleteEdge(id)}
            sx={{
              backgroundColor: "@ffff",
            }}
          >
            <CloseIcon
              style={{
                backgroundColor: "#ffff",
                borderRadius: "50%",
                width: "15px",
                height: "15px",
              }}
            />
          </IconButton>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
