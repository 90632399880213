import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import AiSensy from "../../Assets/AiSensy.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', aisensy: '₹2159/mo' },
    { feature: 'Green Tick Application', pinaippu: 'Free', aisensy: 'Free' },
    { feature: 'Drip marketing ( Sequence )', pinaippu: 'Yes', aisensy: 'No' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', aisensy: 'Yes' },    
    { feature: 'Follow-up Reminders for Re-engagement', pinaippu: 'Yes', aisensy: 'No' },
    { feature: 'Campaign management', pinaippu: 'Advanced', aisensy: 'Standard' },
    { feature: 'Catalogue Flow buildert', pinaippu: 'Free', aisensy: 'Paid' },
    { feature: 'Custom attributes', pinaippu: 'Unlimited', aisensy: '20' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', aisensy: 'Ticketing, Email' },
    
  ];

const ComparisonBlog4 = () => {
    return (
    <div className='comparison-body'>
      <Helmet>
                <title>Best Alternative to AiSensy | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Choose AiSensy over Respond.io for a cost-effective WhatsApp Business API solution
                    with advanced features, comprehensive user support, and limitless custom fields.
                    Explore the Best WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div"  style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px", textAlign: "center"}}>Pinaippu Vs AiSensy</p>
              <img src={AiSensy} style={{ width: "72px", height: "80px" }} alt="AiSensy" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'AiSensy', field: 'aisensy' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>AiSensy</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Competitive pricing compared to Pinaippu. Provides a free Green Tick Application.</li>
                        <li>Offers Click-to-WhatsApp Ads support.</li>
                        <li>Supports Smart Audience Segregation for better targeting.</li>
                        <li>Offers features like Broadcasting and retargeting, Template Message APIs, and Multi-Agent Live Chat.</li>
                        <li>Integrates with popular e-commerce platforms like Shopify and WooCommerce.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Lacks Drip Marketing (Sequence) and Follow-up Reminders for Re-engagement features.</li>
                        <li>Campaign management is standard but not as advanced as Pinaippu.</li>
                        <li>Catalog Flow Builder is available at an additional cost.</li>
                        <li>Customer support options are limited to ticketing and email, lacking call support.</li>
                    </ul>
                </div>
            </div>
            </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Versatile Features and Limitless Scaling</h3></span><br />Pinaippu.io's Elite plan stands out for its advanced features, including unlimited campaigns, messages, and contacts. 
              This capability empowers businesses to scale without the limitations of messaging quotas or contact restrictions. 
              Such versatility ensures that companies can grow their operations without constraints on their communication outreach.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Strategic Campaign Scheduling and Transparency</h3></span><br />The platform's campaign scheduling feature is a pivotal tool for businesses, enabling them to reach their audience at the most opportune moments. This functionality significantly enhances marketing endeavors and customer engagement.
               Moreover, Pinaippu.io's commitment to transparent pricing and clearly defined plan features ensures a clear understanding, helping businesses effectively budget their WhatsApp API usage without hidden costs or custom pricing structures.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Tailored Plans for Diverse Business Needs</h3></span><br />Pinaippu.io excels in offering distinct plans tailored to cater to various business requirements. Whether an organization is a burgeoning startup or an established enterprise, the platform provides a range of plans to suit different scales and scopes of operation. 
              This diversity ensures that businesses can select the plan that best aligns with their specific needs and growth trajectory.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Messaging Flexibility and Absence of Speed Limitations</h3></span><br />In contrast to some competitors like AiSensy, which imposes a messaging speed limit of 1200 Messages/min in its Basic plan, Pinaippu.io does not specify such limitations. This lack of speed constraints offers businesses greater flexibility, especially those with varying messaging requirements. 
              Additionally, the absence of messaging limits further enhances the platform's appeal for companies seeking unrestricted communication capabilities.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog4;
