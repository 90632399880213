import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
  Avatar,
  Badge,
  InputAdornment,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { notify } from "../Common";
import { btnCss, choosebtn, TextfieldCss } from "../../Styles";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../Context/Auth";
// import profile from "../../Assets/profile.png";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { reSetPassword } from "../../API/AuthLayer";
const ResetPassword = () => {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
    setError,
  } = methods;
  const watchAllFields = watch();

  const { errors } = formState;
  const auth = useAuth();
  const user = auth?.user?.user;
  const [ApiLoading, SetApiLoading] = useState(false);

  const [showPassword1, setShowpwd1] = useState(false);
  const [showPassword2, setShowpwd2] = useState(false);

  const onSubmit = async (data) => {
    const body = {
      old_password: data.old_password,
      new_password1: data.new_password,
      new_password2: data.rentered_new_password,

      // email: data.email,
    };
    await reSetPassword(body).then((res) => {
      if (res.ok) {
        reset();
      }
      console.log("res", res);
    });
  };

  useEffect(() => {
    setValue("first_name", user?.first_name || "");
    setValue("last_name", user?.last_name || "");
    setValue("email", user?.email || "");
  }, []);
  useEffect(() => {
    if (
      watchAllFields?.new_password &&
      !watchAllFields?.rentered_new_password
    ) {
      setError("rentered_new_password", { message: "Re Enter the Password " });
    } else if (
      watchAllFields?.new_password !== watchAllFields?.rentered_new_password
    ) {
      setError("rentered_new_password", { message: "Password didn't match" });
    }
  }, [watchAllFields]);
  return (
    <Paper
      elevation={3}
      style={{
        margin: "20px 0",
        width: "100%",
        padding: "15px",
        // display: "grid",
        // placeItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowX: "auto",
        maxWidth: "500px",
        // height: "80vh",
        // maxHeight: "90vh",
      }}
    >
      <>
        <FormProvider>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: "500px", width: "100%" }}
          >
            {/* <h2 style={{ display: "grid", placeItems: "center" }}>
                Remainder Messages
              </h2> */}
            <p className="pageH">Reset Password</p>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Old Password
                </InputLabel>
                <Controller
                  name="old_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword1 ? "text" : "password"}
                      size="small"
                      fullWidth
                      // sx={{ maxWidth: "412px", marginBottom: "20px" }}
                      // label="Old Password"
                      variant="outlined"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowpwd1(!showPassword1)}
                              edge="end"
                            >
                              {showPassword1 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors?.old_password)}
                      helperText={
                        Boolean(errors?.old_password) &&
                        (errors?.old_password?.message
                          ? errors?.old_password?.message
                          : "old password is required")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  New Password
                </InputLabel>
                <Controller
                  name="new_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword2 ? "text" : "password"}
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowpwd2(!showPassword2)}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors?.new_password)}
                      helperText={
                        Boolean(errors?.new_password) &&
                        (errors?.new_password?.message
                          ? errors?.new_password?.message
                          : "new password is required")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Renter the New Password
                </InputLabel>
                <Controller
                  name="rentered_new_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword2 ? "text" : "password"}
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowpwd2(!showPassword2)}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors?.rentered_new_password)}
                      helperText={
                        Boolean(errors?.rentered_new_password) &&
                        (errors?.rentered_new_password?.message
                          ? errors?.rentered_new_password?.message
                          : "new password is required")
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  type="submit"
                  name="reset_password"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </>
    </Paper>
  );
};

export default ResetPassword;
