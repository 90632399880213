import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Checkbox,
  InputAdornment,
  Modal,
  Autocomplete,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Grid,
  FormControl,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InfiniteScroll from "react-infinite-scroll-component";
// import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Controller, FormProvider, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
// import MaterialTable from "material-table";
import { apiFetch } from "../../../API/CommonApi";
import { notify, parseFormattedText } from "../../Common";
import { debounce, drop, set } from "lodash";
import { AddTemplate } from "../../TemplateMessage/AddTemplate";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import { btnCss, TextfieldCss, ClsBtn } from "../../../Styles";
import { convertToUTC } from "../../Common";
import { loadContacts as apiLoadContacts } from "../../../API/ContactLayer";
import { loadTemplates as selectedTemplate } from "../../../API/TemplateLayer";
import { templatePreview as apiTemplatePreview } from "../../../API/TemplateLayer";
import {
  loadTemplates as apiLoadTemplates,
  createCampaign as apiCreateCampaign,
  loadCampaigns as apiLoadCampaigns,
  campaignAnalytics,
  downloadSampleFile,
} from "../../../API/CampaignLayer";
import CallIcon from "@mui/icons-material/Call";
import LaunchIcon from "@mui/icons-material/Launch";
import CustomFilter from "../../CustomFilter";
import { isMobile } from "../../Common";
import { loadAttributes as apiLoadAttributes } from "../../../API/customAttr";
const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "991px",
  // maxHeight: "617px",

  backgroundColor: "#FFFFFF",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "40px",
};
const mobileModal = {
  width: "100%",
  height: "100%",
  backgroundColor: "#FFFFFF",
  borderRadius: "5px",
  padding: "15px",
  overflow: "auto",
};
const BtnCss = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  height: "50px",
  width: "100%",
  maxWidth: "228px",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
  letterSpacing: "0.05em",
  color: "#FFFFFF",
  position: "absolute",
  bottom: 0,
  marginBottom: "40px",
  textTransform: "capitalize",
};

const modalContent = {
  display: "flex",
  // marginTop: "30px",
  height: "430px",
};
const mobileContent = {
  // marginTop: "30px",
  height: "430px",
};
const modalCtr1 = {
  width: "40%",
  height: "100%",
};
const mobileCtr1 = {
  width: "100%",
  height: "100%",
};
const modalCtr2 = {
  width: "60%",
  marginLeft: "20px",
  height: "100%",
};
const mobileCtr2 = {
  marginTop: "30px",
  marginBottom: "40px",
  width: "100%",
  height: "100%",
  minHeight: "130px",
};
const NewCampaign = ({ open, setOpen, editData, setRowData }) => {
  const { register } = useForm();
  const methods = useForm({ mode: "onBlur" });
  const { handleSubmit, reset, control, setValue, watch, formState } = methods;
  const { errors } = formState;
  const watchAllFields = watch();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [selectedContacts, setSelectedRows] = useState([]);
  const [contacts, setContacts] = useState(null);
  const [template, setTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [openNewTemplate, setOpenNewTemplate] = useState(false);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All Contacts");
  const [fetchMoreContacts, setFetchMoreContacts] = useState();
  const [totalContactCount, setTotalContactCount] = useState(0);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [datehelperText, setDatehelperText] = useState("");
  const [timehelperText, setTimehelperText] = useState("");
  const mobileScreen = isMobile();

  const options = [
    { id: 0, value: "pongal", label: "Pongal" },
    { id: 1, value: "newYear", label: "New Year" },
    { id: 2, value: "discount", label: "Discount" },
    { id: 3, value: "wellcome", label: "Wellcome" },
  ];

  const menuOptions = [
    { value: "all", label: "All Contacts" },
    { value: "filter", label: "Filter by Custom Field" },
    { value: "specific", label: "Specific Contacts" },
    { value: "Retarget", label: "Retarget Campaign" },
    { value: "upload", label: "Batch transaction" },
  ];
  const optionsForTextType = [
    { value: "contains", label: "Contains" },
    { value: "startswith", label: "Starts With" },
    { value: "endswith", label: "Ends With" },
    { value: "exact", label: "Exact Match" },
  ];

  const optionsForNumberType = [
    { value: "lt", label: "Less Than" },
    { value: "gt", label: "Greater Than" },
    { value: "exact", label: "Equal To" },
  ];

  const optionsForDateType = [
    { value: "lt", label: "Before" },
    { value: "gt", label: "After" },
    { value: "exact", label: "On" },
  ];

  const optionsForDropdownType = [
    { value: "in", label: "In" },
    { value: "not-in", label: "Not In" },
  ];

  // const handleRowSelection = (rows) => {
  //   setSelectedRows(rows);
  // };
  let dropdownsChanged = false;
  const handleOpenNewTemplate = () => {
    setOpenNewTemplate(true);
  };
  const handleRowSelect = (row) => {
    if (selectedContacts.some((selectedRow) => selectedRow.id === row.id)) {
      // If row is already selected, remove it from selected rows
      setSelectedRows(
        selectedContacts.filter((selectedRow) => selectedRow.id !== row.id)
      );
    } else {
      // If row is not selected, add it to selected rows
      setSelectedRows([...selectedContacts, row]);
    }
  };

  const validateDateTimeChange = (date = "", time = "") => {
    const now = new Date();
    if (date != "" && time != "") {
      const dateTime = new Date(`${date} ${time}`);
      console.log("dateTime", dateTime);
      if (dateTime < now) {
        notify("warn", "Invalid Date or Time");
        setTimehelperText("Invalid Date or Time");
        setIsSaveDisabled(true);
      } else {
        setDatehelperText("");
        setTimehelperText("");
        setIsSaveDisabled(false);
      }
    } else if (date != "") {
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      if (date < formattedDate) {
        notify("warn", "Invalid Date");
        setDatehelperText("Invalid Date");
        setIsSaveDisabled(true);
      } else {
        setDatehelperText("");
        setTimehelperText("");
        setIsSaveDisabled(false);
      }
    } else if (time != "") {
      const hour = now.getHours().toString().padStart(2, "0");
      const minute = now.getMinutes().toString().padStart(2, "0");
      const second = now.getSeconds().toString().padStart(2, "0");
      const formattedTime = `${hour}:${minute}:${second}`;
      if (time < formattedTime) {
        notify("warn", "Invalid Time");
        setTimehelperText("Invalid Time");
        setIsSaveDisabled(true);
      } else {
        setDatehelperText("");
        setTimehelperText("");
        setIsSaveDisabled(false);
      }
    } else {
      setDatehelperText("");
      setTimehelperText("");
      setIsSaveDisabled(false);
    }
  };
  // Function to handle select/deselect all rows
  const handleSelectAll = () => {
    if (selectedOption === "all") {
      // If the selectedOption is "all", select all contacts

      setSelectedRows([...contacts]);
    }
    if (selectedContacts.length === contacts.length) {
      // If all rows are already selected, deselect all rows
      setSelectedRows([]);
    } else {
      // If not all rows are selected, select all rows
      setSelectedRows([...contacts]);
    }
  };
  const [allContacts, setAllContacts] = useState([]);
  useEffect(() => {
    if (selectedOption === "Retarget") {
      loadCampaigns();
    }
  }, [selectedOption]);

  const loadCampaigns = () => {
    let totCount = 0;
    const loadNextPage = (page = 1) => {
      const nextPageParam = `page=${page}`;

      apiLoadCampaigns(nextPageParam).then((res) => {
        if (res?.results && res.results.length > 0) {
          totCount += res.results.length;
          setAllCampaigns((prevCampaigns) => [
            ...prevCampaigns,
            ...res.results,
          ]);
          if (totCount < res?.count) {
            loadNextPage(page + 1);
          }
        }
      });
    };
    loadNextPage();
  };

  const RetargetCampaignConatct = () => {
    const params = new URLSearchParams();
    params.append("campaign", reTargetOption.campaign);
    params.append("type", reTargetOption.type);

    apiFetch(
      "GET",
      `whatsapp-business/campaign/${
        reTargetOption.campaign
      }/retarget-contacts?${params.toString()}`,
      null,
      true
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setContacts(data);
      });
  };

  const loadContacts = (
    param = null,
    nextPage = false,
    resetPagination = false
  ) => {
    if (nextPage) {
      var page = parseInt(fetchMoreContacts?.current_page) + 1;
      if (resetPagination) {
        page = 1;
      }
      param = param ? `${param}&page=${page}` : `page=${page}`;
    }

    apiLoadContacts(param)
      .then((res) => {
        if (nextPage) {
          setFetchMoreContacts(res);
          // setContacts((prevState) => [...prevState, ...res?.results]);
          setContacts((prevState) =>
            resetPagination ? res?.results : [...prevState, ...res?.results]
          );
        } else {
          setTotalContactCount(res?.count);
          setContacts(res?.results);
          setFetchMoreContacts(res);
        }
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };
  const [variables, setVariables] = useState([]);
  const [variableData, setVariableData] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState("");

  const addVariableData = (variable, value) => {
    const updatedVariableData = { ...variableData };

    if (value.trim() === "") {
      delete updatedVariableData[variable];
    } else {
      updatedVariableData[variable] = value;
    }

    setVariableData(updatedVariableData);
  };

  const loadTemplate = (param) => {
    selectedTemplate(param)
      .then((res) => {
        console.log(param);
        console.log("res", res.results);
        const result = res.results;
        const foundTemplate = result.find((template) => template.id === param);
        console.log(foundTemplate);
        if (foundTemplate && foundTemplate.body_content) {
          const placeholders =
            foundTemplate.body_content.match(/{{(.*?)}}/g) || [];

          const variableNames = placeholders.map((placeholder) =>
            placeholder.replace(/[{}]/g, "").trim()
          );

          setVariables(variableNames);
        }
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      });
  };

  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
        console.log(res);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };
  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    }
  }, 600);

  useEffect(() => {
    if (preview?.header) {
      var link = preview.header.split("?")[0];
      var file = link.split("/").pop();
      setFileName(file);
    }
  }, [preview]);

  // Template Preview
  const ifCallTOAction = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type === "URL" || obj.type === "PHONE_NUMBER")
    ) {
      return (
        <div style={{ borderTop: "1px solid #dadde1" }}>
          {buttons?.map((item) => (
            <p
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "5px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                gap: "10px",
                fontWeight: "normal",
                padding: "10px 15px",
              }}
            >
              {item.type == "PHONE_NUMBER" ? (
                <CallIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              ) : (
                <LaunchIcon
                  style={{
                    width: "20px",
                    marginRight: "-5px",
                  }}
                />
              )}
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };
  const ifQuickreply = (data) => {
    const buttons = typeof data === "string" ? JSON.parse(data) : data;
    if (
      buttons?.some((obj) => obj.type !== "URL" && obj.type !== "PHONE_NUMBER")
    ) {
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "295px",
            justifyContent: "center",
          }}
        >
          {buttons?.map((item) => (
            <p
              style={{
                color: "#33b7f6",
                textAlign: "center",
                margin: "2px",
                background: "#ffffff",
                minWidth: "100px",
                cursor: "pointer",
                padding: "10px 15px",
                borderRadius: "10px",
                flexGrow: 1,
              }}
            >
              {item.text}
            </p>
          ))}
        </div>
      );
    }
  };
  const templatePreview = (body) => {
    if (body.template_name) {
      apiTemplatePreview(body)
        .then((res) => {
          setPreview(res);
          setPreviewLoading(false);
        })
        .catch((error) => {
          notify("error", "Something went wrong with Preview");
        });
    }
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (watchAllFields?.template?.name) {
      templatePreview({ template_name: watchAllFields?.template?.name });
    }
  }, [watchAllFields.template]);

  useEffect(() => {
    if (editData) {
      setValue("campaignName", editData?.name);
      setValue("template", editData?.template);

      const filteredData = contacts.filter(
        (row) =>
          Array.isArray(editData.contacts) &&
          editData.contacts.some((i) => i.phone_number === row.phone_number)
      );
      setSelectedRows(filteredData);
      if (!editData.send_now) {
        setIsScheduled(true);
        const dateString = editData.scheduled_time?.substr(0, 10);
        const timeString = editData.scheduled_time?.substr(11, 8);
        setValue("Date", dateString);
        setValue("Time", timeString);
      }
    }
  }, [editData, open]);

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <>
          <p>{rowData?.name}</p>
        </>
      ),
    },
    {
      title: "Phone",
      field: "phone",
      render: (rowData) => (
        <>
          <p>{rowData?.phone_number}</p>
        </>
      ),
    },
  ];

  const loadAttributes = (param) => {
    apiLoadAttributes(param)
      .then((res) => {
        const attributeNamesWithType = res.results.map((attribute) => ({
          name: attribute.name,
          type: attribute.type,
          options: attribute.options,
        }));
        const exOpt = {
          name: "created on",
          type: "date",
          options: null,
        };
        setAttributes([...attributeNamesWithType, exOpt]);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const handleClose = () => {
    setSelectedRows([]);
    setRowData(null);
    setOpen(false);
    setFile(null);
    setFileName(null);
    setDropdownCount(1);
    setDropdown([{ id: 0, data: {} }]);
    setSelectedOption(null);
    setTargetCount(0);
    setSelectedVariable("");
    setVariables([]);
    setVariableData([]);
    setSelectedAttribute("");
    setSelectedType("");
    setSelectedDate("");
    setSelectedTime("");
    setDatehelperText("");
    setTimehelperText("");
    setShowFilterData(true);
    setIsSaveDisabled(false);
    reset();
    if (editData) {
      setFormData(null);
    }
    setStep(1);
    setIsScheduled(false);
    loadTemplates([]);
  };

  const extractDuplicateKeyMessage = (errorText) => {
    const match = errorText.match(/Key \([^)]+\)=\(([^)]+)\) already exists\./);
    if (match && match[1]) {
      const keyValueWithOne = match[1].replace(/ 1$/, "");
      const keyValue = keyValueWithOne.replace(/,/g, "");
      return `${keyValue} already exists`;
    }
    return "Unknown duplicate key error";
  };

  const onSubmit = (data) => {
    console.log(data);
    const hasdropDownData = dropdown.some((item) =>
      Object.values(item.data).some((value) => value !== "")
    );
    const hasreTargetOption = Object.values(reTargetOption).some(
      (value) => value !== null && value !== ""
    );

    const hasVariables = Object.values(variableData).some(
      (value) => value !== null && value !== ""
    );
    console.log(variableData);

    SetApiLoading(true);

    if (data) {
      let body;

      if (hasdropDownData) {
        const dropdownData = dropdown.map((item) => item.data);
        const customFilter = JSON.stringify(dropdownData);
        if (isScheduled) {
          const dateTime = `${selectedDate} ${selectedTime}`;
          const utcDateTime = convertToUTC(dateTime);
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            custom_filter: customFilter,
            send_now: false,
            scheduled_time: utcDateTime,
          };
        } else {
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            custom_filter: customFilter,
            send_now: true,
          };
        }
      } else if (hasreTargetOption) {
        if (isScheduled) {
          const dateTime = `${selectedDate} ${selectedTime}`;
          const utcDateTime = convertToUTC(dateTime);
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            re_target: reTargetOption,
            send_now: false,
            scheduled_time: utcDateTime,
          };
        } else {
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            re_target: reTargetOption,
            send_now: true,
          };
        }
      } else if (selectedContacts) {
        const selectedContactIds = selectedContacts.map(
          (contact) => contact.id
        );
        let allContacts = 0;
        if (selectedOption === "all") {
          allContacts = 1;
        }
        if (isScheduled) {
          const dateTime = `${selectedDate} ${selectedTime}`;
          const utcDateTime = convertToUTC(dateTime);
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            contacts: selectedContactIds,
            send_now: false,
            scheduled_time: utcDateTime,
            send_to_all_contacts: Boolean(allContacts),
          };
        } else if (file && ufileName) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileName", ufileName);
          formData.append("name", data?.campaignName);
          formData.append("template", data?.template?.id);
          if (isScheduled) {
            const dateTime = `${data?.Date} ${data?.Time}`;
            const utcDateTime = convertToUTC(dateTime);
            formData.append("send_now", "false");
            formData.append("scheduled_time", utcDateTime);
          } else {
            formData.append("send_now", "true");
          }
          body = Object.fromEntries(formData);
        } else {
          body = {
            name: data?.campaignName,
            template: data?.template?.id,
            contacts: selectedContactIds,
            send_now: true,
            send_to_all_contacts: Boolean(allContacts),
          };
        }
      }
      if (hasVariables) {
        body = {
          ...body,
          template_variables: variableData,
        };
      }

      const url = editData
        ? `whatsapp-business/campaign/${editData.id}/`
        : "whatsapp-business/campaign/";

      apiCreateCampaign(url, body, editData)
        .then((res) => {
          if (res.status === 500) {
            res?.text().then((text) => {
              const errorText = JSON.stringify(text);
              if (errorText.includes("already exists")) {
                const duplicateKeyMessage =
                  extractDuplicateKeyMessage(errorText);
                notify("warn", `Campaign Name: ${duplicateKeyMessage}`);
              } else {
                notify("error", `${errorText}`);
              }
            });
          }
          if (editData && res.ok) {
            notify("success", "Campaign Updated");
          } else if (res.ok) {
            notify("success", "New Campaign Added");
          } else {
            notify("fail", res);
          }
          handleClose();
          reset();
          SetApiLoading(false);
        })
        .catch((error) => {
          notify("error", "Something went wrong");
          SetApiLoading(false);
          console.log("error", error);
        });
    }
    if (data) {
      setFormData([formData, ...data]);
    }
  };

  const onCheckboxChange = (event) => {
    setIsScheduled(event.target.checked);
  };

  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadContacts(`search=${searchKey}`);
    }
  }, 600);

  useEffect(() => {
    loadContacts();
    loadTemplates(null);
    loadAttributes();
  }, []);

  useEffect(() => {
    if (watchAllFields?.template) {
      setTemplate(watchAllFields?.template);
    }
  }, [watchAllFields]);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownCount, setDropdownCount] = useState(1);
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const newValueRef = useRef("");

  const [resetAddFilter, setResetAddFilter] = useState(false);

  const handleAddIconClick = () => {
    setResetAddFilter(true);
  };

  const [resetFilter, setResetFilter] = useState(false);

  const handleResetFilter = () => {
    setResetFilter(true);
    loadContacts();
  };

  const handleDropdownChange = (newValue) => {
    setSelectedOption(newValue?.value || "all");
    setSelectedRows([]);
    // setDropdownCount(1);
    setDropdown([]);
    setSelectedAttribute("");
    setSelectedType("");
    setAllContacts([]);
    setAllCampaigns([]);
    setReTargetOption([]);
    //setSelectedOption(e.target.value);
    setShowDropdown(false);
  };

  const [analytic, setAnalytic] = useState([]);
  const [targetCount, setTargetCount] = useState(0);
  const [reTargetOption, setReTargetOption] = useState({
    campaign: null,
    type: null,
  });

  const campaignContact = (event, rowId) => {
    setReTargetOption((prevState) => ({
      ...prevState,
      campaign: rowId,
    }));
    if (rowId) {
      campaignAnalytics(rowId).then((res) => {
        const resResults = res?.message_count;
        setAnalytic(resResults);
      });
    }
  };
  let lastAppliedFilters = null;
  const [dropdown, setDropdown] = useState([]);
  const handleInfiniteScroll = () => {
    const params = dropdown
      .filter((item) => {
        return Object.values(item.data).some((value) => value !== "");
      })
      .map((item) => {
        return `attribute=${item.data.selectedAttribute}&type=${item.data.selectedType}&value=${item.data.selectedValue}&attrType=${item.data.attrType}`;
      })
      .join("&");
    console.log(params);
    if (params !== "") {
      const hasMorePages = contacts?.length < fetchMoreContacts?.count;
      if (hasMorePages || dropdownsChanged) {
        loadContacts(params, true, dropdownsChanged);
        dropdownsChanged = false;
      }
    } else {
      loadContacts(null, true);
    }
  };

  const handleFilterUpdate = (filterDropdown) => {
    setDropdown(filterDropdown);
    dropdownsChanged = true;
    handleInfiniteScroll();
  };

  const [ufileName, setuFileName] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target.result);
        setuFileName(file.name);
      };
      reader.readAsDataURL(file);
    } else {
      setFile(null);
      setuFileName(null);
    }
  };
  const [showFilterData, setShowFilterData] = useState(true);
  const handleDownloadSample = () => {
    downloadSampleFile(selectedTemplateId)
      .then((data) => {
        const url = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.download = "sample.xlsx";
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  let table = (
    <div className="chatContact" id="scrollableDiv">
      <InfiniteScroll
        dataLength={contacts?.length || fetchMoreContacts?.count || 0}
        next={handleInfiniteScroll}
        hasMore={
          selectedOption === "filter"
            ? filteredContacts?.length < fetchMoreContacts?.count
            : contacts?.length < fetchMoreContacts?.count
        }
        style={{ display: "flex", flexDirection: "column" }}
        // loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>--*--</b>
          </p>
        }
        scrollableTarget="scrollableDiv"
      >
        <TableContainer
          component={Paper}
          style={{
            width: "auto",
            // overflowY: "auto",
            // maxHeight: "430px",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Checkbox
                    checked={selectedOption === "all"}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell align="left">name</TableCell>
                <TableCell align="right">Phone Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts !== null &&
                contacts?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">
                      <Checkbox
                        checked={
                          selectedContacts?.some(
                            (selectedRow) => selectedRow.id === row.id
                          ) ||
                          selectedOption === "all" ||
                          selectedOption === "filter" ||
                          selectedOption === "Retarget"
                        }
                        onChange={() => handleRowSelect(row)}
                      />
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.phone_number}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </div>
  );
  let dropdowns;
  if (selectedOption === "all") {
    dropdowns = null;
  } else if (selectedOption === "filter") {
    dropdowns = (
      <CustomFilter
        attributes={attributes}
        handleParam={handleInfiniteScroll}
        onUpdateFilter={handleFilterUpdate}
        resetFilter={resetFilter}
        resetFilterFunction={() => setResetFilter(false)}
        resetAddFilter={resetAddFilter}
        resetAddFilterFunction={() => setResetAddFilter(false)}
      />
    );
  } else if (selectedOption === "Retarget") {
    dropdowns = (
      <div>
        <InputLabel
          htmlfor="campaigns"
          sx={{
            marginLeft: mobileScreen ? "0" : "178px",
          }}
        >
          Select Campaign
        </InputLabel>
        <Controller
          name="campaigns"
          control={control}
          defaultValue={null}
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              value={value}
              options={allCampaigns ? allCampaigns : []}
              getOptionLabel={(option) => option?.name}
              onChange={(e, data) => {
                const selectedCampaignId = data?.id || null;
                campaignContact(e, selectedCampaignId);
                onChange(data);
              }}
              size="small"
              loadingText="Loading..."
              noOptionsText="No options"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: mobileScreen ? "100%" : "350px",
                    margin: mobileScreen ? "0" : "10px 4px",
                    float: "right",
                  }}
                  error={Boolean(errors.template)}
                  helperText={errors.template && "Campaign is required"}
                  placeholder="Select From the List"
                />
              )}
            />
          )}
          rules={{ required: "Campaign is required" }}
        />

        <InputLabel
          htmlFor="responseStatus"
          sx={{
            marginLeft: mobileScreen ? "0" : "178px",
            marginTop: mobileScreen ? "70px" : "16px",
          }}
        >
          Contact Type
        </InputLabel>

        <Controller
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              size="small"
              value={value}
              options={[
                { label: "Responded", value: "responded" },
                { label: "Not Responded", value: "not_responded" },
              ]}
              getOptionLabel={(option) => option.label || ""}
              onInputChange={(e, newInputValue) => {
                if (newInputValue === "Responded") {
                  setTargetCount(analytic.replied);
                } else {
                  setTargetCount(analytic.sent - analytic.replied);
                }
              }}
              onChange={(e, data) => {
                onChange(data);
                setReTargetOption((prevState) => ({
                  ...prevState,
                  type: data.label,
                }));
              }}
              loadingText="Loading..."
              noOptionsText="No options"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: mobileScreen ? "100%" : "350px",
                    margin: mobileScreen ? "0" : "10px 4px",
                    float: "right",
                  }}
                  placeholder="Select From the List"
                />
              )}
            />
          )}
          rules={{ required: "Response Status is required" }}
          name="responseStatus"
          control={control}
        />
      </div>
    );
  } else if (selectedOption === "upload") {
    dropdowns = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "30px",
          gap: "10px",
        }}
      >
        <input
          accept=".xlsx, .csv"
          type="file"
          id="upload-media"
          style={{ display: "none" }}
          onChange={handleFileChange}
          name="file"
        />
        <div>
          <label htmlFor="upload-media">
            <Button variant="contained" component="span">
              Upload File
            </Button>
          </label>
          <p>{ufileName}</p>
        </div>

        <p
          onClick={handleDownloadSample}
          style={{
            color: "#de4467",
            textDecoration: "none",
            cursor: "pointer",
            textWrap: "wrap",
          }}
        >
          Download Sample
        </p>
      </div>
    );
  } else if (selectedOption === "specific") {
    dropdowns = (
      <div sx={{ marginBottom: "60px" }}>
        <Controller
          name="Search"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="campaignName"
              variant="outlined"
              fullWidth
              sx={{ marginTop: "30px" }}
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                debouncedSearch(e.target.value);
                if (!e.target.value) {
                  loadContacts();
                }
              }}
            />
          )}
        />
      </div>
    );
  }
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    if (watchAllFields.template === null) {
      loadTemplates();
    }
  }, [watchAllFields.template]);
  return (
    <>
      <Modal
        disablePortal={true}
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={mobileScreen ? mobileModal : modal}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p className="modalTitle">New Campaign</p>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <div style={mobileScreen ? mobileContent : modalContent}>
            <div style={mobileScreen ? mobileCtr1 : modalCtr1}>
              {step === 1 && (
                <FormProvider {...methods}>
                  <form>
                    <InputLabel
                      for="campaignName"
                      sx={{ marginBottom: "12px", color: "#303535" }}
                    >
                      Campaign Name
                    </InputLabel>

                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          sx={{ marginBottom: "30px" }}
                          error={Boolean(errors.campaignName)}
                          variant="outlined"
                          helperText={
                            errors?.campaignName && "Campaign Name is required"
                          }
                          placeholder="For Example : Pongal Campaign"
                        />
                      )}
                      rules={{ required: true }}
                      name="campaignName"
                      control={control}
                    />

                    <div className="inPutLableCtr">
                      <InputLabel
                        htmlfor="template"
                        sx={{ marginBottom: "12px", color: "#303535" }}
                      >
                        Select Template
                      </InputLabel>
                      <Button
                        className="createNewCamp"
                        onClick={handleOpenNewTemplate}
                      >
                        <AddIcon /> <p>Create New Template</p>
                      </Button>
                    </div>
                    {/* <Controller
                    rules={{ required: "Template is required" }}
                    name="template"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        options={options}
                        styles={customSelectStyles}
                        placeholder="Select From the List"
                        value={options.find(
                          (v) =>
                            v.value === formData?.template || v.value === value
                        )}
                        onChange={(val) => {
                          onChange(val.value);
                        }}
                      />
                    )}
                  /> */}
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={templates ? templates : []}
                          loading={loadingTemplates}
                          getOptionLabel={(option) => option?.name}
                          onInputChange={(e, newInputValue) => {
                            debouncedTemplates(newInputValue);
                          }}
                          size="small"
                          onChange={(e, data) => {
                            setSelectedTemplateId(data ? data.id : null);
                            loadTemplate(data ? data.id : null);
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.template)}
                              helperText={
                                errors.template && "Template is required"
                              }
                              placeholder="Select From the List"
                            />
                          )}
                        />
                      )}
                      rules={{ required: "Template is required" }}
                      name="template"
                      control={control}
                    />
                    <>
                      {mobileScreen ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            paddingTop: "10px",
                            margin: "10px 0",
                          }}
                        >
                          {watchAllFields?.template?.name ? (
                            <>
                              {preview ? (
                                <>
                                  <div className="preview">
                                    <div className="previewMCtr">
                                      {preview.header &&
                                      preview.header.includes("http") &&
                                      preview.header.includes(".pdf") ? (
                                        <>
                                          <a
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginTop: "5px",
                                              paddingTop: "5px",
                                              borderTop: "1px solid #f2f2f2",
                                            }}
                                            href={preview.header}
                                            target="_blank"
                                            name="header"
                                          >
                                            <PictureAsPdfIcon />
                                            <p
                                              style={{
                                                textWrap: "wrap",
                                                overflowWrap: "break-word",
                                                wordBreak: "break-all",
                                              }}
                                            >
                                              {fileName}
                                            </p>
                                          </a>
                                        </>
                                      ) : preview.header &&
                                        preview.header.includes("http") ? (
                                        <Avatar
                                          src={preview.header}
                                          alt="Image Preview"
                                          style={{ marginTop: "10px" }}
                                          name="header"
                                        />
                                      ) : (
                                        <p>{preview.header}</p>
                                      )}
                                      <p style={{ whiteSpace: "pre-wrap" }}>
                                        {parseFormattedText(preview?.body)}
                                      </p>
                                      <p>{preview.footer}</p>
                                      {ifCallTOAction(preview.buttons)}
                                    </div>
                                  </div>
                                  {ifQuickreply(preview.buttons)}
                                </>
                              ) : (
                                <p>Loading...</p>
                              )}
                            </>
                          ) : (
                            <p style={{ textAlign: "center" }}>
                              Select Template to preview
                            </p>
                          )}
                        </div>
                      ) : null}
                    </>
                    <div
                      style={
                        mobileScreen
                          ? {
                              display: "flex",
                              justifyContent: "center",
                            }
                          : {}
                      }
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        sx={BtnCss}
                        onClick={handleSubmit((data) => {
                          setFormData(data);
                          setStep(variables.length === 0 ? 3 : 2);
                          loadContacts();
                          setSelectedOption("all");
                        })}
                      >
                        Proceed
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              )}
              {step === 2 && (
                <div>
                  <p
                    style={{
                      marginBottom: "20px",
                      fontStyle: "italic",
                      marginRight: mobileScreen ? "0" : "-300px",
                    }}
                  >
                    Use this section to customize variables for this campaign.
                    If values are not provided here, corresponding values will
                    be taken from the contact
                  </p>
                  <div
                    style={{
                      maxHeight: "70%",
                      overflow: "auto",
                      padding: "7px",
                    }}
                  >
                    {variables.map((variable, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "15px",
                          display: mobileScreen ? "block" : "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <InputLabel style={{ marginTop: "10px" }}>
                          {variable}
                        </InputLabel>
                        <TextField
                          size="small"
                          placeholder={`Enter ${variable}`}
                          onChange={(e) => {
                            addVariableData(variable, e.target.value);
                          }}
                        />
                      </div>
                    ))}
                  </div>

                  <Button
                    variant="contained"
                    className="submitBtn"
                    type="submit"
                    sx={{
                      ...BtnCss,
                      width: mobileScreen ? "30%" : "100%",
                    }}
                    onClick={handleSubmit((data) => {
                      setFormData(data);
                      setStep(3);
                      loadContacts();
                      setSelectedOption("all");
                    })}
                    disabled={variableData.length <= 0}
                  >
                    Proceed
                  </Button>
                </div>
              )}
              {step === 3 && (
                <FormProvider {...methods}>
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <InputLabel htmlFor="selectOption">Filter</InputLabel>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              size="small"
                              value={
                                menuOptions.find(
                                  (option) => option.value === selectedOption
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                field.onChange(newValue?.value || "all");
                                handleDropdownChange(newValue);
                                if (newValue?.value === "all") {
                                  setSelectedRows([...contacts]);
                                }
                                setShowFilterData(true);
                              }}
                              options={menuOptions}
                              getOptionLabel={(option) => option.label || ""}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value
                              }
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  style={{
                                    width: mobileScreen ? "100%" : "350px",
                                  }}
                                  name="selectOption"
                                />
                              )}
                            />
                          )}
                          rules={{ required: true }}
                          name="selectOption"
                          control={control}
                        />
                      </div>
                    </div>
                    {selectedOption !== "upload" && (
                      <div>
                        {selectedOption !== "filter" &&
                        selectedOption !== "Retarget" ? (
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "17px",
                              color: "#007913",
                            }}
                          >
                            {selectedOption === "specific"
                              ? selectedContacts?.length
                              : totalContactCount}{" "}
                            Contacts Selected
                          </p>
                        ) : (
                          <div>
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                lineHeight: "17px",
                                color: "#007913",
                              }}
                            >
                              {selectedOption == "filter"
                                ? `${fetchMoreContacts.count} Contacts Selected`
                                : `${targetCount} Contacts Selected`}
                            </p>
                            <a
                              href="#"
                              style={{
                                fontWeight: "500",
                                fontSize: "15px",
                                lineHeight: "17px",
                                color: "#de4467",
                                textDecoration: "none",
                                cursor: "pointer",
                                float: "right",
                              }}
                              onClick={() => {
                                setShowFilterData(!showFilterData);
                                if (selectedOption == "Retarget") {
                                  RetargetCampaignConatct();
                                }
                              }}
                            >
                              {showFilterData
                                ? "Click to View"
                                : "Back to Filter"}
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                    <>
                      {(selectedOption === "specific" ||
                        selectedOption === "upload") &&
                        dropdowns}
                    </>

                    {step === 3 && mobileScreen && (
                      <div style={{ marginTop: "40px" }}>
                        {selectedOption === "filter" ? (
                          showFilterData ? (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginBottom: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{ marginBottom: "8px" }}
                                  size="small"
                                  onClick={handleAddIconClick}
                                >
                                  ADD NEW FILTER
                                </Button>
                              </div>
                              {dropdowns}
                            </div>
                          ) : filteredContacts !== null &&
                            filteredContacts !== undefined ? (
                            table
                          ) : null
                        ) : selectedOption === "Retarget" ? (
                          showFilterData ? (
                            <>{dropdowns}</>
                          ) : (
                            <>
                              {/* {RetargetCampaignConatct()} */}
                              {table}
                            </>
                          )
                        ) : selectedOption === "all" ||
                          selectedOption === "upload" ? (
                          <div></div>
                        ) : contacts !== null && contacts !== undefined ? (
                          table
                        ) : null}
                      </div>
                    )}

                    <div
                      style={
                        mobileScreen
                          ? {
                              position: "relative",
                              Top: "100px",
                              marginTop: "40px",
                            }
                          : { position: "absolute", bottom: "70px" }
                      }
                    >
                      <p>
                        <Checkbox
                          name="isEnabled"
                          checked={isScheduled}
                          onChange={onCheckboxChange}
                        />
                        Schedule Campaign
                      </p>
                      <Controller
                        name="Date"
                        control={control}
                        helperText={datehelperText != "" ? datehelperText : ""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="campaignName"
                            variant="outlined"
                            type="date"
                            fullWidth
                            disabled={!isScheduled}
                            sx={{ marginBottom: "10px" }}
                            size="small"
                            onChange={(e) => {
                              setSelectedDate(e.target.value);
                              validateDateTimeChange(
                                e.target.value,
                                selectedTime
                              );
                            }}
                          />
                        )}
                      />

                      <Controller
                        name="Time"
                        control={control}
                        helperText={timehelperText != "" ? timehelperText : ""}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="campaignName"
                            variant="outlined"
                            type="time"
                            fullWidth
                            disabled={!isScheduled}
                            sx={{ marginBottom: "30px" }}
                            size="small"
                            onChange={(e) => {
                              setSelectedTime(e.target.value);
                              validateDateTimeChange(
                                selectedDate,
                                e.target.value
                              );
                            }}
                          />
                        )}
                      />
                    </div>

                    <div
                      style={
                        mobileScreen
                          ? {
                              display: "flex",
                              justifyContent: "center",
                            }
                          : {}
                      }
                    >
                      <Button
                        variant="contained"
                        className="submitBtn"
                        //type="submit"
                        disabled={
                          isSaveDisabled ||
                          (isScheduled &&
                            (selectedDate == "" || selectedTime == "")) ||
                          (!selectedContacts?.length &&
                            selectedOption === "specific") ||
                          (selectedOption === "filter" && !dropdown)
                          // &&
                          //   !(
                          //     dropdown[0].data.selectedAttribute &&
                          //     dropdown[0].data.selectedType &&
                          //     dropdown[0].data.selectedValue
                          //   )
                        }
                        sx={{
                          ...BtnCss,
                          ...(mobileScreen && {
                            position: "relative",
                            marginTop: "auto",
                          }),
                        }}
                        onClick={() => {
                          onSubmit(formData);
                        }}
                      >
                        {isScheduled ? "Save" : "Start"}
                      </Button>
                    </div>
                  </form>
                </FormProvider>
              )}
            </div>
            <div style={mobileScreen ? {} : modalCtr2}>
              {step === 1 && !mobileScreen && (
                <div className="templateReview">
                  {watchAllFields?.template?.name ? (
                    <>
                      {preview ? (
                        <>
                          <div className="preview">
                            <div className="previewMCtr">
                              {preview.header &&
                              preview.header.includes("http") &&
                              preview.header.includes(".pdf") ? (
                                <>
                                  <a
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "5px",
                                      paddingTop: "5px",
                                      borderTop: "1px solid #f2f2f2",
                                    }}
                                    href={preview.header}
                                    target="_blank"
                                    name="header"
                                  >
                                    <PictureAsPdfIcon />
                                    <p
                                      style={{
                                        textWrap: "wrap",
                                        overflowWrap: "break-word",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      {fileName}
                                    </p>
                                  </a>
                                </>
                              ) : preview.header &&
                                preview.header.includes("http") ? (
                                <Avatar
                                  src={preview.header}
                                  alt="Image Preview"
                                  style={{ marginTop: "10px" }}
                                  name="header"
                                />
                              ) : (
                                <p>{preview.header}</p>
                              )}
                              <p style={{ whiteSpace: "pre-wrap" }}>
                                {parseFormattedText(preview?.body)}
                              </p>
                              <p>{preview.footer}</p>
                              {ifCallTOAction(preview.buttons)}
                            </div>
                          </div>
                          {ifQuickreply(preview.buttons)}
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      Select Template to preview
                    </p>
                  )}
                </div>
              )}
              {step === 2 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    className="submitBtn"
                    type="submit"
                    sx={{
                      ...BtnCss,
                      width: mobileScreen ? "30%" : "100%",
                    }}
                    onClick={handleSubmit((data) => {
                      setSelectedVariable("");
                      setVariableData([]);
                      setStep(3);
                      loadContacts();
                      setSelectedOption("all");
                    })}
                  >
                    Skip
                  </Button>
                </div>
              )}
              {step === 3 &&
                !mobileScreen &&
                (selectedOption === "filter" ? (
                  showFilterData ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          // sx={{ backgroundColor: "green", color: "white" }}
                          style={{ marginBottom: "8px" }}
                          size="small"
                          onClick={handleAddIconClick}
                        >
                          ADD NEW FILTER
                        </Button>
                      </div>
                      {dropdowns}
                    </div>
                  ) : filteredContacts !== null &&
                    filteredContacts !== undefined ? (
                    table
                  ) : null
                ) : selectedOption === "Retarget" ? (
                  showFilterData ? (
                    <>{dropdowns}</>
                  ) : (
                    <>
                      {/* {RetargetCampaignConatct()} */}

                      {table}
                    </>
                  )
                ) : selectedOption === "all" || selectedOption === "upload" ? (
                  <div></div>
                ) : contacts !== null && contacts !== undefined ? (
                  table
                ) : null)}
            </div>
          </div>
        </div>
      </Modal>
      <AddTemplate
        openNewTemplate={openNewTemplate}
        setOpenNewTemplate={setOpenNewTemplate}
      />
      {ApiLoading && (
        <div className="overlay">
          <Spinner_2 style={{ width: "80px" }} />
        </div>
      )}
    </>
  );
};

export default NewCampaign;
