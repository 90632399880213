import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, choosebtn, TextfieldCss } from "../../../Styles";
import { notify } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import {
  createStatus as apiCreateStatus,
  editStatus as apiEditStatus,
} from "../../../API/OrderStatusLayer";
// import { editProduct as apiEditProduct } from "../../../API/ProductLayer";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import { ClsBtn } from "../../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { loadTemplates as apiLoadTemplates } from "../../../API/CampaignLayer";
function AddStatus({ handleClose, open, loadStatus, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const [editId, setEditID] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState(null);

  const HandleOnClose = () => {
    reset();
    setImage(null);
    handleClose();
  };
  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };
  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    } else {
      loadTemplates();
    }
  }, 600);
  useEffect(() => {
    reset();
  }, [open]);
  const onSubmit = (data) => {
    SetApiLoading(true);
    const body = {
      status_name: data.status_name,
      message_template: data?.message_template?.id,
      is_final_status: data?.is_final_status,
    };
    if (editData) {
      apiEditStatus(editData.id, body)
        .then((res) => {
          if (res.ok) {
            handleClose();
            loadStatus(null);
            reset();
            SetApiLoading(false);
          } else {
            res?.text().then((text) => {
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    } else {
      apiCreateStatus(body)
        .then((res) => {
          if (res.ok) {
            handleClose();
            loadStatus(null);
            reset();
            SetApiLoading(false);
          } else {
            res?.text().then((text) => {
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (editData) {
      const name = editData.status_name
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase());
      setValue("status_name", name);
      setValue("is_final_status", editData?.is_final_status);
      setValue("message_template", editData?.message_template);
      // //setValue("image",image);
      // setImage(editData.image); // Set the existing image URL or base64 data
      // setImageName(editData.imageName);
      // setEditID(editData.id);
    }
  }, [editData]);
  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        style={{
          // margin: "20px 40px",
          width: "100%",
          //   minHeight: "300px",
          maxWidth: "400px",
          padding: "20px",
          // height: "100%",
        }}
      >
        <FormProvider {...methods} enctype="multipart/form-data">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Add Status</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Status Name
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      sx={{ maxWidth: "412px", marginBottom: "10px" }}
                      error={Boolean(errors.status_name)}
                      helperText={
                        errors?.status_name && "Status Name is required"
                      }
                    />
                  )}
                  rules={{ required: true }}
                  name="status_name"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Message Template
                </InputLabel>
                <Controller
                  name="message_template"
                  control={control}
                  // rules={{ required: "Template is required" }}
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      options={templates ? templates : []}
                      loading={loadingTemplates}
                      getOptionLabel={(option) => option?.name}
                      onInputChange={(e, newInputValue) => {
                        debouncedTemplates(newInputValue);
                      }}
                      onChange={(e, data) => {
                        onChange(data);
                      }}
                      loadingText="Loading..."
                      noOptionsText="No options"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(errors.message_template)}
                          onFocus={() => loadTemplates()}
                          helperText={
                            errors.message_template &&
                            " Message Template is required"
                          }
                          placeholder="Select Template"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid>
                <Controller
                  name="is_final_status"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={onChange}
                          name="Checkbox"
                        />
                      }
                      label="Completed Status"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Button variant="contained" fullWidth sx={btnCss} type="submit">
                  {editData ? "Update" : "Add"} Status
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </Paper>
    </Modal>
  );
}

export default AddStatus;
