import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Fab,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { sendFile } from "../../../../API/ChatLayer";
export default function FileUpload({ fetchMSGData, selectedChat, wpDetails }) {
  const allowedTypes = [
    "audio/aac",
    "audio/mp4",
    "audio/mpeg",
    "audio/amr",
    "audio/ogg",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "text/plain",
    "application/msword",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/jpeg",
    "image/png",
    "video/3gp",
    "video/mp4",
    "image/webp",
  ];
  const sizeLimits = {
    "audio/aac": 16 * 1024 * 1024, //16MB
    "audio/mp4": 16 * 1024 * 1024,
    "audio/mpeg": 16 * 1024 * 1024,
    "audio/amr": 16 * 1024 * 1024,
    "audio/ogg": 16 * 1024 * 1024,
    "application/pdf": 100 * 1024 * 1024,
    "application/vnd.ms-powerpoint": 100 * 1024 * 1024,
    "text/plain": 100 * 1024 * 1024,
    "application/msword": 100 * 1024 * 1024,
    "application/vnd.ms-excel": 100 * 1024 * 1024,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      100 * 1024 * 1024,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      100 * 1024 * 1024,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      100 * 1024 * 1024,
    "image/jpeg": 5 * 1024 * 1024,
    "image/png": 5 * 1024 * 1024,
    "video/3gp": 16 * 1024 * 1024,
    "video/mp4": 16 * 1024 * 1024,
    "image/webp": 100 * 1024,
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateFile(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const validateFile = (file) => {
    if (file && allowedTypes.includes(file.type)) {
      const maxSizeInBytes = sizeLimits[file.type];

      if (file.size <= maxSizeInBytes) {
        setSelectedFile(file);
        setErrorMessage("");
      } else {
        setSelectedFile(null);
        setErrorMessage("File size exceeds the limit.");
      }
    } else {
      setSelectedFile(null);
      setErrorMessage("Unsupported file type.");
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
    setErrorMessage("");
  };

  const handleUpload = () => {
    // console.log(selectedFile);
    closeDialog();
  };

  const handleClickSelect = () => {
    fileInputRef.current.click();
  };

  const handleSendFile = () => {
    if (selectedFile) {
      sendFile(selectedChat, wpDetails, selectedFile)
        .then((res) => {
          return fetchMSGData();
        })
        .then((res) => {
          closeDialog();
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setErrorMessage("Please select a file.");
    }
  };

  return (
    <div>
      <Fab
        sx={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          margin: "10px 0",
        }}
        onClick={openDialog}
      >
        <AttachFileIcon />
      </Fab>
      {/* <IconButton
        aria-label="AttachFile"
        sx={{
          position: "absolute",
          right: "5px",
          mt: "5px",
          h: "26px",
        }}
      >
        <AttachFileIcon />
      </IconButton> */}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Send File</DialogTitle>
        <DialogContent>
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}

          {selectedFile ? (
            <div className="DnD_container" onClick={handleClickSelect}>
              <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <p>
                <strong>Click to change file</strong>{" "}
              </p>
              <p>
                <strong>Selected File:</strong> {selectedFile.name}
              </p>
            </div>
          ) : (
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className="DnD_container"
              onClick={handleClickSelect}
            >
              <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <Typography variant="body2">
                Drag & Drop or Click to select
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendFile} disabled={!selectedFile}>
            Send
          </Button>
          <Button onClick={closeDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
