import {
  Button,
  Grid,
  TextField,
  Box,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import React from "react";
import "./Login.css";
import {
  apiFetch,
  GOOGLE_CLIENT_ID,
  CLIENT_ID,
  FACEBOOK_APP_ID,
  JS_ORIGIN,
  REDIRECT_URI,
} from "../../API/CommonApi";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import { useAuth } from "../../Context/Auth";
import { notify } from "../Common";
import {
  login as apiLogin,
  social as apiSocial,
  facebookLogin as apiFacebookLogin,
} from "../../API/AuthLayer";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import UseWindowSize from "../UseWindowSize";
import { useGoogleLogin } from "@react-oauth/google";
import { isMobile } from "../Common";
export default function LoginForm() {
  const [ScreenWidth, screenHeight] = UseWindowSize();
  const navigate = useNavigate();
  const auth = useAuth();
  const [apiErrors, setErrors] = useState();
  const methods = useForm();
  const { handleSubmit, reset, control, setValue, watch, formState } = methods;
  const { errors } = formState;
  const [ApiLoading, SetApiLoading] = useState(false);
  const SocialLoginBtnCss = {
    color: "#303535",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    background: "#ffff",
    width: "200px",
    height: "50px",
    border: " 1px solid #E8E8E8",
    borderRadius: "5px",
  };
  const onSubmit = (data) => {
    SetApiLoading(true);
    setErrors(null);
    apiLogin(data?.email, data?.password)
      .then((data) => {
        if ("access_token" in data) {
          auth.login(data);
          apiFetch("GET", "user-details/", null, true)
            .then((res) => {
              if (res.ok) {
              }
              return res.json();
            })
            .then((data) => {
              const dataString = JSON.stringify(data);
              localStorage.setItem("UserData", dataString);
            });
          notify("success", "Successful login");
          navigate("/conversations", { replace: true });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notify("error", "Something went wrong");
        SetApiLoading(false);
      });
  };
  // For Google Login

  // const googleLoginFN = useGoogleLogin({
  //   flow: "auth-code",
  //   onSuccess: (codeResponse) => {
  //     console.log("codeResponse", codeResponse);
  //     console.log("codeResponse", codeResponse.code);
  //     const accessCode = codeResponse?.code;
  //     if (accessCode) {
  //       apiSocial("google", accessCode)
  //         .then((data) => {
  //           if ("access_token" in data) {
  //             auth.login(data);
  //             notify("success", "Successful login");
  //             navigate("/conversations", { replace: true });
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //           notify("error", "Something went wrong");
  //           SetApiLoading(false);
  //         });
  //     }
  //     SetApiLoading(false);
  //   },
  //   onError: (errorResponse) => {
  //     notify("error", "Something went wrong");
  //     console.log("errorResponse", errorResponse);
  //     SetApiLoading(false);
  //   },
  //   onNonOAuthError: (nonOAuthError) => {
  //     notify("error", "Something went wrong");
  //     console.log("nonOAuthError", nonOAuthError);
  //     SetApiLoading(false);
  //   },
  // });

  // For FaceBook LoginIN
  const FacebookLoginApi = () => {
    SetApiLoading(true);
    if (window.FB) {
      window.FB.login(function (response) {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          if (accessToken) {
            apiFacebookLogin(accessToken)
              .then((data) => {
                if ("access_token" in data) {
                  auth.login(data);

                  notify("success", "Successful login");
                  navigate("/conversations", { replace: true });
                } else {
                  SetApiLoading(false);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                notify("error", "Something went wrong");
                SetApiLoading(false);
              });
          } else {
            console.log("Error:", response);
            notify("error", "Something went wrong");
            SetApiLoading(false);
          }
        } else {
          console.log("User cancelled login .");
          SetApiLoading(false);
        }
      });
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get("code");

  if (code) {
    console.log(code);
    window.opener.postMessage({ code: code }, JS_ORIGIN);
  }

  const googleLoginFN = () => {
    const googleAuthUrl =
      "https://accounts.google.com/o/oauth2/v2/auth" +
      `?redirect_uri=${REDIRECT_URI}` +
      "&prompt=consent" +
      "&response_type=code" +
      `&client_id=${CLIENT_ID}` +
      "&scope=openid%20email%20profile" +
      "&access_type=offline";
    console.log(CLIENT_ID);
    const popup = window.open(googleAuthUrl, "_blank", "width=500,height=600");
    const checkPopupInterval = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupInterval);
        const urlSearchParams = new URLSearchParams(popup.location.search);
        const code = urlSearchParams.get("code");
        console.log(code);
        if (code) {
          apiSocial("google", code)
            .then((data) => {
              if ("access_token" in data) {
                auth.login(data);
                console.log(auth);
                const dataString = JSON.stringify(data);
                localStorage.setItem("UserData", dataString);
                notify("success", "Successful login");
                navigate("/conversations", { replace: true });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              notify("error", "Something went wrong");
              SetApiLoading(false);
            });
        }
      }
    }, 1000);
  };

  const [showPassword, setShowpwd] = useState(false);
  const handleClickShowPassword = () => {
    setShowpwd(!showPassword);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      methods.handleSubmit(onSubmit)();
    }
  };
  return (
    <div
      style={{
        padding: "auto",
        margin: isMobile() ? "60px 10px 0 10px" : "30px 0 0 0",
      }}
    >
      <Box container>
        <FormProvider {...methods}>
          <form onKeyDown={handleKeyDown} onSubmit={handleSubmit(onSubmit)}>
            <p className="heading" style={{ marginBottom: "8px" }}>
              Our Partners Login{" "}
            </p>
            <p style={{ marginBottom: "38px", width: "auto" }}>
              Hey, Enter your details to get sign in to your account
            </p>
            <Grid item xs={12} md={10}>
              {apiErrors?.non_field_errors ? (
                <p style={{ color: "red", margin: "10px 20px" }}>
                  {apiErrors?.non_field_errors}
                </p>
              ) : (
                ""
              )}
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your Email *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    // required
                    type={"email"}
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px", marginBottom: "10px" }}
                    error={Boolean(errors.email)}
                    helperText={errors.email && "Email is required"}
                  />
                )}
                rules={{ required: true }}
                name="email"
                control={control}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your Password *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    fullWidth
                    sx={{ maxWidth: "412px" }}
                    size="small"
                    type={showPassword ? "text" : "password"}
                    // value={values.password}
                    // onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={Boolean(errors.password)}
                  />
                )}
                rules={{ required: true }}
                name="password"
                control={control}
              />
              {errors.password && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  This field is required
                </p>
              )}
            </Grid>
            <Grid item xs={12} md={10}>
              <p>
                <Controller
                  render={({ field }) => <Checkbox {...field} />}
                  name="remember-my-password"
                  control={control}
                />
                &nbsp;Remember my password
              </p>
            </Grid>
            <Grid item xs={12} md={10}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  maxWidth: "412px",
                  padding: "16px 0 15px 0",
                  marginTop: "25px",
                  background:
                    "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                  borderRadius: " 5px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "19px",
                  textAlign: "center",
                  letterSpacing: "0.05em",
                  marginBottom: "3px",
                }}
                type="submit"
              >
                Sign in
              </Button>
              {/* <Link
              to="/resetPassword"
              className="no-underline"
              // style={{ marginTop : "20px" }}
            >
              <p
                style={{
                textAlign: "right",
                marginBottom: "20px",
                color:"black"
              }}
              >Forgot Password?</p>
              </Link> */}
            </Grid>

            <p
              style={{
                textAlign: "center",
                maxWidth: "412px",
                margin: "20px",
              }}
            >
              ━ Or Sign in with ━
            </p>
            <div
              style={{
                // maxWidth: "512px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <Button
                sx={SocialLoginBtnCss}
                onClick={() => {
                  SetApiLoading(true);
                  googleLoginFN();
                }}
              >
                <GoogleIcon />
                &nbsp;Google
              </Button>

              <Button sx={SocialLoginBtnCss} onClick={FacebookLoginApi}>
                <FacebookRoundedIcon />
                &nbsp;Facebook
              </Button>
            </div>

            <Link
              to="/signup"
              className="no-underline"
              // style={{ marginTop : "20px" }}
            >
              <p className="Link">Don't have an account? Sign up here</p>
            </Link>
          </form>
        </FormProvider>
      </Box>

      {ApiLoading && (
        <div className="overlay">
          <Spinner_2 style={{ width: "80px" }} />
        </div>
      )}
    </div>
  );
}
