import React, { useState } from "react";
import { Grid, Menu, MenuItem, Button } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import NewCampaign from "../Modal/NewCampaign";
const BtnCss = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "14px",
  padding: "0 15px",
  height: "35px",
  textAlign: "center",
  position: "absolute",
  top: "75px",
  right: "40px",
};
function OverView() {
  const [selectedOption, setSelectedOption] = useState("week");
  const [open, setOpen] = React.useState(false);
  const [tempRowData, setRowData] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <div className="mainCtr">
      <Grid container>
        <Grid item xs={10}>
          <h3 className="title"> Overview</h3>
          <p className="subTitle">Let’s see an overview of your customers</p>
        </Grid>
        <div>
          <Button variant="contained" sx={BtnCss} onClick={handleClickOpen}>
            <AddIcon /> New Campaign
          </Button>
        </div>
        <Grid xs={2}>
          {/* <span className="Sortby">
            <label for="cars">View:</label>
            <p>{selectedOption}</p>
            <select name="view" value={selectedOption}>
              <option value="today">Today</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
            </select>
          </span> */}
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <div className="layoutbox"> </div>
        </Grid>
        <Grid item container="true" xs={6} spacing={4}>
          <Grid item xs={6}>
            <div className="layoutbox2"> </div>
          </Grid>
          <Grid item xs={6}>
            <div className="layoutbox2"> </div>
          </Grid>
          <Grid item xs={6}>
            <div className="layoutbox2"> </div>
          </Grid>
          <Grid item xs={6}>
            <div className="layoutbox2"> </div>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <div className="layoutbox2"> </div>
        </Grid>
        <Grid item xs={3}>
          <div className="layoutbox2"> </div>
        </Grid>
        <Grid item xs={3}>
          <div className="layoutbox2"> </div>
        </Grid>
        <Grid item xs={3}>
          <div className="layoutbox2"> </div>
        </Grid>
      </Grid>
      <NewCampaign
        open={open}
        setOpen={setOpen}
        editData={tempRowData}
        setRowData={setRowData}
      />
    </div>
  );
}

export default OverView;
