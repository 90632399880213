import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Checkbox,
  Paper,
  Autocomplete,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { btnCss, ClsBtn } from "../../../Styles";
import PhoneInput from "react-phone-input-2";
import { getRoles } from "../../../API/UserManagementLayer";
import { addUser } from "../../../API/UserManagementLayer";
import { get, set } from "lodash";
import { notify } from "../../Common";
import { updateUser } from "../../../API/UserManagementLayer";

function AddUsers({ open, handleClose, loadUsers, editData }) {
  const [roles, setRoles] = useState([]);
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;

  const { errors } = formState;

  const HandleOnClose = () => {
    reset();
    handleClose();
  };

  const onsubmit = (data) => {
    data = {
      first_name: data.name,
      email: data.email,
      username: data.email,
      role: data.role.id,
      phone_number: `+${data.phone_number}`,
    };
    addUser(data)
      .then((res) => {
        notify("success", "User Added Successfully");
        loadUsers();
        reset();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUser = (data) => {
    console.log("editData", data);
    data = {
      id: editData.id,
      first_name: data.name,
      email: data.email,
      username: data.email,
      role: data.role ? data.role.id : null,
      phone_number: `+${data.phone_number}`,
    };

    updateUser(data)
      .then((res) => {
        notify("success", "User Updated Successfully");
        loadUsers();
        reset();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const loadRoles = async () => {
    getRoles()
      .then((res) => {
        const rolesData = res?.results.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setRoles(rolesData);
        for (let i = 2; i <= res.total_pages; i++) {
          getRoles(`page=${i}`)
            .then((res) => {
              const rolesData = res?.results.map((item) => ({
                id: item.id,
                name: item.name,
              }));
              setRoles((prev) => [...prev, ...rolesData]);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (editData) {
      console.log("eeeeeeeeeeee", editData);
      setValue("name", editData.first_name);
      setValue("email", editData.username);
      setValue("phone_number", editData.phone_number);
      const selectedRole = roles.find((role) => role.id === editData.role);
      console.log("selectedRole", selectedRole);
      setValue("role", selectedRole || null);
    }
  }, [editData, open, roles]);

  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        // style={{
        //     margin: "20px 40px",
        //     minWidth: "400px",
        //     //   minHeight: "300px",
        //     padding: "20px",
        // }}
        className="modal"
      >
        <FormProvider {...methods}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{editData ? "Edit User" : "Add User"}</h2>
            <CloseIcon
              style={ClsBtn}
              className="Icon2"
              onClick={HandleOnClose}
            />
          </div>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Enter Name
            </InputLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  sx={{ maxWidth: "388.58px", marginBottom: "10px" }}
                  error={Boolean(errors.job_id)}
                  helperText={errors?.job_id && "name is required"}
                />
              )}
              rules={{ required: true }}
              name="name"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Enter e-mail
            </InputLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  sx={{
                    width: "100%",
                    maxWidth: "388.58px",
                    marginBottom: "10px",
                  }}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email && "email is required"}
                />
              )}
              name="email"
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter mobile number *
              </InputLabel>

              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <div>
                    <PhoneInput
                      value={value || ""}
                      // disabled={edit}
                      onChange={onChange}
                      country={"in"}
                      inputStyle={{
                        width: "100%",
                        maxWidth: "388.58px",
                        height: "40px",
                        background: "#ffff",
                        border: "solid 1px #7C8686",
                        borderRadius: "4px",
                      }}
                      specialLabel={""}
                      buttonStyle={{
                        border: "solid 1px #7C8686",
                      }}
                    />
                  </div>
                )}
                name="phone_number"
                control={control}
                rules={{ required: true }}
              />
              {errors.phone_number && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  This Phone Number is required
                </p>
              )}
            </>
          </Grid>
          <Grid item xs={6}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Enter Role
            </InputLabel>
            <Controller
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={roles}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      sx={{ width: "100%", maxWidth: "388.58px" }}
                    />
                  )}
                  onChange={(_, value) => {
                    console.log("field", field.name);
                    console.log("value", value);
                    setValue(field.name, value ? value : null); // Set the role ID as value
                  }}
                />
              )}
              name="role"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            {editData ? (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                onClick={handleSubmit(editUser)}
              >
                Update User
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                onClick={handleSubmit(onsubmit)}
              >
                ADD User
              </Button>
            )}
          </Grid>
        </FormProvider>
      </Paper>
    </Modal>
  );
}

export default AddUsers;
