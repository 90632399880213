import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  Autocomplete,
  FormControl,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SearchIcon from "@mui/icons-material/Search";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useAuth } from "../../Context/Auth";
import { apiFetch, logOut } from "../../API/CommonApi";
// import {  } from "../Common";
import { notify, isMobile, formatDate } from "../Common";
import { AddContact } from "./AddContact";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, TextfieldCss, ClsBtn } from "../../Styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import SendMessageTemplate from "../TemplateMessage/SendTemplate";
import { loadContacts as apiLoadContacts } from "../../API/ContactLayer";
import {
  deleteAttribute as apiDeleteAttribute,
  downloadSampleFile,
} from "../../API/ContactLayer";
import { deleteContact as apiDeleteContact } from "../../API/ContactLayer";
import { checkwhatsAppBusinessAcc as WPCheck } from "../../API/AuthLayer";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ChatIcon from "@mui/icons-material/Chat";
import { loadAttributes as apiLoadAttributes } from "../../API/customAttr";
import CustomFilter from "../CustomFilter";
import { createContact as apiCreateContact } from "../../API/ContactLayer";

function Contacts() {
  const navigate = useNavigate();
  const profileUrl = "https://picsum.photos/200";
  const accessToken = JSON.parse(
    localStorage.getItem("userTokens")
  )?.access_token;
  const mobileScreen = isMobile();
  const [contacts, setContacts] = useState(null);
  const [open, setOpen] = useState(false);
  const [tempRowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [attAnchorEl, setAttAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const deleteAttDrop = Boolean(attAnchorEl);
  const [WpDetails, setWpDetails] = useState(null);
  const [editData, setEditData] = useState(null);
  // const [param, setParam] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [attData, setAttData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [allowAdd, setAllowAdd] = useState(true);
  const [btnMsg, setBtnMsg] = useState(null);
  const auth = useAuth();
  const [fetchMoreContacts, setFetchMoreContacts] = useState();
  const [totalContactCount, setTotalContactCount] = useState(0);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [resetAddFilter, setResetAddFilter] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [ufileName, setuFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [filterObjs, setFilterObjs] = useState(null);
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState("");
  const [searchKey, setSearch] = useState(null);
  const [filtersQuery, setFiltersQuery] = useState("");

  const userPermissions = auth?.userDetails?.permission;
  const handleClose = () => {
    setOpen(false);
    loadContacts();
    // setResetFilter(true);
    // setedit(null);
  };
  const handleAddorEdit = (data) => {
    setEditData(data);
    setOpen(true);
  };

  const loadAttributes = (param) => {
    apiLoadAttributes(param)
      .then((res) => {
        const attributeNamesWithType = res.results.map((attribute) => ({
          name: attribute.name,
          type: attribute.type,
          options: attribute.options,
        }));

        setAttributes(attributeNamesWithType);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const loadContacts = () => {
    const param = queryParams ? queryParams : "";
    apiLoadContacts(param)
      .then((res) => {
        setTotalContactCount(res?.count);
        setContacts(res?.results);
        setFetchMoreContacts(res);
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  // delete Attribute Chip
  const deleteAttribute = (data, attrData) => {
    apiDeleteAttribute(attrData)
      .then((res) => {
        setAttAnchorEl(null);
        loadContacts();
        notify("warn", "Attribute Deleted");
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  // wpDetails
  useEffect(() => {
    /* if (
      userPermissions?.max_contact_count === 0 ||
      totalContactCount < userPermissions?.max_contact_count
    ) {
      setAllowAdd(true);
      setBtnMsg(null);
    } else {
      setAllowAdd(false);
      setBtnMsg(
        "You have reached the maximum limit of contacts for your plan. Please upgrade to add more contacts."
      );
    } */
    WPCheck()
      .then((res) => {
        if (typeof res === "object" && res?.length !== 0) {
          auth.setWpDetails(res);
          setWpDetails(res?.[0]);
        } else {
          navigate("/whatsapp-signup");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (
      totalContactCount >= userPermissions?.max_contact_count &&
      userPermissions?.max_contact_count > 0
    ) {
      setAllowAdd(false);
      setBtnMsg(
        "You have reached the maximum limit of Campaigns for your plan. Please upgrade to add more Campaigns."
      );
    } else {
      setAllowAdd(true);
      setBtnMsg(null);
    }
  }, [contacts]);
  // delete Contact
  const deleteContact = (rowData) => {
    apiDeleteContact(rowData.id)
      .then((res) => {
        setAnchorEl(null);
        loadContacts();
        notify("warn", "Contact Deleted");
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  useEffect(() => {
    loadContacts();
    loadAttributes();
  }, [accessToken]);

  const redirectToConversation = (data) => {
    navigate(`/conversations?phone_number=${data?.phone_number}`);
  };

  const handleAddIconClicks = () => {
    setResetAddFilter(true);
  };

  const handleResetFilters = () => {
    setResetFilter(true);
    setFiltersQuery("");
    loadContacts();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target.result);
        setuFileName(file.name);
      };
      reader.readAsDataURL(file);
      const formData = new FormData();
      formData.append("contact_file", file, ufileName);
      apiCreateContact(formData).then((res) => {
        if (res.ok) {
          notify("success", "Contacts Added");
        } else {
          notify("error", "Something went wrong");
        }
        document.getElementById("upload-media").value = "";
        loadContacts();
        setFile(null);
        setuFileName(null);
      });
    } else {
      setFile(null);
      setuFileName(null);
    }
  };

  const handleDownloadSample = () => {
    downloadSampleFile(null)
      .then((data) => {
        const url = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.download = "sample.xlsx";
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      setSearch(searchKey);
    } else {
      setSearch(null);
    }
  }, 600);

  const handleFilterUpdate = (filterDropdown) => {
    const params = filterDropdown
      ?.filter((item) => {
        return Object.values(item.data).some((value) => value !== "");
      })
      .map((item) => {
        return `attribute=${item.data.selectedAttribute}&type=${item.data.selectedType}&value=${item.data.selectedValue}&attrType=${item.data.attrType}`;
      })
      .join("&");

    params ? setFiltersQuery(params) : setFiltersQuery("");
  };

  useEffect(() => {
    const params = [];
    page && params.push(`page=${page}`);
    searchKey && params.push(`search=${searchKey}`);
    filtersQuery && params.push(filtersQuery);
    const queryString = params.join("&");

    if (queryString) {
      setQueryParams(queryString);
    }
  }, [page, searchKey, filtersQuery]);

  useEffect(() => {
    loadContacts();
  }, [queryParams]);

  const columns = [
    {
      title: "Contact Name",
      field: "contactName",
      render: (rowData) => (
        <div className="column-1">
          <img src={profileUrl} className="profilePic" />
          <span>
            <p>{rowData?.name}</p>
            <p>Updated on {formatDate(rowData?.updated_on)}</p>
          </span>
        </div>
      ),
    },
    {
      title: "Contact",
      field: "contact",
      render: (rowData) => (
        <div className="column-2">
          {/* <p>
            <MailOutlineIcon className="Icon" /> ---
          </p> */}
          <p>
            <PhoneIphoneOutlinedIcon className="Icon" /> {rowData?.phone_number}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => (
        <div className="column-2">
          {/* <p>
            <MailOutlineIcon className="Icon" /> ---
          </p> */}
          <p> {rowData?.email}</p>
        </div>
      ),
    },
    {
      title: "Custom Attribute",
      field: "customAttribute",
      render: (rowData) => (
        <div
          className="column-3"
          style={{ maxWidth: "400px", justifyContent: "center" }}
        >
          {rowData?.custom_attribute.map(
            (x) =>
              x.value && (
                <>
                  <p
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CancelIcon
                      style={ClsBtn}
                      className="Icon2"
                      onClick={(event) => {
                        setRowData(rowData);
                        setAttAnchorEl(event.currentTarget);
                        setAttData(x);
                      }}
                    />
                    {x.custom_field.replace(/_/g, " ")} : {x.value}
                  </p>
                  <Menu
                    id="basic-menu"
                    anchorEl={attAnchorEl}
                    open={deleteAttDrop}
                    onClose={() => {
                      setRowData(null);
                      setAttAnchorEl(null);
                      setAttData(null);
                    }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div
                      style={{
                        padding: "5px",
                      }}
                    >
                      <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
                      <Button
                        variant="contained"
                        sx={{
                          margin: "5px",
                        }}
                        size="small"
                        onClick={() => {
                          deleteAttribute(tempRowData, attData);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          margin: "5px",
                        }}
                        onClick={() => {
                          setAttAnchorEl(null);
                          setRowData(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Menu>
                </>
              )
          )}
        </div>
      ),
    },
    {
      title: "Created Date",
      field: "createdDate",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.created_on)}</p>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <ChatIcon
              className="Icon"
              onClick={() => {
                // setedit(rowData);
                redirectToConversation(rowData);
              }}
            />
          </p>
          <p>
            <FontAwesomeIcon
              icon={faPaperPlane}
              className="Icon"
              onClick={() => {
                setPhoneNumber(rowData?.phone_number);
                openDialog();
              }}
            />
          </p>
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                // setedit(rowData);
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              aria-controls="basic-menu"
              aria-haspopup="true"
              className="Icon"
              onClick={(event) => {
                setRowData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setRowData(null);
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>

              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  deleteContact(tempRowData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setRowData(null);
                }}
              >
                Cancel
              </Button>
            </div>
            {/* <MenuItem
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              Logout
            </MenuItem> */}
          </Menu>
        </div>
      ),
    },
  ];
  return (
    <>
      <div>
        <div className="pageInNav">
          <p className="pageH">Contacts</p>
          <div className="inNavCtr">
            {/* <Button
              variant="outlined"
              size="small"
              sx={{
                marginRight: "35px",
              }}
            >
              DOWNLOAD EXCEL FILE
            </Button> */}
            <Tooltip title={btnMsg}>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleAddorEdit(null);
                  }}
                  disabled={!allowAdd}
                  sx={btnCss}
                >
                  ADD CONTACT
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="table">
          <div className="customToolbar">
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: mobileScreen ? "250px" : "315px",
                }}
              >
                <TextField
                  style={TextfieldCss}
                  // onKeyDown={handleKeyDown}
                  size="small"
                  variant="outlined"
                  placeholder="Search  "
                  type="search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    debouncedSearch(e.target.value);
                    if (!e.target.value) {
                      loadContacts();
                    }
                  }}
                />
              </div>
              {showFilterBox ? (
                <>
                  <IconButton
                    sx={{ color: "#de4467" }}
                    onClick={() => {
                      handleResetFilters();
                      setShowFilterBox(!showFilterBox);
                    }}
                  >
                    <FilterAltOffIcon />
                  </IconButton>
                  <Button
                    variant="text"
                    onClick={handleResetFilters}
                    style={{ textTransform: "capitalize" }}
                  >
                    Reset Filter
                  </Button>
                </>
              ) : (
                <IconButton
                  sx={{ color: "#de4467" }}
                  onClick={() => {
                    handleResetFilters();
                    setShowFilterBox(!showFilterBox);
                  }}
                >
                  <FilterAltIcon />
                </IconButton>
              )}
            </div>

            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                flexWrap: "wrap",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button
                variant="text"
                onClick={handleDownloadSample}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 550,
                  maxWidth: "200px",
                }}
              >
                Download Sample
              </Button>
              <input
                accept=".xlsx, .csv"
                type="file"
                id="upload-media"
                style={{ display: "none" }}
                onChange={handleFileChange}
                name="file"
              />
              <div>
                <label htmlFor="upload-media">
                  <Button variant="contained" component="span">
                    Upload File
                  </Button>
                </label>
                <p>{ufileName}</p>
              </div>
            </div>
          </div>
          {showFilterBox && (
            <Stack
              border={"1px solid #ccc"}
              borderRadius={"5px"}
              padding={1}
              marginBottom={1}
            >
              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <span> </span>
                <Button
                  variant="text"
                  onClick={handleAddIconClicks}
                  // disabled={filterObjs.length === 5}
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "600",
                    maxWidth: "200px",
                  }}
                >
                  + Add New Filter
                </Button>
              </Stack>
              <Box justifyContent={"center"}>
                <CustomFilter
                  attributes={attributes}
                  onUpdateFilter={handleFilterUpdate}
                  resetFilter={resetFilter}
                  resetFilterFunction={() => setResetFilter(false)}
                  resetAddFilter={resetAddFilter}
                  resetAddFilterFunction={() => setResetAddFilter(false)}
                />
              </Box>
            </Stack>
          )}
          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={contacts ? contacts : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={totalContactCount}
                  page={page - 1}
                  onPageChange={(e, page) => {
                    setPage(page + 1);
                    // handleParam();
                  }}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              // selection: true,
              search: false,
              toolbar: false,
              columnWidth: "auto",
              showTitle: false,
              paging: true,
              pageSize: 10,
              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                // borderTop: "1px solid #EDDCDC",
                // BorderBottom: "1px solid #EDDCDC",
                // borderLeft: "1px solid #D0D0D0",
                // borderRight: "1px solid #D0D0D0",
                backgroundColor: "#FBFBFB",
                zIndex: 2,
              },
            }}
          />
        </div>
      </div>

      <AddContact
        WpDetails={WpDetails}
        loadContacts={loadContacts}
        handleClose={handleClose}
        editData={editData}
        open={open}
      />
      <SendMessageTemplate
        closeDialog={closeDialog}
        isOpen={isOpen}
        selectedChat={phoneNumber}
      />
    </>
  );
}

export default Contacts;
