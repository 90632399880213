import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Paper,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { Controller, FormProvider, useForm } from "react-hook-form";
import WidgetPreview from "./WidgetPreview";
import { btnCss, TextfieldCss, ClsBtn } from "../../../Styles";
import { useAuth } from "../../../Context/Auth";
import { apiFetch } from "../../../API/CommonApi";
import { saveWidgetSettings, getWidgetDetails } from "../../../API/widgetLayer";
import { notify } from "../../Common";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Widget() {
  const auth = useAuth();
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const watchAllFields = watch();
  const [scriptState, setScriptState] = useState();
  const [image, setImage] = useState(null);
  // const [imageName, setImageName] = useState(null);
  const [widgetData, setWidgetData] = useState(null);
  const [editData, SetEditData] = useState(null);
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setImage(e.target.result);
  //       setImageName(file.name);
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setImage(null);
  //     setImageName(null);
  //   }
  // };

  const onSubmit = async (data) => {
    const buttonType = window.event.submitter.name;
    const formData = new FormData();
    formData.append("brand_logo", data?.logo, data?.logo?.name);
    formData.append("phone_number", data.phone_number);
    formData.append("img_name", data?.logo?.name);
    formData.append("brand_name", data.business_name);
    formData.append("first_message", data.first_message);

    // data = Object.fromEntries(formData);
    if (buttonType === "save_widget_settings") {
      try {
        const res = editData
          ? await saveWidgetSettings(formData, editData?.id)
          : await saveWidgetSettings(formData);
        if (res?.ok) {
          const responseData = await res.json();
          setWidgetData(responseData);
          notify("success", "Widget Saved");
        } else {
          throw new Error("Failed to save widget settings");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const generateScript = (data) => {
    if (widgetData) {
      const uuid = widgetData?.uuid;
      const scriptContent = `<script>  
        var uuid="${uuid}";
    </script>
    <script src="https://pinaippu.io/widget/chat-widget.js"></script>`;

      setScriptState(scriptContent);
      navigator.clipboard.writeText(scriptContent);
    }
  };
  const { errors } = formState;
  useEffect(() => {
    if (auth.wpDetails) {
      const ph_no = auth.wpDetails[0].phone_numbers[0].display_phone_number;
      setValue("phone_number", ph_no);
      getWidgetDetails().then((res) => {
        SetEditData(res[0]);
      });
    }
  }, []);

  useEffect(() => {
    generateScript(widgetData);
  }, [widgetData]);
  useEffect(() => {
    setImage(editData?.brand_logo);
    setWidgetData(editData);
    setValue("business_name", editData?.brand_name);
    setValue("first_message", editData?.first_message);
  }, [editData]);
  useEffect(() => {
    const file = watchAllFields.logo;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
    }
  }, [watchAllFields.logo]);

  return (
    <>
      <div className="pageInNav">
        <p className="pageH">Widget</p>
        {/* <div className="inNavCtr"></div> */}
      </div>
      <div
        style={{
          minHeight: "78vh",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        <div>
          <Paper
            elevation={2}
            style={{
              padding: "20px",
              // maxWidth: "500px",
              height: "500px",
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Business Name
                  </InputLabel>
                  <Controller
                    rules={{ required: true }}
                    name="business_name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        sx={{ marginBottom: "10px" }}
                        error={Boolean(errors.business_name)}
                        helperText={
                          errors?.business_name && "Business name is required"
                        }
                      />
                    )}
                  />
                </div>
                <div>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Brand Logo
                  </InputLabel>
                  <Controller
                    rules={{
                      required: false,
                      validate: (value) => value !== null,
                    }}
                    name="logo"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <input
                          // {...field}
                          id="upload-file"
                          type="file"
                          accept="image/*"
                          // onChange={handleImageChange}
                          onChange={(e) => onChange(e.target.files[0])}
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="upload-file"
                          style={{ display: "inline-block" }}
                        >
                          <Button variant="contained" component="span">
                            Choose Image
                          </Button>
                        </label>
                      </>
                    )}
                  />
                  {editData && (
                    <p style={{ padding: "10px" }}>
                      selected:{" "}
                      <a href={editData?.brand_logo} target="_blank">
                        logo URL
                      </a>
                    </p>
                  )}
                </div>
                <div>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Phone Number
                  </InputLabel>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        // value={phoneNo}
                        value={value}
                        onChange={onChange}
                        specialLabel={""}
                        country={"in"}
                        inputStyle={{
                          width: "100%",
                          //   maxWidth: "412px",
                          height: "40px",
                          background: "#ffff",
                          border: "solid 1px #c4c4c4",
                          borderRadius: "4px",
                        }}
                        inputProps={{
                          name: "phone_number",
                          required: true,
                          // autoFocus: true,
                        }}
                        buttonStyle={{
                          border: "solid 1px #7C8686",
                        }}
                        disabled={true}
                        dropdownClass={"countryDropDownCss"}
                        // onBlur={handleValidation}
                        // defaultErrorMessage={"Invalid Phone number"}
                        isValid={(value, country) => {
                          if (value.length > 10) {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                      />
                    )}
                  />
                  {errors?.phone_number && (
                    <p
                      style={{
                        color: "#d32f2f",
                        fontSize: "12px",
                        marginLeft: "15px",
                        marginTop: "5px",
                      }}
                    >
                      Phone number is required
                    </p>
                  )}
                </div>
                <div>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    First message
                  </InputLabel>
                  <Controller
                    // rules={{ required: true }}
                    name="first_message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ marginBottom: "10px" }}
                        error={Boolean(errors.first_message)}
                        helperText={
                          errors?.first_message && "First message is required"
                        }
                      />
                    )}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    type="submit"
                    name="save_widget_settings"
                  >
                    Save Widget Settings
                  </Button>
                </div>
                <div></div>
              </form>
            </FormProvider>
          </Paper>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <WidgetPreview
            business_name={watchAllFields?.business_name}
            logo_url={image}
            phone_number={watchAllFields?.phone_number}
            first_message={watchAllFields?.first_message}
          />
        </div>
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            flexDirection: "column",
            // alignContent: "flex-start",
            // height: "800px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "9px",
              padding: "10px",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Instructions</h2>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ContentCopyIcon
                style={ClsBtn}
                className="Icon2"
                onClick={generateScript}
              />
              <p style={{ margin: "0 5px" }}>Copy Text</p>
            </div>
          </div>
          <div
            style={{
              overflow: "auto",
              border: "1px solid black",
              borderRadius: "10px",
            }}
          >
            <p
              style={{
                background: "#f2f2f2",
                padding: "15px",
                borderRadius: "10px",
                whiteSpace: "pre-line",
              }}
            >
              {scriptState
                ? scriptState
                : `<script>
                  var uuid="unique ID";
              </script>
      <script src="https://pinaippu.io/widget/chat-widget.js"></script>`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Widget;
