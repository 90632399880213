import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import Limechat from "../../Assets/Limechat.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', limechat: '$499/mo' },
    { feature: 'Number of agents', pinaippu: '10', limechat: '5' },
    { feature: 'Green Tick Application', pinaippu: 'Free', limechat: '₹ 3750' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', limechat: 'Yes' },    
    { feature: 'Smart Audience Segmentation', pinaippu: 'Yes', limechat: 'No' },
    { feature: 'Drip marketing( Sequence-based )', pinaippu: 'Yes', limechat: 'No' },
    { feature: 'Appointment booking', pinaippu: 'Advanced', limechat: 'No' },
    { feature: 'WhatsApp automation flows', pinaippu: 'Unlimited', limechat: 'Limited' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', limechat: 'Ticketing, Email, Call' },
  ];

const ComparisonBlog6 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best alternative to Limechat | WhatsApp marketing automation</title>
                <meta
                    name="description"
                    content="Discover why Pinaippu.io is the preferred WhatsApp Business API alternative to Limechat,
                    offering affordability, advanced features, and higher agent limits. Explore the Best WhatsApp
                    Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px" }}>Pinaippu Vs Limechat</p>
              <img src={Limechat} style={{ width: "67px", height: "77px" }} alt="Limechat" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Limechat', field: 'limechat' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Limechat</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Provides Click-to-WhatsApp Ads.</li>
                        <li>Offers support for Green Tick Application.</li>
                        <li>Supports ticketing, email, and calls for customer support.</li>
                        <li>Includes Click-to-WhatsApp Ads for marketing.</li>
                        <li>Offers support for WhatsApp automation flows.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Higher monthly cost compared to Pinaippu.</li>
                        <li>Limited number of agents available (5).</li>
                        <li>Does not include smart audience segmentation.</li>
                        <li>Lacks drip marketing features.</li>
                        <li>No advanced appointment booking functionality.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Affordability and Cost-Effectiveness</h3></span><br />
                Pinaippu.io emerges as a cost-effective solution by offering significantly lower pricing compared to Limechat, making it particularly suitable for small and medium-sized businesses facing budget constraints.
                 The lower pricing structure of Pinaippu.io can result in substantial cost savings for businesses over time, providing an advantageous financial edge.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Features, Limits, and Advanced Capabilities</h3></span><br />
                Pinaippu.io's plan allows for up to 10 agents, double the number offered by Limechat. This higher agent limit grants greater flexibility, especially for businesses with larger teams or those planning for future growth. 
                Moreover, Pinaippu.io introduces advanced features such as smart audience segmentation, sequence-based drip marketing, and appointment booking, enhancing personalized and efficient customer engagement—a capability that Limechat lacks.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Automation, Support, and Green Tick Application</h3></span><br />
              Pinaippu.io stands out with its provision of unlimited WhatsApp automation flows, empowering businesses to create numerous automated processes for streamlined customer interactions. Both platforms support the Green Tick Application, but Pinaippu.io offers this feature at no extra cost, while Limechat charges additionally for it. 
              Pinaippu.io also provides transparent pricing, free from hidden fees, aiding businesses in better understanding their costs—a feature lacking in Limechat's transparency.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Support and Marketing Capabilities</h3></span><br />
               Both Pinaippu.io and Limechat offer comprehensive support through ticketing, email, and calls, ensuring multiple avenues for issue resolution. 
               Additionally, both platforms support Click-to-WhatsApp Ads, a valuable tool for marketing and customer acquisition, offering businesses an effective way to engage with potential customers via WhatsApp.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog6;
