import { apiFetch } from "./CommonApi";
import { useAuth } from "../Context/Auth";
import { useEffect } from "react";
export const useChatLayer = () => {
  const {
    wpDetails,
    dropDownFlag,
    contactFlag,
    moreConversations,
    setMoreConversations,
    conversations,
    setConversations,
    setSelectedChat,
    user,
    chatSearchKey,
    phoneNumber,
    timerCount,
  } = useAuth();
  const contactChatList = (wpDetails, params = null) => {
    const url = params
      ? `whatsapp-messages/latest-messages/?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}&${params}`
      : `whatsapp-messages/latest-messages/?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}`;
    return apiFetch("GET", url, null, true).then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to Fetch");
      }
    });
  };

  const getContactChatList = async (params = null, nextPage = false) => {
    if (wpDetails) {
      if (dropDownFlag == "assigned") {
        const id = user?.user?.pk;
        params = params ? `${params}&assignee=${id}` : `assignee=${id}`;
      } else if (dropDownFlag == "is_unread") {
        params = params ? `${params}&is_unread=True` : `is_unread=True`;
      }
      if (contactFlag !== "all") {
        params = params
          ? `${params}&status=${contactFlag}`
          : `status=${contactFlag}`;
      }
      if (nextPage) {
        const next_page = parseInt(moreConversations?.current_page) + 1;
        params = params ? `${params}&page=${next_page}` : `page=${next_page}`;
      }
      if (chatSearchKey) {
        params = params
          ? `${params}&search=${chatSearchKey}`
          : `search=${chatSearchKey}`;
      }
      try {
        const res = await contactChatList(wpDetails, params);
        if (res.ok) {
          const data = await res.json();

          if (nextPage) {
            setMoreConversations(data);
            setConversations((prevState) => [...prevState, ...data?.results]);
          } else {
            const resResult = data?.results;
            if (JSON.stringify(resResult) !== JSON.stringify(conversations)) {
              setConversations(data?.results);
            }
            setMoreConversations(data);
            if (phoneNumber && data?.results.length < 2)
              setSelectedChat(data?.results[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching contact chat list:", error);
      }
    }
  };

  useEffect(() => {
    getContactChatList();
  }, [dropDownFlag, timerCount]);

  useEffect(() => {
    getContactChatList();
    setSelectedChat(null);
  }, [contactFlag]);

  return {
    contactChatList,
    getContactChatList,
  };
};
