import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadAppointments = (param) => {
  const url = param ? `appointments/?${param}` : "appointments/?page=1";
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      // notify("error", "Failed to Load a");
    }
  });
};

export const createAppointment = (body) => {
  return apiFetch("POST", `appointments/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Appointment Created");
        return res;
      } else {
        // notify("error", "Failed to create Appointment");
        throw new Error("Failed to create Appointment");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const editAppointment = (editId, body) => {
  return apiFetch("PUT", `appointments/${editId}/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Appointment Updated");
        return res;
      } else {
        // notify("error", "Failed to create Appointment");
        throw new Error("Failed to Update Appointment");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const delAppointment = (id) => {
  return apiFetch("DELETE", `appointments/${id}/`, null, true).then((res) => {
    if (res.ok) {
      return true;
    }
  });
};

export const getAppintmentMessages = () => {
  const url = `appointments/messages`;
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      // notify("error", "Failed to Load a");
    }
  });
};
export const setAppintmentMessages = (body) => {
  return apiFetch("POST", `appointments/messages`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Saved");
        return res;
      } else {
        // notify("error", "Failed to create Appointment");
        throw new Error("Failed ");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

// appointments/availability/?date_based=false
export const getAvailability = (param) => {
  const url = `appointments/availability/?date_based=${param}`;
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      // notify("error", "Failed to Load a");
    }
  });
};

export const setAvailability = (param, body) => {
  const url = `appointments/availability/?date_based=${param}`;
  return apiFetch("POST", url, body, true)
    .then((res) => {
      if (res.ok) {
        // notify("success", "Saved");
        return res;
      } else {
        // notify("error", "Failed to create Appointment");
        throw new Error("Failed ");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const delTimings = (id, body) => {
  const url = `appointments/availability/${id}`;

  return apiFetch("DELETE", `${url}`, body, true).then((res) => {
    if (res.ok) {
      return true;
    }
  });
};

export const patchTimings = (id, body) => {
  const url = `appointments/availability/${id}/`;

  return apiFetch("PATCH", `${url}`, body, true).then((res) => {
    if (res.ok) {
      return true;
    }
  });
};
export const loadComments = (id) => {
  const url = `appointments/${id}/comments/`;
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      // notify("error", "Failed to Load a");
    }
  });
};

export const postComment = (id, body) => {
  const url = `appointments/${id}/comments/`;
  return apiFetch("POST", url, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Comment posted");
        return res;
      } else {
        notify("error", "Comment failed");
        throw new Error("Failed:", res);
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
