import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Autocomplete,
  Chip,
  Switch,
  FormControlLabel,
  IconButton,
  Modal,
} from "@mui/material";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  Controls,
  Panel,
  MiniMap,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { apiFetch } from "../../API/CommonApi";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { notify } from "../Common";
import { debounce, template } from "lodash";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { useAuth } from "../../Context/Auth";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import SaveSharpIcon from "@mui/icons-material/SaveSharp";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../Styles";
import { isMobile } from "../Common";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { createContact as apiCreateContact } from "../../API/ContactLayer";
import { updateContact as apiUpdateContact } from "../../API/ContactLayer";
import { loadTemplates as apiLoadTemplates } from "../../API/CampaignLayer";
import { templatePreview as apiTemplatePreview } from "../../API/TemplateLayer";
import {
  loadNodes as apiLoadNodes,
  deleteNode as apiDeleteNode,
  setConnectionsAndPositions as ApiSetConnectionsAndPositions,
} from "../../API/AutomationLayer";
import CustomNode from "./reactFlowComponents/CustomNode";
import AddCard from "./reactFlowComponents/AddCard";
import CustomEdge from "./reactFlowComponents/CustomEdge";
const nodeTypes = { custom: CustomNode };
const edgeTypes = { default: CustomEdge };
const panelCss = {
  background: "#ffff",
  padding: "10px",
  borderRadius: "9px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  width: "190px",

  // marginTop: "30px",
};
function AddFlowChatAutomate({ loadAutomations, handleClose, open, editData }) {
  const chatBot_id = editData?.id;
  const [openAddCard, setOpenAddCard] = useState(false);
  const [editCardData, setEditCardData] = useState(null);
  const [defaultcardID, setDefaultcardID] = useState(null);
  const context = useAuth();
  const mobile = isMobile();
  const nodeColor = (node) => {
    switch (node?.data?.type) {
      case "input":
        return "#6ede87";
      case "output":
        return "#6865A5";
      default:
        return "#ff0072";
    }
  };
  const initialNodes = [
    {
      id: "start",
      position: {
        x: 74,
        y: 288,
      },
      data: {
        name: "start",
        isConnectable: true,
        maxConnections: 1,
        default_handles: {
          // inId: "testA_in",
          outId: "flowStarting",
        },
      },
      type: "custom",
    },
    // {
    //   id: "1112",
    //   position: {
    //     x: 111,
    //     y: 17,
    //   },
    //   data: {
    //     name: "test",
    //     message:
    //       '"Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",',
    //     variable: "test",
    //     options: [
    //       {
    //         id: 1111,
    //         option_text: "yes",
    //         handle_id: "test_yes_0",
    //         node_to_trigger: null,
    //       },
    //       {
    //         id: 1112,
    //         option_text: "no",
    //         handle_id: "test_no_1",
    //         node_to_trigger: null,
    //       },
    //     ],
    //     default_handles: {
    //       inId: "test_in",
    //       outId: "test_out",
    //     },
    //   },
    //   type: "custom",
    // },
  ];
  const initialEdges = [
    // {
    //   source: "2",
    //   sourceHandle: "outId1",
    //   target: "3",
    //   targetHandle: "inId2",
    //   id: "reactflow__edge-2outId1-3inId2",
    // },
  ];
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // connections
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const onNodesDelete = useCallback(
    (deleted) => {
      const filteredNodes = nodes.filter(
        (item) => parseInt(item.id) !== parseInt(deleted[0].id)
      );
      const filteredEdges = edges.filter(
        (item) =>
          parseInt(item.source) !== parseInt(deleted[0].id) &&
          parseInt(item.target) !== parseInt(deleted[0].id)
      );
      console.log("deleted", deleted);
      console.log("Nodes", nodes);
      console.log("filteredNodes", filteredNodes);
      console.log("edges", edges);
      console.log("filteredEdges", filteredEdges);
      saveWithoutClosing(filteredNodes, filteredEdges);
      apiDeleteNode(chatBot_id, deleted[0].id).then(() =>
        console.log("Deleted", deleted)
      );
    },
    [nodes]
  );
  const onEdgesDelete = useCallback(
    (deleted) => {
      saveWithoutClosing(nodes, edges);
    },
    [edges]
  );
  // to Open Drawer
  const handleAddorEditCard = (data) => {
    saveWithoutClosing(nodes, edges);
    setOpenAddCard(true);
    data && setEditCardData(data);
  };
  // to close Drawer
  const handleCloseDrawer = () => {
    setOpenAddCard(false);
    setEditCardData(null);
  };
  const handleCloseModal = () => {
    // setEdges([]);
    // setNodes([]);
    loadAutomations();
    handleClose();
  };

  const loadNodes = (param) => {
    const tParam = param;
    let defaultCrdId;
    apiLoadNodes(tParam)
      .then((res) => {
        // console.log("res", res);
        defaultCrdId = res?.default_card;
        const tNodes = res.nodes.map((card) => {
          console.log("card", card);
          return {
            id: `${card.id}`,
            position: card.position,
            data: {
              name: card.name,
              message: card.message,
              variable: card.variable,
              options: card.options,
              default_handles: card?.default_handles,
              header_file: card?.header_file,
              header_type: card?.header_type,

              action: card?.action,
              assignee: card?.assignee,
              team: card?.team,
              send_template: card?.send_template,
              appointment_name: card?.appointment_name,
              appointment_duration: card?.appointment_duration,
              full_day_event: card?.full_day_event,
            },
            type: "custom",
          };
        });

        let exNodes = [...initialNodes, ...tNodes];
        // tNodes.map((x) => {
        //   const isIdFound = exNodes.some((obj) => obj.id === x.id);
        //   if (!isIdFound) {
        //     exNodes = exNodes.concat(x);
        //   }
        // });
        const defaultCard = exNodes?.filter((x) => x.id === defaultCrdId);
        const defaultEdge = {
          source: "start",
          sourceHandle: "flowStarting",
          target: `${defaultCrdId}`,
          targetHandle: "test Card_in",
          id: `reactflow__edge-startflowStarting-${defaultCrdId}${defaultCard.name}_in`,
        };
        setNodes(exNodes);
        let tEdges = [defaultEdge, ...res?.edges];
        setEdges(tEdges);
      })

      .catch((error) => {
        console.error("Error", error);
      });
  };
  const saveWithoutClosing = (nodes, edges) => {
    const filteredEdges = edges?.filter(
      (item) => item.source !== "null" && item.target !== "null"
    );
    const fNodes = nodes?.filter((x) => x.id !== "start");
    const fEdges = edges?.filter((x) => x.source !== "start");
    const starting_card_id = filteredEdges?.find((e) => {
      if (e.source === "start") {
        return e;
      }
    })?.target;

    if (nodes && edges) {
      const body = {
        nodes: fNodes,
        edges: fEdges,
        default_card: starting_card_id,
      };
      ApiSetConnectionsAndPositions(chatBot_id, body).then((res) => {
        if (res.ok) {
          return res;
        } else {
          notify("error", "something went wrong");
        }
      });
    }
  };
  const saveFlow = () => {
    // console.log("nodes", nodes);
    // console.log("edges", edges);
    const filteredEdges = edges.filter(
      (item) => item.source !== "null" && item.target !== "null"
    );
    const fNodes = nodes?.filter((x) => x.id !== "start");
    const fEdges = edges?.filter((x) => x.source !== "start");
    const starting_card_id = filteredEdges?.find((e) => {
      if (e.source === "start") {
        return e;
      }
    })?.target;

    if (nodes && edges) {
      const body = {
        nodes: fNodes,
        edges: fEdges,
        default_card: starting_card_id,
      };
      ApiSetConnectionsAndPositions(chatBot_id, body).then((res) => {
        if (res.ok) {
          notify("success", "Saved");

          handleCloseModal();
        } else {
          notify("error", "something went wrong");
        }
      });
    }
  };
  useEffect(() => {
    if (context.editCardData !== null) {
      handleAddorEditCard(context.editCardData);
    }
  }, [context.editCardData]);
  useEffect(() => {
    setEdges([]);
    setNodes(initialNodes);
    chatBot_id && loadNodes(`${chatBot_id}`);
  }, [open]);
  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="mCtr"
        style={{ zIndex: "9" }}
      >
        <div
          className="modal"
          style={
            mobile
              ? {
                  width: "100vw",
                  height: "100%",
                  padding: "0 0 0 0",
                  // margin: "15% 0 0 0",
                  backgroundColor: "#ffffff",
                  borderRadius: "4px",
                  overflowY: "auto",
                }
              : {
                  width: "100vw",
                  height: "100%",
                  padding: "0 0 0 0",
                  margin: "2% 0 0 0",
                  backgroundColor: "#ffffff",
                  borderRadius: "4px",
                  overflowY: "auto",
                }
          }
        >
          {/* <div style={{ minHeight: "20px" }}>
            <span style={{ position: "absolute", right: "5px" }}>
              <CloseIcon
                style={ClsBtn}
                className="Icon2"
                onClick={handleCloseModal}
              />
            </span>
          </div> */}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodesDelete={onNodesDelete}
            onEdgesDelete={onEdgesDelete}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            deleteKeyCode="Delete"
            //   fitView
          >
            <Background variant="dots" gap={12} size={1} />
            <Controls />
            <Panel
              position="top-left"
              style={{
                background: "#ffff",
                padding: "10px",
                borderRadius: "9px",
                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                minWidth: "100px",
                marginTop: "60px",
              }}
            >
              <KeyboardBackspaceIcon
                style={ClsBtn}
                className="Icon2"
                onClick={handleCloseModal}
              />
              <p>{editData?.name}</p>
              <p style={{ fontSize: "12px", color: "#de4467" }}>Flow:</p>
              {mobile && (
                <div>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleAddorEditCard(null);
                    }}
                  >
                    <LibraryAddIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={saveFlow}
                  >
                    <SaveSharpIcon />
                  </IconButton>
                </div>
              )}
            </Panel>
            {/* <Panel position="top-left"></Panel> */}

            <Panel
              position="top-right"
              style={{
                display: mobile ? "none" : "block",
                marginTop: "60px",
              }}
            >
              <div style={panelCss}>
                {/* <p style={{ margin: "10px 0" }}>Panel:</p> */}
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  onClick={saveFlow}
                  name="send_to_draft"
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    // background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                    borderRadius: " 5px",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "14px",
                    // width: "124px",
                    padding: "0 15px",
                    height: "35px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                  // type="submit"
                  // name="send_to_draft"
                  onClick={() => {
                    handleAddorEditCard(null);
                  }}
                >
                  Add Card
                </Button>
                <p
                  style={{
                    fontSize: "12px",
                    margin: "10px 0 0 0",
                    color: "#787878",
                    fontWeight: 430,
                  }}
                >
                  Select a card and press “Delete” to delete the card
                </p>
              </div>
            </Panel>
          </ReactFlow>
        </div>
      </Modal>
      <AddCard
        openAddCard={openAddCard}
        handleCloseDrawer={handleCloseDrawer}
        editCardData={editCardData}
        chatBot_id={chatBot_id}
        loadNodes={loadNodes}
        edges={edges}
        nodes={nodes}
        saveWithoutClosing={saveWithoutClosing}
      />
    </>
  );
}

export default AddFlowChatAutomate;
