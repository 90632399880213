import { apiFetch } from "./CommonApi";

// export const saveWidgetSettings = (data) => {
//   const formData = new FormData();
//   console.log(data);

//   if ("brand_logo" in data && data.brand_logo) {
//     return fetch(data.brand_logo)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const file = new File([blob], data.img_name);

//         formData.append("brand_name", data.brand_name);
//         formData.append("brand_logo", file);
//         formData.append("imageName", data.img_name);
//         formData.append("first_message", data.first_message);
//         formData.append("phone_number", data.phone_number);
//         return apiFetch("POST", "chat-widget/", formData, true);
//       });
//   }
// };

export const saveWidgetSettings = (data, id) => {
  console.log(data);
  if (id) {
    return apiFetch("PUT", `chat-widget/${id}/`, data, true);
  } else {
    return apiFetch("POST", "chat-widget/", data, true);
  }
};

export const getWidgetDetails = (param) => {
  const url = `chat-widget`;

  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};
