import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  TablePagination,
  IconButton,
  Paper,
  Stack,
  Chip,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { ZOHO_CLIENT_ID, REAL_URL } from "../../../API/CommonApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinkIcon from "@mui/icons-material/Link";
import ZOHOBOOKSLOGO from "../../../Assets/ZOHOBOOKSLOGO.png";
import { useAuth } from "../../../Context/Auth";
import { useIntegrationsLayer } from "../../../API/useIntegrationsLayer";
export const ZohoBooks = () => {
  const { zohoBooksDetails } = useAuth();
  const { getZohoHookDetails } = useIntegrationsLayer();
  const [copied, setCopied] = useState(false);

  const handleRedirect = () => {
    const URL = `https://accounts.zoho.in/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&client_id=${ZOHO_CLIENT_ID}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${REAL_URL}integrations/zohobooks/redirect-url`;
    window.location.href = URL;
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(zohoBooksDetails?.webhook_url);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };
  useEffect(() => {
    Boolean(zohoBooksDetails) == false && getZohoHookDetails();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        paddingTop: "55px",
      }}
    >
      <Box
        sx={{
          margin: "2%",
          display: "flex",
          borderRadius: "5px",
          border: "1px solid #cccccc",
          gap: 3,
          padding: "3%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <Stack gap={3} flexDirection={"row"}>
          <Stack gap={3}>
            <img
              src={ZOHOBOOKSLOGO}
              alt="ZOHO_Books"
              style={{
                mixBlendMode: "multiply",
                maxWidth: "200px",
                height: "auto",
              }}
            />
            {zohoBooksDetails?.active ? (
              <Button
                variant="contained"
                color={"success"}
                onClick={handleCopy}
                startIcon={<CheckCircleIcon />}
              >
                Active
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirect}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                <strong> Authenticate</strong>
              </Button>
            )}
          </Stack>
          {zohoBooksDetails?.webhook_url && (
            <Stack justifyContent={"center"} flexGrow={1} alignItems={"center"}>
              <TextField
                fullWidth
                size="medium"
                value={zohoBooksDetails?.webhook_url}
                InputProps={{
                  readOnly: true,
                  startAdornment: <LinkIcon style={{ marginRight: "10px" }} />,
                  endAdornment: (
                    <IconButton
                      onClick={handleCopy}
                      color={copied ? "success" : "primary"}
                    >
                      {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                    </IconButton>
                  ),
                }}
                sx={{ maxWidth: "600px" }}
                helperText="Copy this URL for setup in ZohoBooks"
                FormHelperTextProps={{
                  sx: {
                    textAlign: "center",
                  },
                }}
              />
            </Stack>
          )}
        </Stack>

        {/* content */}
        <Stack alignItems={"center"}>
          <Box
            sx={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="h6">
              <strong> Integrations Process:</strong>
            </Typography>
            <p>
              Our seamless integration with ZohoBooks allows you to automate the
              process of sending invoices directly from ZohoBooks to your
              clients through WhatsApp, ensuring efficient and timely
              communications. Follow the steps below to integrate ZohoBooks with
              Pinaippu:
            </p>
            <p>
              <strong>Setup URL in ZohoBooks:</strong>
            </p>
            <ol
              style={{ display: "flex", flexDirection: "column", gap: "3px" }}
            >
              <li>
                Log in to your ZohoBooks account and navigate to the{" "}
                <strong>Settings</strong> section. .
              </li>
              <li>
                Under <strong>Automation</strong>, select Workflow Rules.
              </li>
              <li>
                Click on <strong>Add New Workflow</strong> to create a new
                workflow.
              </li>
              <li>
                Choose a <strong>Module:</strong> Select the module where you
                want to apply the workflow (e.g., Invoices).
              </li>
              <li>
                <strong>Set the Trigger Condition:</strong> Define the condition
                that should trigger the workflow. For example, you might choose
                "When an Invoice is created or updated."
              </li>
              <li>
                <strong>Add a Webhook:</strong> In the "Actions" section, select
                "Webhook" as the action type.
              </li>
              <li>
                Paste the copied URL into the <strong>Webhook URL</strong>{" "}
                field.
              </li>
              <li>
                <strong>Save</strong> the changes to complete the workflow
                setup.
              </li>
            </ol>

            <p>
              <strong> Integration Complete:</strong> After saving the workflow
              in ZohoBooks, the integration setup is complete. Pinaippu will now
              be able to send invoices automatically through WhatsApp based on
              the trigger conditions you've set up in ZohoBooks.
            </p>
          </Box>
        </Stack>
      </Box>
      <Outlet />
    </Box>
  );
};
