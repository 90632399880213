import React, { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";
import { uaeAuth, useAuth } from "../../Context/Auth";
import {
  Button,
  Grid,
  TextField,
  Box,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
  Switch,
} from "@mui/material";
import "./Pricing.css";
const Pricing = () => {
  const auth = useAuth();
  const [annual, setAnnual] = useState(false);
  const data = [
    {
      icon: <EmojiNatureOutlinedIcon />,
      name: "Basic",
      price: "749",
      annual: "637",
      sub: "Suitable for businesses who get started with WhatsApp API.",
      features: [
        "2 Agents.",
        "2 Campaigns per month.",
        "Unlimited messages.",
        "100 contacts.",
        "Shared team inbox.",
      ],
    },
    {
      icon: <PrecisionManufacturingOutlinedIcon />,
      name: " Advanced",
      price: "1499",
      annual: "1274",
      sub: "Suitable for medium businesses who want to dive deeper.",

      features: [
        "5 Agents.",
        "5 Campaigns per month.",
        "Unlimited messages.",
        "500 contacts.",
        "Shared team inbox.",

        "Unlimited whatsapp automation flows.",
      ],
    },
    {
      icon: <OfflineBoltOutlinedIcon />,
      name: "Elite",
      price: "1999",
      annual: "1699",
      sub: "Unlock all our features with unlimited access.",

      features: [
        "10 Agents.",
        "Unlimited Campaigns per month.",
        "Unlimited messages.",
        "Unlimited contacts.",
        "Shared team inbox.",

        "Unlimited whatsapp automation flows.",
        "Campaign scheduling.",
      ],
    },
  ];
  useEffect(() => {
    auth.RedirectBasedOnDomain();
  }, []);
  return (
    <div
      style={{
        minHeight: "45vw",
        width: "100%",
        marginTop: "60px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Our Plans</h1>
        <p style={{ fontSize: "20px", margin: "5px 0 13px 0" }}>
          Select the plan that suits you best
        </p>
        <p>
          <span>Monthly</span> <Switch onChange={() => setAnnual(!annual)} />
          <span>
            Annual
            <sup style={{ color: "#da1e7a" }}>15%Off</sup>
            {/* <span className="discointChip">15% Off</span> */}
          </span>
        </p>
      </div>
      <div className="pricingCtr">
        {data.map((x, index) => (
          <div xs={12} md={4} className="pricingCard" key={index}>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2>
                {x.icon} {x.name}
              </h2>
              <p style={{ fontSize: "14px", color: "#888888" }}>{x.sub}</p>
            </div>

            <p style={{ display: "flex" }}>
              <span style={{ fontSize: "35px", fontWeight: 500 }}>
                <CurrencyRupeeOutlinedIcon />
                {annual ? x.annual : x.price}
              </span>
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  fontSize: "12px",
                  paddingBottom: "4px",
                }}
              >
                <span>&nbsp;+Taxes</span>
                <span>&nbsp;/Month</span>
              </span>
            </p>
            <Button
              variant="contained"
              href="https://calendly.com/pinaippu/productdemo"
              target="_blank_"
              //   color="#4a2e91"
              style={{
                textTransform: "capitalize",
              }}
            >
              Get Started
            </Button>
            <h4 style={{ width: "100%", marginLeft: "35px" }}>Features:</h4>
            <ul>
              {x.features.map((y, index) => (
                <li key={index} style={{ width: "100%" }}>
                  <span>
                    <CheckIcon style={{ width: "20px" }} />
                  </span>
                  <span> {y} </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
