import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Menu,
  InputAdornment,
  TablePagination,
  Paper,
} from "@mui/material";
import { debounce } from "lodash";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { formatDate, formatDateOnly, isMobile } from "../Common";
import { apiFetch } from "../../API/CommonApi";
import { notify } from "../Common";

import { deleteService as apiDeleteService } from "../../API/ServiceLayer";

function ServicesList({
  handleEdit,
  services,
  loadServices,
  count,
  page,
  setPage,
  searchKey,
  setSearch,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [deleteData, setDeleteData] = useState(null);
  const mobileScreen = isMobile();

  const columns = [
    {
      title: "Order id",
      defaultSort: "desc",
      type: "numeric",
      field: "job_id",
      render: (rowData) => (
        <p style={{ textAlign: "center" }}>{rowData?.order_id}</p>
      ),
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Customer name",
      field: "contactName",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p>{rowData.contact?.name}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Contact",
      field: "contact",
      render: (rowData) => (
        <div className="column-2">
          <p>
            <PhoneIphoneOutlinedIcon className="Icon" />{" "}
            {rowData.contact?.phone_number}
          </p>
        </div>
      ),
    },

    {
      title: "Status",
      field: "status",
    },
    {
      title: "Created at",
      field: "created_at",
      render: (rowData) => (
        <p className="column-4">{formatDateOnly(rowData.created_at)}</p>
      ),
    },
    {
      title: "Updated at",
      field: "updated_at",
      render: (rowData) => (
        <p className="column-4">{formatDateOnly(rowData?.updated_at)}</p>
      ),
    },

    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delService(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setDeleteData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];

  // delete Service
  const delService = (rowData) => {
    apiDeleteService(rowData.id)
      .then((res) => {
        setAnchorEl(null);
        setDeleteData(null);
        if (page && searchKey) {
          loadServices(`page=${page}&search=${searchKey}`);
        }
        if (page) {
          loadServices(`page=${page}`);
        }
        notify("warn", "Service Deleted");
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const debouncedSearch = debounce((searchKey) => {
    setSearch(searchKey);
    if (searchKey) {
      loadServices(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    if (page && searchKey) {
      loadServices(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadServices(`page=${page}`);
    }
  }, [page]);
  return (
    <Paper elevation={2} className="table">
      <div>
        <div className="customToolbar">
          <TextField
            style={{
              fontWeight: 400,
              fontSize: "13px",
              lineHeight: "16px",
              color: "#464646",
              maxHeight: "35px",
              maxWidth: "315px",
              background: "#FFFAFB",
              padding: "dence",
              width: "100%",
              zIndex: 5,
            }}
            size="small"
            variant="outlined"
            placeholder="Search "
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              debouncedSearch(e.target.value);
              if (!e.target.value) {
                loadServices();
              }
            }}
          />
        </div>

        <MaterialTable
          sx={{
            border: "1px solid red",
            borderRadius: "10px",
          }}
          columns={columns}
          data={services ? services : []}
          components={{
            Toolbar: () => <></>,
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                count={count ? count : 0}
                page={page - 1}
                onPageChange={(e, page) => setPage(page + 1)}
                labelDisplayedRows={() => null}
              />
            ),
          }}
          options={{
            search: false,
            toolbar: false,
            showTitle: false,
            paging: true,
            pageSize: 10,
            headerStyle: {
              border: "1px solid #EDDCDC",
              maxHeight: "50px",
              zIndex: 2,
              backgroundColor: "#FBFBFB",
            },
          }}
        />
      </div>
    </Paper>
  );
}

export default ServicesList;
