import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const styleDiv = {
  borderTop: "1px solid #f2f2f2",
  marginTop: "5px",
  padding: "5px 0",
};

function DisplayContacts({ data }) {
  const [view_more, setView_more] = useState(false);
  const closeDialog = () => {
    setView_more(false);
  };
  return (
    <div>
      <p>Contacts:</p>
      {data.contacts_attached.length <= 2 ? (
        <>
          {data.contacts_attached[0] && (
            <div style={styleDiv}>
              <p>{data.contacts_attached[0].name}</p>
              <p>{data.contacts_attached[0].phone_number}</p>
            </div>
          )}
          {data.contacts_attached[1] && (
            <div style={styleDiv}>
              <p>{data.contacts_attached[1].name}</p>
              <p>{data.contacts_attached[1].phone_number}</p>
            </div>
          )}
        </>
      ) : (
        <div>
          {data.contacts_attached[0] && (
            <div style={styleDiv}>
              <p>{data.contacts_attached[0].name}</p>
              <p>{data.contacts_attached[0].phone_number}</p>
            </div>
          )}
          {data.contacts_attached[1] && (
            <div style={styleDiv}>
              <p>{data.contacts_attached[1].name}</p>
              <p>{data.contacts_attached[1].phone_number}</p>
            </div>
          )}
          <div style={styleDiv}>
            <p>and {data.contacts_attached.length - 2} other contacts.</p>
            <p
              style={{
                textAlign: "center",
                marginTop: "4px",
                cursor: "pointer",
              }}
              onClick={() => setView_more(!view_more)}
            >
              View More
            </p>
          </div>
        </div>
      )}
      <Dialog open={view_more} onClose={closeDialog}>
        <DialogTitle>Contacts:</DialogTitle>
        <DialogContent sx={{ maxHeight: "300px", width: "300px" }}>
          {data.contacts_attached.map((x, index) => (
            <div style={styleDiv} key={index}>
              <p>{x.name}</p>
              <p>{x.phone_number}</p>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DisplayContacts;
