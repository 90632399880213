import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddStatus from "./AddStatus";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { debounce } from "lodash";
import { btnCss, TextfieldCss, ClsBtn } from "../../../Styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { notify, formatDate, formatDateOnly, isMobile } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import { loadStatus as apiLoadStatus } from "../../../API/OrderStatusLayer";
import { delStatus as apiDelStatus } from "../../../API/OrderStatusLayer";

function OrderStatus() {
  //states
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [searchKey, setSearch] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [deleteData, setDeleteData] = useState(null);
  const mobileScreen = isMobile();
  // to Open modal
  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
  };

  // to close Modal
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  //Loading statuses

  const loadStatus = (param) => {
    apiLoadStatus(param)
      .then((res) => {
        setStatuses(res.results);
        setCount(res.count);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const delStatus = (rowData) => {
    apiDelStatus(rowData.id).then(() => {
      setAnchorEl(null);
      setDeleteData(null);
      loadStatus(null);
      notify("warn", "Status Deleted");
    });
  };

  useEffect(() => {
    if (page && searchKey) {
      loadStatus(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadStatus(`page=${page}`);
    }
  }, [page]);

  //columns
  const columns = [
    {
      title: "Status Name",
      field: "status_name",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p style={{ textTransform: "capitalize" }}>
              {rowData.status_name.replace(/_/g, " ")}
            </p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Message Template",
      field: "message_template",
      render: (rowData) => (
        <div className="column-2">
          {rowData?.message_template?.name && (
            <p>{rowData?.message_template?.name.replace(/_/g, " ")}</p>
          )}
        </div>
      ),
    },
    {
      title: "Created Date",
      field: "createdDate",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.created_at)}</p>
      ),
    },
    {
      title: "Updated at",
      field: "updated_at",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.updated_at)}</p>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delStatus(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setDeleteData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadStatus(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    loadStatus(null);
  }, []);

  return (
    <div>
      <div className="pageInNav">
        <p className="pageH">Order Status</p>
        <div className="inNavCtr"></div>
      </div>
      <Paper elevation={2} className="table">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 10px",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: mobileScreen ? "100%" : "315px",
              }}
            >
              <TextField
                style={TextfieldCss}
                size="small"
                variant="outlined"
                placeholder="Search "
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    loadStatus();
                  }
                }}
              />
            </div>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Status
            </Button>
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={statuses ? statuses : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={count ? count : 0}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,

              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
              },
            }}
          />
        </div>
      </Paper>
      <AddStatus
        open={open}
        handleClose={handleClose}
        loadStatus={loadStatus}
        editData={editData}
      />
    </div>
  );
}

export default OrderStatus;
