import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadTemplates = (param) => {
  const url = param
    ? `whatsapp-business/templates/?${param}`
    : "whatsapp-business/templates/?page=1";

  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};
export const loadSpecificTemplate = (templateId) => {
  return apiFetch(
    "GET",
    `whatsapp-business/templates/${templateId}/`,
    null,
    true
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const delTemplate = (templateId) => {
  return apiFetch(
    "DELETE",
    `whatsapp-business/templates/${templateId}/`,
    null,
    true
  ).then((res) => {
    if (res.ok) {
      return true;
    }
  });
};

export const customButtonItems = (call_to_action, quickReply, buttons) => {
  let ButtonItems;
  if (buttons?.value === "call_to_action") {
    ButtonItems = call_to_action.map((item) => {
      const call_to_actionItem = {
        type: item?.typeOfAction?.value,
        text: item?.buttonText,
      };

      if (call_to_actionItem?.type === "PHONE_NUMBER") {
        call_to_actionItem.phone_number = "+" + item?.phone_number;
      }

      if (call_to_actionItem.type === "URL") {
        call_to_actionItem.url = item?.url;
      }

      return call_to_actionItem;
    });
  } else if (buttons?.value === "quick_reply") {
    ButtonItems = quickReply.map((item) => {
      const quickReplyItem = {
        type: "QUICK_REPLY",
        text: item?.buttonText,
      };
      return quickReplyItem;
    });
  } else if (buttons && buttons[0]) {
    // console.log("buttons", buttons);
    ButtonItems = [
      {
        type: buttons[0].type,
        text: buttons[0].text,
      },
    ];
  }
  return ButtonItems;
};

export const createTemplateReview = (data, selectedMedia, buttonData) => {
  const customButtons = customButtonItems(
    buttonData.call_to_action,
    buttonData.quickReply,
    buttonData.buttons
  );

  const formData = new FormData();
  customButtons?.forEach((button, index) => {
    Object.entries(button).forEach(([key, value]) => {
      formData.append(`buttons.${index}.${key}`, value);
    });
  });

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (key === "file" || key === "fileName" || key === "template_type") {
        continue;
      } else {
        formData.append(key, value);
      }
    }
  }
  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);
        if (selectedMedia === "image") {
          formData.append("header_content_image", file);
        }
        if (selectedMedia === "document") {
          formData.append("header_content_document", file);
        }
        if (selectedMedia === "video") {
          formData.append("header_content_video", file);
        }
        formData.append("header_fileName", data?.fileName);
        return apiFetch("POST", "whatsapp-business/templates/", formData, true)
          .then((res) => {
            if (res) {
              return res.json();
            } else {
              //throw new Error("Failed to create template draft");
            }
          })
          .then((res) => {
            if (res.id) {
              templateStatus({
                template_id: res.id,
                status: "in_review",
              });
            }
          });
      });
  }
  // console.log("formData", Object.fromEntries(formData));

  return apiFetch("POST", "whatsapp-business/templates/", formData, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to create template draft");
      }
    })
    .then((res) => {
      if (res.id) {
        templateStatus({
          template_id: res.id,
          status: "in_review",
        });
      }
    });
};

export const templateStatus = (body) => {
  return apiFetch(
    "POST",
    "whatsapp-business/template/status/",
    body,
    true
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("Failed to update template status");
    }
  });
};

export const createTemplateDraft = (data, selectedMedia, buttonData) => {
  const customButtons = customButtonItems(
    buttonData.call_to_action,
    buttonData.quickReply,
    buttonData.buttons
  );

  const formData = new FormData();
  customButtons?.forEach((button, index) => {
    Object.entries(button).forEach(([key, value]) => {
      formData.append(`buttons.${index}.${key}`, value);
    });
  });
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (key === "file" || key === "fileName" || key === "template_type") {
        continue;
      } else {
        formData.append(key, value);
      }
    }
  }

  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);
        if (selectedMedia === "image") {
          formData.append("header_content_image", file);
        }
        if (selectedMedia === "document") {
          formData.append("header_content_document", file);
        }
        if (selectedMedia === "video") {
          formData.append("header_content_video", file);
        }
        formData.append("header_fileName", data?.fileName);
        // console.log(Object.fromEntries(formData));
        return apiFetch(
          "POST",
          "whatsapp-business/templates/",
          formData,
          true
        ).then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error("Failed to create template draft");
          }
        });
      });
  }
  // console.log("formData", Object.fromEntries(formData));

  return apiFetch("POST", "whatsapp-business/templates/", formData, true).then(
    (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to create template draft");
      }
    }
  );
};

export const templateEditDraft = (
  templateId,
  data,
  selectedMedia,
  buttonData
) => {
  const customButtons = customButtonItems(
    buttonData.call_to_action,
    buttonData.quickReply,
    buttonData.buttons
  );
  // console.log(data);
  const formData = new FormData();
  customButtons?.forEach((button, index) => {
    Object.entries(button).forEach(([key, value]) => {
      formData.append(`buttons.${index}.${key}`, value);
    });
  });

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (
        key === "file" ||
        key === "fileName" ||
        key === "body_example" ||
        key === "header_example"
      ) {
        continue;
      } else {
        formData.append(key, value);
      }
    }
  }
  if (
    ("body_example" in data && data.body_example) ||
    ("header_example" in data && data.header_example)
  ) {
    const bodyExample =
      typeof data.body_example === "string"
        ? data.body_example.split(",")
        : data.body_example;
    const headreExample =
      typeof data.header_example === "string"
        ? data.header_example.split(",")
        : data.header_example;
    bodyExample &&
      bodyExample.forEach((value, index) => {
        formData.append(`body_example.[${index}]`, value);
      });
    headreExample &&
      headreExample.forEach((value, index) => {
        formData.append(`header_example.[${index}]`, value);
      });
  }
  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);
        if (selectedMedia === "image") {
          formData.append("header_content_image", file);
        }
        if (selectedMedia === "document") {
          formData.append("header_content_document", file);
        }
        if (selectedMedia === "video") {
          formData.append("header_content_video", file);
        }
        formData.append("header_fileName", data?.fileName);
        return apiFetch(
          "PUT",
          `whatsapp-business/templates/${templateId}/`,
          formData,
          true
        ).then((res) => {
          if (res.ok) {
            if (templateId) {
              templateStatus({
                template_id: templateId,
                status: "draft",
              });
            }
            return res;
          } else {
            // console.log(res.json());
            throw new Error("Failed to create template draft");
          }
        });
      });
  }

  return apiFetch(
    "PUT",
    `whatsapp-business/templates/${templateId}/`,
    formData,
    true
  ).then((res) => {
    if (res.ok) {
      if (templateId) {
        templateStatus({
          template_id: templateId,
          status: "draft",
        });
      }
      return res;
    } else {
      throw new Error("Failed to update template");
    }
  });
};
export const editTemplateReview = (
  templateId,
  data,
  selectedMedia,
  buttonData
) => {
  const customButtons = customButtonItems(
    buttonData.call_to_action,
    buttonData.quickReply,
    buttonData.buttons
  );
  // console.log(data);
  const formData = new FormData();
  customButtons?.forEach((button, index) => {
    Object.entries(button).forEach(([key, value]) => {
      formData.append(`buttons.${index}.${key}`, value);
    });
  });

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (
        key === "file" ||
        key === "fileName" ||
        key === "body_example" ||
        key === "header_example"
      ) {
        continue;
      } else {
        formData.append(key, value);
      }
    }
  }
  if (
    ("body_example" in data && data.body_example) ||
    ("header_example" in data && data.header_example)
  ) {
    const bodyExample =
      typeof data.body_example === "string"
        ? data.body_example.split(",")
        : data.body_example;
    const headreExample =
      typeof data.header_example === "string"
        ? data.header_example.split(",")
        : data.header_example;
    bodyExample &&
      bodyExample.forEach((value, index) => {
        formData.append(`body_example.[${index}]`, value);
      });
    headreExample &&
      headreExample.forEach((value, index) => {
        formData.append(`header_example.[${index}]`, value);
      });
  }
  if ("file" in data && data.file) {
    return fetch(data.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], data.fileName);
        if (selectedMedia === "image") {
          formData.append("header_content_image", file);
        }
        if (selectedMedia === "document") {
          formData.append("header_content_document", file);
        }
        if (selectedMedia === "video") {
          formData.append("header_content_video", file);
        }
        formData.append("header_fileName", data?.fileName);
        return apiFetch(
          "PUT",
          `whatsapp-business/templates/${templateId}/`,
          formData,
          true
        ).then((res) => {
          if (res.ok) {
            if (templateId) {
              templateStatus({
                template_id: templateId,
                status: "in_review",
              });
            }
            return res;
          } else {
            // console.log(res.json());
            throw new Error("Failed to create template draft");
          }
        });
      });
  }

  return apiFetch(
    "PUT",
    `whatsapp-business/templates/${templateId}/`,
    formData,
    true
  ).then((res) => {
    if (res.ok) {
      if (templateId) {
        templateStatus({
          template_id: templateId,
          status: "in_review",
        });
      }
      return res;
    } else {
      throw new Error("Failed to update template");
    }
  });
};

// export const editTemplateReview = (
//   templateId,
//   data,
//   selectedMedia,
//   buttonData
// ) => {
//   const customButtons = customButtonItems(
//     buttonData.call_to_action,
//     buttonData.quickReply,
//     buttonData.buttons
//   );

//   const formData = new FormData();
//   customButtons?.forEach((button, index) => {
//     Object.entries(button).forEach(([key, value]) => {
//       formData.append(`buttons.${index}.${key}`, value);
//     });
//   });

//   for (let key in data) {
//     if (data.hasOwnProperty(key)) {
//       const value = data[key];
//       if (
//         key === "file" ||
//         key === "fileName" ||
//         key === "body_example" ||
//         key === "header_example"
//       ) {
//         continue;
//       } else {
//         formData.append(key, value);
//       }
//     }
//   }
//   if (
//     ("body_example" in data && data.body_example) ||
//     ("header_example" in data && data.header_example)
//   ) {
//     const bodyExample =
//       typeof data.body_example === "string"
//         ? data.body_example.split(",")
//         : data.body_example;
//     const headreExample =
//       typeof data.header_example === "string"
//         ? data.header_example.split(",")
//         : data.header_example;
//     bodyExample &&
//       bodyExample.forEach((value, index) => {
//         formData.append(`body_example.[${index}]`, value);
//       });
//     headreExample &&
//       headreExample.forEach((value, index) => {
//         formData.append(`header_example.[${index}]`, value);
//       });
//   }

//   if ("file" in data && data.file) {
//     return fetch(data.file)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const file = new File([blob], data.fileName);
//         if (selectedMedia === "image") {
//           formData.append("header_content_image", file);
//         }
//         if (selectedMedia === "document") {
//           formData.append("header_content_document", file);
//         }
//         if (selectedMedia === "video") {
//           formData.append("header_content_video", file);
//         }
//         formData.append("header_fileName", data?.fileName);
//       });
//   }

//   return apiFetch(
//     "PUT",
//     `whatsapp-business/templates/${templateId}/`,
//     formData,
//     true
//   )
//     .then((res) => {
//       if (res.ok) {
//         return res.json();
//       } else {
//         throw new Error("Failed to Edit template ");
//       }
//     })
//     .then((res) => {
//       if (templateId) {
//         templateStatus({
//           template_id: templateId,
//           status: "in_review",
//         });
//       }
//     });
// };

export const templatePreview = (body) => {
  return apiFetch(
    "POST",
    "whatsapp-business/template/preview/",
    body,
    true
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      console.log(res);
      throw new Error("Failed to get template preview");
    }
  });
};

export const getTemplateTags = (param) => {
  const url = `whatsapp-business/template-tags/?text=${param}`;

  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};

export const sendReview = (templateId) => {
  const url = `whatsapp-business/templates/${templateId}/send-to-review/`;

  return apiFetch("POST", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
};
