import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { campaignAnalytics } from "../../../API/CampaignLayer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import MaterialTable from "material-table";

function Analytics({ rowData, setRowData }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#ababab",
      },
      secondary: {
        main: "#e8e83f",
      },
    },
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageRead, setMessageRead] = useState(0);
  const [messageSent, setMessageSent] = useState(0);
  const [messageDelivered, setMessageDelivered] = useState(0);
  const [messageReplied, setMessageReplied] = useState(0);
  const [totalMessage, setTotalMessage] = useState(0);
  const [campaignName, setCampaignName] = useState("");

  const handleOpenDialog = (event, rowData) => {
    console.log("rowData", rowData);
    if (rowData) {
      const rowId = rowData.id;
      campaignAnalytics(rowId).then((res) => {
        const resResults = res?.message_count;
        console.log("resResults", resResults);
        setMessageRead(resResults.read);
        setMessageSent(resResults.sent);
        setMessageDelivered(resResults.delivered);
        setMessageReplied(resResults.replied);
        setTotalMessage(resResults.total);
        setCampaignName(rowData.name);
      });
    }
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setMessageRead(0);
    setMessageSent(0);
    setMessageDelivered(0);
    setMessageReplied(0);
    setTotalMessage(0);
    setCampaignName("");
    setDialogOpen(false);
  };

  const columns = [
    { title: "Status", field: "status" },
    {
      title: "Count",
      field: "count",
      align: "center",
      cellStyle: {
        paddingLeft: "0",
      },
      headerStyle: {
        paddingLeft: "0",
      },
    },
    {
      render: (rowData) => (
        <div style={{ width: "65px" }}>
          <CircularProgressbar
            maxValue={totalMessage == 0 ? 1 : totalMessage}
            strokeWidth={10}
            value={totalMessage == 0 ? 0 : rowData.count}
            text={
              totalMessage == 0
                ? `0%`
                : `${Math.round((rowData.count / totalMessage) * 100)}%`
            }
            styles={{
              path: { stroke: rowData.color },
              text: { fill: "#454545" },
            }}
          />
        </div>
      ),
    },
  ];

  const data = [
    { status: "Sent", count: messageSent, color: "#a3191e" },
    { status: "Delivered", count: messageDelivered, color: "#f5f543" },
    { status: "Read", count: messageRead, color: "#1889ff" },
    { status: "Replied", count: messageReplied, color: "#278435" },
  ];

  return (
    <>
      <IconButton
        sx={{
          color: "#de4467",
        }}
        onClick={() => {
          setRowData(rowData);
          handleOpenDialog(null, rowData);
        }}
      >
        <EqualizerIcon />
      </IconButton>
      <ThemeProvider theme={theme}>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{ textAlign: "left", padding: "20px 20px 20px 43px" }}
          >
            Campaign Analytics
          </DialogTitle>
          <DialogContent
            style={{
              textAlign: "left",
              height: "570px",
              paddingBottom: 0,
              paddingTop: 0,
            }}
          >
            <h3 className="campaignName">Campaign Name : {campaignName}</h3>
            <div style={{ width: "500px", height: "500px" }}>
              <div className="totalmessage">
                <p>Total Contacts : {totalMessage}</p>
              </div>
              <div className="analysis">
                <MaterialTable
                  columns={columns}
                  data={data}
                  options={{
                    search: false,
                    paging: false,
                    toolbar: false,
                    sorting: false,
                    draggable: false,
                    headerStyle: {
                      backgroundColor: "#f2f2f2",
                      fontWeight: "bold",
                    },
                  }}
                />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
}

export default Analytics;
