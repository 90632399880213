import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Menu,
  InputAdornment,
  TablePagination,
  Paper,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { btnCss, TextfieldCss } from "../../../Styles";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { formatDate } from "../../Common";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddRoles from "./AddRoles";
import { debounce } from "lodash";
import { getRoles, deleteRole } from "../../../API/UserManagementLayer";
import { notify, isMobile } from "../../Common";

function Roles() {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [searchKey, setSearch] = useState(null);
  const [tempRowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [roles, setRoles] = useState([]);
  const [editData, setEditData] = useState(null);
  const mobileScreen = isMobile();
  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const loadRoles = (params) => {
    getRoles(params)
      .then((res) => {
        setRoles(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteRole = async (rowData) => {
    deleteRole(rowData.id)
      .then((res) => {
        loadRoles();
        setAnchorEl(null);
        notify("warn", "Role Deleted Successfully");
      })
      .catch((err) => {
        notify("error", "Something went wrong");
        console.log(err);
      });
  };

  useEffect(() => {
    loadRoles();
  }, []);

  useEffect(() => {
    if (page && searchKey) {
      loadRoles(`search=${searchKey}&page=${page}`);
    }
    if (searchKey) {
      loadRoles(`search=${searchKey}`);
    }
    if (page) {
      loadRoles(`page=${page}`);
    }
  }, [page]);

  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      setSearch(searchKey);
      loadRoles(`search=${searchKey}`);
    } else {
      setSearch(null);
    }
  }, 600);

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p style={{ textTransform: "capitalize" }}>{rowData.name}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Access Pages",
      field: "accesspages",
      render: (rowData) => (
        <span>
          <p style={{ textTransform: "capitalize" }}>
            {rowData.permissions.map((item) => item).join(", ")}
          </p>
        </span>
      ),
    },
    {
      title: "Created at",
      field: "created_at",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p>{formatDate(rowData.created_at)}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setRowData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setRowData(null);
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  handleDeleteRole(tempRowData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setRowData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <>
      <Paper elevation={2} className="table">
        <div>
          <div className="customMTableToolbar">
            <div
              style={{
                width: mobileScreen ? "200px" : "315px",
              }}
            >
              <TextField
                style={TextfieldCss}
                size="small"
                variant="outlined"
                placeholder="Search "
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  if (!e.target.value) {
                    loadRoles();
                  }
                }}
              />
            </div>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Roles
            </Button>
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={roles ? roles?.results : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={roles?.count}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              // selection: true,
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,

              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                // borderTop: "1px solid #EDDCDC",
                // BorderBottom: "1px solid #EDDCDC",
                // borderLeft: "1px solid #D0D0D0",
                // borderRight: "1px solid #D0D0D0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
              },
            }}
          />
        </div>
      </Paper>
      <AddRoles
        open={open}
        handleClose={handleClose}
        loadRoles={loadRoles}
        editData={editData}
      />
    </>
  );
}
export default Roles;
