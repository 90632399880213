import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Autocomplete,
  Paper,
  Drawer,
} from "@mui/material";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SchemaRoundedIcon from "@mui/icons-material/SchemaRounded";
import { debounce } from "lodash";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../Styles";
import { notify, formatDate, formatDateOnly, isMobile } from "../Common";
import {
  loadAppointments as apiLoadAppointments,
  delAppointment as apiDelAppointment,
} from "../../API/AppointmentLayer";
import ForumIcon from "@mui/icons-material/Forum";
import SidePanel from "./SidePanel";
import AddAppointment from "./AddAppointment";
const Tasks = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [searchKey, setSearch] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const mobileScreen = isMobile();
  const [selectedValue, setSelectedValue] = useState({
    label: "Pending",
    value: false,
  });
  // to Open modal
  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
  };
  // to close Modal
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };
  // SidePanel handle
  const toggleSidePanel = () => {
    setSelectedRow(null);
  };
  const loadAppointments = (param) => {
    apiLoadAppointments(
      `appointment_type=task&is_completed=${selectedValue.value}&${param}`
    )
      .then((res) => {
        const currentDate = new Date();
        const filteredData = res.results.filter((event) => {
          const eventStartTime = new Date(event.start_time);
          return eventStartTime > currentDate;
        });
        setAppointments(res.results);
        setCount(res.count);
        setCount(res.count);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  const delAppointment = (rowData) => {
    apiDelAppointment(rowData.id).then(() => {
      setAnchorEl(null);
      setDeleteData(null);
      loadAppointments(null);
      notify("warn", "Appointment Deleted");
    });
  };
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadAppointments(`search=${searchKey}`);
    }
  }, 600);

  //columns
  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p style={{ textTransform: "capitalize" }}>
              {rowData.name.replace(/_/g, " ")}
            </p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Due at",
      field: "start_time",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.start_time)}</p>
      ),
    },
    // {
    //   title: "End Time",
    //   field: "end_time",
    //   render: (rowData) => (
    //     <p className="column-4">
    //       {rowData.full_day_event ? "Full Day" : formatDate(rowData?.end_time)}
    //     </p>
    //   ),
    // },
    {
      title: "Comments/Edit/Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <IconButton
              sx={{ color: "#de4467" }}
              onClick={() => {
                toggleSidePanel();
                if (selectedRow) {
                  setSelectedRow(null);
                } else setSelectedRow(rowData);
              }}
              // disabled={selectedValue.value !== "upcoming"}
            >
              <ForumIcon className="Icon" />
            </IconButton>
          </p>
          <p>
            <IconButton
              sx={{ color: "#de4467" }}
              onClick={() => {
                handleAddorEdit(rowData);
              }}
              disabled={selectedValue.value}
            >
              <EditIcon className="Icon" />
            </IconButton>
          </p>
          <p>
            <IconButton
              sx={{ color: "#de4467" }}
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
              disabled={selectedValue.value}
            >
              <DeleteOutlineOutlinedIcon className="Icon" />
            </IconButton>
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delAppointment(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                // onClick={() => {
                //   setAnchorEl(null);
                //   setDeleteData(null);
                // }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (page && searchKey) {
      loadAppointments(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadAppointments(`page=${page}`);
    }
  }, [page, selectedValue]);
  return (
    <div>
      <Paper elevation={2} className="table">
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "15px",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: mobileScreen ? "100%" : "315px",
              }}
            >
              <TextField
                style={TextfieldCss}
                size="small"
                variant="outlined"
                placeholder="Search "
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    loadAppointments();
                  }
                }}
              />
            </div>
            <Autocomplete
              id="combo-box-demo"
              value={selectedValue}
              disableClearable
              options={[
                {
                  label: "Pending",
                  value: false,
                },
                {
                  label: "Completed",
                  value: true,
                },
              ]}
              sx={{ width: mobileScreen ? "100%" : 200 }}
              getOptionLabel={(option) => option.label}
              onChange={(e, data) => {
                setSelectedValue(data);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Filter by status"
                  size="small"
                  fullWidth
                />
              )}
            />
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={appointments ? appointments : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={count ? count : 0}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,
              rowStyle: (rowData) => ({
                backgroundColor:
                  selectedRow?.id === rowData.id || editData?.id === rowData.id
                    ? "#eeee"
                    : "#ffff",
              }),
              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
              },
            }}
          />
        </div>
      </Paper>
      <SidePanel toggleSidePanel={toggleSidePanel} selectedRow={selectedRow} />
      <AddAppointment
        open={open}
        handleClose={handleClose}
        handleAddorEdit={handleAddorEdit}
        loadAppointments={loadAppointments}
        editData={editData}
      />
    </div>
  );
};

export default Tasks;
