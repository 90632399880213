import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import zoko from "../../Assets/zoko.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', zoko: '$399/mo' },
    { feature: 'Green Tick Application', pinaippu: 'Free Green-tick', zoko: 'Green-tick application' },
    { feature: 'Conversation charges', pinaippu: 'Standard WhatsApp Business conversation charges', zoko: 'WhatsApp charge + extra charge based on subscription plan' },
    { feature: 'Chatbot templates', pinaippu: 'Cover all sector &amp; industry workflows', zoko: 'Limited to E-commerce workflows' },    
    { feature: 'Chatbot Type', pinaippu: 'Keyword-based + Advanced', zoko: 'Basic chatbot' },
    { feature: 'Marketing campaigns', pinaippu: 'Advanced', zoko: 'Standard' },
    { feature: 'Shopify Plugin', pinaippu: 'Free', zoko: '$4.99/mo' },
    { feature: 'Integration', pinaippu: '15+ Integrations', zoko: 'Shopify' },
    { feature: 'Support', pinaippu: 'WhatsApp + Email + Call', zoko: 'WhatsApp + Email' },
  ];

const ComparisonBlog10 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Zoko | WhatsApp marketing Automation</title>
                <meta
                    name="description"
                    content="Choose Pinaippu.io over Zoko for an economical WhatsApp Business API solution with
                    enhanced features, extensive user support, and limitless custom fields. Explore the Best
                    WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px" }}>Pinaippu Vs Zoko</p>
              <img src={zoko} style={{ width: "70px", height: "70px" }} alt="zoko" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Zoko', field: 'zoko' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Zoko</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Zoko supports multiple messaging channels, including WhatsApp, Facebook Messenger, and Instagram, providing flexibility in reaching customers.</li>
                        <li>Zoko offers fair use limits on conversations, agents, teams, routing rules, and segments, allowing businesses to manage their usage and costs effectively.</li>
                        <li>The platform provides custom fields for organizing and categorizing customer data, enhancing contact management.</li>
                        <li>Zoko offers prebuilt flows for various use cases, such as abandoned checkout, COD reconfirmation, and more, making it easier to implement chatbot automation.</li>
                        <li>Zoko includes features like the Shopify plugin and premium apps, catering to the needs of e-commerce businesses.</li>
                        <li>Zoko offers agent analytics, allowing businesses to monitor and analyze agent performance.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Zoko&#39;s pricing structure includes various tiers and fair use limits, which can be complex to navigate for businesses with varying needs.</li>
                        <li>While there is no markup on Meta&#39;s rates, there is an additional markup on conversation costs, which could add to the overall expenses.</li>
                        <li>The lower-priced plans, such as Plus and Starter have limitations on the number of agents, which may not be suitable for businesses with larger support teams.</li>
                        <li>The Starter plan offers email-only support, which may not provide real-time assistance for businesses requiring immediate help.</li>
                        <li>Zoko provides 1,000 free service conversations per month, which may not be sufficient for businesses with high messaging volumes.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Pricing and Cost Comparison</h3></span><br />
              Pinaippu offers a significantly more affordable pricing structure at ₹1699/mo in contrast to Zoko's pricing, which stands much higher at $399/mo. 
              This pricing gap makes Pinaippu a more cost-effective solution for businesses, especially for those operating under budget constraints.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Green Tick Application and Chatbot Capabilities</h3></span><br />
               Pinaippu includes a free Green Tick application, a pivotal tool for building trust and credibility on WhatsApp. 
               In contrast, Zoko's Green Tick application might involve additional costs. Furthermore, Pinaippu's wide array of chatbot templates across various sectors and industries makes it suitable for diverse businesses, whereas Zoko is limited to E-commerce workflows, potentially restricting its applicability.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Chatbot Functionality and Marketing Capabilities</h3></span><br />
               Pinaippu stands out with its offering of keyword-based and advanced chatbot capabilities, enabling sophisticated automation. Zoko, however, provides only basic chatbot functionality. 
               In terms of marketing campaigns, Pinaippu provides advanced capabilities crucial for effective audience engagement, while Zoko offers standard marketing campaigns, potentially limiting customization and effectiveness.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Integrations and Support Channels</h3></span><br />
               Pinaippu supports 15+ integrations, providing flexibility in connecting with various platforms, while Zoko primarily focuses on Shopify integration. Regarding support, Pinaippu offers multiple channels for assistance, including WhatsApp, email, and call support.
                Conversely, Zoko offers WhatsApp and email support, but it's not explicitly mentioned whether call support is available, potentially impacting accessibility and varied support options for users.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog10;
