import React from "react";
import profile from "../../../../Assets/profile.png";
import "./ChatList.css";
import { formatDate } from "../../../Common";

export default function ({ data }) {

  return (
    <>
      <div className=" conversation">
        <div className="profileStat">
          <img src={profile} />
          <p className="online"></p>
        </div>
        <div>
          <p className="Head">
            <span className="name">{data?.contact?.name}</span>
            <span className="timeStamp">{formatDate(data?.last_message_time)}</span>
          </p>
          <p className="Head">
            <span className="LatestMessage">{data?.content?.length > 30
              ? `${data?.content.split(" ").slice(0, 3).join(" ")} ...`
              : data?.content}{" "}</span>
            {data?.total_unread_messages != 0 ? <span className="msgcount"> {data?.total_unread_messages}</span> : ""}
          </p>
        </div>
      </div>
    </>
  );
}
