import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  Select,
  InputAdornment,
  TablePagination,
  Paper,
} from "@mui/material";
import { debounce } from "lodash";
import MaterialTable, { MTableToolbar } from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { btnCss, TextfieldCss } from "../../../Styles";
import AddAttribute from "./AddAttribute";
import { notify, formatDate, formatDateOnly, isMobile } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import { loadAttributes as apiLoadAttributes } from "../../../API/OrderAttri";
import { deleteAttribute as apiDeleteAttribute } from "../../../API/OrderAttri";

function OrderAttributes() {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const mobileScreen = isMobile();
  const [deleteData, setDeleteData] = useState(null);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(null);
  const [searchKey, setSearch] = useState(null);

  // to Open modal
  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
  };

  // to close Modal
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const loadAttributes = (param) => {
    apiLoadAttributes(param)
      .then((res) => {
        setAttributes(res.results);
        setCount(res.count);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const delAttribute = (rowData) => {
    apiDeleteAttribute(rowData.id)
      .then((res) => {
        setAnchorEl(null);
        setDeleteData(null);
        loadAttributes(null);
        notify("warn", "Attribute Deleted");
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };
  useEffect(() => {
    if (page && searchKey) {
      loadAttributes(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadAttributes(`page=${page}`);
    }
  }, [page]);

  // columns
  const columns = [
    {
      title: "name",
      field: "name",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p style={{ textTransform: "capitalize" }}>
              {rowData.name.replace(/_/g, " ")}
            </p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Type",
      field: "type",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p style={{ textTransform: "capitalize" }}>{rowData.type}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Created at",
      field: "created_at",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p>{formatDate(rowData.created_at)}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Updated at",
      field: "updated_at",
      render: (rowData) => (
        <div className="column-1">
          {/* <img src={profileUrl} className="profilePic" /> */}
          <span>
            <p>{formatDate(rowData.updated_at)}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delAttribute(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setDeleteData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadAttributes(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    loadAttributes(null);
  }, []);
  return (
    <>
      <div className="pageInNav">
        <p className="pageH">Order Attributes</p>
        <div className="inNavCtr"></div>
      </div>
      <Paper elevation={2} className="table">
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 10px",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: mobileScreen ? "100%" : "315px",
              }}
            >
              <TextField
                style={TextfieldCss}
                size="small"
                variant="outlined"
                placeholder="Search "
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    loadAttributes();
                  }
                }}
              />
            </div>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Attribute
            </Button>
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={attributes ? attributes : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={count ? count : 0}
                  page={page}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              // selection: true,
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,

              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                // borderTop: "1px solid #EDDCDC",
                // BorderBottom: "1px solid #EDDCDC",
                // borderLeft: "1px solid #D0D0D0",
                // borderRight: "1px solid #D0D0D0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
              },
            }}
          />
        </div>
      </Paper>
      <AddAttribute
        open={open}
        handleClose={handleClose}
        loadAttributes={loadAttributes}
        editData={editData}
      />
    </>
  );
}

export default OrderAttributes;
