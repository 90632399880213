import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox,
  Avatar,
  Badge,
  InputAdornment,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { debounce } from "lodash";
import { notify } from "../Common";
import { btnCss, choosebtn, TextfieldCss } from "../../Styles";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../Context/Auth";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ResetPassword from "./ResetPassword";
import { saveUserDetails } from "../../API/AuthLayer";
const EditProfile = () => {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const watchAllFields = watch();

  const { errors } = formState;
  const auth = useAuth();
  const user = auth?.user?.user;
  const userDetails = auth?.userDetails;

  // const user = JSON.parse(localStorage.getItem("userProfile"));
  const [ApiLoading, SetApiLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfile(reader.result);
      setValue("profile_pic", file);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    const buttonType = window.event.submitter.name;
    // console.log("data", data);

    const formData = new FormData();

    if (
      data?.profile_pic instanceof File ||
      data?.profile_pic instanceof Blob
    ) {
      formData.append("profile_pic", data.profile_pic, data.profile_pic.name);
    }
    formData.append("first_name", data.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("email", data.email);
    // console.log("Fdata", formData);
    if (buttonType === "save_user_details") {
      try {
        const res = await saveUserDetails(formData);
        if (res?.ok) {
          const responseData = await res.json();
          const { permission } = userDetails || null;
          const updatedUserDetails = {
            ...responseData,
            permission: permission,
          };
          localStorage.setItem(
            "userDetails",
            JSON.stringify(updatedUserDetails)
          );
          auth.setUserDetails(updatedUserDetails);
        } else {
          // notify("error", "Failed to save User Details");
          throw new Error("Failed to save User Details");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    // console.log("userDetails", userDetails);
    // console.log("permission", permission);
    // console.log("userDetails?.permission", userDetails?.permission);

    setProfile(userDetails?.profile_pic);
    setValue("first_name", userDetails?.first_name || "");
    setValue("last_name", userDetails?.last_name || "");
    setValue("email", userDetails?.email || "");
  }, [userDetails, setValue]);
  return (
    <Paper
      elevation={3}
      style={{
        margin: "20px 0",
        width: "100%",
        padding: "15px",
        display: "grid",
        // placeItems: "center",
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "space-between",
        overflowX: "auto",
        maxWidth: "500px",
        // height: "80vh",
        // maxHeight: "90vh",
      }}
    >
      <p className="pageH">User Details</p>

      <FormProvider>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ maxWidth: "500px", width: "100%" }}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id="image-input"
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="image-input"
                      style={{
                        background: "#e8e8e8",
                        borderRadius: "50%",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <CameraAltIcon />
                    </label>
                  </>
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 0.5,
                    borderRadius: "50%",
                    boxShadow: 4,
                  }}
                >
                  <Avatar
                    alt="profile_pic"
                    src={profile}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
              </Badge>
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                First name
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{
                      width: "100%",
                    }}
                    error={Boolean(errors?.first_name)}
                    helperText={
                      Boolean(errors?.first_name) && "First name is required"
                    }
                  />
                )}
                rules={{ required: true }}
                name="first_name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Last Name
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{
                      width: "100%",
                    }}
                    error={Boolean(errors?.last_name)}
                    helperText={
                      Boolean(errors?.last_name) && "Last name is required"
                    }
                  />
                )}
                rules={{ required: true }}
                name="last_name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Email
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{
                      width: "100%",
                    }}
                    error={Boolean(errors?.email)}
                    helperText={Boolean(errors?.email) && "Email is required"}
                  />
                )}
                rules={{ required: true }}
                name="email"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                type="submit"
                name="save_user_details"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {ApiLoading && (
        <div className="overlay">
          <Spinner_2 style={{ width: "80px" }} />
        </div>
      )}
      {/* <ResetPassword /> */}
    </Paper>
  );
};

export default EditProfile;
