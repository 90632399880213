import React, { useState, useEffect } from "react";
import "../Contacts/Contacts.css";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  Select,
  MenuItem,
  TablePagination,
  Autocomplete,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MaterialTable from "material-table";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SearchIcon from "@mui/icons-material/Search";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useAuth } from "../../Context/Auth";
import { apiFetch, logOut } from "../../API/CommonApi";
import { formatDate } from "../Common";
import { notify } from "../Common";
import { debounce } from "lodash";
import { AddTemplate } from "./AddTemplate";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { loadTemplates as apiLoadTemplates } from "../../API/TemplateLayer";
import { delTemplate as apiDelTemplate } from "../../API/TemplateLayer";
import { sendReview } from "../../API/TemplateLayer";
import WarningIcon from "@mui/icons-material/Warning";
const pending = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#f8662b",
  borderRadius: "60px",
  backgroundColor: "#FFE4D9",
  textAlign: "center",
  padding: "5px",
};
const in_progress = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#7E56DA",
  borderRadius: "60px",
  backgroundColor: "#FBE3FF",
  textAlign: "center",
  padding: "5px",
};
const ready_for_pickup = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#278435",
  borderRadius: "60px",
  backgroundColor: "#F1FFF3",
  textAlign: "center",
  padding: "6px",
};
const delivered = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#F1FFF3",
  borderRadius: "60px",
  backgroundColor: "#278435",
  textAlign: "center",
  padding: "5px",
};
const dot = {
  width: "6px",
  height: "6px",
  position: "relative",
  top: "-2px",
  right: "10px",
};
const btnCss = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "14px",
  padding: "0 15px",
  height: "35px",
  textAlign: "center",
};

function TemplateMessage() {
  const navigate = useNavigate();
  const profileUrl = "https://picsum.photos/200";
  const accessToken = JSON.parse(
    localStorage.getItem("userTokens")
  )?.access_token;
  const [templates, setTemplates] = useState(null);
  const [openNewTemplate, setOpenNewTemplate] = useState(false);

  const [open, setOpen] = useState(false);
  const [tempRowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [searchKey, setSearch] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const handleClose = () => {
    setOpenNewTemplate(false);
  };

  const onSubmit = (data) => {
    // console.log("data", data);
    sendReview(data.id)
      .then((res) => {
        // console.log("res", res);
        loadTemplates();
        notify("success", "Template sent for review");
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      });
  };

  const handleAddorEdit = (data) => {
    setEditData(data);
    setOpenNewTemplate(true);
  };

  const loadTemplates = (param) => {
    apiLoadTemplates(param)
      .then((res) => {
        setTemplates(res);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      });
  };
  // delete Templates

  const delTemplate = (rowData) => {
    apiDelTemplate(rowData.id)
      .then((success) => {
        if (success) {
          setAnchorEl(null);
          loadTemplates();
          notify("warn", "Template Deleted");
        } else {
          notify("error", "Template Not Deleted");
        }
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Failed to delete template");
      });
  };

  const debouncedSearch = debounce((Key) => {
    if (Key) {
      setSearch(Key);
      loadTemplates(`search=${Key}`);
    } else {
      setSearch(null);
    }
  }, 600);
  const debouncedFilter = debounce((Key) => {
    if (Key.value) {
      // console.log("selectedValue", Key);
      setSelectedValue(Key);
      loadTemplates(`status=${Key.value}`);
    } else {
      setSelectedValue(null);
    }
  }, 600);
  useEffect(() => {
    loadTemplates();
  }, [accessToken]);

  useEffect(() => {
    if (page && selectedValue) {
      loadTemplates(`page=${page}&status=${selectedValue}`);
    }
    if (page && searchKey && selectedValue) {
      loadTemplates(`page=${page}&search=${searchKey}&status=${selectedValue}`);
    }
    if (page && searchKey) {
      loadTemplates(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadTemplates(`page=${page}`);
    }
    if (searchKey) {
      loadTemplates(`search=${searchKey}`);
    }
  }, [page]);

  const getTemplateNameTooltip = (status) => {
    const openInNewIcon = (
      <OpenInNewIcon style={{ fontSize: "15px", verticalAlign: "middle" }} />
    );

    if (status === "draft") {
      return (
        <span>
          This template is in draft mode. You can send it to customers only
          after getting it approved from Meta. In order to send the template for
          approval, click on the {openInNewIcon} in this table.
        </span>
      );
    } else if (status === "failed" || status === "rejected") {
      return (
        <span>
          Meta has rejected your template for some reasons. You can update the
          template content and send it again for review.
        </span>
      );
    } else {
      return "";
    }
  };

  const columns = [
    {
      title: "Template Name",
      field: "name",
      render: (rowData) => (
        <div
          className="column-1"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span>
            <p
              style={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              {rowData?.name.replace("_", " ")}
              {rowData?.status === "draft" ||
              rowData?.status === "rejected" ||
              rowData?.status === "failed" ? (
                <Tooltip
                  title={getTemplateNameTooltip(rowData.status)}
                  placement="right"
                >
                  <WarningIcon
                    sx={{
                      marginLeft: "10px",
                      color: "#ffc107",
                      fontSize: "15px",
                    }}
                  />
                </Tooltip>
              ) : null}
            </p>
            <p>Updated on {formatDate(rowData?.updated_at)}</p>
          </span>
        </div>
      ),
    },
    {
      title: "Category",
      field: "category",
      render: (rowData) => (
        <div className="column-2">
          <p>{rowData?.category}</p>
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        if (rowData.status === "rejected") {
          return (
            <p style={pending}>
              <FiberManualRecordIcon style={dot} />
              Rejected
            </p>
          );
        }
        if (rowData.status === "failed") {
          return (
            <p style={pending}>
              <FiberManualRecordIcon style={dot} />
              Failed
            </p>
          );
        }
        if (rowData.status === "draft") {
          return (
            <p style={in_progress}>
              <FiberManualRecordIcon style={dot} />
              Draft
            </p>
          );
        }
        if (rowData.status === "in_review") {
          return (
            <p style={ready_for_pickup}>
              <FiberManualRecordIcon style={dot} />
              In Review
            </p>
          );
        }
        if (rowData.status === "published") {
          return (
            <p style={delivered}>
              <FiberManualRecordIcon style={dot} />
              Published
            </p>
          );
        }
      },
    },

    {
      title: "Created Date",
      field: "createdDate",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.created_at)}</p>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <IconButton
            sx={{
              color: "#de4467",
            }}
            onClick={() => {
              onSubmit(rowData);
            }}
            disabled={
              rowData.status === "in_review" ||
              rowData.status === "published" ||
              rowData.status === "failed"
            }
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton
            sx={{
              color: "#de4467",
            }}
            onClick={() => {
              handleAddorEdit(rowData);
            }}
            disabled={rowData.status === "in_review"}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{
              color: "#de4467",
            }}
            onClick={(event) => {
              setRowData(rowData);
              setAnchorEl(event.currentTarget);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setRowData(null);
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delTemplate(tempRowData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setRowData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  return (
    <>
      <div>
        <div className="pageInNav">
          <div>
            <p className="pageH">Template Messages</p>
            {/* <h3 className="title"> Template Messages</h3> */}
            {/* <p className="subTitle">
              Let’s see an overview of your Template Messages
            </p> */}
          </div>
          <div className="inNavCtr">
            {/* <Button
              variant="outlined"
              size="small"
              sx={{
                marginRight: "35px",
              }}
            >
              DOWNLOAD EXCEL FILE
            </Button> */}
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD TEMPLATE
            </Button>
          </div>
        </div>
        <div className="table">
          <div
            style={{
              display: "flex",
              padding: "15px 10px",
              width: "100%",
              gap: "10px",
            }}
            className="customToolbar"
          >
            <TextField
              style={{
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "16px",
                color: "#464646",
                maxHeight: "35px",
                maxWidth: "315px",
                width: "100%",
                background: "#FFFAFB",
                padding: "dence",
              }}
              size="small"
              variant="outlined"
              placeholder="Search  "
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                debouncedSearch(e.target.value);
                if (!e.target.value) {
                  loadTemplates();
                }
              }}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedValue}
              options={[
                {
                  label: "In Review",
                  value: "in_review",
                },
                {
                  label: "Draft",
                  value: "draft",
                },
                {
                  label: "Published",
                  value: "published",
                },
                {
                  label: "Rejected",
                  value: "rejected",
                },
              ]}
              getOptionLabel={(option) => option.label}
              onChange={(e, data) => {
                debouncedFilter(data);
                if (!data) {
                  loadTemplates();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Filter by status"
                  size="small"
                  fullWidth
                  style={{
                    fontWeight: 400,
                    fontSize: "13px",
                    lineHeight: "16px",
                    color: "#464646",
                    maxHeight: "35px",
                    maxWidth: "315px",
                    width: "100%",
                    minWidth: "200px",
                    background: "#FFFAFB",
                    padding: "dence",
                  }}
                />
              )}
            />
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
              height: "900px",
            }}
            columns={columns}
            data={templates?.results}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={templates?.count}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              // selection: true,
              search: false,
              toolbar: false,
              columnWidth: "auto",
              showTitle: false,
              paging: true,
              pageSize: 10,
              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                // borderTop: "1px solid #EDDCDC",
                // BorderBottom: "1px solid #EDDCDC",
                // borderLeft: "1px solid #D0D0D0",
                // borderRight: "1px solid #D0D0D0",
                backgroundColor: "#FBFBFB",
                zIndex: 2,
              },
            }}
          />
        </div>
      </div>
      <AddTemplate
        loadTemplates={loadTemplates}
        editData={editData}
        openNewTemplate={openNewTemplate}
        setOpenNewTemplate={setOpenNewTemplate}
        setEditData={setEditData}
      />
    </>
  );
}

export default TemplateMessage;
