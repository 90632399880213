import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import bitespeed from "../../Assets/bitespeed.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', bitespeed: '$249/mo' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', bitespeed: 'No' },
    { feature: 'WhatsApp Conversation charges', pinaippu: 'Standard WhatsApp Business conversation charges', bitespeed: 'Standard WhatsApp Businessconversation charges +Additional charges' },
    { feature: 'Workflow Automation', pinaippu: 'Advanced', bitespeed: 'Basic' },    
    { feature: 'WhatsApp Campaigns', pinaippu: 'Advanced', bitespeed: 'Basic' },
    { feature: 'Chatbots ', pinaippu: 'Unlimited', bitespeed: 'Limited' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', bitespeed: 'Ticketing, Email, Call' },
    { feature: 'Chat management', pinaippu: 'Advanced', bitespeed: 'Basic' },
  ];

const ComparisonBlog2 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Bitespeed | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Choose Pinaippu.io over Bitespeed for a cost-effective WhatsApp Business API solution with
                    advanced features, comprehensive user support, and limitless custom fields. Explore the Best
                    WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px", textAlign: "center" }}>Pinaippu Vs Bitespeed</p>
              <img src={bitespeed} style={{ width: "50px", height: "50px" }} alt="Bitespeed" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>                
            <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Bitespeed', field: 'bitespeed' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p className='brand-title' style={{ fontSize: "45px" }}>Bitespeed</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Bitespeed offers a wide range of features, including automated messages, abandoned cart recovery, product recommendations, and more, making it a comprehensive solution for Shopify stores.</li>
                        <li>Bitespeed supports Facebook, WhatsApp, and Instagram, allowing businesses to engage with customers across multiple channels.</li>
                        <li>Bitespeed provides WhatsApp and Facebook chat widgets, enhancing customer engagement and support.</li>
                        <li>Bitespeed offers WhatsApp customer support, WhatsApp Business API setup, and a dedicated account manager, ensuring businesses receive assistance and guidance.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Bitespeed is priced higher than Pinaippu, which might not be suitable for businesses with budget constraints.</li>
                        <li>Bitespeed mentions additional charges for WhatsApp conversations, potentially leading to unexpected costs for businesses.</li>
                        <li>Unlike Pinaippu, Bitespeed does not offer support for Click-to-WhatsApp Ads, which can limit marketing capabilities on WhatsApp.</li>
                        <li>Bitespeed provides basic workflow automation compared to Pinaippu&#39;s advanced automation features.</li>
                    </ul>
                </div>
            </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
              <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
            </div>
            <div class="container">
              <div class="box central-box"></div>
                <div class="box top-left">
                  <p style={{marginTop:'7px'}}><span><h3>WhatsApp Conversation Charges</h3></span><br />
                  Pinaippu and Bitespeed have different approaches to WhatsApp conversation charges. 
                  Pinaippu follows the standard WhatsApp conversation charges, ensuring transparency in pricing for businesses. 
                  This transparent pricing model is advantageous because it prevents businesses from encountering unexpected fees, helping them manage their budgets more effectively.</p>
                </div>
                <div class="box top-right">
                  <p style={{marginTop:'7px'}}><span><h3>Click-to-WhatsApp Ads</h3></span><br />Pinaippu offers robust support for Click-to-WhatsApp Ads, a powerful feature for marketing and customer engagement. 
                  In contrast, Bitespeed lacks this functionality, which limits its marketing capabilities on WhatsApp. 
                  Businesses using Pinaippu can leverage this feature to reach their target audience more effectively and drive customer engagement.</p>
                </div>
                <div class="box bottom-left">
                  <p style={{marginTop:'7px'}}><span><h3>Workflow Automation</h3></span><br />Pinaippu and Bitespeed differ in their offerings related to workflow automation. 
                  Pinaippu provides advanced workflow automation capabilities, allowing businesses to streamline their processes and enhance customer interactions. On the other hand, Bitespeed offers only basic workflow automation, which may not meet the complex needs of businesses with more intricate workflows.</p>
                </div>
                <div class="box bottom-right">
                  <p style={{marginTop:'7px'}}><span><h3>WhatsApp Campaigns and Chat Management</h3></span><br />Pinaippu offers advanced features for WhatsApp campaign management, enabling businesses to execute targeted and effective marketing campaigns. 
                  This can be a significant advantage in customizing and optimizing marketing efforts. Additionally, Pinaippu provides advanced chat management tools, enhancing the efficiency of handling customer inquiries and conversations. 
                  In contrast, Bitespeed offers basic campaign features and chat management, potentially limiting campaign customization and chat efficiency for businesses.</p>
                </div>
          </div>
        </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog2;
