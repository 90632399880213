import React, { useEffect } from "react";

import { Link, Outlet, NavLink, useNavigate } from "react-router-dom";
import NewCampaign from "./Modal/NewCampaign";

// import "./Campaign.css";

function Campaign() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/campaign/history"); // navigate to Overview page by default
  }, []);

  return (
    <>
      <div className="CampaignNav">
        <nav>
          {/* <NavLink to="overView"> Overview </NavLink> */}
          <NavLink to="history">Campaigns</NavLink>
          {/* <NavLink to="scheduleCampaign">Schedule Campaign</NavLink> */}
          {/* <NavLink to="templateMessage">Template Message</NavLink> */}
        </nav>
        {/* 
          <div>
            
            <Button
            variant=""
            sx={{
              width: "33px",
              height: "25px",
              background: "#F3F3F3",
              borderRadius: "5px",
              marginLeft: "35px",
            }}
          >
            <MoreHorizIcon />
          </Button>
          </div>
        */}
      </div>
      <Outlet />
    </>
  );
}

export default Campaign;
