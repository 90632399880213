import React, { useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FACEBOOK_APP_ID } from "../../API/CommonApi";
import { WpAddAccount } from "../../API/AuthLayer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Calendly } from "../../Assets/calendly.svg";
import { CalendlyURL } from "../../API/CommonApi";
const socialButtonCSS = {
  color: "#303535",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  textTransform: "capitalize",
  background: "#25d366",
  minWidth: "200px",
  height: "50px",
  border: " 1px solid #E8E8E8",
  borderRadius: "5px",
};
const socialButtonCSSDemo = {
  color: "#303535",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  textTransform: "capitalize",
  background: "#25d366",
  minWidth: "200px",
  height: "50px",
  border: " 1px solid #E8E8E8",
  borderRadius: "5px",
  textAlign: "center",
};
export const WhatsAppSignUp = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: FACEBOOK_APP_ID,
  //       autoLogAppEvents: true,
  //       xfbml: true,
  //       version: "v15.0",
  //     });
  //   };
  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) return;
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }, []);
  // const launchWhatsAppSignup = () => {
  //   console.log("button clicked");
  //   window.FB.login(
  //     function (response) {
  //       console.log("FB.login");
  //       console.log("response", response);

  //       if (response.authResponse) {
  //         const accessToken = response.authResponse.accessToken;
  //         console.log(" accessToken", accessToken);
  //         console.log("response", response);
  //         WpAddAccount(accessToken).then((res) => {
  //           if (res.ok) {
  //             console.log("res", res);
  //             navigate("/conversations", { replace: true });
  //             return response.json();
  //           } else {
  //             throw new Error("something went wrong", response);
  //           }
  //         });
  //         // Use this token to call the debug_token API and get the shared WABA's ID
  //       } else {
  //         console.log("User cancelled login or did not fully authorize.");
  //       }
  //     },
  //     {
  //       scope:
  //         "whatsapp_business_messaging,whatsapp_business_management,business_management,email,public_profile",
  //       extras: {
  //         feature: "whatsapp_embedded_signup",
  //         // Display new flow to user
  //         version: 2,
  //         setup: {
  //           //... // Prefilled data
  //         },
  //       },
  //     }
  //   );
  // };

  function openWidget() {
    document
      .querySelector(
        'button img[src="https://pinaippu.io/widget/WhatsAppIcon.png"]'
      )
      .parentElement.click();
  }
  return (
    <div>
      <Box
        sx={{
          bgcolor: "background.paper",

          p: 4,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column ",
          }}
        >
          {/* <p style={{ marginBottom: "15px" }}>
            You don't have a whatsapp Business account. Register here
          </p>
          <Button sx={socialButtonCSS} onClick={launchWhatsAppSignup}>
            <WhatsAppIcon /> &nbsp; Connect with WhatsApp &nbsp;
          </Button> */}
          <Typography variant="h2">Thank you!</Typography>
          <p
            style={{
              marginBottom: "15px",
              maxWidth: "600px",
              textAlign: "center",
            }}
          >
            For signing up with us. Please contact us to setup your account
          </p>

          {/*
          Thank you for signing up with us. To connect your WhatsApp number
            with PINAIPPU, please click the link below to schedule a call with
            our team.

           <Button
            className="no-underline "
            style={{
              color: "#005fe6",
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "17px",
              textTransform: "capitalize",
              background: "#ffff",
              minWidth: "200px",
              height: "50px",
              border: " 1px solid #E8E8E8",
              borderRadius: "30px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
            }}
            href={CalendlyURL}
            target="_blank_"
          >
            <span style={{ width: "30px", height: "30px" }}>
              <Calendly />
            </span>
            <span>&nbsp; Schedule call &nbsp;</span>
          </Button> */}
          {
            <Button sx={socialButtonCSS} onClick={openWidget}>
              <WhatsAppIcon /> &nbsp; Connect with WhatsApp &nbsp;
            </Button>
          }
        </div>
      </Box>
    </div>
  );
};
