import { useState, useEffect } from "react";
import "./DashBoard.css";
import ChatList from "./ChatBox/ChatList/ChatList";
import ChatContent from "./ChatBox/ChatContent/ChatContent";
import UserProfile from "./ChatBox/UserProfile/UserProfile";
import EmptyPage from "./ChatBox/EmptyPage/EmptyPage";
import { useAuth } from "../../Context/Auth";
import { apiFetch } from "../../API/CommonApi";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { checkwhatsAppBusinessAcc as WPCheck } from "../../API/AuthLayer";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../Common";
import { useChatLayer } from "../../API/useChatLayer";
export default function DashBoard() {
  const auth = useAuth();
  const { getContactChatList } = useChatLayer();
  const navigate = useNavigate();
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [renderFlag, setRenderFlag] = useState(false);

  const [WpDetails, setWpDetails] = useState(null);
  const mobileScreen = isMobile();

  useEffect(() => {
    WPCheck()
      .then((res) => {
        if (typeof res === "object" && res?.length !== 0) {
          auth.setWpDetails(res);
          setWpDetails(res?.[0]);
        } else {
          navigate("/whatsapp-signup");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    auth.setConversationspage("chatList");
    auth.setSelectedChat(null);
  }, []);

  const fetchAssigneeOptions = () => {
    apiFetch("GET", "users", null, true)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAssigneeOptions(data?.results);
      })
      .catch((error) => {
        console.error("Error fetching assignee options:", error);
      });
    fetchTeamOptions();
  };
  const fetchTeamOptions = () => {
    apiFetch("GET", "teams", null, true)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setTeamOptions(data?.results);
      })
      .catch((error) => {
        console.error("Error fetching team options:", error);
      });
  };

  useEffect(() => {
    if (auth.isLoading) return;
    getContactChatList();
    fetchAssigneeOptions();
  }, [auth.isLoading, renderFlag]);

  return auth.wpDetails ? (
    <>
      <div style={{ height: "94vh", display: "flex", flexFlow: "column" }}>
        {mobileScreen ? (
          <div className="container">
            {auth.conversationsPage === "chatList" ? <ChatList /> : ""}
            {auth.conversationsPage === "chatContent" ? (
              auth.selectedChat ? (
                <ChatContent />
              ) : (
                <EmptyPage />
              )
            ) : (
              ""
            )}

            {auth.conversationsPage === "profile" ? (
              auth.selectedChat ? (
                <UserProfile
                  assigneeOptions={assigneeOptions}
                  teamOptions={teamOptions}
                  renderFlag={renderFlag}
                  setRenderFlag={setRenderFlag}
                />
              ) : (
                <EmptyPage />
              )
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="container">
            <ChatList />
            {auth.selectedChat ? (
              <>
                <ChatContent />
                <UserProfile
                  assigneeOptions={assigneeOptions}
                  teamOptions={teamOptions}
                  renderFlag={renderFlag}
                  setRenderFlag={setRenderFlag}
                />
              </>
            ) : (
              <div className="midleContainer container2">
                <EmptyPage />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner_2 style={{ width: "80px", height: "94vh" }} />
    </div>
  );
}
