import {
  Button,
  Grid,
  Paper,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Fab,
  Zoom,
} from "@mui/material";
import { useEffect, useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { ReactComponent as SignUPBG } from "../../Assets/SignUPBG.svg";
import { ReactComponent as Conversation } from "../../Assets/conversation.svg";
import Frame_55_n from "../../Assets/Frame_55_n.png";
import Frame_54_n from "../../Assets/Frame_54_n.png";
import f1_n from "../../Assets/f1_n.png";
import f2_n from "../../Assets/f2_n.png";
import f3_n from "../../Assets/f3_n.png";
import f4_n from "../../Assets/f4_n.png";
import f5_n from "../../Assets/f5_n.png";
import f6_n from "../../Assets/f6_n.png";
import f7_n from "../../Assets/f7_n.png";
import b1_n from "../../Assets/b1_n.png";
import b2_n from "../../Assets/b2_n.png";
import b3_n from "../../Assets/b3_n.png";
import b4_n from "../../Assets/b4_n.png";
import b5_n from "../../Assets/b5_n.png";

import undraw_chatting_re_j55r_1_n from "../../Assets/undraw_chatting_re_j55r_1_n .png";
import undraw_messages_re_qy9x_1_n from "../../Assets/undraw_messages_re_qy9x_1_n.png";
import { CalendlyURL } from "../../API/CommonApi";
import { Link } from "react-router-dom";
import { DynamicStar } from "react-dynamic-star";
import { blogList } from "./BlogList";
import { ReactComponent as ArrowIcon } from "../../Assets/icons/ArrowIcon.svg";
import RequestDemoForm from "../RequestDemo/RequestDemoForm";
import UseWindowSize from "../UseWindowSize";
import { useAuth } from "../../Context/Auth";
import { isMobile } from "../Common";
import "./Home.css";
import Testimonials from "./Testimonials";

const SpecialsRight = {
  marginTop: "50px",
  display: "flex",
  justifyContent: "right",
  width: "100%",
  flexWrap: "wrap",
};
const SpecialsLeft = {
  marginTop: "50px",
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
};

const testimonialData = [
  {
    name: "Deepika Ganesan",
    designation: "Communication Coach",
    company: {
      title: "Practills Learning",
      link: "http://practills.com/",
    },
    text: "Pinaippu has proven to be an invaluable asset for our small organization, bringing us countless benefits. Previously, we would spend a significant amount of money on promotional efforts, but now we can achieve the same results with just a fraction of the cost. What's even more impressive is that Pinaippu has gone above and beyond by customizing certain features specifically for our needs. This level of personalization has truly elevated our experience. And whenever we encounter any challenges, the Pinaippu team has been there for us, providing exceptional support and guidance. We couldn't be happier with our partnership.",
  },
  {
    name: "J Balasubramaniam",
    designation: "Director",
    company: {
      title: "VB DACE",
      link: "http://vbdace.com/",
    },
    text: "Pinnaipu's dedication was evident in their exceptional service. Their prompt response, adaptability to customization needs, and the valuable support with manpower for messaging truly set them apart. We're grateful for the outstanding partnership.",
  },
  {
    name: "Adil Kootteeri",
    designation: null,
    company: {
      title: "Tycoon Real Estate",
      link: "https://tycoonproperties.ae/",
    },
    text: "Pinaippu has been a great help for us in reaching out to our customers to keep them posted on our business updates. Their easy-to-use interface and helpful support team are the perfect traits that you look for in a tech partner.",
  },
];

export default function Home() {
  const auth = useAuth();
  const [screenWidth, screenHeight] = UseWindowSize();
  useEffect(() => {
    auth.RedirectBasedOnDomain();
  }, []);

  return (
    <div
      style={{
        padding: isMobile() ? "40px 8px 8px 8px" : "100px 0 0 0",
        color: "#301447",
        overflowX: "hidden",
      }}
    >
      <div>
        <img
          src={Frame_55_n}
          alt="Frame_55_n"
          style={{
            float: "right",
            top: 30,
          }}
        />
      </div>

      <div
        style={{
          color: "#301447",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          paddingTop: "100px",
        }}
      >
        <div style={{ paddingLeft: "80px" }} className="bg_img">
          <h1 style={{ fontSize: "80px", fontWeight: "900px" }}>
            The <span style={{ color: "#16BE59" }}>WhatsApp</span> way to
            <br /> connect with customers
          </h1>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <img
                src={undraw_chatting_re_j55r_1_n}
                alt="undraw_chatting_re_j55r_1_n"
                style={{
                  paddingTop: "50px",
                }}
              />
            </div>
            <div>
              <p style={{ fontSize: "40px", fontWeight: "700px" }}>
                Build your customer relationship at ease with our
                <br />
                end to end Whatsapp
                <span style={{ color: "#791656" }}>
                  <strong> marketing</strong>{" "}
                </span>{" "}
                platform.
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px",
                  marginTop: "40px",
                }}
              >
                <Link to="/signup" className="no-underline">
                  <Button class="SignButton">Sign Up</Button>
                </Link>
                <a
                  href={CalendlyURL}
                  className="no-underline"
                  sx={{
                    margin: "10px",
                    color: "#301447",
                    minWidth: "100px",
                    minHeight: "60px",
                  }}
                  target="_blank"
                >
                  <p style={{ fontSize: "20px", paddingLeft: "20px" }}>
                    Request Demo
                  </p>
                </a>
              </div>
            </div>
            <div>
              <img
                src={undraw_messages_re_qy9x_1_n}
                alt="undraw_messages_re_qy9x_1_n"
                style={{
                  paddingTop: "50px",
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <img
            src={Frame_54_n}
            alt="Frame_54_n"
            style={{
              position: "absolute",
              left: -290,
              marginTop: "-100px",
              maxHeight: "400px",
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <div style={{ paddingTop: "90px" }}>
            <p style={{ fontSize: "50px" }}>
              <strong>Our Features</strong>
            </p>
            <div style={{ padding: "80px 180px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar
                          src={f1_n}
                          alt="f1"
                          className="featureImage"
                          class="featureImage"
                        />
                        <Typography class="featureDes">
                          Shared inboxes for effortless team collaboration with
                          options to assign chats and send private notes
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f2_n} alt="f2" class="featureImage" />
                        <Typography class="featureDes">
                          Send personalised interactive messages to all your
                          customers in a single click using Campaigns
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f3_n} alt="f3" class="featureImage" />
                        <Typography class="featureDes">
                          Automate the follow-up system based on your sales
                          process and business needs to keep your customers
                          informed at every step.
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f4_n} alt="f4" class="featureImage" />
                        <Typography class="featureDes">
                          Roadmap features Shopify & Woocommerce integrations
                          Campaign analytics Automated Campaigns
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f5_n} alt="f5" class="featureImage" />
                        <Typography class="featureDes">
                          An in-built CRM where you can segment contacts into
                          target audiences.
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f6_n} alt="f6" class="featureImage" />
                        <Typography class="featureDes">
                          Set Auto-Replies to answer Frequently Asked Questions
                          on WhatsApp and provide a more complete 24/7 customer
                          service.
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Avatar src={f7_n} alt="f7" class="featureImage" />
                        <Typography class="featureDes">
                          Retarget campaigns to retarget a particular set of
                          users with another Broadcast to increase the chances
                          of conversions.
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* <div style={{height:"700px",backgroundColor:"#E6D3DF"}} className='bg_img1'>
                <h2 style={{alignContent:"center",paddingTop:"40px",fontSize:"40px"}}>Industries where Pinaippu can be used</h2>
              </div> */}
        </div>
        <div style={{ paddingTop: "40px" }}>
          <p style={{ fontSize: "50px" }}>
            <b>Benefits</b>
          </p>
          <div style={{ padding: "40px 140px", marginTop: "30px" }}>
            <Grid container spacing={4}>
              <Grid item xs={2.4}>
                <img src={b1_n} alt="b1" class="customImage" />
                <Typography sx={{ fontSize: "30px" }}>
                  <b>
                    <span style={{ fontSize: "80px" }}>60%</span>
                    <br /> Reduced CAC
                  </b>
                </Typography>
              </Grid>

              <Grid item xs={2.4}>
                <img src={b2_n} alt="b2" class="customImage" />
                <Typography sx={{ fontSize: "30px" }}>
                  <b>
                    <span style={{ fontSize: "80px" }}>60%</span>
                    <br />
                    Increased CSAT rating
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                <img src={b3_n} alt="b3" class="customImage" />
                <Typography sx={{ fontSize: "30px" }}>
                  <b>
                    <span style={{ fontSize: "80px" }}>50%</span>
                    <br />
                    Faster Checkout
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                <img src={b4_n} alt="b4" class="customImage" />
                <Typography sx={{ fontSize: "30px" }}>
                  <b>
                    <span style={{ fontSize: "80px" }}>60%</span>
                    <br />
                    Time saved in following up
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={2.4}>
                <img src={b5_n} alt="b5" class="customImage" />
                <Typography sx={{ fontSize: "30px" }}>
                  <b>
                    <span style={{ fontSize: "80px" }}>60%</span>
                    <br />
                    Increased reach to promotions
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div>
          <img
            src={Frame_55_n}
            alt="Frame_55_n"
            style={{
              float: "right",
            }}
          />
        </div>

        <Testimonials testimonialData={testimonialData} />
        <div>
          <img
            src={Frame_54_n}
            alt="Frame_54_n"
            style={{
              position: "absolute",
              left: -290,
              marginTop: "-100px",
              maxHeight: "400px",
              maxWidth: "100%",
              height: "auto",
            }}
          />
          <div style={{ paddingTop: "90px" }}>
            <p style={{ fontSize: "50px" }}>
              <b>Our Latest Blogs</b>
            </p>
          </div>
          <div style={{ paddingTop: "40px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Grid item xs={3}>
                <a href={blogList[0].link} target="_blank" className="blogLink">
                  <Card className="blog">
                    <CardMedia
                      component="img"
                      height="200"
                      image="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*TNqYQcRTBEwzVov-sCgdYw.png"
                    />
                    <CardContent>
                      <p className="blogHeading">{blogList[0].heading}</p>

                      <p className="blogSubHead">
                        {blogList[0].description
                          .split(" ")
                          .slice(0, 15)
                          .join(" ")}
                        ...
                      </p>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
              <Grid item xs={3}>
                <a href={blogList[1].link} target="_blank" className="blogLink">
                  <Card className="blog">
                    <CardMedia
                      component="img"
                      height="200"
                      image="https://miro.medium.com/v2/resize:fit:828/format:webp/1*MyOVCq7H6C9KcS0Aw0SJIQ.png"
                    />
                    <CardContent>
                      <p className="blogHeading">{blogList[1].heading}</p>

                      <p className="blogSubHead">
                        {blogList[1].description
                          .split(" ")
                          .slice(0, 15)
                          .join(" ")}
                        ...
                      </p>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
              <Grid item xs={3}>
                <a href={blogList[2].link} target="_blank" className="blogLink">
                  <Card className="blog">
                    <CardMedia
                      component="img"
                      height="200"
                      image="https://miro.medium.com/v2/resize:fit:828/format:webp/1*FU3Hs02hEX7rdhKWI4xAmg.png"
                    />
                    <CardContent>
                      <p className="blogHeading">{blogList[2].heading}</p>

                      <p className="blogSubHead">
                        {blogList[2].description
                          .split(" ")
                          .slice(0, 15)
                          .join(" ")}
                        ...
                      </p>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
