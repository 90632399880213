import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';

import delightchat from "../../Assets/delightchat.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', delightchat: '$299/mo' },
    { feature: 'Green Tick Application', pinaippu: 'Free', delightchat: 'Free' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', delightchat: 'No' },
    { feature: 'Self-Service Widget', pinaippu: 'Yes', delightchat: 'No' },    
    { feature: 'Number of contacts', pinaippu: 'Unlimted', delightchat: 'Limited' },
    { feature: 'Drip marketing( Sequence-based )', pinaippu: 'Yes', delightchat: 'No' },
    { feature: 'Chatbot', pinaippu: 'Advanced + Keyword based', delightchat: 'Basic' },
    { feature: 'Automated replies', pinaippu: 'Yes', delightchat: 'No' },
    { feature: 'Integrations', pinaippu: '15+', delightchat: 'Limited' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', delightchat: 'Ticketing, Email, Call' },
  ];

const ComparisonBlog5 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to DelighChat | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Choose Pinaippu.io over DelightChat for a cost-effective WhatsApp Business API
                    solution with Click-to-WhatsApp Ads support, advanced marketing features, and
                    comprehensive customer support. Discover the best WhatsApp Business API solution
                    today!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px", textAlign: "center"  }}>Pinaippu Vs Delightchat</p>
              <img src={delightchat} style={{ width: "50px", height: "50px" }} alt="delightchat" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'DelightChat', field: 'delightchat' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Delightchat</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Robust features tailored for WhatsApp and messaging-based customer support and marketing.</li>
                        <li>Green Tick Application is included.</li>
                        <li>The number of contacts might suffice for many businesses.</li>
                        <li>Offers advanced support channels like WhatsApp group.</li>
                        <li>Provides a dedicated account manager.</li>
                        <li>Supports automated onboarding with personalized setup.</li>
                        <li>Offers WhatsApp API integration.</li>
                        <li>Suitable for businesses with extensive WhatsApp support and marketing needs.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Significantly higher monthly cost compared to Pinaippu.</li>
                        <li>Expensive additional user pricing.</li>
                        <li>Limited or no support for certain features like Click-to-WhatsApp Ads, self-service widgets, and drip marketing.</li>
                        <li>Basic chatbot capabilities.</li>
                        <li>Fewer integrations compared to Pinaippu.</li>
                        <li>Limited information about response time SLAs.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Pricing and Cost-Effectiveness</h3></span><br />
                Pinaippu.io stands out for its significantly lower pricing compared to DelightChat, making it a cost-effective choice for businesses, particularly those operating within tight budget constraints.
                 This stark difference in pricing could be a compelling factor for startups and small businesses seeking robust communication solutions without exorbitant expenses.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Customer Scalability and Marketing Features</h3></span><br />
               Pinaippu.io's provision of unlimited contacts allows businesses to scale their customer base without additional costs or limitations. In contrast, DelightChat restricts the number of contacts, posing potential constraints for businesses with extensive customer databases.
                Moreover, Pinaippu.io supports sequence-based drip marketing campaigns, a personalized and automated approach for customer engagement, which DelightChat lacks.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Chatbot Capabilities and Automated Responses</h3></span><br />
                The platform's advanced chatbot capabilities, especially its keyword-based functionalities, empower businesses to effectively automate responses and interactions with customers. In contrast, DelightChat offers only basic chatbot functionality. 
                Additionally, Pinaippu.io includes automated replies, a feature that streamlines customer interactions and response times, while DelightChat lacks this specific feature.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Unique Features and Integrations</h3></span><br />
               Pinaippu.io's support for Click-to-WhatsApp Ads is a valuable asset for businesses aiming to drive traffic and engage with customers through WhatsApp. This feature is absent in DelightChat. Furthermore, Pinaippu.io offers a self-service widget, enhancing the customer experience by allowing users to independently access information and assistance, a feature not provided by DelightChat. 
               Lastly, while both platforms support integrations, Pinaippu.io offers a broader spectrum with 15+ integrations, providing businesses with a more extensive range of connectivity options compared to the limited integrations supported by DelightChat.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog5;
