import React, { memo, useState, useEffect } from "react";
import {
  Handle,
  useReactFlow,
  useStoreApi,
  Position,
  ReactFlowProps,
} from "reactflow";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { useAuth } from "../../../Context/Auth";

const buttonNode = {
  backgroundColor: "#f2f2f2",
  // border: "2px solid #da1e7a",
  // width: "10px",
  // height: "10px",
  padding: "7px",
  borderRadius: "8px",
  margin: "5px",
  textAlign: "center",
};
const handleStyle = {
  right: "-6px",
  width: "6px",
  height: "10px",
  borderRadius: " 2px",
  backgroundColor: "#da1e7a",
};
const rightHandleStyle = {
  left: "-6px",
  width: "6px",
  height: "10px",
  borderRadius: " 2px",
  backgroundColor: "#da1e7a",
};
function ButtonNode({ value, handleId, nodeId, name }) {
  const store = useStoreApi();
  return (
    <div className="custom-node__btn">
      <p style={buttonNode}>{name}</p>
      <Handle
        type="source"
        position={Position.Right}
        id={handleId}
        style={handleStyle}
      />
    </div>
  );
}

function CustomNode({ id, data }) {
  const context = useAuth();

  // const [isClicked, setClicked] = useState(false);
  // const handleOnclickCard = () => {
  //   console.log("clicked");
  //   setClicked((prevState) => !prevState);
  // };
  const { deleteElements, getNodes, getNode } = useReactFlow();

  const deleteCard = () => {
    const currentNode = getNode(id);
    deleteElements({ nodes: [{ id: currentNode.id }] });
  };

  const editCard = () => {
    const currentNode = getNode(id);
    context.setEditCardData(currentNode);
    deleteElements(currentNode);
  };
  return (
    <>
      {data?.default_handles?.inId && (
        <Handle
          type="target"
          position={Position.Left}
          id={data?.default_handles?.inId}
          style={rightHandleStyle}
        />
      )}
      {data?.default_handles?.outId && (
        <Handle
          type="source"
          position={Position.Right}
          style={handleStyle}
          id={data?.default_handles?.outId}
        />
      )}
      {data.name === "start" ? (
        <div className="custom-node__starting_card">
          <PlayArrowIcon
            style={{ height: "40px", width: "auto", color: "#ffff" }}
          />
          {/* <p style={{ fontSize: "20px" }}>Start</p> */}
        </div>
      ) : (
        <div className={`custom-node__outerBody`}>
          <div className="custom-node__HnMCtr">
            <div className="custom-node__header">
              <span>{data?.name}</span>{" "}
              <span>
                <IconButton
                  style={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    editCard(id);
                  }}
                >
                  <EditIcon style={{ width: "15px" }} />
                </IconButton>
                <IconButton
                  style={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    deleteCard(id);
                  }}
                >
                  <DeleteIcon style={{ width: "15px" }} />
                </IconButton>
              </span>
            </div>
            {data.header_type && data.header_file && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {data.header_type === "image" ? (
                  <img
                    src={data.header_file}
                    style={{
                      maxWidth: "270px",
                      margin: "auto",
                      marginTop: "5px",
                      borderRadius: "4px",
                    }}
                  />
                ) : data.header_type === "document" ? (
                  <>
                    <a href={data.header_file} target="_blank">
                      <PictureAsPdfIcon
                        style={{ width: "100px", height: "100px" }}
                      />
                    </a>
                  </>
                ) : (
                  <a href={data.header_file} target="_blank">
                    <VideoFileIcon
                      style={{ width: "100px", height: "100px" }}
                    />
                  </a>
                )}
              </div>
            )}
            <div style={{ padding: "10px" }}>{data?.message}</div>
          </div>
          <div className="custom-node__buttons">
            {data?.options?.map((opt) => (
              <ButtonNode
                key={opt.id}
                nodeId={id}
                name={opt?.option_text}
                handleId={opt?.handle_id}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default memo(CustomNode);
