import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
} from "@mui/material";
import { add, debounce, set } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, TextfieldCss } from "../../../Styles";
import { notify } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import { ClsBtn } from "../../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { createAttribute as apiCreateAttribute } from "../../../API/customAttr";
import { editAttribute as apiEditAttribute } from "../../../API/customAttr";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function AddAttribute({ handleClose, open, loadAttributes, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;

  const { errors } = formState;
  const [editId, setEditID] = useState(null);
  const typeOptions = [
    { name: "Text", value: "text" },
    { name: "Number", value: "number" },
    { name: "Date", value: "date" },
    { name: "Dropdown", value: "dropdown" },
  ];
  const [ApiLoading, SetApiLoading] = useState(false);
  const [type, setType] = useState("text");
  const [values, setValues] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([""]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const HandleOnClose = () => {
    setIsAddButtonDisabled(false);
    setType("text");
    setValues([]);
    setAdditionalFields([""]);
    reset();
    handleClose();
  };

  const createAttribute = (data) => {
    SetApiLoading(true);
    if (data) {
      if (data.type && data.type.value === "dropdown") {
        let options = values.map((v) => v.trim().replace(/\s+/g, " "));
        data.values = options.concat(additionalFields);
      }

      apiCreateAttribute(data)
        .then((res) => {
          notify("success", "Attribute Created");
          handleClose();
          loadAttributes(null);
          reset();
          SetApiLoading(false);
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", error.message);
        });
    }
  };

  const editAttribute = (data) => {
    SetApiLoading(true);

    if (data) {
      if (data.type && data.type.value === "dropdown") {
        data.values = additionalFields;
      }

      apiEditAttribute(editId, data)
        .then((res) => {
          notify("success", "Attribute Updated");
          handleClose();
          loadAttributes(null);
          reset();
          SetApiLoading(false);
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", error.message);
        });
    }
  };

  const addAdditionalField = () => {
    setAdditionalFields([...additionalFields, ""]);
  };

  const removeAdditionalField = (indexToRemove) => {
    setAdditionalFields((prevFields) =>
      prevFields.filter((field, index) => index !== indexToRemove)
    );
  };

  const handleAdditionalFieldChange = (e, indexToUpdate) => {
    if (indexToUpdate === 0 && e.target.value.length === 0) {
      setIsAddButtonDisabled(true);
    } else {
      setIsAddButtonDisabled(false);
    }
    const updatedFields = [...additionalFields];
    updatedFields[indexToUpdate] = e.target.value;
    setAdditionalFields(updatedFields);
  };

  useEffect(() => {
    if (editData) {
      const name = editData.name
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase());
      setValue("name", name);
      setEditID(editData.id);
      const preType = typeOptions.find((p) => p.value === editData.type);
      setValue("type", preType);
      setType(preType.value);
      if (editData.type === "dropdown" && editData.options) {
        setAdditionalFields(editData.options);
        setValues(editData.options);
        setValue("options", editData.options);
      } else {
        setValues([]);
        setValue("options", []);
      }
    }
  }, [editData]);

  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        style={{
          // margin: "20px 40px",
          width: "100%",
          //   minHeight: "300px",
          maxWidth: "400px",
          padding: "20px",
          // height: "100%",
        }}
      >
        <FormProvider {...methods}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Attributes</h2>
            <CloseIcon
              style={ClsBtn}
              className="Icon2"
              onClick={HandleOnClose}
            />
          </div>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Field Type
            </InputLabel>
            <Controller
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  value={value}
                  options={typeOptions}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, data) => {
                    onChange(data);
                    setType(data.value);
                    if (data.value === "dropdown") {
                      setIsAddButtonDisabled(true);
                      setValues([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={Boolean(errors.type)}
                      helperText={errors.type && "Type is required"}
                    />
                  )}
                />
              )}
              rules={{ required: "Type is required" }}
              name="type"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Name
            </InputLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  sx={{ maxWidth: "412px", marginBottom: "10px" }}
                  error={Boolean(errors.name)}
                  helperText={errors?.name && "Name is required"}
                />
              )}
              rules={{ required: true }}
              name="name"
              control={control}
            />
          </Grid>
          {type === "dropdown" && (
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Options
                </InputLabel>
                <IconButton
                  onClick={addAdditionalField}
                  disabled={additionalFields[0] == "" ? true : false}
                  style={{
                    color: additionalFields[0] == "" ? "#CCCCCC" : "#25d366",
                  }}
                >
                  <AddCircleIcon style={{ height: "20px", width: "20px" }} />
                </IconButton>
              </div>
              {additionalFields.map((field, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px", marginBottom: "10px" }}
                    value={field}
                    onChange={(e) => handleAdditionalFieldChange(e, index)}
                    helperText={
                      additionalFields[0] == ""
                        ? "Please enter atleast one option"
                        : null
                    }
                  />
                  <IconButton
                    onClick={() => removeAdditionalField(index)}
                    disabled={additionalFields.length > 1 ? false : true}
                    style={{
                      color:
                        additionalFields.length > 1 ? "#df1d5a" : "#CCCCCC",
                    }}
                  >
                    <RemoveCircleIcon
                      style={{ height: "20px", width: "20px" }}
                    />
                  </IconButton>
                </Box>
              ))}
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            {editData ? (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                onClick={handleSubmit(editAttribute)}
              >
                Update Attribute
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                onClick={handleSubmit(createAttribute)}
                disabled={isAddButtonDisabled}
              >
                ADD Attribute
              </Button>
            )}
          </Grid>
          {/* </Grid> */}
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </Paper>
    </Modal>
  );
}

export default AddAttribute;
