import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import respondio from "../../Assets/respond-io.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', respond: '$79/mo' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', respond: 'No' },
    { feature: 'Green Tick Application', pinaippu: 'Free', respond: 'Additional charges' },
    { feature: 'Marketing campaigns', pinaippu: 'Advanced', respond: 'Standard' },    
    { feature: 'Chatbot', pinaippu: 'Advanced + Keyword bases', respond: 'Advanced' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', respond: 'Ticketing, Email' },
    { feature: 'Zoom Call/ Gmeet Support', pinaippu: 'Yes', respond: 'No' },
  ];

const ComparisonBlog7 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Respond.io | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Choose Pinaippu.io over Respond.io for a cost-effective WhatsApp Business API
                    solution with advanced features, comprehensive user support, and limitless custom
                    fields. Explore the Best WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px" }}>Pinaippu Vs Respond.<span style={{ textTransform: 'lowercase' }}>io</span></p>
              <img src={respondio} style={{ width: "55px", height: "50px" }} alt="respondio" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Respond.io', field: 'respond' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Respond.<span style={{ textTransform: 'lowercase' }}>io</span></p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Offers support for a wide range of messaging channels, including WhatsApp, Facebook,Instagram, and more.</li>
                        <li>Provides advanced reporting and data export features for in-depth analytics.</li>
                        <li>Offers a free 14-day trial, allowing users to explore the platform before committing.</li>
                        <li>Has a variety of integrations, including CRM syncing and third-party applications.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Pricing may be higher compared to Pinaippu, potentially impacting affordability.</li>
                        <li>Additional charges may apply for services like the Green Tick Application.</li>
                        <li>Lacks support for Click-to-WhatsApp Ads, limiting advertising options on WhatsApp.</li>
                        <li>Does not offer Zoom Call or Gmeet support, potentially reducing accessibility for customer assistance.</li>
                    </ul>
                </div>
            </div>
              </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Pricing and Cost-Effectiveness</h3></span><br />
                Pinaippu enters the market with competitive pricing starting at ₹1699/month, providing a cost-effective solution suitable for businesses of varying sizes. In contrast, Respond.io's pricing commences at $79/month, potentially rendering it less budget-friendly for certain businesses. 
                This difference in pricing structure could significantly impact the affordability and accessibility of these platforms for different business scales.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Marketing Capabilities and Advertisement Tools</h3></span><br />
               Pinaippu distinguishes itself by supporting Click-to-WhatsApp Ads, offering businesses the capability to generate, execute, and track ads on the powerful WhatsApp platform. This feature serves as a robust marketing tool. 
               In contrast, Respond.io lacks this specific feature, limiting advertising opportunities on WhatsApp and potentially constraining businesses in their marketing endeavors on this prominent messaging platform.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3> Marketing Campaigns and Support Services</h3></span><br />
               Pinaippu not only provides advanced marketing campaign features but also categorizes its tools and capabilities as advanced, potentially offering businesses a more comprehensive set of options for their marketing strategies. In terms of support services, Pinaippu offers a multi-channel approach through ticketing, email, and call support, ensuring businesses have varied avenues to seek assistance. 
               On the other hand, Respond.io provides ticketing and email support but lacks phone call support, which could affect accessibility and immediacy in issue resolution for businesses.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Communication Tools and Real-Time Support</h3></span><br />
                Pinaippu further stands out by offering support for Zoom Calls and Gmeet, providing users additional options for real-time communication with support teams. This added communication channel offers users enhanced flexibility and potential ease of interaction. 
                Conversely, Respond.io doesn't offer these communication options, potentially limiting the ease and efficiency of communication for users seeking real-time support and assistance.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog7;
