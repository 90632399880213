import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, TextField, IconButton, InputAdornment } from '@mui/material';
import { btnCss } from '../../Styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setPassword } from '../../API/AuthLayer';
import { notify } from '../Common';
import Login from '../Login/Login';
import { set } from 'lodash';

function SetPassword() {
  const navigate = useNavigate();
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword1, setShowpwd1] = useState(false);
  const handleClickShowPassword1 = () => {
    setShowpwd1(!showPassword1);
  };
  const [showPassword2, setShowpwd2] = useState(false);
  const handleClickShowPassword2 = () => {
    setShowpwd2(!showPassword2);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (newPassword.length < 8 || confirmPassword.length < 8) {
      setError('Passwords must be at least 8 characters long.');
      return;
    }
    setPassword(token, newPassword)
        .then((res) => {
            console.log("res", res);
            if (res.ok) {
                notify('success', 'Password set successfully');
                navigate('/login');
            } else {
                throw new Error('Failed to set password');
            }
            })
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '200px',
        height: '50vh',
      }}
    >
      <Box container>
        <form onSubmit={handleSubmit}>
          <h2 style={{ marginBottom: '30px' }}>Set Your Password</h2>
          <TextField
            type={showPassword1 ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            size="small"
            fullWidth
            sx={{ maxWidth: '412px', marginBottom: '20px' }}
            label="New Password"
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    edge="end"
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showPassword2 ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            size="small"
            fullWidth
            sx={{ maxWidth: '412px', marginBottom: '20px' }}
            label="Confirm Password"
            variant="outlined"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div style={{ marginTop: '20px' }}>
          <Button variant="contained" fullWidth sx={{ ...btnCss, width: '200px', height: '40px' }}type="submit">
            Confirm Password
          </Button>
            </div>
        </form>
      </Box>
    </div>
  );
}

export default SetPassword;