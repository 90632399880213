import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const fetchMoreData = (wpDetails, moreFetchData, phone_number) => {
  if (wpDetails && moreFetchData) {
    const next_page = parseInt(moreFetchData?.current_page) + 1;
    const encodedValue = encodeURIComponent(phone_number);
    const params = `?phone_number_id=${wpDetails?.phone_numbers?.[0].phone_number_id}&page=${next_page}&contacts=${encodedValue}`;
    return apiFetch(
      "GET",
      `whatsapp-messages/conversation/${params}`,
      null,
      true
    ).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to fetch chat list");
      }
    });
  }
};
 
 

export const getChatList = (wpDetails, phone_number) => {
  if (wpDetails && phone_number) {
    const encodedValue = encodeURIComponent(phone_number);
    const params = `?phone_number_id=${wpDetails?.phone_numbers?.[0].phone_number_id}&contacts=${encodedValue}`;
    return apiFetch(
      "GET",
      `whatsapp-messages/conversation/${params}`,
      null,
      true
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Failed to fetch chat list");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch chat list", error);
        throw error;
      });
  }
};

export const sendMessage = (
  selectedChat,
  wpDetails,
  message,
  isPrivateNote,
  mentionedUserIds
) => {
  const params = "?type=message";
  const receiver = selectedChat.contact;
  const sender = wpDetails?.phone_numbers?.[0];
  const body = {
    contact_no: receiver?.phone_number,
    message_content: message,
    phone_number_id: sender?.phone_number_id,
    is_private_note: isPrivateNote,
    mentioned_users: mentionedUserIds,
  };
  if (message !== null) {
    return apiFetch("Post", `whatsapp-messages/${params}`, body, true)
      .then((res) => {
        if (res.ok) {
          return res;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};

export const forwardMessage = (
  selectedChats,
  wpDetails,
  message_id,
) => {
  const sender = wpDetails?.phone_numbers?.[0];
  const phoneNumbers = selectedChats.map((chat) => chat.phone_number);
  const body = {
    contact_no: phoneNumbers,
    message: message_id,
    phone_number_id: sender?.phone_number_id,
  };
  if (message_id !== null) {
    return apiFetch("Post", "whatsapp-messages/messages/forward/", body, true)
      .then((res) => {
        if (res.ok) {
          return res;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};

export const sendContacts = (selectedChat, wpDetails, contacts) => {
  const params = "?type=attach_contact";
  const receiver = selectedChat.contact;
  const sender = wpDetails?.phone_numbers?.[0];
  const body = {
    contact_no: receiver?.phone_number,
    phone_number_id: sender?.phone_number_id,
    contacts_attached: contacts,
  };
  if (contacts.length > 0) {
    return apiFetch("Post", `whatsapp-messages/${params}`, body, true)
      .then((res) => {
        if (res.ok) {
          return res;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};

export const sendFile = (selectedChat, wpDetails, file) => {
  const params = "?type=attach_file";
  const receiver = selectedChat.contact;
  const sender = wpDetails?.phone_numbers?.[0];
  var formData = new FormData();

  formData.append("file", file, file.name);
  formData.append("contact_no", receiver.phone_number);
  formData.append("phone_number_id", sender.phone_number_id);

  if ([...formData.entries()].length >= 3) {
    return apiFetch("Post", `whatsapp-messages/${params}`, formData, true)
      .then((res) => {
        if (res.ok) {
          return res;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};

export const SendTemplates = (selectedChat, wpDetails, template) => {

  const params = "?type=template";
  const receiver = selectedChat;
  const sender = wpDetails?.phone_numbers?.[0];
  const body = {
    contact_no: receiver,
    template_id: template.id,
    phone_number_id: sender.phone_number_id,
  };
  
  if (template !== null) {
    return apiFetch("Post", `whatsapp-messages/${params}`, body, true)
      .then((res) => {
        if (res.ok) {
          return res;
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
};

export const updateMessageStatus =(wpDetails,data) =>{

  const phone_number_id= wpDetails?.[0].phone_numbers?.[0].phone_number_id;
  const body = {
    "contact_no": data?.contact?.phone_number,
    "status": "read",
    "phone_number_id": phone_number_id,
  };
  return apiFetch( 
    'POST',
    '/whatsapp-messages/status/',
    body,
     true 
 )
   .then(response => {
      if (response.ok) {
        return response;
   }
   else {
      throw new Error('Failed to update message status');
    }
 } )
   
};

export const updateAssignee=(wpDetails,conversationId,data) =>{
  const params = `?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}`
  return apiFetch(
    "PATCH",
    `whatsapp-messages/latest-messages/${conversationId}/${params}`,
    data,
    true  
  )
    .then((res) => {
      if (res.ok) {
        return res;
      }
    
    else{
      throw new Error('Failed to update assignee');
    }
   })
    
};

export const updateStatus=(wpDetails,conversationId,data) =>{
  const params = `?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}`
  return apiFetch(
    "PATCH",
    `whatsapp-messages/latest-messages/${conversationId}/${params}`,
    data,
    true  
  )
    .then((res) => {
      if (res.ok) {
        return res;
      }
    else{
      throw new Error('Failed to update status');
    }
   })
    
}

export const contactChatList=(wpDetails,params=null) => {
  const url = params
        ? `whatsapp-messages/latest-messages/?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}&${params}`
        : `whatsapp-messages/latest-messages/?phone_number_id=${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}`;
      return apiFetch(
        "GET",url,null,true
      ).then((res) =>{
      if(res.ok){
        return res;
      }else{
        throw new Error('Failed to Fetch')
      }
      })
}