import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Login from "./Components/Login/Login";
import FirstTime from "./Components/SignUp/FirstTime";
import DashBoard from "./Components/Conversations/DashBoard";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Contacts from "./Components/Contacts/Contacts";
// import Settings from "./Components/Settings/Settings";
import UserManagement from "./Components/UserManagement/UserManagement";
import Campaign from "./Components/Campaign/Campaign";
import { AuthProvider, useAuth } from "./Context/Auth";
import RequireAuth from "./Components/RequireAuth";
import { ToastContainer } from "react-toastify";
import SetPassword from "./Components/SetPassword/SetPassword";
import "react-toastify/dist/ReactToastify.css";
import OverView from "./Components/Campaign/Overview/OverView";
import ScheduleCamaign from "./Components/Campaign/ScheduleCamaign/ScheduleCamaign";
import TemplateMessage from "./Components/TemplateMessage/TemplateMessage";
import History from "./Components/Campaign/History/History";
import NavBar from "./Components/NavBar/NavBar";
import Service from "./Components/Service/Service";
import TermsAndConditions from "./Components/TermsAndCondi_PraivacyPolicy/TermsAndConditions";
import PrivacyPolicy from "./Components/TermsAndCondi_PraivacyPolicy/PrivacyPolicy";
import Footer from "./Components/Footer/Footer";
import { RequestDemo } from "./Components/RequestDemo/RequestDemo";
import { apiFetch, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "./API/CommonApi";
import { WhatsAppSignUp } from "./Components/WhatsAppSignUp/WhatsAppSignUp";
import CustomAttributes from "./Components/Settings/CustomAttributes/CustomAttributes";
import Products from "./Components/Settings/Products/Products";
import Pricing from "./Components/Pricing/Pricing";
import ReactGA from "react-ga4";
import { GTM_TRACKING_ID } from "./API/CommonApi";
import Users from "./Components/UserManagement/Users/Users";
import Roles from "./Components/UserManagement/Roles/Roles";
import Team from "./Components/UserManagement/Team/Team";
import AutomationChats from "./Components/AutomationChat/AutomationChats";
import Widget from "./Components/Settings/Widget/Widget";
import OrderStatus from "./Components/Settings/OrderStatus/OrderStatus";
import OrderAttributes from "./Components/Settings/OrderAttributes/OrderAttributes";
import Automation from "./Components/AutomationChat/ScheduledAutomation/Automation";
import ComparisonBlog1 from "./Components/ComparisonBlogs/ComparisonBlog1";
import ComparisonBlog2 from "./Components/ComparisonBlogs/ComparisonBlog2";
import ComparisonBlog3 from "./Components/ComparisonBlogs/ComparisonBlog3";
import ComparisonBlog4 from "./Components/ComparisonBlogs/ComparisonBlog4";
import ComparisonBlog5 from "./Components/ComparisonBlogs/ComparisonBlog5";
import ComparisonBlog6 from "./Components/ComparisonBlogs/ComparisonBlog6";
import ComparisonBlog7 from "./Components/ComparisonBlogs/ComparisonBlog7";
import ComparisonBlog8 from "./Components/ComparisonBlogs/ComparisonBlog8";
import ComparisonBlog9 from "./Components/ComparisonBlogs/ComparisonBlog9";
import ComparisonBlog10 from "./Components/ComparisonBlogs/ComparisonBlog10";
import AppointmentSettings from "./Components/Settings/Appointments/AppointmentSettings";
import Appointments from "./Components/Appointment/Appointments";

import EventDrivenAutomated from "./Components/AutomationChat/EventDrivenAutomated/EventDrivenAutomated";
import { useState } from "react";
import ErrorPage from "./Components/ErrorPage";
import Events from "./Components/Appointment/Events";
import Tasks from "./Components/Appointment/Tasks";
import Profile from "./Components/Profile/Profile";
import { ZohoBooks } from "./Components/Integrations/ZohoBooks/ZohoBooks";
import RedirectScreen from "./Components/Integrations/ZohoBooks/RedirectScreen";
function App() {
  const [userPermissions, setUserPermission] = useState(null);
  React.useEffect(() => {
    // Load the JavaScript SDK asynchronously
    window.fbAsyncInit = (function () {
      window?.FB?.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v17.0",
      });
    })();
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/all.js#version=v17.0";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, [FACEBOOK_APP_ID]);

  React.useEffect(() => {
    if (GTM_TRACKING_ID) {
      console.log("GTM starts");

      // Starting Google Analytics
      ReactGA.initialize(GTM_TRACKING_ID);
    }
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#de4467",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const auth = useAuth();

  const hasWhatsAppAccount =
    auth && auth.wpDetails && auth.wpDetails.length > 0;
  React.useEffect(() => {
    setUserPermission(auth?.user?.user?.permission);
  }, [auth.user]);
  return (
    <ThemeProvider theme={theme}>
      <div className="Body">
        {/* <NavBar /> */}

        <Routes>
          <Route
            path="/comparison-interakt"
            element={
              <>
                <ComparisonBlog1 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-bitespeed"
            element={
              <>
                <ComparisonBlog2 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-gallabox"
            element={
              <>
                <ComparisonBlog3 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-aisensy"
            element={
              <>
                <ComparisonBlog4 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-delightchat"
            element={
              <>
                <ComparisonBlog5 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-limechat"
            element={
              <>
                <ComparisonBlog6 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-respond.io"
            element={
              <>
                <ComparisonBlog7 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-ownchat"
            element={
              <>
                <ComparisonBlog8 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-wati"
            element={
              <>
                <ComparisonBlog9 />
                <Footer />
              </>
            }
          />
          <Route
            path="/comparison-zoko"
            element={
              <>
                <ComparisonBlog10 />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <Home />
                <Footer />
              </>
            }
          ></Route>
          <Route
            path="signup"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <FirstTime />
                <Footer />
              </>
            }
          />
          <Route
            path="profile"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <Profile />
                <Footer />
              </>
            }
          />
          <Route
            path="login"
            element={
              <>
                <NavBar />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="password-setup/:token"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <SetPassword />
                <Footer />
              </>
            }
          />
          <Route
            path="request-demo"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <RequestDemo />
              </>
            }
          />
          <Route
            path="whatsapp-signup"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <WhatsAppSignUp />
              </>
            }
          />
          <Route
            path="pricing"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <Pricing />
                {auth?.isNativeApp === "true" ? null : <Footer />}
              </>
            }
          />
          <Route
            path="terms-and-conditions"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <TermsAndConditions />
                <Footer />
              </>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          <Route
            path="/conversations"
            element={
              <>
                <NavBar />

                <RequireAuth>
                  <DashBoard />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/contacts"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <RequireAuth>
                  <Contacts />
                </RequireAuth>
              </>
            }
          />
          {userPermissions?.orders === false ? (
            ""
          ) : (
            <Route
              path="/orders"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : <NavBar />}
                  <RequireAuth>
                    <Service />
                  </RequireAuth>
                </>
              }
            />
          )}

          <Route
            path="/templateMessage"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <RequireAuth>
                  <TemplateMessage />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/calender"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/calender" />
                )}

                <RequireAuth>
                  <Appointments />
                </RequireAuth>
              </>
            }
          >
            <Route
              path="events"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/calender" />
                  )}

                  <Events />
                </>
              }
            />
            <Route
              path="tasks"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/calender" />
                  )}
                  <Tasks />
                </>
              }
            />
          </Route>
          <Route
            path="/UserManagement"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/UserManagement" />
                )}
                <RequireAuth>
                  <UserManagement />
                </RequireAuth>
              </>
            }
          >
            <Route
              path="users"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/UserManagement" />
                  )}
                  <RequireAuth>
                    <Users />
                  </RequireAuth>
                </>
              }
            />
            <Route
              path="roles"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/UserManagement" />
                  )}
                  <RequireAuth>
                    <Roles />
                  </RequireAuth>
                </>
              }
            />
            <Route
              path="team"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/UserManagement" />
                  )}
                  <RequireAuth>
                    <Team />
                  </RequireAuth>
                </>
              }
            />
          </Route>
          {userPermissions?.automations === false ? (
            ""
          ) : (
            <Route
              path="/chat_automations"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/chat_automations" />
                  )}
                  <RequireAuth>
                    <AutomationChats />
                  </RequireAuth>
                </>
              }
            />
          )}
          <Route
            path="/event_driven_automation/"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/event_driven_automation" />
                )}
                <RequireAuth>
                  <EventDrivenAutomated />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/message_automations"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/message_automations" />
                )}
                <RequireAuth>
                  <Automation />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/Campaign/"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/Campaign/" />
                )}
                <RequireAuth>
                  <Campaign />
                </RequireAuth>
              </>
            }
          >
            <Route
              path="overView"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/Campaign/" />
                  )}{" "}
                  <RequireAuth>
                    <OverView />
                  </RequireAuth>
                </>
              }
            />
            <Route
              path="history"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/Campaign/" />
                  )}{" "}
                  <RequireAuth>
                    <History />
                  </RequireAuth>
                </>
              }
            />
            <Route
              path="scheduleCampaign"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/Campaign/" />
                  )}{" "}
                  <RequireAuth>
                    <ScheduleCamaign />
                  </RequireAuth>
                </>
              }
            />
          </Route>

          <Route
            path="/custome-attributes"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/custome-attributes" />
                )}
                <RequireAuth>
                  <CustomAttributes />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/products"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/products" />
                )}
                <RequireAuth>
                  <Products />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/widget"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/widget" />
                )}
                <RequireAuth>
                  <Widget />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/order-attributes"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/order-attributes" />
                )}
                <RequireAuth>
                  <OrderAttributes />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/order-status"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/order-status" />
                )}
                <RequireAuth>
                  <OrderStatus />
                </RequireAuth>
              </>
            }
          />
          <Route
            path="/appointment"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : (
                  <NavBar currentPage="/appointment" />
                )}
                <RequireAuth>
                  <AppointmentSettings />
                </RequireAuth>
              </>
            }
          />
          {userPermissions?.integrations && (
            <Route
              path="/integrations/zohobooks"
              element={
                <>
                  {auth?.isNativeApp === "true" ? null : (
                    <NavBar currentPage="/integrations/zohobooks" />
                  )}
                  <RequireAuth>
                    <ZohoBooks />
                  </RequireAuth>
                </>
              }
            >
              <Route
                path="redirect-url/"
                element={
                  <>
                    {auth?.isNativeApp === "true" ? null : (
                      <NavBar currentPage="/integrations/zohobooks" />
                    )}
                    <RequireAuth>
                      <RedirectScreen />
                    </RequireAuth>
                  </>
                }
              />
            </Route>
          )}

          <Route
            path="*"
            element={
              <>
                {auth?.isNativeApp === "true" ? null : <NavBar />}
                <ErrorPage />
              </>
            }
          />
        </Routes>
        {/* <Footer /> */}
      </div>
      <ToastContainer position="top-center" autoClose={2500} />
    </ThemeProvider>
  );
}

export default App;
