import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Autocomplete,
  Chip,
  Switch,
  FormControlLabel,
  Drawer,
  TimeField,
  Checkbox,
  InputAdornment,
  Modal,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreatableSelect from "react-select/creatable";
// import { apiFetch } from "../../API/CommonApi";
// import Modal from "@material-ui/core/Modal";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { notify } from "../../Common";
import { debounce, template } from "lodash";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { loadTemplates as apiLoadTemplates } from "../../../API/CampaignLayer";
import { templatePreview as apiTemplatePreview } from "../../../API/TemplateLayer";
import {
  createAutomation as apiCreateAutomation,
  editAutomation as apiEditAutomation,
  loadAutomationWithId,
} from "../../../API/EventDrivenAutomation";
import CircleIcon from "@mui/icons-material/Circle";
import { Height } from "@mui/icons-material";
import { LoadAttributes as apiLoadAttributes } from "../../../API/EventDrivenAutomation";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #de4467" : "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused ? "2px solid #de4467" : "1px solid #212121",
    },
  }),
};
const frmElement = { marginBottom: "10px" };
const AddAutomation = ({
  WpDetails,
  loadAutomations,
  handleClose,
  editData,
  open,
}) => {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      steps: [
        {
          template: "",
          trigger_delay: {
            type: null,
            value: "",
          },
          trigger_time: {
            time: "",
            days: [],
          },
        },
      ],
    },
  });

  const { handleSubmit, reset, control, formState, setValue, watch } = methods;
  const { errors } = formState;
  const watchAllFields = watch();

  const {
    fields: steps,
    append: appendStep,
    remove: removeStep,
  } = useFieldArray({
    control,
    name: "steps",
  });
  const {
    fields: tStarts,
    append: appendTStart,
    remove: removeTStart,
  } = useFieldArray({
    control,
    name: "tStarts",
  });
  const {
    fields: tEnds,
    append: appendTEnds,
    remove: removeTEnds,
  } = useFieldArray({
    control,
    name: "tEnds",
  });

  const [ApiLoading, SetApiLoading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [contactAttributes, setContactAttributes] = useState([]);
  const [orderAttributes, setOrderAttributes] = useState([]);
  const moduleOptions = [
    {
      name: "Contact",
      value: "contact",
    },
    {
      name: "Order",
      value: "order",
    },
  ];
  const triggerTypeOptions = [
    {
      name: "Create",
      value: "create",
    },
    {
      name: "Update",
      value: "update",
    },
    {
      name: "Create / Update",
      value: "create_or_update",
    },
  ];
  const TDelayOptions = [
    {
      name: "Days",
      value: "days",
    },
    {
      name: "Hours",
      value: "hours",
    },
    {
      name: "Minutes",
      value: "minutes",
    },
  ];
  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error", error);
        // notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };
  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    } else {
      loadTemplates();
    }
  }, 600);

  const handleCloseModal = () => {
    setTemplates(null);
    reset();
    loadAutomations();
    handleClose();
    
  };

  const loadAttributes = () => {
    const CUrl = "whatsapp-business/custom-fields/";
    const OUrl = "service/order-custom-field/";
   
    apiLoadAttributes(CUrl)
      .then((res) => {
        const attributeNamesWithType = res.results.map((attribute) => ({
          name: attribute.name,
          type: attribute.type,
          options: attribute.options,
        }));
        const exOpt = {
          name: "created on",
          type: "date",
          options: null,
        };
        setContactAttributes([...attributeNamesWithType, exOpt]);
      })
      .catch((error) => {
        console.error("Error", error);
      });

    apiLoadAttributes(OUrl)
      .then((res) => {
        const attributeNamesWithType = res.results.map((attribute) => ({
          name: attribute.name,
          type: attribute.type,
          options: attribute.options,
        }));
        const exOpt = {
          name: "created on",
          type: "date",
          options: null,
        };
        setOrderAttributes([...attributeNamesWithType, exOpt]);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  const getTypeOptions = (type) => {
    if (type === "dropdown") {
      return ["equal to", "not equal to"];
    }
    if (type === "text") {
      return ["contains", "start with", "ends with", "exact match"];
    }
    if (type === "number") {
      return ["less than", "greater than", "equal to"];
    }
    if (type === "date") {
      return ["before", "after", "on"];
    }
  };
  const onSubmit = (data) => {
    const steps = data?.steps.map((x, index) => {
    const stepData = {
      template: x?.template.id,
      trigger_delay: x?.trigger_delay?.type.value,
      trigger_delay_value: x?.trigger_delay?.value,
      time: x?.trigger_time?.time,
     days_range: x?.trigger_time?.days ? x.trigger_time.days.join(',') : '',
    };

    if (editData?.steps && editData.steps[index]?.id) {
      stepData.id = editData.steps[index].id;
    }

    return stepData;
  });
    const body = {
      name: data?.name,
      module_type: data?.module_type.value,
      trigger_type: data?.trigger_type.value,
      trigger_time: data?.trigger_time,
      trigger_start: [
        {
          selectedAttribute: data?.trigger_start_condition?.custom_field?.name,
          attrType: data?.trigger_start_condition?.custom_field?.type,
          selectedType: data?.trigger_start_condition?.type,
          selectedValue: data?.trigger_start_condition?.value,
        },
      ],

      trigger_end: [
        {
          selectedAttribute: data?.trigger_stop_condition?.custom_field?.name,
          attrType: data?.trigger_stop_condition?.custom_field?.type,
          selectedType: data?.trigger_stop_condition?.type,
          selectedValue: data?.trigger_stop_condition?.value,
        },
      ],
      steps: steps,
    };
    if (editData) {
      apiEditAutomation(editData.id, body).then((res) => {
        if (res.ok) {
          handleCloseModal();
        }
      });
    } else {
      apiCreateAutomation(body).then((res) => {
        if (res.ok) {
          handleCloseModal();
          
        }
      });
      reset();

    }
  };


useEffect(() => {
  reset();
  loadAttributes();
  if (editData) {
    const findAttributeOption = (value) => {
      if (editData.module_type === "contact") {
        const attribute = contactAttributes.find((p) => p.name === value);
        return attribute;
      } else {
        const attribute = orderAttributes.find((p) => p.name === value);
        return attribute;
      }
    };
    const preType = moduleOptions.find(
        (p) => p.value === editData.module_type
      );
       const pretriggerType = triggerTypeOptions.find(
        (p) => p.value === editData.trigger_type
      );
     
    setValue("name", editData?.name);

    setValue("module_type", preType);
    setValue("trigger_type", pretriggerType);
    setValue("trigger_time", editData.trigger_time);

  if (editData.steps && editData.steps.length > 0) {
      const initialSteps = editData.steps.map((item, index) => ({
        template: item.template,
        trigger_delay: {
          type: TDelayOptions.find((p) => p.value === item.trigger_delay),
          value: item.trigger_delay_value,
        },
        trigger_time: {
          time: item.time,
          days: item.days_range.split(','),
        },
      }));

      setValue('steps', initialSteps);
        appendStep({
          template: '',
          trigger_delay: { type: null, value: '' },
          trigger_time: { from_time: '', to_time: '', days: [] },
        });
        removeStep(editData.steps.length);
    }
    if (editData.trigger_start) {
      setValue("trigger_start_condition.custom_field", findAttributeOption(editData.trigger_start[0].selectedAttribute));
      setValue("trigger_start_condition.type", editData.trigger_start[0].selectedType);
      setValue("trigger_start_condition.value", editData.trigger_start[0].selectedValue);
    }

    if (editData.trigger_end) {
      setValue("trigger_stop_condition.custom_field", findAttributeOption(editData.trigger_end[0].selectedAttribute));
      setValue("trigger_stop_condition.type", editData.trigger_end[0].selectedType);
      setValue("trigger_stop_condition.value", editData.trigger_end[0].selectedValue);
    }
  } else {
    reset();
  }
}, [editData]);



  useEffect(() => {
    loadAttributes();
  }, [open]);

  useEffect(() => {
    if (watchAllFields.module_type?.value) {
      loadAttributes(watchAllFields.module_type?.value);
    }
  }, [watchAllFields.module_type]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <div className="modal" style={{ padding: "20px" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{editData ? "Update Automation" : "Add Automation"}</h2>
            <span
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CloseIcon
                style={ClsBtn}
                className="Icon2"
                onClick={handleClose}
              />
            </span>
          </div>
          <div style={{ padding: "20px 10px 10px 10px" }}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  minHeight: "84vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Name *
                    </InputLabel>
                    <Controller
                      rules={{ required: true }}
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // type="date"
                          size="small"
                          fullWidth
                          // sx={{ marginBottom: "30px" }}
                          variant="outlined"
                          placeholder="Name"
                          error={Boolean(errors?.name)}
                          helperText={
                            errors?.name && "Name of the flow is required"
                          }
                        />
                        
                      )}
                    />
                  </div>
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Module*
                    </InputLabel>

                    <Controller
                      name="module_type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={moduleOptions}
                          getOptionLabel={(option) => option?.name}
                          onChange={(e, data) => {
                            onChange(data);

                            setValue("trigger_start_condition,value", "");
                            setValue("trigger_start_condition,type", "");
                            setValue(
                              "trigger_start_condition,custom_field",
                              ""
                            );
                            setValue("trigger_stop_condition,value", "");
                            setValue("trigger_stop_condition,type", "");
                            setValue("trigger_stop_condition,custom_field", "");
                          }}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.module_type)}
                              // onFocus={() => loadTemplates()}
                              helperText={
                                errors.module_type && "Module  is required"
                              }
                              placeholder="Select Module"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Trigger Type*
                    </InputLabel>
                    <Controller
                      name="trigger_type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={triggerTypeOptions}
                          getOptionLabel={(option) => option?.name}
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.trigger_type)}
                              // onFocus={() => loadTemplates()}
                              helperText={
                                errors.trigger_type &&
                                "Trigger Type  is required"
                              }
                              placeholder="Select"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  {watchAllFields.module_type && (
                    <>
                      <div style={frmElement}>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Trigger start condition*
                        </InputLabel>
                        <div
                          style={{ display: "flex", gap: "5px", width: "100%" }}
                        >
                          <Controller
                            name="trigger_start_condition.custom_field"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                value={value}
                                disableClearable
                                options={
                                  watchAllFields.module_type?.value ===
                                  "contact"
                                    ? contactAttributes
                                    : orderAttributes
                                }
                                getOptionLabel={(option) => option?.name}
                                onChange={(e, data) => {
                                  onChange(data);
                                }}
                                noOptionsText="No options"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "120px" }}
                                    error={Boolean(
                                      errors.trigger_start_condition
                                        ?.custom_field
                                    )}
                                    // helperText={
                                    //   errors.trigger_start_condition
                                    //     ?.custom_field &&
                                    //   "custom field is required"
                                    // }
                                    placeholder="Custom Field"
                                  />
                                )}
                              />
                            )}
                          />
                          <Controller
                            name="trigger_start_condition.type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                value={value}
                                disableClearable
                                options={getTypeOptions(
                                  watchAllFields.trigger_start_condition
                                    ?.custom_field?.type
                                )}
                                // getOptionLabel={(option) => option?.name}
                                onChange={(e, data) => {
                                  onChange(data);
                                }}
                                disabled={
                                  !watchAllFields.trigger_start_condition
                                    ?.custom_field?.type
                                }
                                noOptionsText="No options"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "120px" }}
                                    error={Boolean(
                                      errors.trigger_start_condition?.type
                                    )}
                                    // helperText={
                                    //   errors.trigger_start_condition?.type &&
                                    //   "type condition is required"
                                    // }
                                    placeholder="type"
                                  />
                                )}
                              />
                            )}
                          />
                          {watchAllFields.trigger_start_condition?.custom_field
                            ?.type === "dropdown" ? (
                            <Controller
                              name="trigger_start_condition.value"
                              control={control}
                              rules={{ required: true }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  disableClearable
                                  options={
                                    watchAllFields.trigger_start_condition
                                      ?.custom_field?.options
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      fullWidth
                                      sx={{ width: "120px" }}
                                      placeholder="value"
                                      error={Boolean(
                                        errors.trigger_start_condition?.value
                                      )}
                                    />
                                  )}
                                />
                              )}
                            />
                          ) : (
                            <Controller
                              rules={{ required: true }}
                              name="trigger_start_condition.value"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type={
                                    watchAllFields.trigger_start_condition
                                      ?.custom_field?.type
                                  }
                                  disabled={
                                    !watchAllFields.trigger_start_condition
                                      ?.custom_field?.type
                                  }
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  sx={{ width: "120px" }}
                                  placeholder="value"
                                  error={Boolean(
                                    errors.trigger_start_condition?.value
                                  )}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div style={frmElement}>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Trigger stop condition
                        </InputLabel>
                        <div
                          style={{ display: "flex", gap: "5px", width: "100%" }}
                        >
                          <Controller
                            name="trigger_stop_condition.custom_field"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                value={value}
                                disableClearable
                                options={
                                  watchAllFields.module_type?.value ===
                                  "contact"
                                    ? contactAttributes
                                    : orderAttributes
                                }
                                getOptionLabel={(option) => option?.name}
                                onChange={(e, data) => {
                                  onChange(data);
                                }}
                                noOptionsText="No options"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "120px" }}
                                    error={Boolean(
                                      errors.trigger_stop_condition
                                        ?.custom_field
                                    )}
                                    // helperText={
                                    //   errors.trigger_stop_condition
                                    //     ?.custom_field &&
                                    //   "custom field is required"
                                    // }
                                    placeholder="Custom Field"
                                  />
                                )}
                              />
                            )}
                          />
                          <Controller
                            name="trigger_stop_condition.type"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                value={value}
                                disableClearable
                                options={getTypeOptions(
                                  watchAllFields.trigger_stop_condition
                                    ?.custom_field?.type
                                )}
                                onChange={(e, data) => {
                                  onChange(data);
                                }}
                                noOptionsText="No options"
                                disabled={
                                  !watchAllFields.trigger_stop_condition
                                    ?.custom_field?.type
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    sx={{ width: "120px" }}
                                    error={Boolean(
                                      errors.trigger_stop_condition?.type
                                    )}
                                    // helperText={
                                    //   errors.trigger_stop_condition?.type &&
                                    //   "type condition is required"
                                    // }
                                    placeholder="type"
                                  />
                                )}
                              />
                            )}
                          />
                          {watchAllFields.trigger_stop_condition?.custom_field
                            ?.type === "dropdown" ? (
                            <Controller
                              name="trigger_stop_condition.value"
                              control={control}
                              // rules={{ required: true }}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  disableClearable
                                  options={
                                    watchAllFields.trigger_stop_condition
                                      ?.custom_field?.options
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size="small"
                                      fullWidth
                                      sx={{ width: "120px" }}
                                      placeholder="value"
                                      error={Boolean(
                                        errors.trigger_stop_condition?.value
                                      )}
                                    />
                                  )}
                                />
                              )}
                            />
                          ) : (
                            <Controller
                              // rules={{ required: true }}
                              name="trigger_stop_condition.value"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  type={
                                    watchAllFields.trigger_stop_condition
                                      ?.custom_field?.type
                                  }
                                  size="small"
                                  disabled={
                                    !watchAllFields.trigger_stop_condition
                                      ?.custom_field?.type
                                  }
                                  fullWidth
                                  variant="outlined"
                                  sx={{ width: "120px" }}
                                  placeholder="value"
                                  error={Boolean(
                                    errors.trigger_stop_condition?.value
                                  )}
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Trigger Time*
                    </InputLabel>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        rules={{ required: true }}
                        name="trigger_time"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="time"
                            size="small"
                            fullWidth
                            // sx={{ marginBottom: "30px" }}
                            variant="outlined"
                            placeholder="from"
                            error={Boolean(errors?.trigger_time)}
                            helperText={
                              errors?.trigger_time &&
                              "Trigger Time is required"
                            }
                          />
                        )}
                      />
                    </div>
                    
                    {errors?.trigger_time && (
                      <p
                        style={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          margin: "5px",
                        }}
                      >
                        Time Trigger is required
                      </p>
                    )}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h4>Steps:</h4>
                      </AccordionSummary>
                      <AccordionDetails>
                        {steps.map((field, index) => (
                          <div key={field.id} style={frmElement}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p>Step {index + 1} </p>
                              <span>
                                <IconButton
                                  aria-label="add"
                                  onClick={() =>
                                    appendStep({
                                      template: "",
                                      trigger_delay: {
                                        type: null,
                                        value: "",
                                      },
                                      trigger_time: {
                                        from_time: "",
                                        to_time: "",
                                        days: [],
                                      },
                                    })
                                  }
                                  color="primary"
                                >
                                  <AddCircleIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => removeStep(index)}
                                  style={{ marginLeft: "10px" }}
                                  disabled={watchAllFields?.steps?.length < 2}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </span>
                            </div>
                            <div style={frmElement}>
                              <InputLabel
                                sx={{ color: "#303535", margin: "5px 0" }}
                              >
                                Template*
                              </InputLabel>

                              <Controller
                                name={`steps[${index}].template`}
                                control={control}
                                rules={{ required: true }}
                                defaultValue={null}
                                render={({ field: { value, onChange } }) => (
                                  <Autocomplete
                                    value={value}
                                    options={templates ? templates : []}
                                    loading={loadingTemplates}
                                    getOptionLabel={(option) =>
                                      option?.name ? option?.name : "none"
                                    }
                                    onInputChange={(e, newInputValue) => {
                                      debouncedTemplates(newInputValue);
                                    }}
                                    onChange={(e, data) => {
                                      onChange(data);
                                    }}
                                    loadingText="Loading..."
                                    noOptionsText="No options"
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        error={Boolean(
                                          errors?.steps?.[index]?.template
                                        )}
                                        onFocus={() => loadTemplates()}
                                        helperText={
                                          errors?.steps?.[index]?.template &&
                                          "Template is required"
                                        }
                                        placeholder="Select Template"
                                      />
                                    )}
                                  />
                                )}
                              />
                            </div>
                            <div style={frmElement}>
                              <InputLabel
                                sx={{ color: "#303535", margin: "5px 0" }}
                              >
                                Trigger Delay*
                              </InputLabel>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Controller
                                  name={`steps[${index}].trigger_delay.type`}
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue={null}
                                  render={({ field: { value, onChange } }) => (
                                    <Autocomplete
                                      value={value}
                                      options={TDelayOptions}
                                      getOptionLabel={(option) => option?.name}
                                      onChange={(e, data) => {
                                        onChange(data);
                                      }}
                                      noOptionsText="No options"
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size="small"
                                          sx={{ width: "160px" }}
                                          error={Boolean(
                                            errors?.steps?.[index]
                                              ?.trigger_delay?.type
                                          )}
                                          // onFocus={() => loadTemplates()}
                                          // helperText={
                                          //   errors.module_type && "Module  is required"
                                          // }
                                          placeholder="Select Module"
                                        />
                                      )}
                                    />
                                  )}
                                />

                                <Controller
                                  // rules={{ required: true }}
                                  name={`steps[${index}].trigger_delay.value`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      type="number"
                                      size="small"
                                      fullWidth
                                      // sx={{ marginBottom: "30px" }}
                                      variant="outlined"
                                      placeholder={
                                        watchAllFields?.steps?.[index]
                                          ?.trigger_delay?.type?.value
                                      }
                                      error={Boolean(
                                        errors?.steps?.[index]?.trigger_delay
                                          ?.value
                                      )}
                                      // helperText={
                                      //   errors?.trigger_time?.from && "Time Trigger is required"
                                      // }
                                    />
                                  )}
                                />
                              </div>

                              {errors?.steps?.[index]?.trigger_delay && (
                                <p
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: "12px",
                                    margin: "5px",
                                  }}
                                >
                                  Trigger Delay is required
                                </p>
                              )}
                            </div>
                            <div style={frmElement}>
                              <InputLabel
                                sx={{ color: "#303535", margin: "5px 0" }}
                              >
                                TriggerTime *
                              </InputLabel>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Controller
                                  // rules={{ required: true }}
                                  name={`steps[${index}].trigger_time.time`}
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      type="time"
                                      size="small"
                                      fullWidth
                                      // sx={{ marginBottom: "30px" }}
                                      variant="outlined"
                                      placeholder="from"
                                      // error={Boolean(
                                      //   errors?.steps?.[index]?.trigger_time
                                      //     ?.time
                                      // )}
                                      // helperText={
                                      //   errors?.steps?.[index]?.trigger_time
                                      //     .time && "Trigger Time is required"
                                      // }
                                    />
                                  )}
                                />
                              </div>
                              <Controller
                                name={`steps[${index}].trigger_time.days`}
                                control={control}
                                // rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                  <Autocomplete
                                    onChange={(e, data) => {
                                      onChange(data);
                                    }}
                                    value={value}
                                    multiple={true}
                                    options={[
                                      "Monday",
                                      "Tuesday",
                                      "Wednesday",
                                      "Thursday",
                                      "Friday",
                                      "Saturday",
                                      "Sunday",
                                    ]}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        sx={{
                                          width: "100%",
                                          marginTop: "10px",
                                        }}
                                        placeholder="Days"
                                        error={Boolean(
                                          errors?.steps?.[index]?.trigger_time
                                            ?.days
                                        )}
                                      />
                                    )}
                                  />
                                )}
                              />
                              {/* <Controller
                                // rules={{ required: true }}
                                name={`steps[${index}].trigger_time.days`}
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="number"
                                    size="small"
                                    fullWidth
                                    sx={{ marginTop: "10px" }}
                                    variant="outlined"
                                    placeholder="Days"
                                    error={Boolean(
                                      errors?.steps?.[index]?.trigger_time?.days
                                    )}
                                    // helperText={
                                    //   errors?.trigger_time?.from && "Time Trigger is required"
                                    // }
                                  />
                                )}
                              /> */}
                              {errors?.steps?.[index]?.trigger_time && (
                                <p
                                  style={{
                                    color: "#d32f2f",
                                    fontSize: "12px",
                                    margin: "5px",
                                  }}
                                >
                                  Time Trigger is required
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
                <div style={frmElement}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    type="submit"
                    name="send_to_draft"
                  >
                    Save Automation
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default AddAutomation;
