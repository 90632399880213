import React, { useEffect, useState } from "react";

import bg1_n from "../../Assets/bg1_n.png";
import bg2_n from "../../Assets/bg2_n.png";
import bg3_n from "../../Assets/bg3_n.png";
import bg4_n from "../../Assets/bg4_n.png";
import bg5_n from "../../Assets/bg5_n.png";
import bg6_n from "../../Assets/bg6_n.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Grid,
  Paper,
  Box,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  Fab,
  Zoom,
} from "@mui/material";

const Testimonials = ({ testimonialData }) => {
  const [activeStep, setActiveStep] = useState(0);

  const imageUrls = [bg1_n, bg2_n, bg3_n, bg4_n, bg5_n, bg6_n];
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === testimonialData.length - 1 ? 0 : prevActiveStep + 1
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? testimonialData.length - 1 : prevActiveStep - 1
    );
  };
  const currentTestimonial = testimonialData[activeStep];

  function distributeImages(containerSelector, height, width, numImages) {
    const container = document.querySelector(containerSelector);
    if (!container) {
      console.error(
        `Container element with selector "${containerSelector}" not found.`
      );
      return;
    }

    console.log("Container element:", container);

    const numRepetitions = containerSelector === ".bg_img2" ? 5 : 7;
    const imageWidth = 50;
    const imageHeight = 50;
    const totalWidth = width;
    const totalHeight = height;

    const horizontalSpacing =
      (container.offsetWidth - totalWidth) / (numImages - 1);
    const verticalSpacing =
      (container.offsetHeight - totalHeight) / (numRepetitions - 1);

    for (let repetition = 0; repetition < numRepetitions; repetition++) {
      for (let i = 0; i < numImages; i++) {
        const img = document.createElement("img");

        const randomImageUrl =
          imageUrls[Math.floor(Math.random() * imageUrls.length)];
        img.src = randomImageUrl;
        container.style.position = "relative";
        img.style.position = "absolute";
        const xPosition = i * (imageWidth + horizontalSpacing);
        const yPosition = repetition * (imageHeight + verticalSpacing);

        img.style.left = `${xPosition}px`;
        img.style.top = `${yPosition}px`;
        img.style.opacity = 0.5;
        container.appendChild(img);
      }
    }
  }
  useEffect(() => {
    distributeImages(".bg_img", 200, 300, 18);
    distributeImages(".bg_img1", 350, 400, 18);
    distributeImages(".bg_img2", 250, 300, 6);
  }, []);

  return (
    <div>
      <div style={{ marginTop: "200px", paddingLeft: "100px" }}>
        <p style={{ fontSize: "50px" }}>
          <strong>Testmonials</strong>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "100px",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#791656",
            }}
          >
            <IconButton
              style={{ cursor: "pointer", color: "#791656" }}
              onClick={handleBack}
            >
              <FontAwesomeIcon icon={faCircleChevronLeft} size="2xl" />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <Paper
                style={{
                  backgroundColor: "#F2E8EF",
                  color: "#301447",
                  width: "900px",
                  height: "450px",
                }}
                className="bg_img2"
              >
                <div style={{ padding: "30px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      src={currentTestimonial.avatar}
                      alt="Avatar"
                      style={{ width: "120px", height: "120px" }}
                    />
                    <div style={{ margin: "15px 30px", textAlign: "left" }}>
                      <Typography sx={{ fontSize: "35px" }}>
                        <b>{currentTestimonial.name}</b>
                      </Typography>
                      <Typography sx={{ fontSize: "27px" }}>
                        {currentTestimonial?.designation}
                      </Typography>
                      <a
                        href={currentTestimonial?.company?.link}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          fontWeight: "600",
                          color: "#791656",
                          marginLeft: 4,
                        }}
                      >
                        {currentTestimonial?.company?.title}
                      </a>
                    </div>
                  </div>
                  <Typography
                    sx={{
                      paddingTop: "30px",
                      fontSize: "20px",
                      textAlign: "justify",
                      // overflowY: "auto",
                      // height: "220px",
                    }}
                  >
                    {currentTestimonial.text}
                  </Typography>
                </div>
              </Paper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                {testimonialData.map((_, index) => (
                  <IconButton
                    style={{ cursor: "pointer", color: "#791656" }}
                    onClick={() => handleStepChange(index)}
                  >
                    <span
                      key={index}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor:
                          index === activeStep ? "#791656" : "#F2E8EF",
                        margin: "0 5px",
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                ))}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#791656",
            }}
          >
            <IconButton
              style={{ cursor: "pointer", color: "#791656" }}
              onClick={handleNext}
            >
              <FontAwesomeIcon icon={faCircleChevronRight} size="2xl" />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Testimonials;
