import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
} from "@mui/material";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useAuth } from "../../Context/Auth";
import { notify } from "../Common";
import { AddContact } from "../Contacts/AddContact";
import AddProduct from "../Settings/Products/AddProduct";
import { apiFetch } from "../../API/CommonApi";
import { formatDate, formatDateOnly } from "../Common";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { btnCss, TextfieldCss, ClsBtn } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { loadContacts as apiLoadContacts } from "../../API/ContactLayer";
import { loadProducts as apiLoadProducts } from "../../API/ProductLayer";
import { loadAttributs as apiLoadAttributs } from "../../API/OrderAttri";
import {
  createService as apiCreateService,
  editService as apiEditService,
} from "../../API/ServiceLayer";
import {
  loadAutoCompleteContacts as apiLoadAutoCompleteContacts,
  loadAutoCompleteProducts as apiLoadAutoCompleteProducts,
} from "../../API/ServiceLayer";
import { loadStatus as ApiLoadStatus } from "../../API/OrderStatusLayer";

function AddService({
  open,
  handleClose,
  loadServices,
  // contacts,
  //products,
  rowData,
  // loadContacts,
  WpDetails,
  page,
  searchKey,
}) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const customFilter = createFilterOptions({
    stringify: (option) => option.name + option.phone_number,
  });
  const warchAllFields = watch();
  const [openAddContact, setOpenAddContact] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [statuses, setStatuses] = useState(false);
  const statusOptions = [
    {
      name: "Pending",
      value: "pending",
      id: 1,
    },
    {
      name: "In Progress",
      value: "in_progress",
      id: 2,
    },
    {
      name: "Ready for pickup",
      value: "ready_for_pickup",
      id: 3,
    },
    {
      name: "Delivered",
      value: "delivered",
      id: 4,
    },
  ];
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [allcontacts, setAllContacts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [product, setProduct] = useState([]);
  const [allProducts, setAllProducts] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [edit, setedit] = useState(false);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [attributes, setAttributes] = useState(null);
  const loadStatuses = () => {
    // setLoadingContacts(true);
    ApiLoadStatus()
      .then((res) => {
        const tStat = res.results.map((x) => {
          return { id: x.id, name: x.status_name, value: x.status_name };
        });
        setStatuses(tStat);
      })
      .catch((error) => {
        notify("error", "Something went wrong with Statuses");
        console.error("Error", error);
      });
    // .finally(() => {
    //   // setLoadingContacts(false);
    // });
  };
  const loadAutoCompleteContacts = (text) => {
    setLoadingContacts(true);

    apiLoadAutoCompleteContacts(text)
      .then((res) => {
        setContacts(res.data);
      })
      .catch((error) => {
        notify("error", "Something went wrong with contacts");
        console.error("Error", error);
      })
      .finally(() => {
        setLoadingContacts(false);
      });
  };

  const loadAutoCompleteProducts = (text) => {
    setLoadingProducts(true);
    apiLoadAutoCompleteProducts(text)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((error) => {
        notify("error", "Something went wrong with products");
        console.error("Error", error);
      })
      .finally(() => {
        setLoadingProducts(false);
      });
  };
  useEffect(() => {
    loadContacts();
    loadStatuses();
    loadAutoCompleteProducts("");
  }, []);

  const loadContacts = (param) => {
    apiLoadContacts(param)
      .then((res) => {
        setAllContacts(res.results);
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  useEffect(() => {
    loadContacts();
    loadProducts();
  }, []);

  const loadProducts = (param) => {
    apiLoadProducts(param)
      .then((res) => {
        setAllProducts(res.results);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const debouncedContacts = debounce((searchKey) => {
    if (searchKey) {
      loadAutoCompleteContacts(searchKey);
    } else {
      setContacts(null);
    }
  }, 600);
  const handleCreateNewContact = () => {
    setOpenAddContact(true);
    setValue([]);
  };

  const handleNewClose = () => {
    setOpenAddContact(false);
  };

  const handleAddContactClose = (contact) => {
    if (contact) {
      setValue("contact", contact);
    }
    setOpenAddContact(false);
  };

  const debouncedProducts = debounce((searchKey) => {
    if (searchKey) {
      loadAutoCompleteProducts(searchKey);
    } else {
      setProduct(null);
    }
  }, 600);
  const handleCreateNewProduct = () => {
    setOpenAddProduct(true);
    setValue([]);
  };
  const handleAddProductClose = (res) => {
    setValue("products", res);
    setOpenAddProduct(false);
  };

  const onSubmit = (data) => {
    const {
      order_id,
      contact,
      products,
      status,
      dispatched_products,
      delivered_products,
      ...custom_attribute
    } = data;
    const final_custom_attribute = Object.entries(custom_attribute).map(
      ([key, value]) => {
        const option = attributes.find((option) => option.name === key);
        if (!option) {
          throw new Error(`Custom attribute "${key}" not found in options.`);
        }
        if (rowData) {
          const getAttributeIDWithName = (name) => {
            const attribute = rowData.custom_attribute.find(
              (attr) => attr.custom_field === name
            );
            return attribute ? attribute.id : null;
          };
          return {
            id: getAttributeIDWithName(option.name),
            custom_field: getAttributeIDWithName(option.name),
            value: value,
            type: option.type,
          };
        } else {
          return {
            custom_field: option.id,
            value: value !== undefined ? value : null,
          };
        }
      }
    );
    const body = {
      order_id: rowData ? rowData?.order_id : order_id,
      contact: contact.id,
      status: status.id,
      products: products?.map((item) => item.id),
      dispatched_products: dispatched_products?.map((item) => item.id),
      delivered_products: delivered_products?.map((item) => item.id),
      custom_attribute: final_custom_attribute,
    };
    SetApiLoading(true);
    if (rowData) {
      // Edit Service
      apiEditService(rowData.id, body)
        .then((res) => {
          if (res.ok) {
            if (page && searchKey) {
              loadServices(`page=${page}&search=${searchKey}`);
            }
            if (page) {
              loadServices(`page=${page}`);
            }
            handleClose();
            setedit(false);
            notify("success", "Service updated");
            reset();
            SetApiLoading(false);
          } else {
            notify("error", "Something went wrong");
            SetApiLoading(false);
            console.error(res);
          }
        })
        .catch((error) => {
          notify("error", "Something went wrong");
          SetApiLoading(false);
          console.log(error);
        });
    } else {
      // create Service
      apiCreateService(body)
        .then((res) => {
          notify("success", "Service Added");
          handleClose();
          loadServices(null);
          reset();
          SetApiLoading(false);
          return res.json();
        })
        .catch((error) => {
          notify("error", "Something went wrong");
          SetApiLoading(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    loadAutoCompleteProducts(" ");
  }, [
    warchAllFields.dispatched_products,
    warchAllFields.delivered_products,
    warchAllFields.products,
  ]);
  // pre fill data for Edit service
  useEffect(() => {
    if (rowData) {
      setedit(true);
      setServiceId(rowData.id);
      if (rowData) {
        const preStatus = statuses.find((p) => p.value === rowData.status);
        setValue("status", preStatus);
        setValue("contact", rowData.contact);
        setValue("products", rowData.products);
        setValue("delivered_products", rowData.delivered_products);
        setValue("dispatched_products", rowData.dispatched_products);
        rowData.custom_attribute.map((x) => setValue(x.custom_field, x.value));
      }
    } else {
      reset();
      setedit(false);
    }
  }, [rowData, open]);

  useEffect(() => {
    apiFetch("GET", "service/order-custom-field/", null, true)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          notify("error", "something went wrong");
          console.log("error res", res);
        }
      })
      .then((res) => {
        setAttributes(res.results);
      });
  }, []);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <div className="modal">
        <div className="popupContent">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{edit ? "Update Order" : "Add Order"}</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Grid item xs={6}>
                    {edit ? (
                      // <p>
                      //   Job ID: {''}
                      // </p>
                      ""
                    ) : (
                      <>
                        <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                          Enter Order ID *
                        </InputLabel>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              sx={{ maxWidth: "412px", marginBottom: "10px" }}
                              error={Boolean(errors.order_id)}
                              helperText={
                                errors?.order_id && "job id is required"
                              }
                            />
                          )}
                          rules={{ required: true }}
                          name="order_id"
                          control={control}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Contact *
                    </InputLabel>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={contacts ? contacts : []}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }

                            if (option.name) {
                              return option.name;
                            }
                            if (option.phone_number) {
                              return option.phone_number;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.name;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              {option.name}
                              {option.phone_number &&
                                ` (${option.phone_number})`}
                            </li>
                          )}
                          loading={loadingContacts}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          onInputChange={(e, newInputValue) => {
                            debouncedContacts(newInputValue);
                          }}
                          onChange={(e, newValue) => {
                            if (
                              newValue &&
                              newValue.inputValue &&
                              !loadingContacts
                            ) {
                              handleCreateNewContact();
                            } else {
                              onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = customFilter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            if (inputValue === "") {
                              const subsetContacts = allcontacts.slice(0, 4);
                              // onclick = console.log(subsetContacts);
                              return subsetContacts;
                            }
                            const isExisting = options.some(
                              (option) =>
                                inputValue === option.name ||
                                inputValue === option.phone_number
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                name: `Add New contact`,
                              });
                            }

                            return filtered;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.contact)}
                              helperText={
                                errors.contact && "Contact is required"
                              }
                            />
                          )}
                        />
                      )}
                      rules={{ required: true }}
                      name="contact"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Status *
                    </InputLabel>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={statuses}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.status)}
                              helperText={errors.status && "Status is required"}
                            />
                          )}
                        />
                      )}
                      rules={{ required: "Status is required" }}
                      name="status"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Products
                    </InputLabel>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          multiple
                          value={value}
                          options={product ? product : []}
                          getOptionLabel={(option) => option.name}
                          //onChange={(e, values) => onChange(values)}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          loading={loadingProducts}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          onInputChange={(e, newInputValue) => {
                            debouncedProducts(newInputValue);
                          }}
                          onChange={(e, newValue) => {
                            var newValueLength = newValue.length;
                            if (
                              newValue &&
                              newValueLength > 0 &&
                              newValue[newValueLength - 1].inputValue &&
                              !loadingProducts
                            ) {
                              handleCreateNewProduct();
                            } else {
                              onChange(newValue);
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = customFilter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            if (inputValue === "") {
                              const subsetProducts = allProducts.slice(0, 4);
                              // onclick = console.log(subsetProducts);
                              return subsetProducts;
                            }
                            const isExisting = options.some(
                              (option) => inputValue === option.name
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                name: `Add New Product`,
                              });
                            }

                            return filtered;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.products)}
                              helperText={
                                errors.products && "product is required"
                              }
                            />
                          )}
                        />
                      )}
                      // rules={{ required: true }}
                      name="products"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Dispatched products
                    </InputLabel>
                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          multiple
                          value={value}
                          options={product ? product : []}
                          loading={loadingProducts}
                          getOptionLabel={(option) => option?.name}
                          onInputChange={(e, newInputValue) => {
                            debouncedProducts(newInputValue);
                          }}
                          onChange={(e, data) => {
                            console.log(data);
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.dispatched_products)}
                              helperText={
                                errors.dispatched_products &&
                                "Dispatched products is required"
                              }
                            />
                          )}
                        />
                      )}
                      // rules={{ required: "Template is required" }}
                      name="dispatched_products"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Delivered products
                    </InputLabel>

                    <Controller
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          multiple
                          value={value}
                          options={product ? product : []}
                          loading={loadingProducts}
                          getOptionLabel={(option) => option?.name}
                          onInputChange={(e, newInputValue) => {
                            debouncedProducts(newInputValue);
                          }}
                          onChange={(e, data) => {
                            console.log(data);
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.delivered_products)}
                              helperText={
                                errors.delivered_products &&
                                "Delivered products is required"
                              }
                            />
                          )}
                        />
                      )}
                      // rules={{ required: "Template is required" }}
                      name="delivered_products"
                      control={control}
                    />
                  </Grid>
                  {attributes?.map((field) => {
                    const customeType =
                      field.type === "number"
                        ? "number"
                        : field.type === "date"
                        ? "date"
                        : "text";

                    if (field.type === "dropdown") {
                      const options = field.options;
                      return (
                        <Grid item xs={6} key={field.id}>
                          <InputLabel
                            sx={{
                              color: "#303535",
                              margin: "10px 0 5px 0",
                              textTransform: "capitalize",
                            }}
                          >
                            {field.name.replace(/_/g, " ")}
                          </InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                options={options}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                    // sx={{ width: "388.58px" }}
                                    type={customeType}
                                  />
                                )}
                                onChange={(e, value) => {
                                  setValue(field.name, value);
                                }}
                              />
                            )}
                            name={field.name}
                            control={control}
                          />
                        </Grid>
                      );
                    }

                    return (
                      <Grid item xs={6} key={field.id}>
                        <InputLabel
                          sx={{
                            color: "#303535",
                            margin: "10px 0 5px 0",
                            textTransform: "capitalize",
                          }}
                        >
                          {field.name.replace(/_/g, " ")}
                        </InputLabel>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              fullWidth
                              // sx={{ width: "388.58px" }}
                              type={customeType}
                            />
                          )}
                          name={field.name}
                          control={control}
                        />
                      </Grid>
                    );
                  })}

                  <Grid item>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        maxWidth: "412px",
                        marginTop: "15px",
                        background:
                          "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                        borderRadius: " 5px",
                      }}
                      type="submit"
                    >
                      {rowData ? " Update Order" : "Add Order"}
                    </Button>
                  </Grid>
                </Box>
              </form>
            </FormProvider>
            <AddContact
              WpDetails={WpDetails}
              loadContacts={loadContacts}
              handleClose={handleNewClose}
              handleAddContactClose={handleAddContactClose}
              // editData={}
              open={openAddContact}
            />

            <AddProduct
              //WpDetails={WpDetails}
              handleClose={handleAddProductClose}
              open={openAddProduct}
              loadProducts={loadProducts}
            />
          </div>
        </div>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AddService;
