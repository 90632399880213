import React, { useState, useEffect } from "react";
import { watch } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
} from "@mui/material";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, choosebtn, TextfieldCss } from "../../../Styles";
import { notify } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import { loadAttributes as apiLoadAttributes } from "../../../API/customAttr";
import { loadTemplates as apiLoadTemplates } from "../../../API/CampaignLayer";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import { ClsBtn } from "../../../Styles";
import { convertToUTCTime, convertToLocal } from "../../Common";
import CloseIcon from "@mui/icons-material/Close";
import {
  createMessageAutomation,
  editMessageAutomation,
} from "../../../API/AutomationLayer";

function AddAutomation({ handleClose, open, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const [editId, setEditID] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [attributes, setAttributes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedAttr, setSelectedAttr] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const automation_type = watch("automation_type");
  const trigger_on = watch("trigger_on");
  const recurr_on = watch("recurr_on");

  useEffect(() => {
    loadAttributes();
    loadTemplates();
  }, []);

  useEffect(() => {
    if (editData) {
      setValue("name", editData?.name);
      setValue("automation_type", editData?.automation_type);
      setValue("template", editData?.template);
      setValue("trigger_on", editData.trigger_on);
      setValue("recurr_on", editData.recurr_on);
      setValue("day", editData?.day);
      setValue("notify", editData.notify);
      setValue("month", editData.month);
      setValue("date", editData.date);
      const temp = convertToLocal(editData.time);
      console.log(temp);
      const [hour, minute] = temp.split(":");
      setValue("hour", hour);
      setValue("Minute", minute);
      if (editData.custom_attr !== null) {
        setSelectedAttr(editData.custom_attr.name);
      }
    } else {
      reset();
    }
  }, [editData, open]);

  const loadAttributes = (param) => {
    apiLoadAttributes(param)
      .then((res) => {
        const dateAttributes = res.results.filter(
          (attribute) => attribute.type === "date"
        );
        console.log(res.results);
        const attributeNamesWithType = dateAttributes.map((attribute) => ({
          id: attribute.id,
          name: attribute.name,
          type: attribute.type,
        }));
        setAttributes(attributeNamesWithType);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const loadTemplates = (text) => {
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        notify("error", "Something went wrong with templates");
      });
  };

  const HandleOnClose = () => {
    reset();
    handleClose();
  };

  const createEditAutomation = (data) => {
    SetApiLoading(true);
    data.notify = data.notify ? parseInt(data.notify, 10) : undefined;
    data.month = data.month ? parseInt(data.month, 10) : undefined;
    data.day = data.day ? parseInt(data.day, 10) : undefined;
    data = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== undefined)
    );
    if (data.hour !== undefined && data.Minute !== undefined) {
      const hour = parseInt(data.hour, 10);
      const minute = parseInt(data.Minute, 10);

      const time = `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      const temp = convertToUTCTime(time);
      console.log(temp);
      data.time = temp;
    }

    if (data.template && typeof data.template === "object") {
      data.template = data.template.id;
    }
    if (data.automation_type === "custom_field") {
      const selectedAttribute = attributes.find(
        (attr) => attr.name === selectedAttr
      );
      if (selectedAttribute) {
        data.custom_attr = selectedAttribute.id;
      }
    }
    console.log(data);

    if (editData) {
      editMessageAutomation(editData.id, data).then((res) => {
        if (res.ok) {
          console.log(res);
        }
      });
    } else {
      createMessageAutomation(data).then((res) => {
        if (res.ok) {
          console.log(res);
        }
      });
    }
    SetApiLoading(false);
    reset();
    handleClose();
  };

  const handleSelectedAttrChange = (newValue) => {
    setSelectedAttr(newValue);
  };

  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    }
  }, 600);

  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        style={{
          // margin: "20px 40px",
          // minWidth: "400px",

          width: "100%",
          maxWidth: "400px",
          padding: "20px",
        }}
      >
        <FormProvider {...methods} enctype="multipart/form-data">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Message Automation</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <Grid container sx={{ marginTop: "12px" }}>
            <Grid Item xs={12}>
              <InputLabel sx={{ marginBottom: "5px" }}>
                Select an option
              </InputLabel>
              <Controller
                name="automation_type"
                control={control}
                render={({ field }) => (
                  <Select {...field} fullWidth size="small">
                    <MenuItem value="custom_field">
                      Based on Custom Field
                    </MenuItem>
                    <MenuItem value="specific_date">Specific Date</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <InputLabel sx={{ marginBottom: "5px" }}>
                Automation Name
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField {...field} size="small" fullWidth />
                )}
                rules={{ required: true }}
                name="name"
                control={control}
              />
            </Grid>
            {automation_type === "custom_field" && (
              <>
                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <InputLabel htmlFor="custom_attr">
                    Custom Attributes
                  </InputLabel>
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={selectedAttr}
                        onChange={(event, newValue) =>
                          handleSelectedAttrChange(newValue)
                        }
                        options={attributes.map((attribute) => attribute.name)}
                        getOptionLabel={(option) => option || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    )}
                    name="custom_attr"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <InputLabel sx={{ marginBottom: "5px" }}>
                    Trigger On
                  </InputLabel>
                  <Controller
                    name="trigger_on"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} fullWidth size="small">
                        <MenuItem value="before">Before</MenuItem>
                        <MenuItem value="after">After</MenuItem>
                        <MenuItem value="same_day">Same Day</MenuItem>
                      </Select>
                    )}
                  />
                </Grid>
                {trigger_on !== "same_day" && trigger_on !== "" && (
                  <Grid item xs={12} sx={{ marginTop: "10px" }}>
                    <InputLabel sx={{ marginBottom: "5px" }}>
                      Notify {trigger_on}
                    </InputLabel>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          type="number"
                        />
                      )}
                      rules={{ required: true }}
                      name="notify"
                      control={control}
                    />
                  </Grid>
                )}
              </>
            )}
            {automation_type === "specific_date" && (
              <>
                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <InputLabel sx={{ marginBottom: "5px" }}>
                    Recurring Type
                  </InputLabel>
                  <Controller
                    name="recurr_on"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} fullWidth size="small">
                        <MenuItem value="exact">Exact Date</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    )}
                  />
                </Grid>
              </>
            )}

            <Grid container spacing={2} sx={{ marginTop: "5px" }}>
              <>
                <Grid item xs={3}>
                  <InputLabel sx={{ marginBottom: "5px" }}>Hour</InputLabel>
                  <Controller
                    name="hour"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: 23 }}
                        size="small"
                        onBlur={(e) => {
                          const value = parseInt(e.target.value, 10);
                          if (value < 0) {
                            field.onChange(0);
                          } else if (value > 23) {
                            field.onChange(23);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputLabel sx={{ marginBottom: "5px" }}>Minute</InputLabel>
                  <Controller
                    name="Minute"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="outlined"
                        type="number"
                        fullWidth
                        inputProps={{ min: 0, max: 30, step: 30 }}
                        size="small"
                        onBlur={(e) => {
                          const value = parseInt(e.target.value, 10);
                          if (value === 0 || value === 30) {
                            field.onChange(value);
                          } else {
                            field.onChange("0");
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
              {automation_type === "custom_field" ? (
                <Grid item xs={6}>
                  <InputLabel sx={{ marginBottom: "5px" }}>
                    Recurring Type
                  </InputLabel>
                  <Controller
                    name="recurr_on"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} fullWidth size="small">
                        <MenuItem value="exact">Exact Date</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                    )}
                  />
                </Grid>
              ) : (
                <>
                  {recurr_on === "exact" && (
                    <Grid item xs={6}>
                      <InputLabel sx={{ marginBottom: "5px" }}>Date</InputLabel>
                      <Controller
                        name="date"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="date"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {recurr_on === "yearly" && (
                    <>
                      <Grid item xs={3}>
                        <InputLabel sx={{ marginBottom: "5px" }}>
                          Month
                        </InputLabel>
                        <Controller
                          name="month"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              type="number"
                              fullWidth
                              inputProps={{ min: 1, max: 12 }}
                              size="small"
                              onBlur={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (value < 1) {
                                  field.onChange(1);
                                } else if (value > 12) {
                                  field.onChange(12);
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel sx={{ marginBottom: "5px" }}>
                          Day
                        </InputLabel>
                        <Controller
                          name="day"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              variant="outlined"
                              type="number"
                              fullWidth
                              inputProps={{ min: 1, max: 31 }}
                              size="small"
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  {recurr_on === "monthly" && (
                    <Grid item xs={6}>
                      <InputLabel sx={{ marginBottom: "5px" }}>Day</InputLabel>
                      <Controller
                        name="day"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="number"
                            fullWidth
                            inputProps={{ min: 1, max: 31 }}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              <InputLabel htmlfor="template">Select Template</InputLabel>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={value}
                    options={templates ? templates : []}
                    getOptionLabel={(option) => option?.name}
                    onInputChange={(e, newInputValue) => {
                      debouncedTemplates(newInputValue);
                    }}
                    onChange={(e, data) => {
                      console.log(data);
                      setSelectedTemplateId(data ? data.id : null);
                      onChange(data);
                    }}
                    loadingText="Loading..."
                    noOptionsText="No options"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={Boolean(errors.template)}
                        helperText={errors.template && "Template is required"}
                        placeholder="Select From the List"
                      />
                    )}
                  />
                )}
                rules={{ required: "Template is required" }}
                name="template"
                control={control}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              {editData ? (
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  onClick={handleSubmit((data) => {
                    createEditAutomation(data);
                  })}
                >
                  Update Automation
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  onClick={handleSubmit((data) => {
                    createEditAutomation(data);
                  })}
                >
                  ADD Automation
                </Button>
              )}
            </Grid>
          </Grid>
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </Paper>
    </Modal>
  );
}

export default AddAutomation;
