import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadStatus = (param) => {
  const url = param
    ? `service/order-status/?${param}`
    : "service/order-status/?page=1";
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      // navigate("/login");
    }
  });
};

export const delStatus = (StatusId) => {
  return apiFetch(
    "DELETE",
    `service/order-status/${StatusId}/`,
    null,
    true
  ).then((res) => {
    if (res.ok) {
      return true;
    }
  });
};

export const createStatus = (body) => {
  return apiFetch("POST", `service/order-status/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "status Created");
        return res;
      } else {
        notify("error", "Failed to create Status");
        throw new Error("Failed to create Status");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const editStatus = (editId, body) => {
  return apiFetch("PUT", `service/order-status/${editId}/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Status Updated");
        return res;
      } else {
        notify("error", "Failed to Edit Status");
        throw new Error("Failed to Edit Status");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
