import React from "react";
import { Grid } from "@mui/material";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link ,NavLink } from "react-router-dom";
import { CalendlyURL } from "../../API/CommonApi";
import { AuthProvider, useAuth } from "../../Context/Auth";

const Footer = () => {
  const auth = useAuth();
  const brandLoading = auth.brandLoading;
  const brandDetails = auth.brandDetails;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };  
  return (
    // <div className="Footer">
    //   <div className="ctr">
    //     <div>
    //       <p className="FooterHeading">PINAIPPU</p>
    //       <ul className="SocialIcons">
    //         <li>
    //           <Link className="link " to="https://www.facebook.com">
    //             <TwitterIcon />
    //           </Link>
    //         </li>
    //         <li>
    //           <Link className="link " to="https://www.facebook.com">
    //             <FacebookIcon />
    //           </Link>
    //         </li>
    //         <li>
    //           <Link className="link " to="https://www.facebook.com">
    //             <InstagramIcon />
    //           </Link>
    //         </li>
    //         <li>
    //           <Link className="link " to="https://www.facebook.com">
    //             <LinkedInIcon />
    //           </Link>
    //         </li>
    //       </ul>
    //     </div>
    //     <div style={{ paddingLeft: "10px" }}>
    //       <p className="FooterH2">Policy Links</p>
    //       <ul className="Policy">
    //         <li>
    //           <Link to="/terms-and-conditions" className="link">
    //             Terms And Conditions
    //           </Link>
    //         </li>
    //         <li>
    //           <Link to="/privacy-policy" className="link">
    //             Privacy Policy
    //           </Link>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    //   <p style={{ textAlign: "center", marginTop: "10px" }}>
    //     {" "}
    //     2023 © Copyright pinaippu
    //   </p>
    // </div>

    <Grid container className="Footer">
      {/* <Grid className="ctr"> */}
      <Grid item xs={12} md={2}>
        {brandLoading ? (
          <p>loading...</p>
        ) : brandDetails?.brand_name ? (
          <p className="FooterHeading">{brandDetails?.brand_name}</p>
        ) : (
          <p className="FooterHeading">PINAIPPU</p>
        )}
        <ul>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-aisensy" onClick={scrollToTop}>
              PINAIPPU vs AiSensy
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-bitespeed" onClick={scrollToTop}>
              PINAIPPU vs Bitespeed
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-delightchat" onClick={scrollToTop}>
              PINAIPPU vs DelightChat
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-gallabox" onClick={scrollToTop}>
              PINAIPPU vs Gallabox
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-interakt" onClick={scrollToTop}>
              PINAIPPU vs Interakt
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-limechat" onClick={scrollToTop}>
              PINAIPPU vs Limechat
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-ownchat" onClick={scrollToTop}>
              PINAIPPU vs Ownchat
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-respond.io" onClick={scrollToTop}>
              PINAIPPU vs Respond.io
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-wati" onClick={scrollToTop}>
              PINAIPPU vs Wati
            </NavLink>
          </li>
          <li className="comparison-list">
            <NavLink className="comparison" to="/comparison-zoko" onClick={scrollToTop}>
              PINAIPPU vs Zoko
            </NavLink>
          </li>
        </ul>

        {/* <ul className="SocialIcons">
          <li>
            <Link className="link " to="https://www.facebook.com">
              <TwitterIcon />
            </Link>
          </li>
          <li>
            <Link className="link " to="https://www.facebook.com">
              <FacebookIcon />
            </Link>
          </li>
          <li>
            <Link className="link " to="https://www.facebook.com">
              <InstagramIcon />
            </Link>
          </li>
          <li>
            <Link className="link " to="https://www.facebook.com">
              <LinkedInIcon />
            </Link>
          </li>
        </ul> */}
      </Grid>
      <Grid item md={8}></Grid>
      <Grid item xs={12} md={2}>
        <p className="FooterH2">Policy Links</p>
        <ul className="Policy">
          <li>
            <a
              target="_blank_"
              href={CalendlyURL}
              className="link"
            >
              Book a Demo
            </a>
          </li>
          <li>
            <Link className="link" to="/pricing" onClick={scrollToTop}>
              Pricing
            </Link>{" "}
          </li>
          <li>
            <Link to="/terms-and-conditions" className="link" onClick={scrollToTop}>
              Terms And Conditions
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className="link" onClick={scrollToTop}>
              Privacy Policy
            </Link>
          </li>
        </ul>
      </Grid>
      {/* </Grid> */}
      <Grid item xs={12}>
        <p style={{ textAlign: "center", marginTop: "10px" }}>
          {brandLoading ? (
            <p>loading...</p>
          ) : brandDetails?.brand_name ? (
            `2023 © Copyright ${brandDetails?.brand_name}`
          ) : (
            "2023 © Copyright pinaippu"
          )}
        </p>
      </Grid>
    </Grid>
  );
};

export default Footer;
