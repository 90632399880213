import React, { useEffect, useState } from "react";
import { Button, Typography, Menu, MenuItem, Avatar } from "@mui/material";
import "./NavBar.css";
import { AuthProvider, useAuth } from "../../Context/Auth";
import justLogo from "../../Assets/justLogo.png";
import { apiFetch } from "../../API/CommonApi";
import profile from "../../Assets/profile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { CalendlyURL } from "../../API/CommonApi";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { isMobile } from "../Common";
import {
  useNavigate,
  createSearchParams,
  NavLink,
  Link,
} from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { logout as apiLogout } from "../../API/AuthLayer";
import UseWindowSize from "../UseWindowSize";
import { profilebtn } from "../../Styles";
import { set } from "lodash";
const menuNav = {
  color: "#464646",
  fontWeight: 400,
  fontSize: "15px",
  paddingLeft: "30px",
  lineHeight: "18px",
  cursor: "pointer",
  textDecoration: "none",
  width: "100vw",
};
const navMenu = {
  color: "#464646",
  fontWeight: 400,
  fontSize: "17px",
  paddingLeft: "5px",
  lineHeight: "12px",
  cursor: "pointer",
  textDecoration: "none",
  textTransform: "none",
};
export default function NavBar(props) {
  const { currentPage } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [screenWidth, height] = UseWindowSize();
  const [isOpen, setIsOpen] = useState("");
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showSubMenu, setShowSubMenu] = useState("");

  const userTokens = JSON.parse(localStorage?.getItem("userTokens"));

  const handleMenuClick = (event, nav) => {
    setIsOpen(nav);
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setIsOpen("");
    setShowSubMenu("");
  };

  const logOut = () => {
    apiLogout()
      .then(() => {
        // localStorage.removeItem("userTokens");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const isCurrentPage = (page) => {
    return currentPage === page;
  };

  const auth = useAuth();
  const userDetails = auth.userDetails;
  const userPermissions = auth?.user?.user?.permission;
  const brandLoading = auth.brandLoading;
  const brandDetails = auth.brandDetails;
  const hasWhatsAppAccount =
    auth && auth.wpDetails && auth.wpDetails.length > 0;

  const toggleSubMenu = (section) => {
    setShowSubMenu((prevSection) => (prevSection === section ? "" : section));
  };
  const handleMenuNav = (url) => {
    navigate(url);
    setShowSubMenu("");
    handleMenuClose();
  };
  return (
    <>
      <header
        className="mainNavBar"
        style={{ padding: isMobile() ? "10px" : "10px" }}
      >
        <Link
          to={userTokens ? "/conversations" : "/"}
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
          className="logoDiv"
        >
          <div className="logoDiv">
            {brandLoading ? (
              <Spinner_2 style={{ width: "35px" }} />
            ) : brandDetails?.brand_logo ? (
              <img
                src={brandDetails.brand_logo}
                style={{
                  // maxWidth: "100px",
                  maxHeight: "30px",
                  mixBlendMode: "multiply",
                }}
                alt={brandDetails.brand_name}
              />
            ) : (
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    marginLeft: "3px",
                    fontSize: "27px",
                    color: "#1B1464",
                  }}
                >
                  INAIPPU
                </p>
              </>
            )}
          </div>
        </Link>

        {isMobile() ? (
          <>
            <button
              className={`hamburger ${isOpen ? "open" : ""}`}
              onClick={(e) => handleMenuClick(e, "mobile")}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            {userTokens && "access_token" in userTokens ? (
              <>
                <Menu
                  id="basic-menu"
                  anchorEl={menuAnchorEl}
                  open={isOpen === "mobile"}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  {hasWhatsAppAccount && (
                    <div>
                      <MenuItem
                        component={NavLink}
                        style={menuNav}
                        to="/conversations"
                        onClick={handleMenuClose}
                      >
                        Conversations
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        style={menuNav}
                        to="/contacts"
                        onClick={handleMenuClose}
                      >
                        Contacts
                      </MenuItem>
                      {userPermissions?.orders === false ? (
                        ""
                      ) : (
                        <MenuItem
                          component={NavLink}
                          style={menuNav}
                          to="/orders"
                          onClick={handleMenuClose}
                        >
                          Orders
                        </MenuItem>
                      )}
                      <MenuItem
                        component={NavLink}
                        style={menuNav}
                        to="/Campaign"
                        onClick={handleMenuClose}
                      >
                        Campaign
                      </MenuItem>
                      <MenuItem
                        component={NavLink}
                        style={menuNav}
                        to="/templateMessage"
                        onClick={handleMenuClose}
                      >
                        Template Message
                      </MenuItem>
                      {userPermissions?.automations === false ? (
                        ""
                      ) : (
                        <List
                          // sx={{
                          //   width: "100%",
                          //   maxWidth: 360,
                          //   bgcolor: "background.paper",
                          // }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          {/* Conversation */}
                          <ListItemButton
                            onClick={() => toggleSubMenu("Automation")}
                            sx={menuNav}
                          >
                            {/* <ListItemIcon>
                              {showSubMenu === "Automation" ? (
                                <ExpandMore />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </ListItemIcon> */}
                            <ListItemText
                              primary={
                                showSubMenu === "Automation" ? (
                                  <strong>Automation</strong>
                                ) : (
                                  "Automation"
                                )
                              }
                            />
                          </ListItemButton>

                          <Collapse
                            in={showSubMenu === "Automation"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ListItemButton
                              sx={menuNav}
                              onClick={() => handleMenuNav("/chat_automations")}
                            >
                              <ListItemText primary="Chat Automations" />
                            </ListItemButton>

                            <ListItemButton
                              sx={menuNav}
                              onClick={() =>
                                handleMenuNav("/message_automations")
                              }
                            >
                              <ListItemText primary="Message Automations" />
                            </ListItemButton>
                            <ListItemButton
                              sx={menuNav}
                              onClick={() =>
                                handleMenuNav("/event_driven_automation")
                              }
                            >
                              <ListItemText primary="Event Driven Automation" />
                            </ListItemButton>
                          </Collapse>
                        </List>
                      )}

                      <MenuItem
                        component={NavLink}
                        to="/calender"
                        style={menuNav}
                        onClick={handleMenuClose}
                      >
                        Calendar
                      </MenuItem>

                      <List
                        // sx={{
                        //   width: "100%",
                        //   maxWidth: 360,
                        //   bgcolor: "background.paper",
                        // }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                      >
                        {/* Conversation */}
                        <ListItemButton
                          onClick={() => toggleSubMenu("Settings")}
                          sx={menuNav}
                        >
                          <ListItemText
                            primary={
                              showSubMenu === "Settings" ? (
                                <strong>Settings</strong>
                              ) : (
                                "Settings"
                              )
                            }
                          />
                        </ListItemButton>

                        <Collapse
                          in={showSubMenu === "Settings"}
                          timeout="auto"
                          unmountOnExit
                        >
                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/appointment")}
                          >
                            <ListItemText primary="Appointment" />
                          </ListItemButton>
                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/custome-attributes")}
                          >
                            <ListItemText primary="Custome Attributes" />
                          </ListItemButton>
                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/order-status")}
                          >
                            <ListItemText primary="Order Status" />
                          </ListItemButton>
                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/order-attributes")}
                          >
                            <ListItemText primary="Order Attributes" />
                          </ListItemButton>

                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/products")}
                          >
                            <ListItemText primary="Products" />
                          </ListItemButton>

                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/widget")}
                          >
                            <ListItemText primary="Widget" />
                          </ListItemButton>
                          <ListItemButton
                            sx={menuNav}
                            onClick={() => handleMenuNav("/UserManagement")}
                          >
                            <ListItemText primary="User Management" />
                          </ListItemButton>
                        </Collapse>
                      </List>

                      {userPermissions?.integrations && (
                        <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          <ListItemButton
                            onClick={() => toggleSubMenu("Integrations")}
                            sx={menuNav}
                          >
                            <ListItemText
                              primary={
                                showSubMenu === "Integrations" ? (
                                  <strong>Integrations</strong>
                                ) : (
                                  "Integrations"
                                )
                              }
                            />
                          </ListItemButton>
                          <Collapse
                            in={showSubMenu === "Integrations"}
                            timeout="auto"
                            unmountOnExit
                          >
                            <ListItemButton
                              sx={menuNav}
                              onClick={() =>
                                handleMenuNav("/integrations/zohoBooks")
                              }
                            >
                              <ListItemText primary="ZohoBooks" />
                            </ListItemButton>
                          </Collapse>
                        </List>
                      )}
                    </div>
                  )}

                  <MenuItem onClick={() => handleMenuNav("/profile")}>
                    <AccountBoxIcon style={{ color: "#da1e7a" }} />
                    &ensp;Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logOut();
                      handleMenuClose();
                    }}
                  >
                    <LogoutIcon style={{ color: "#da1e7a" }} />
                    &ensp;Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Menu
                id="basic-menu"
                anchorEl={menuAnchorEl}
                open={isOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  marginTop: "20px",
                }}
              >
                <MenuItem
                  component={NavLink}
                  to="/pricing"
                  style={menuNav}
                  onClick={handleMenuClose}
                >
                  Pricing
                </MenuItem>
                <MenuItem
                  component="a"
                  href={CalendlyURL}
                  style={menuNav}
                  onClick={handleMenuClose}
                >
                  Book a Demo
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/login"
                  style={menuNav}
                  onClick={handleMenuClose}
                >
                  Login
                </MenuItem>
                <MenuItem
                  component={NavLink}
                  to="/signup"
                  style={menuNav}
                  onClick={handleMenuClose}
                >
                  Sign Up
                </MenuItem>
              </Menu>
            )}
          </>
        ) : (
          // web nav
          <>
            {userTokens ? (
              "access_token" in userTokens ? (
                <>
                  {hasWhatsAppAccount && (
                    <div>
                      <ul className="Navmenu">
                        <li>
                          <NavLink className="normalNav" to="/conversations">
                            Conversations
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink className="normalNav" to="/contacts">
                            Contacts
                          </NavLink>{" "}
                        </li>
                        {userPermissions?.orders === false ? (
                          ""
                        ) : (
                          <li>
                            <NavLink className="normalNav" to="/orders">
                              Orders
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink className="normalNav" to="/Campaign">
                            Campaign
                          </NavLink>
                        </li>
                        <li>
                          <NavLink className="normalNav" to="/templateMessage">
                            Template Message
                          </NavLink>
                        </li>
                        {userPermissions?.automations === false ? (
                          ""
                        ) : (
                          <li>
                            <div className="normalNav">
                              <Button
                                onClick={(e) =>
                                  handleMenuClick(e, "Automation")
                                }
                                style={navMenu}
                              >
                                {currentPage === "/chat_automations" ||
                                currentPage === "/message_automations" ||
                                currentPage === "/event_driven_automation" ? (
                                  <strong>Automation</strong>
                                ) : (
                                  "Automation"
                                )}
                              </Button>
                              <Menu
                                id="automation-menu"
                                anchorEl={menuAnchorEl}
                                open={isOpen === "Automation"}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "automation-menu",
                                }}
                                PaperProps={{
                                  style: {
                                    marginTop: "20px", // Move the menu down by 20px
                                  },
                                }}
                              >
                                {hasWhatsAppAccount && (
                                  <>
                                    <MenuItem
                                      component={NavLink}
                                      to="/chat_automations"
                                      onClick={handleMenuClose}
                                    >
                                      Chat Automations
                                    </MenuItem>
                                    <MenuItem
                                      component={NavLink}
                                      to="/message_automations"
                                      onClick={handleMenuClose}
                                    >
                                      Message Automations
                                    </MenuItem>
                                    <MenuItem
                                      component={NavLink}
                                      to="/event_driven_automation"
                                      onClick={handleMenuClose}
                                    >
                                      Event Driven Automation
                                    </MenuItem>
                                  </>
                                )}
                              </Menu>
                            </div>
                          </li>
                        )}
                        {/* <li>
                          {!isCurrentPage("/UserManagement") ? (
                            <NavLink className="normalNav" to="/UserManagement">
                              User Management
                            </NavLink>
                          ) : (
                            <span className="activeNavLink">
                              User Management
                            </span>
                          )}
                        </li> */}
                        <li>
                          {!isCurrentPage("/calender") ? (
                            <NavLink className="normalNav" to="/calender">
                              Calendar
                            </NavLink>
                          ) : (
                            <span className="activeNavLink">Calender</span>
                          )}
                        </li>
                        <li>
                          <div className="normalNav">
                            <Button
                              onClick={(e) => handleMenuClick(e, "Settings")}
                              style={navMenu}
                            >
                              {currentPage === "/appointment" ||
                              currentPage === "/order-status" ||
                              currentPage === "/widget" ||
                              currentPage === "/products" ||
                              currentPage === "/custome-attributes" ||
                              currentPage === "/custome-attributes" ||
                              currentPage === "/UserManagement" ? (
                                <strong>Settings</strong>
                              ) : (
                                "Settings"
                              )}
                            </Button>
                            <Menu
                              id="automation-menu"
                              anchorEl={menuAnchorEl}
                              open={isOpen === "Settings"}
                              onClose={handleMenuClose}
                              MenuListProps={{
                                "aria-labelledby": "automation-menu",
                              }}
                              PaperProps={{
                                style: {
                                  marginTop: "20px", // Move the menu down by 20px
                                },
                              }}
                            >
                              {hasWhatsAppAccount && (
                                <>
                                  <MenuItem
                                    component={NavLink}
                                    to="/appointment"
                                    onClick={handleMenuClose}
                                  >
                                    Appointment
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/custome-attributes"
                                    onClick={handleMenuClose}
                                  >
                                    Custome Attributes
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/order-status"
                                    onClick={handleMenuClose}
                                  >
                                    Order Status
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/order-attributes"
                                    onClick={handleMenuClose}
                                  >
                                    Order Attributes
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/products"
                                    onClick={handleMenuClose}
                                  >
                                    Products
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/widget"
                                    onClick={handleMenuClose}
                                  >
                                    Widget
                                  </MenuItem>
                                  <MenuItem
                                    component={NavLink}
                                    to="/UserManagement"
                                    onClick={handleMenuClose}
                                  >
                                    User Management
                                  </MenuItem>
                                </>
                              )}
                            </Menu>
                          </div>
                        </li>
                        {userPermissions?.integrations && (
                          <li>
                            <div className="normalNav">
                              <Button
                                onClick={(e) =>
                                  handleMenuClick(e, "Integrations")
                                }
                                style={navMenu}
                              >
                                {currentPage === "/integrations/zohoBooks" ? (
                                  <strong>Integrations</strong>
                                ) : (
                                  "Integrations"
                                )}
                              </Button>
                              <Menu
                                id="automation-menu"
                                anchorEl={menuAnchorEl}
                                open={isOpen === "Integrations"}
                                onClose={handleMenuClose}
                                MenuListProps={{
                                  "aria-labelledby": "automation-menu",
                                }}
                                PaperProps={{
                                  style: {
                                    marginTop: "20px", // Move the menu down by 20px
                                  },
                                }}
                              >
                                {hasWhatsAppAccount && (
                                  <>
                                    <MenuItem
                                      component={NavLink}
                                      to="/integrations/zohoBooks"
                                      onClick={handleMenuClose}
                                    >
                                      Zoho Books
                                    </MenuItem>
                                  </>
                                )}
                              </Menu>
                            </div>
                          </li>
                        )}

                        {/* <li>
                          {!isCurrentPage("/settings") ? (
                            <NavLink className="normalNav" to="/settings">
                              Settings
                            </NavLink>
                          ) : (
                            <span className="activeNavLink">Settings</span>
                          )}
                        </li> */}
                        {/*<li>
                      <NavLink className="normalNav" to="/settings">
                        Settings
                      </NavLink>{" "}
                    </li> */}
                        {/* <li
                      className={
                        currentPage === "conversations" ? "boldNav" : "normalNav"
                      }
                      onClick={() => {
                        navigate("/conversations");
                      }}
                    >
                      Conversations
                    </li>
                    <li
                      className={currentPage === "contacts" ? "boldNav" : "normalNav"}
                      onClick={() => {
                        navigate("/contacts");
                      }}
                    >
                      Contacts
                    </li>
                    <li
                      className={currentPage === "Campaign" ? "boldNav" : "normalNav"}
                      onClick={() => {
                        navigate("/Campaign");
                      }}
                    >
                      Campaign
                    </li>
                    */}
                      </ul>
                    </div>
                  )}
                  <div onClick={handleProfileClick} className="profileBtn">
                    <p>
                      <strong>Hi</strong> {userDetails?.first_name}
                      {/* {userDetails?.last_name} */}
                    </p>
                    {/* <img
                      src={
                        auth?.user?.user
                          ? auth?.user?.user?.profile_pic
                          : profile
                      }
                      alt="profile"
                      className="navProfileImg"
                    /> */}
                    <Avatar
                      alt="profile_pic"
                      src={userDetails ? userDetails?.profile_pic : profile}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        navigate("/profile");
                        handleClose();
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        logOut();
                        handleClose();
                      }}
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                ""
              )
            ) : (
              <>
                {/* <div style={{ display: "flex", justifyContent: "space-between",marginRight:"500px" }}> */}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ marginLeft: "-400px" }}>
                    <ul className="Navmenu">
                      <li>
                        <NavLink className="normalNav" to="/pricing">
                          Pricing
                        </NavLink>{" "}
                      </li>
                      <li>
                        <a
                          target="_blank_"
                          href={CalendlyURL}
                          className="normalNav"
                        >
                          Book a Demo
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ul className="Navmenu">
                    <li>
                      <NavLink className="normalauthNav" to="/signup">
                        Sign Up
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink className="normalauthNav" to="/login">
                        Login
                      </NavLink>{" "}
                    </li>
                  </ul>
                </div>
                {/* </div> */}
              </>
            )}
          </>
        )}
      </header>
    </>
  );
}
