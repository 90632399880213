import {
  Button,
  Grid,
  TextField,
  Box,
  Checkbox,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import "./SignUp.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import React from "react";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  apiFetch,
  GOOGLE_CLIENT_ID,
  FACEBOOK_APP_ID,
} from "../../API/CommonApi";
import { notify } from "../Common";
import { useAuth } from "../../Context/Auth";
import {
  registerUser as apiRegisterUser,
  social as apiSocial,
  checkwhatsAppBusinessAcc as WPCheck,
  facebookLogin as apiFacebookLogin,
} from "../../API/AuthLayer";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { useGoogleLogin } from "@react-oauth/google";

const mainCtr = {
  maxWidth: "480px",
  width: "auto",
  height: "auto",
};
const socialButtonCSS = {
  color: "#303535",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  textTransform: "capitalize",
  background: "#ffff",
  width: "200px",
  height: "50px",
  border: " 1px solid #E8E8E8",
  borderRadius: "5px",
};

export default function Form() {
  const auth = useAuth();
  const navigate = useNavigate();
  const methods = useForm({ mode: "onBlur" });
  const { handleSubmit, reset, control, setValue, watch, formState, setError } =
    methods;
  const [apiErrors, setErrors] = React.useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);

  const { errors } = formState;
  // on submit
  const onSubmit = (data) => {
    SetApiLoading(true);
    apiRegisterUser(data)
      .then((response) => {
        if (response.ok) {
          SetApiLoading(false);

          return response.json();
        }
        // else {
        //   response?.text().then((text) => {
        //     const tempErrors = JSON.parse(text);
        //     setErrors(JSON.parse(text));
        //     Object.values(tempErrors).map((errorList) =>
        //       errorList.map((error) => {
        //         notify("error", error);
        //       })
        //     );
        //   });
        // }
        // console.error("Failed to signup");
        SetApiLoading(false);
      })
      .then((data) => {
        if (data) {
          navigate("/login");
          notify("success", "Successful Signup");
          SetApiLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        SetApiLoading(false);
      });
  };
  // for Google Signup
  const googleLoginFN = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      const accessCode = codeResponse?.code;
      if (accessCode) {
        apiSocial("google", accessCode)
          .then((data) => {
            if ("access_token" in data) {
              auth.login(data);
              notify("success", "Successful Sign Up");

              navigate("/conversations", { replace: true });
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            notify("error", "Something went wrong");
            SetApiLoading(false);
          });
      }
      SetApiLoading(false);
    },
    onError: (errorResponse) => {
      notify("error", "Something went wrong");
      console.log("errorResponse", errorResponse);
      SetApiLoading(false);
    },
    onNonOAuthError: (nonOAuthError) => {
      notify("error", "Something went wrong");
      console.log("nonOAuthError", nonOAuthError);
      SetApiLoading(false);
    },
  });

  // For FaceBook SignUp
  const FacebookLoginApi = () => {
    SetApiLoading(true);
    window.FB.login(function (response) {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        if (accessToken) {
          apiFacebookLogin(accessToken)
            .then((data) => {
              if ("access_token" in data) {
                auth.login(data);
                notify("success", "Successful Sign Up");
                navigate("/conversations", { replace: true });
              } else {
                SetApiLoading(false);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
              notify("error", "Something went wrong");
              SetApiLoading(false);
            });
        } else {
          console.log("Error:", response);
          notify("error", "Something went wrong");
          SetApiLoading(false);
        }
      } else {
        console.log("User cancelled login .");
        SetApiLoading(false);
      }
    });
  };

  const [showPassword1, setShowpwd1] = useState(false);
  const [showPassword2, setShowpwd2] = useState(false);
  const handleClickShowPassword = (x) => {
    if (x === 1) {
      setShowpwd1(!showPassword1);
    } else if (x === 2) {
      setShowpwd2(!showPassword2);
    }
  };

  return (
    <>
      <Box sx={mainCtr}>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your Name *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px" }}
                    error={Boolean(errors.name)}
                    helperText={errors.name && "Name is required"}
                  />
                )}
                rules={{ required: true }}
                name="name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your Email *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    type={"email"}
                    {...field}
                    size="small"
                    fullWidth
                    inputProps={{
                      autoComplete: "off",
                    }}
                    sx={{ maxWidth: "412px" }}
                    error={Boolean(errors.email)}
                    helperText={errors.email && "Email is required"}
                  />
                )}
                rules={{ required: true }}
                name="email"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your mobile number *
              </InputLabel>
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <PhoneInput
                    // value={phoneNo}
                    value={value}
                    onChange={onChange}
                    specialLabel={""}
                    country={"in"}
                    inputStyle={{
                      maxWidth: "412px",
                      width: "100%",
                      height: "40px",
                      background: "#ffff",
                      border: "solid 1px #c4c4c4",
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      name: "phone_number",
                      required: true,
                      // autoFocus: true,
                    }}
                    buttonStyle={{
                      border: "solid 1px #7C8686",
                    }}
                    dropdownClass={"countryDropDownCss"}
                    // onBlur={handleValidation}
                    // defaultErrorMessage={"Invalid Phone number"}
                    isValid={(value, country) => {
                      if (value.length > 10) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                  />
                )}
                name="phone_number"
                control={control}
                rules={{ required: true }}
              />
              {errors.phone_number && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  Phone Number is required
                </p>
              )}
              {/* <Controller
              render={({ field }) => (
                <OtpInput
                  {...field}
                  type="number"
                  placeholder="0000"
                  autoComplete="off"
                  isInputNum
                  inputStyle={{
                    width: "60px",
                    height: "50px",
                    fontSize: "18px",
                    borderRadius: "5px",
                    border: "1px solid #E8E8E8",
                  }}
                  numInputs={4}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                />
              )}
              name="otp"
              control={control}
            /> */}
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Enter your Password *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    fullWidth
                    inputProps={{
                      autoComplete: "off",
                    }}
                    error={Boolean(errors.password1)}
                    helperText={errors.password1 && "this field is required"}
                    sx={{ maxWidth: "412px" }}
                    size="small"
                    type={showPassword1 ? "text" : "password"}
                    // value={values.password}
                    // onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(1)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                rules={{ required: true }}
                name="password1"
                control={control}
              />
              {errors.password1 && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  This field is required
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Re enter your Password *
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <OutlinedInput
                    {...field}
                    fullWidth
                    error={Boolean(errors.password2)}
                    helperText={errors.password2 && "this field is required"}
                    sx={{ maxWidth: "412px" }}
                    size="small"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    type={showPassword2 ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => handleClickShowPassword(2)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                rules={{ required: true }}
                name="password2"
                control={control}
              />
              {errors.password2 && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  This field is required
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              <p>
                <Controller
                  render={({ field }) => <Checkbox {...field} />}
                  name="remember-my-password"
                  control={control}
                />
                &nbsp;Remember my password
              </p>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "capitalize",
                  maxWidth: "412px",
                  padding: "16px 0 15px 0",
                  marginTop: "25px",
                  background:
                    "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                  borderRadius: " 5px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "19px",
                  textAlign: "center",
                  letterSpacing: "0.05em",
                  marginBottom: "20px",
                }}
                onClick={handleSubmit(onSubmit)}
              >
                Create an Account
              </Button>
            </Grid>
            {/* <p
              style={{
                textAlign: "center",
                width: "100%",
                maxWidth: "412px",
                margin: "20px",
              }}
            >
              ━ Or Sign up with ━
            </p> */}
            {/* <div
              style={{
                // maxWidth: "412px",
                // marginBottom: "20px",
                // gap: "15px",
                // display: "flex",
                // justifyContent: "space-between",
                // flexWrap: "wrap",
                marginBottom: "10px",
              }}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  <Button
                    sx={socialButtonCSS}
                    onClick={() => {
                      SetApiLoading(true);
                      googleLoginFN();
                    }}
                  >
                    <GoogleIcon />
                    &nbsp;Google
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  justifyContent={"center"}
                  display={"flex"}
                >
                  {/* <LoginSocialFacebook
                    appId={FACEBOOK_APP_ID}
                    onReject={(error) => {
                      console.log("socialError", error);
                    }}
                    onResolve={(res) => social(res)}
                  >
                    <Button sx={socialButtonCSS}>
                      <FacebookRoundedIcon />
                      &nbsp;Facebook
                    </Button>
                  </LoginSocialFacebook> */}
                  {/* <Button sx={socialButtonCSS} onClick={FacebookLoginApi}>
                    <FacebookRoundedIcon />
                    &nbsp;Facebook
                  </Button>
                </Grid>
              </Grid> */}
            {/* </div> */} 
            <Link to="/login" className="no-underline ">
              <a className="Link">Already have an account? Sign in here</a>
            </Link>
          </Grid>
        </FormProvider>
      </Box>
      {ApiLoading && (
        <div className="overlay">
          <Spinner_2 style={{ width: "80px" }} />
        </div>
      )}
    </>
  );
}
