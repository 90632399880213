// ForwardMessage.js
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Fab,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  InputAdornment,
} from "@mui/material";
import { contactChatList } from "../../../../API/ChatLayer";
import { forwardMessage as apiForwardMessage } from "../../../../API/ChatLayer";
import { debounce } from "lodash";
import { useAuth } from "../../../../Context/Auth";
import { loadContacts as apiLoadContacts } from "../../../../API/ContactLayer";
import { notify } from "../../../Common";
import SearchIcon from "@mui/icons-material/Search";
import { sendContacts } from "../../../../API/ChatLayer";
import ContactsIcon from "@mui/icons-material/Contacts";

function ForwardMessage({ message, onClose }) {
const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
const auth = useAuth();
  const wpDetails = auth.wpDetails?.[0];
  const selectedChat = auth.selectedChat;

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setErrorMessage(false);
    setSelectedRows([]);
    onClose();
  };
  const loadContacts = (param) => {
    apiLoadContacts(param)
      .then((res) => {
        setContacts(res?.results);
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };
  const handleRowSelect = (row) => {
    if (selectedContacts.some((selectedRow) => selectedRow.id === row.id)) {
      setSelectedRows(
        selectedContacts.filter((selectedRow) => selectedRow.id !== row.id)
      );
    } else {
      setSelectedRows([...selectedContacts, row]);
    }
  };
  const handleSelectAll = () => {
    if (selectedContacts.length === contacts.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows([...contacts]);
    }
  };
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadContacts(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    
    loadContacts();
  }, []);
  
const ForwardMessage = () => {
  if (selectedContacts.length > 0) {
    const message_id = message.id; 
    if(message.message_type === "template"){
        notify("warn","Variables in the template will be replaced with the contact information");
      }
      if(selectedContacts.length === 1){
        const phoneNumber = selectedContacts[0].phone_number;
        SearchChat(`search=${phoneNumber}`);
      }
      
      apiForwardMessage(selectedContacts, wpDetails, message_id)
         .then((res) => {
          console.log(`Forwarded Message`);
        })
        .catch((error) => {
          console.error(`Error in Forwarding Message:`, error);
        });
 
    
  } else {
    setErrorMessage(true);
  }
  closeDialog();
}
  const SearchChat = (param) => {
    getContactChatList(param);
  };
  const getContactChatList = async (params = null, nextPage = false) => {
    
      try {
        const res = await contactChatList(auth.wpDetails, params);
        if (res.ok) {
          const data = await res.json();
          auth.setSelectedChat(data.results[0]);
        }
      } catch (error) {
        console.log("Failed to fetch Contacts", error);
      }
    };
  


  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Forward Message</DialogTitle>
      <DialogContent>
        <div style={{ width: "400px", height: "455px" }}>
          <>
            <TextField
              id="searchContacts"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "10px" }}
              size="small"
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                debouncedSearch(e.target.value);
                  if (!e.target.value) {
                    loadContacts();
                  }
              }}
            />
            <TableContainer
              component={Paper}
              style={{
                overflowY: "auto",
                height: "400px",
              }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <Checkbox
                        size="small"
                        checked={
                          selectedContacts.length === contacts.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="right">Phone Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contacts.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Checkbox
                          checked={selectedContacts.some(
                            (selectedRow) => selectedRow.id === row.id
                          )}
                          onChange={() => handleRowSelect(row)}
                        />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">{row.phone_number}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          {selectedContacts.length <= 0 && errorMessage ? (
            <p
              style={{
                textAlign: "center",
                color: "red",
                margin: "5px",
              }}
            >
              Please select at least one contact.
            </p>
          ) : (
            ""
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ padding: "0 20px 20px 0" }}>
          <Button
            disabled={selectedContacts.length <= 0}
            onClick={ForwardMessage}
          >
            Forward
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ForwardMessage;
