import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Paper,
} from "@mui/material";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SchemaRoundedIcon from "@mui/icons-material/SchemaRounded";
import { debounce } from "lodash";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../Styles";
import { Link, Outlet, NavLink, useNavigate } from "react-router-dom";

const Appointments = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/calender/events"); // navigate to Overview page by default
  }, []);

  return (
    <>
      <div className="CampaignNav">
        <nav>
          <NavLink to="events">Events</NavLink>
          <NavLink to="tasks">Tasks</NavLink>
        </nav>
      </div>
      <Outlet />
    </>
  );
};

export default Appointments;
