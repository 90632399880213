import { Button, Grid, TextField, Box, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
// import "./SignUp.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import React from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { apiFetch } from "../../API/CommonApi";
import { notify } from "../Common";
import { useAuth } from "../../Context/Auth";
import { submitDemoRequest as apiSubmitDemoRequest } from "../../API/AuthLayer";
const mainCtr = {
  maxWidth: "420px",
  width: "auto",
  height: "auto",
};
const InputLable = { color: "#303535", margin: "18px 0 5px 0" };
const SubmitButton = {
  textTransform: "capitalize",
  maxWidth: "412px",
  padding: "16px 0 15px 0",
  marginTop: "25px",
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
  letterSpacing: "0.05em",
};
const BtnCtr = {
  display: "flex",
  // justifyContent: "center",
};
export default function RequestDemoForm() {
  const auth = useAuth();
  const navigate = useNavigate();
  const methods = useForm({ mode: "onBlur" });
  const { handleSubmit, reset, control, setValue, watch, formState, setError } =
    methods;
  const [apiErrors, setErrors] = useState(null);
  const { errors } = formState;
  // on submit

  const onSubmit = (data) => {
    setErrors(null);
    apiSubmitDemoRequest(data)
      .then((response) => {
        if (response.ok) {
          console.log("res", response);
          // navigate("/");
          reset();
          setValue("name", "");
          setValue("email", "");
          notify(
            "success",
            "Thank you for contacting us. We’ve received your request and our team will get back to you shortly"
          );
        } else {
          response?.text().then((text) => {
            const tempErrors = JSON.parse(text);
            setErrors(JSON.parse(text));
            Object.values(tempErrors).map((errorList) =>
              errorList.map((error) => {
                notify("error", error);
              })
            );
          });
        }
        console.error("Something went wrong");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    console.log("errors:", errors);
    console.log("errors:", apiErrors);
  }, [errors, apiErrors]);
  return (
    <>
      <Box sx={mainCtr}>
        <div style={{ marginBottom: "0" }}>
          <h2 style={{ marginBottom: "10px" }}>Reach out for assistance</h2>
          {/* <h4>Lorem ipsum dolor sit Lorem ipsum dolor sit </h4> */}
        </div>
        <FormProvider {...methods}>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={InputLable}>Name *</InputLabel>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px" }}
                    error={Boolean(errors.first_Name)}
                    helperText={errors.first_Name && "First Name is required"}
                  />
                )}
                rules={{ required: true }}
                name="name"
                control={control}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <InputLabel sx={InputLable}>Second Name *</InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px" }}
                    error={Boolean(errors.second_Name)}
                    helperText={errors.second_Name && "Second Name is required"}
                  />
                )}
                rules={{ required: true }}
                name="second_Name"
                control={control}
              />
            </Grid> */}

            <Grid item xs={12}>
              <InputLabel sx={InputLable}>Contact Number *</InputLabel>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    // value={phoneNo}
                    value={value}
                    onChange={onChange}
                    specialLabel={""}
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      maxWidth: "412px",
                      height: "40px",
                      background: "#ffff",
                      border: "solid 1px #c4c4c4",
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      name: "phone_number",
                      required: true,
                      // autoFocus: true,
                    }}
                    buttonStyle={{
                      border: "solid 1px #7C8686",
                    }}
                    dropdownClass={"countryDropDownCss"}
                    // onBlur={handleValidation}
                    // defaultErrorMessage={"Invalid Phone number"}
                    isValid={(value, country) => {
                      if (value.length > 10) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                  />
                )}
                name="phone_number"
                control={control}
                rules={{ required: true }}
              />
              {errors.phone_number && (
                <p
                  style={{
                    color: "#D32f2f",
                    fontSize: "12px",
                    marginLeft: "15px",
                    marginTop: "5px",
                  }}
                >
                  Phone Number is required
                </p>
              )}

              <Grid item xs={12}>
                <InputLabel sx={InputLable}>Email ID *</InputLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      type={"email"}
                      value={value}
                      onChange={onChange}
                      size="small"
                      fullWidth
                      inputProps={{
                        autoComplete: "off",
                      }}
                      sx={{ maxWidth: "412px" }}
                      error={Boolean(errors.email)}
                      helperText={errors.email && "Email is required"}
                    />
                  )}
                  rules={{ required: true }}
                  name="email"
                  control={control}
                />
              </Grid>
            </Grid>

            {/* <Grid item xs={12}>
              <InputLabel sx={InputLable}>Name of your Business *</InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px" }}
                    error={Boolean(errors.company)}
                    helperText={
                      errors.company && "Name of your Business is required"
                    }
                  />
                )}
                rules={{ required: true }}
                name="company"
                control={control}
              />
            </Grid> */}
            <Grid item xs={12} style={BtnCtr}>
              <Button
                variant="contained"
                fullWidth
                sx={SubmitButton}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </>
  );
}
