import React, { useState, useEffect } from "react";
// import "./Service.css";
import { Button } from "@mui/material";

import { useAuth } from "../../Context/Auth";
import { apiFetch } from "../../API/CommonApi";
import { useNavigate } from "react-router-dom";
import { notify } from "../Common";
import ServicesList from "./ServicesList";
import AddService from "./AddService";
import { btnCss } from "../../Styles";
import { loadProducts as apiLoadProducts } from "../../API/ProductLayer";
import { loadServices as apiLoadServices } from "../../API/ServiceLayer";
import { checkwhatsAppBusinessAcc as WPCheck } from "../../API/AuthLayer";

// import { logOut } from "../../API/CommonApi";

function Service() {
  // const auth = useAuth();
  const navigate = useNavigate();
  const accessToken = JSON.parse(localStorage.getItem("userTokens"))?.access;
  const [services, setServices] = useState(null);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  // const [contacts, setContacts] = useState(null);
  const [editData, setEditData] = useState(null);
  const [count, setCount] = useState(null);
  const [WpDetails, setWpDetails] = useState(null);
  const auth = useAuth();
  const [page, setPage] = useState(1);
  const [searchKey, setSearch] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };
  const handleAddorEdit = (data) => {
    setOpen(true);
    setEditData(data);
  };

  // Load Products
  const loadProducts = (param) => {
    apiLoadProducts(param)
      .then((res) => {
        // console.log(res);
        setProducts(res);
        // console.log(products.results);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  // Load Services
  const loadServices = (param) => {
    apiLoadServices(param)
      .then((res) => {
        setServices(res.results);
        setCount(res.count);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    loadServices(null);
  }, [accessToken]);

  // useEffect(() => {
  //   loadServices();
  // }, [param]);
  // const searchFn = (event) => {
  //   setParam(`search=${event}`);
  // };

  // wpDetails
  useEffect(() => {
    WPCheck()
      .then((res) => {
        if (typeof res === "object" && res?.length !== 0) {
          auth.setWpDetails(res);
          setWpDetails(res?.[0]);
        } else {
          navigate("/whatsapp-signup");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <>
      <div>
        <div className="pageInNav">
          <p>Orders</p>
          <div className="inNavCtr">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Order
            </Button>
          </div>
        </div>
        <ServicesList
          handleEdit={handleAddorEdit}
          services={services}
          count={count}
          loadServices={loadServices}
          page={page}
          setPage={setPage}
          searchKey={searchKey}
          setSearch={setSearch}
        />
      </div>
      <AddService
        open={open}
        handleClose={handleClose}
        loadServices={loadServices}
        products={products}
        rowData={editData}
        WpDetails={WpDetails}
        page={page}
        setPage={setPage}
        searchKey={searchKey}
        setSearch={setSearch}
      />
    </>
  );
}

export default Service;
