import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Menu,
  InputAdornment,
  TablePagination,
  Paper,
  Avatar,
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { btnCss, TextfieldCss } from "../../../Styles";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddUsers from "./AddUsers";
import { formatDate, isMobile } from "../../Common";
import { debounce, set } from "lodash";
import { notify } from "../../Common";
import { loadUser } from "../../../API/UserManagementLayer";
import { deleteUser } from "../../../API/UserManagementLayer";
import profile from "../../../Assets/profile.png";

function Users() {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchKey, setSearch] = useState(null);
  const [tempRowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [editData, setEditData] = useState(null);
  const mobileScreen = isMobile();
  const dot = {
    width: "6px",
    height: "6px",
    position: "relative",
    top: "-2px",
    right: "10px",
  };

  const pending = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.03em",
    color: "#f8662b",
    borderRadius: "60px",
    backgroundColor: "#FFE4D9",
    textAlign: "center",
    padding: "5px",
  };

  const delivered = {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.03em",
    color: "#F1FFF3",
    borderRadius: "60px",
    backgroundColor: "#278435",
    textAlign: "center",
    padding: "5px",
  };

  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const loadUsers = (params) => {
    loadUser(params)
      .then((res) => {
        setUsers(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteUser = (rowData) => {
    deleteUser(rowData.id)
      .then((res) => {
        loadUsers();
        setAnchorEl(null);
        notify("warn", "User Deleted Successfully");
      })
      .catch((err) => {
        notify("error", "Something went wrong");
        console.log(err);
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    if (page && searchKey) {
      loadUsers(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadUsers(`page=${page}`);
    }
    if (searchKey) {
      loadUsers(`search=${searchKey}`);
    }
  }, [page]);

  const debouncedSearch = debounce((value) => {
    if (value) {
      setSearch(value);
      loadUsers(`search=${value}`);
    } else {
      setSearch(null);
    }
  }, 600);

  const columns = [
    {
      title: "Name",
      field: "name",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <Avatar
              alt="profile_pic"
              src={rowData?.profile_pic ? rowData?.profile_pic : profile}
            />
          </span>
          <p
            style={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              marginLeft: "8px",
              flexWrap: "wrap",
            }}
          >
            {rowData.first_name} {rowData.last_name}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p> {rowData?.username}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Role",
      field: "role",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p style={{ textTransform: "capitalize" }}>{rowData.role_name}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        if (rowData.status === "invited") {
          return (
            <p style={pending}>
              <FiberManualRecordIcon style={dot} />
              Invited
            </p>
          );
        }
        if (rowData.status === "accepted") {
          return (
            <p style={delivered}>
              <FiberManualRecordIcon style={dot} />
              Accepted
            </p>
          );
        }
      },
    },
    {
      title: "Created at",
      field: "created_at",
      render: (rowData) => (
        <div className="column-1">
          <span>
            <p>{formatDate(rowData.created_at)}</p>
            <p> </p>
          </span>
        </div>
      ),
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setRowData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setRowData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  handleDeleteUser(tempRowData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setRowData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];

  return (
    <>
      <Paper elevation={2} className="table">
        <div>
          <div className="customMTableToolbar">
            <div
              style={{
                width: mobileScreen ? "200px" : "315px",
              }}
            >
              <TextField
                style={TextfieldCss}
                size="small"
                variant="outlined"
                placeholder="Search "
                type="search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  if (!e.target.value) {
                    loadUsers();
                  }
                }}
              />
            </div>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Users
            </Button>
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={users ? users?.results : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={users?.count}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              // selection: true,
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,

              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                // borderTop: "1px solid #EDDCDC",
                // BorderBottom: "1px solid #EDDCDC",
                // borderLeft: "1px solid #D0D0D0",
                // borderRight: "1px solid #D0D0D0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
              },
            }}
          />
        </div>
      </Paper>
      <AddUsers
        open={open}
        handleClose={handleClose}
        loadUsers={loadUsers}
        editData={editData}
      />
    </>
  );
}
export default Users;
