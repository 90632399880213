import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { apiFetch } from "../../API/CommonApi";
import Modal from "@material-ui/core/Modal";
import { Controller, FormProvider, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { notify } from "../Common";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import { btnCss, TextfieldCss, ClsBtn } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { createContact as apiCreateContact } from "../../API/ContactLayer";
import { updateContact as apiUpdateContact } from "../../API/ContactLayer";

export const AddContact = ({
  WpDetails,
  loadContacts,
  handleClose,
  editData,
  open,
  handleAddContactClose,
}) => {
  const [edit, setedit] = useState(false);

  const methods = useForm({ mode: "onBlur" });

  const { handleSubmit, reset, control, formState, setValue } = methods;
  const { errors } = formState;
  const [ApiLoading, SetApiLoading] = useState(false);
  const [attributes, setAttributes] = useState(null);

  // create contacts
  const create = (data) => {
    // SetApiLoading(true);
    const { name, phone_number, email, ...custom_attribute } = data;
    const tempData = {
      name,
      phone_number,
      email,
      custom_attribute,
    };
    const final = Object.entries(custom_attribute).map(([key, value]) => {
      const option = attributes.find((option) => option.name === key);
      if (!option) {
        throw new Error(`Custom attribute "${key}" not found in options.`);
      }
      return {
        custom_field: option.id,
        value: value !== undefined ? value : null,
      };
    });
    if (WpDetails) {
      // setPhNoId(WpDetails.phone_numbers?.[0].phone_number_id);
      const body = {
        phone_number: `+${data.phone_number}`,
        name: data?.name,
        email: data?.email,
        custom_attribute: final,
        whatsapp_business_account: WpDetails?.app_id,
      };

      apiCreateContact(body)
        .then((res) => {
          handleClose && handleClose();
          SetApiLoading(false);
          if (res.ok) {
            notify("success", "Contact Added");
          } else {
            notify("error", "Something went wrong");
          }
          return res;
        })
        .then((res) => {
          loadContacts();
          SetApiLoading(false);
          handleAddContactClose(res);
        })
        .catch((error) => {
          SetApiLoading(false);
          handleClose && handleClose();
        });

      reset();
    }
  };

  // Edit contact
  const contactEdit = (data) => {
    apiUpdateContact(data, editData, attributes)
      .then((res) => {
        loadContacts();
        handleClose();
        edit && setedit(null);
        SetApiLoading(false);
        notify("success", "Contact updated");
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong");
        SetApiLoading(false);
      });
  };
  useEffect(() => {
    apiFetch("GET", "whatsapp-business/contacts/custom_fields/", null, true)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          notify("error", "something went wrong");
          console.log("error res", res);
        }
      })
      .then((res) => {
        setAttributes(res.data);
      });
  }, []);

  useEffect(() => {
    if (editData) {
      setedit(true);
      setValue("name", editData.name);
      setValue("phone_number", editData.phone_number);
      setValue("email", editData.email);
      editData.custom_attribute.map((x) => {
        setValue(x.custom_field, x.value);
      });
    } else {
      reset();
      setedit(false);
    }
  }, [editData, open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <div className="modal">
        <div className=".popupContent">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{edit ? "Update Contact" : "Add Contact"}</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <div>
            <FormProvider {...methods}>
              <Box>
                <Grid item xs={6}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Enter Name *
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        sx={{
                          width: "100%",
                          maxWidth: "388.58px",
                          marginBottom: "10px",
                        }}
                        error={Boolean(errors?.name)}
                        helperText={Boolean(errors?.name) && "Name is required"}
                      />
                    )}
                    rules={{ required: true }}
                    name="name"
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Enter mobile number *
                    </InputLabel>

                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <div>
                          <PhoneInput
                            value={value || ""}
                            disabled={edit}
                            onChange={onChange}
                            country={"in"}
                            inputStyle={{
                              width: "100%",
                              maxWidth: "388.58px",
                              height: "40px",
                              background: "#ffff",
                              border: "solid 1px #7C8686",
                              borderRadius: "4px",
                            }}
                            specialLabel={""}
                            buttonStyle={{
                              border: "solid 1px #7C8686",
                            }}
                            // isValid={(value, country) => {
                            //   if (value.length > 10) {
                            //     return true;
                            //   } else {
                            //     return false;
                            //   }
                            // }}
                          />
                        </div>
                      )}
                      name="phone_number"
                      control={control}
                      rules={{ required: true }}
                    />
                    {errors.phone_number && (
                      <p
                        style={{
                          color: "#D32f2f",
                          fontSize: "12px",
                          marginLeft: "15px",
                          marginTop: "5px",
                        }}
                      >
                        This Phone Number is required
                      </p>
                    )}
                  </>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Enter e-mail
                  </InputLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        fullWidth
                        sx={{
                          width: "100%",
                          maxWidth: "388.58px",
                          marginBottom: "10px",
                        }}
                        error={Boolean(errors?.email)}
                      />
                    )}
                    name="email"
                    control={control}
                  />
                </Grid>
                {attributes?.map((field) => {
                  const customeType =
                    field.type === "number"
                      ? "number"
                      : field.type === "date"
                      ? "date"
                      : "text";

                  if (field.type === "dropdown") {
                    const options = field.options;
                    return (
                      <Grid item xs={6} key={field.id}>
                        <InputLabel
                          sx={{
                            color: "#303535",
                            margin: "10px 0 5px 0",
                            textTransform: "capitalize",
                          }}
                        >
                          {field.name.replace(/_/g, " ")}
                        </InputLabel>
                        <Controller
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={options}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  fullWidth
                                  sx={{ width: "100%", maxWidth: "388.58px" }}
                                  type={customeType}
                                />
                              )}
                              onChange={(e, value) => {
                                setValue(field.name, value);
                              }}
                            />
                          )}
                          name={field.name}
                          control={control}
                        />
                      </Grid>
                    );
                  }

                  return (
                    <Grid item xs={6} key={field.id}>
                      <InputLabel
                        sx={{
                          color: "#303535",
                          margin: "10px 0 5px 0",
                          textTransform: "capitalize",
                        }}
                      >
                        {field.name.replace(/_/g, " ")}
                      </InputLabel>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            fullWidth
                            sx={{ width: "100%", maxWidth: "388.58px" }}
                            type={customeType}
                          />
                        )}
                        name={field.name}
                        control={control}
                      />
                    </Grid>
                  );
                })}
                <Grid item>
                  {edit ? (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        maxWidth: "388.58px",
                        marginTop: "15px",
                        background:
                          "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                        borderRadius: " 5px",
                      }}
                      onClick={handleSubmit(contactEdit)}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        maxWidth: "388.58px",
                        marginTop: "15px",
                        background:
                          "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                        borderRadius: " 5px",
                      }}
                      onClick={handleSubmit(create)}
                    >
                      ADD Contact
                    </Button>
                  )}
                </Grid>
              </Box>
            </FormProvider>
          </div>
        </div>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </div>
    </Modal>
  );
};
