import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Autocomplete,
  Chip,
  Switch,
  FormControlLabel,
  Drawer,
  TimeField,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import CreatableSelect from "react-select/creatable";
import { apiFetch } from "../../API/CommonApi";
import Modal from "@material-ui/core/Modal";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { notify, isMobile } from "../Common";
import { debounce, template } from "lodash";
import { ReactComponent as Spinner_2 } from "../../Assets/Spinner_2.svg";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { loadTemplates as apiLoadTemplates } from "../../API/CampaignLayer";
import { templatePreview as apiTemplatePreview } from "../../API/TemplateLayer";
import {
  createAutomation as apiCreateAutomation,
  editAutomation as apiEditAutomation,
} from "../../API/AutomationLayer";
import CircleIcon from "@mui/icons-material/Circle";
import { Height } from "@mui/icons-material";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #de4467" : "1px solid #ced4da",
    boxShadow: "none",
    "&:hover": {
      border: state.isFocused ? "2px solid #de4467" : "1px solid #212121",
    },
  }),
};
const frmElement = { marginBottom: "10px" };
const AddAutomation = ({
  WpDetails,
  loadAutomations,
  handleClose,
  editData,
  open,
}) => {
  const methods = useForm({ mode: "onBlur" });
  const mobile = isMobile();
  const { handleSubmit, reset, control, formState, setValue, watch } = methods;
  const { errors } = formState;
  const watchAllFields = watch();
  const [ApiLoading, SetApiLoading] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const handleCloseModal = () => {
    setTemplates(null);
    reset();
    loadAutomations();
    handleClose();
  };
  const onSubmit = (data) => {
    const keywordsArray = data?.keywords?.map((obj) => obj.value.toLowerCase());
    if (editData) {
      // Edit Automation
      const { initiation_type, template, active, ...replies } = data;
      // const body = {
      //   initiation_type: initiation_type.value,
      //   active: data.active,
      //   ...(initiation_type.value === "keyword_based"
      //     ? { keywords: keywordsArray, reply_to_template: null }
      //     : { reply_to_template: template.id, keywords: null }),
      //   name: data?.name_of_the_flow,
      //   conversation_duration: timeConverter(data?.conversation_duration),
      //   conversation_ending_message: data?.conversation_ending_message,
      //   is_default: data?.is_default,
      // };
      const body = {
        active: data.active,
        name: data?.name_of_the_flow,
        keywords: keywordsArray,
        is_default: data?.is_default,
      };
      // console.log("body", body);

      apiEditAutomation(editData.id, body).then((res) => {
        if (res.ok) {
          handleCloseModal();
        }
      });
    } else {
      // Save Automation
      const { initiation_type, template, ...replies } = data;

      // const body = {
      //   initiation_type: initiation_type.value,
      //   ...(initiation_type.value === "keyword_based"
      //     ? { keywords: keywordsArray, reply_to_template: null }
      //     : { reply_to_template: template.id, keywords: null }),
      //   name: data?.name_of_the_flow,
      //   conversation_duration: timeConverter(data?.conversation_duration),
      //   conversation_ending_message: data?.conversation_ending_message,
      //   is_default: data?.is_default,
      // };
      const body = {
        name: data?.name_of_the_flow,
        keywords: keywordsArray,
        is_default: data?.is_default,
      };
      // console.log("body", body);

      apiCreateAutomation(body).then((res) => {
        if (res.ok) {
          handleCloseModal();
        }
      });
    }
  };

  // const typesOfMsg = [
  //   {
  //     name: "All new conversations",
  //     value: "all_new_conversations",
  //     id: 1,
  //   },
  //   {
  //     name: "Keyword based",
  //     value: "keyword_based",
  //     id: 2,
  //   },
  //   {
  //     name: "Reply to Template",
  //     value: "reply_to_template",
  //     id: 3,
  //   },
  // ];

  useEffect(() => {
    reset();
    // loadTemplates();
    if (editData) {
      setValue("active", editData.active);
      // setValue(
      //   "conversation_duration",
      //   timeConverter(editData.conversation_duration)
      // );
      // setValue(
      //   "conversation_ending_message",
      //   editData.conversation_ending_message
      // );
      setValue("is_default", editData.is_default);
      const keywordsObject = editData.keywords?.map((value) => ({
        label: value,
        value: value.toLowerCase(),
      }));

      setValue("name_of_the_flow", editData.name);
      // editData.keywords?.length > 0
      //   ? setValue("initiation_type", typesOfMsg[1])
      //   : setValue("initiation_type", typesOfMsg[2]);

      setValue("keywords", keywordsObject);
      // editData?.template && setValue("template", editData.template);
    } else {
      reset();
      // setValue("initiation_type", typesOfMsg[0]);
    }
  }, [editData, open]);

  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };
  // const debouncedTemplates = debounce((searchKey) => {
  //   if (searchKey) {
  //     loadTemplates(searchKey);
  //   } else {
  //     loadTemplates();
  //   }
  // }, 600);

  useEffect(() => {
    if (watchAllFields.template?.name) {
      apiTemplatePreview({ template_name: watchAllFields.template?.name })
        .then((res) => {
          if (res.buttons) {
            var resButtons = res.buttons;
            if (typeof resButtons === "string")
              resButtons = JSON.parse(res.buttons);
          } else {
            notify(
              "error",
              `${watchAllFields.template?.name} Template has No Buttons`
            );
          }
        })
        .catch((error) => {
          console.error("Error", error);
          notify(
            "error",
            `${watchAllFields.template?.name} Template has No Buttons`
          );
        });
    }
  }, [watchAllFields.template]);

  const timeString = (data) => {
    const [hrs, mins] = data.split(":");
    return (
      <span style={{ color: "#de4467", fontSize: "14px" }}>
        {hrs > 0 ? `${hrs} hrs` : ""} {mins > 0 ? `${mins} mins` : ""}
      </span>
    );
  };
  const timeConverter = (data) => {
    let result;
    if (typeof data === "string") {
      const timeString = data;
      const [hoursStr, minutesStr] = timeString.split(":");
      const hours = parseInt(hoursStr, 10);
      const minutes = parseInt(minutesStr, 10);
      const totalSeconds = hours * 3600 + minutes * 60;
      result = totalSeconds;
    } else {
      const totalSeconds = data;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const hoursStr = hours.toString().padStart(2, "0");
      const minutesStr = minutes.toString().padStart(2, "0");
      result = `${hoursStr}:${minutesStr}`;
    }
    return result;
  };
  return (
    <Drawer
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
      anchor="right"
    >
      <div style={{ padding: "20px", width: mobile ? "100vw" : "450px" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{editData ? "Update Automation" : "Add Automation"}</h2>
            <span
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <CloseIcon
                style={ClsBtn}
                className="Icon2"
                onClick={handleClose}
              />
            </span>
          </div>
          <div style={{ padding: "20px 10px 10px 10px" }}>
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  // minHeight: "84vh",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {editData && (
                    <div style={frmElement}>
                      <Controller
                        name="active"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value}
                                onChange={onChange}
                                name="gilad"
                              />
                            }
                            label="Active"
                          />
                        )}
                      />
                    </div>
                  )}
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Name of the flow*
                    </InputLabel>
                    <Controller
                      rules={{ required: true }}
                      name="name_of_the_flow"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // type="date"
                          size="small"
                          fullWidth
                          // sx={{ marginBottom: "30px" }}
                          variant="outlined"
                          placeholder="Name"
                          error={Boolean(errors?.name_of_the_flow)}
                          helperText={
                            errors?.name_of_the_flow &&
                            "Name of the flow is required"
                          }
                        />
                      )}
                    />
                  </div>{" "}
                  {/* <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Conversation duration
                    </InputLabel>
                    <Controller
                      rules={{ required: true }}
                      name="conversation_duration"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          size="small"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                minutes
                              </InputAdornment>
                            ),
                          }}
                          // sx={{ marginBottom: "30px" }}
                          variant="outlined"
                          placeholder="Number of minutes"
                          error={Boolean(errors?.conversation_duration)}
                          helperText={
                            errors?.conversation_duration &&
                            "Conversation duration of the flow is required"
                          }
                        />
                      )}
                    />
                  </div> */}
                  {/* <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Type of Message
                    </InputLabel>
                    <Controller
                      name="initiation_type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={typesOfMsg}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          disableClearable
                          suppressDefaultStyles={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors?.initiation_type)}
                              helperText={
                                errors?.initiation_type &&
                                "initiation_type is required"
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </div> */}
                  {/* {watchAllFields?.initiation_type?.value ===
                    "reply_to_template" && (
                    <div style={frmElement}>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Select Template
                      </InputLabel>
                      <Controller
                        name="template"
                        control={control}
                        rules={{ required: "Template is required" }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            options={templates ? templates : []}
                            loading={loadingTemplates}
                            getOptionLabel={(option) => option?.name}
                            onInputChange={(e, newInputValue) => {
                              debouncedTemplates(newInputValue);
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                            }}
                            loadingText="Loading..."
                            noOptionsText="No options"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={Boolean(errors.template)}
                                onFocus={() => loadTemplates()}
                                helperText={
                                  errors.template && "Template is required"
                                }
                                placeholder="Select Template"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  )}
                  {watchAllFields?.initiation_type?.value ===
                    "keyword_based" && (
                    <div style={frmElement}>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Select Keywords
                      </InputLabel>
                      <Controller
                        name="keywords"
                        control={control}
                        rules={{ required: "Template is required" }}
                        render={({ field: { value, onChange } }) => (
                          <CreatableSelect
                            isMulti
                            styles={customStyles}
                            onChange={onChange}
                            value={value}
                            placeholder="Select Keywords"
                          />
                        )}
                      />
                    </div>
                  )} */}
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Select Keywords
                    </InputLabel>
                    <Controller
                      name="keywords"
                      control={control}
                      // rules={{ required: "Template is required" }}
                      render={({ field: { value, onChange } }) => (
                        <CreatableSelect
                          isMulti
                          styles={customStyles}
                          onChange={onChange}
                          value={value}
                          placeholder="Create Keywords"
                          noOptionsMessage={({ inputValue }) =>
                            !inputValue ? "Please type something" : " "
                          }
                        />
                      )}
                    />
                  </div>
                  {/* <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Conversation ending message
                    </InputLabel>
                    <Controller
                      rules={{ required: true }}
                      name="conversation_ending_message"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // type="test"
                          multiline
                          minRows={4}
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder="Conversation ending message"
                          error={Boolean(errors?.conversation_ending_message)}
                          helperText={
                            errors?.conversation_ending_message &&
                            "Conversation ending message is required"
                          }
                        />
                      )}
                    />
                  </div> */}
                  <div style={frmElement}>
                    <Controller
                      name="is_default"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={value}
                              onChange={onChange}
                              name="Checkbox"
                            />
                          }
                          label="Set as Default"
                        />
                      )}
                    />
                  </div>
                </div>

                <div style={frmElement}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    type="submit"
                    name="send_to_draft"
                  >
                    Save Automation
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default AddAutomation;
