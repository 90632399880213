import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Paper,
} from "@mui/material";
import MaterialTable from "material-table";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SchemaRoundedIcon from "@mui/icons-material/SchemaRounded";
import { debounce } from "lodash";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../../Styles";
import { notify, formatDate, formatDateOnly, isMobile } from "../../Common";
import AddAutomation from "./AddAutomation";

import {
  loadMessageAutomation,
  deleteMessageAutomation,
} from "../../../API/AutomationLayer";
import CircleIcon from "@mui/icons-material/Circle";
function Automation() {
  //states
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [searchKey, setSearch] = useState(null);
  const [automations, setAutomations] = useState([]);
  const [open, setOpen] = useState(false);
  const [openFlow, setOpenFlow] = useState(false);
  const [editData, setEditData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const [deleteData, setDeleteData] = useState(null);
  const mobileScreen = isMobile();
  // to Open modal
  const handleAddorEdit = (data) => {
    setOpen(true);
    data && setEditData(data);
    console.log(data);
  };

  // to close Modal
  const handleClose = () => {
    loadAutomations(null);
    setOpen(false);
    setEditData(null);
  };
  useEffect(() => {
    loadAutomations(null);
  }, [open]);

  //Loading automations

  const loadAutomations = (param) => {
    const tParam = param ? param : "page=1";
    loadMessageAutomation(tParam)
      .then((res) => {
        console.log(res.results);
        setAutomations(res.results);
        setCount(res.count);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const delAutomations = (rowData) => {
    deleteMessageAutomation(rowData.id).then(() => {
      setAnchorEl(null);
      setDeleteData(null);
      loadAutomations(null);
      notify("warn", "Automation Deleted");
    });
  };

  useEffect(() => {
    if (page && searchKey) {
      loadAutomations(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadAutomations(`page=${page}`);
    }
  }, [page]);
  const pageHandle = (e, page) => {
    setPage(page + 1);
  };
  const recurrTypeMap = {
    exact: "Exact Date",
    yearly: "Yearly",
    monthly: "Monthly",
  };

  //columns
  const columns = [
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <p className="column-4">
          {rowData.name.toUpperCase().replace(/_/g, " ")}
        </p>
      ),
    },
    {
      title: "Template",
      field: "template",
      render: (rowData) => <p>{rowData.template.name}</p>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Custom Field / Specific Date",
      field: "",
      render: (rowData) => (
        <p>
          {rowData.automation_type === "custom_field"
            ? rowData.custom_attr.name
            : rowData.date}
        </p>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Recurr Type",
      field: "recurrType",
      render: (rowData) => <p>{recurrTypeMap[rowData.recurr_on]}</p>,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Created Date",
      field: "createdDate",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.created_at)}</p>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Updated at",
      field: "updated_at",
      render: (rowData) => (
        <p className="column-4">{formatDate(rowData?.updated_at)}</p>
      ),
      cellStyle: {
        textAlign: "center",
      },
    },

    {
      title: "Edit / Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <p>
            <EditIcon
              className="Icon"
              onClick={() => {
                handleAddorEdit(rowData);
              }}
            />
          </p>
          <p>
            <DeleteOutlineOutlinedIcon
              className="Icon"
              onClick={(event) => {
                setDeleteData(rowData);
                setAnchorEl(event.currentTarget);
              }}
            />
          </p>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setAnchorEl(null);
              setDeleteData(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delAutomations(deleteData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margin: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setDeleteData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu>
        </div>
      ),
    },
  ];
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadAutomations(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    loadAutomations(null);
  }, []);

  return (
    <div>
      <div className="pageInNav">
        <p>Message Automations</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="inNavCtr">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorEdit(null);
              }}
              sx={btnCss}
            >
              ADD Automation
            </Button>
          </div>{" "}
          {/* <div className="inNavCtr">
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleAddorFlow(null);
              }}
              sx={btnCss}
            >
              ADD Automation Flow
            </Button>
          </div> */}
        </div>
      </div>
      <Paper elevation={2} className="table">
        <div>
          <div className="customMTableToolbar">
            <TextField
              style={TextfieldCss}
              size="small"
              variant="outlined"
              placeholder="Search "
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                debouncedSearch(e.target.value);
                setSearch(e.target.value);
                if (!e.target.value) {
                  loadAutomations();
                }
              }}
            />
          </div>

          <MaterialTable
            sx={{
              border: "1px solid red",
              borderRadius: "10px",
            }}
            columns={columns}
            data={automations ? automations : []}
            components={{
              Toolbar: () => <></>,
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  rowsPerPage={10}
                  rowsPerPageOptions={[10]}
                  count={count ? count : 0}
                  page={page - 1}
                  onPageChange={pageHandle}
                  labelDisplayedRows={() => null}
                />
              ),
            }}
            options={{
              search: false,
              toolbar: false,
              showTitle: false,
              paging: true,
              pageSize: 10,

              headerStyle: {
                border: "1px solid #EDDCDC",
                maxHeight: "50px0",
                zIndex: 2,
                backgroundColor: "#FBFBFB",
                textAlign: "center",
              },
            }}
          />
        </div>
      </Paper>
      <AddAutomation
        open={open}
        handleClose={handleClose}
        editData={editData}
      />
    </div>
  );
}

export default Automation;
