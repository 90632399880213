import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const LoadAttributes = (url) => {
  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to fetch services");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const loadAutomations = (param) => {
  return apiFetch("GET", `event-automations/?${param}`, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        notify("error", "Failed to Load Automation");
        throw new Error("Failed to load Automatios");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const deleteAutomation = (automationId) => {
  return apiFetch("DELETE", `event-automations/${automationId}/`, null, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

// export const LoadOrderAttributes = () => {
//   apiFetch("GET", "service/order-custom-field/", null, true)
//     .then((res) => {
//       if (res.ok) {
//         return res.json();
//       } else {
//         throw new Error("Failed to fetch services");
//       }
//     })
//     .catch((error) => {
//       console.error("Error", error);
//       throw error;
//     });
// };
export const createAutomation = (body) => {
  return apiFetch("POST", "event-automations/", body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Automation Created");
        return res;
      } else {
        notify("error", "Failed to create Automation");
        throw new Error("Failed to create Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const editAutomation = (id, body) => {
  return apiFetch("PUT", `event-automations/${id}/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Automation Updated");
        return res;
      } else {
        console.error("error", res);
        notify("error", "Failed to Edit Automation");
        throw new Error("Failed to Edit Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const loadAutomationWithId = (param) => {
  return apiFetch("GET", `event-automations/${param}`, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        notify("error", "Failed to Load Automation");
        throw new Error("Failed to load Automatios");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
