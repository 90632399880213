import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  // Grid,
  Checkbox,
  Paper,
  // Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { btnCss, ClsBtn } from "../../../Styles";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { addRole } from "../../../API/UserManagementLayer";
import { updateRole } from "../../../API/UserManagementLayer";
import { notify } from "../../Common";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
// import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
function AddRoles({ open, handleClose, loadRoles, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;

  const { errors } = formState;
  const [showSection, setShowSection] = useState("");
  const [showLeftSection, setShowLeftSection] = useState("");

  const toggleSection = (section) => {
    setShowSection((prevSection) => (prevSection === section ? "" : section));
  };

  const toggleLeftSection = (section) => {
    setShowLeftSection((prevSection) =>
      prevSection === section ? "" : section
    );
  };

  const onSubmit = async (data) => {
    const trueNames = Object.keys(data).filter((key) => data[key] === true);
    const { name, ...rest } = data;

    addRole({ name, permissions: trueNames })
      .then((res) => {
        notify("success", "Role Added Successfully");
        console.log(res);
        loadRoles();
        reset();
        setShowSection("");
        setShowLeftSection("");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editRole = async (data) => {
    const trueNames = Object.keys(data).filter((key) => data[key] === true);
    const { name, ...rest } = data;
    data.id = editData.id;
    data = {
      id: editData.id,
      name,
      permissions: trueNames,
    };
    updateRole(data)
      .then((res) => {
        notify("success", "Role Updated Successfully");
        loadRoles();
        reset();
        setShowSection("");
        setShowLeftSection("");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        notify("error", "Something went wrong");
      });
  };

  const HandleOnClose = () => {
    reset();
    setShowSection("");
    setShowLeftSection("");
    handleClose();
  };

  const handleCheckboxChange = (name, checked) => {
    setValue(name, checked);
  };

  useEffect(() => {
    if (editData) {
      const { name, permissions } = editData;
      setValue("name", name);
      permissions.forEach((permission) => {
        setValue(permission, true);
      });
    } else {
      reset();
    }
  }, [editData, open]);

  const automationData = [
    { label: "Chatbot", viewName: "viewChatbot", editName: "editChatbot" },
    {
      label: "Recurring",
      viewName: "viewRecurring",
      editName: "editRecurring",
    },
    {
      label: "Event-driven sequence",
      viewName: "viewEventDrivenSequence",
      editName: "editEventDrivenSequence",
    },
  ];

  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper elevation={2} className="modal">
        <FormProvider {...methods}>
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <h2>{editData ? " Update Role" : "Add Role"}</h2>
                <CloseIcon
                  style={ClsBtn}
                  className="Icon2"
                  onClick={HandleOnClose}
                />
              </div>
              <div>
                <InputLabel
                  sx={{ color: "#303535", margin: "5px 0", fontSize: "20px" }}
                >
                  Name
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      fullWidth
                      sx={{ maxWidth: "800px", marginBottom: "10px" }}
                      error={Boolean(errors.job_id)}
                      helperText={errors?.job_id && "name is required"}
                    />
                  )}
                  rules={{ required: true }}
                  name="name"
                  control={control}
                />
              </div>
            </div>
            <div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <p
                      style={{
                        color: "#303535",
                        fontSize: "20px",
                      }}
                    >
                      Permissions
                    </p>
                  </ListSubheader>
                }
              >
                {/* Conversation */}
                <ListItemButton
                  onClick={() => toggleLeftSection("Conversation")}
                >
                  <ListItemIcon>
                    {showLeftSection === "Conversation" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Conversation" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "Conversation"}
                  timeout="auto"
                  unmountOnExit
                >
                  {/* <List component="div" disablePadding>
                    <ListItemButton
                      onClick={() => toggleLeftSection("Conversation")}
                    >
                      <ListItemIcon>
                        <Controller
                          name="viewAllChats"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultValue={false}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                handleCheckboxChange(
                                  field.name,
                                  e.target.checked
                                );
                              }}
                            />
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText primary="View all chats" />
                    </ListItemButton>

                    <ListItemButton
                      onClick={() => toggleLeftSection("Conversation")}
                    >
                      <ListItemIcon>
                        <Controller
                          name="viewTeamChats"
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultValue={false}
                              checked={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                                handleCheckboxChange(
                                  field.name,
                                  e.target.checked
                                );
                              }}
                            />
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText primary="View only team chats" />
                    </ListItemButton>
                  </List> */}
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <p>
                      <Controller
                        name="viewAllChats"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            defaultValue={false}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View all chats</span>
                    </p>
                    <p>
                      <Controller
                        name="viewTeamChats"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            defaultValue={false}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View only team chats</span>
                    </p>
                  </div>
                </Collapse>
                {/* Automation */}
                <ListItemButton onClick={() => toggleLeftSection("Automation")}>
                  <ListItemIcon>
                    {showLeftSection === "Automation" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Automation" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "Automation"}
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      marginLeft: "30px",
                      maxWidth: "300px",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {automationData.map((item) => (
                            <TableRow key={item.label}>
                              <TableCell
                                style={{
                                  padding: "8px",
                                  maxWidth: "100px",
                                  // minWidth: "50px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  border: "none",
                                  // textAlign: "end",
                                }}
                              >
                                {item.label}
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "none",
                                  minWidth: "60px",
                                }}
                              >
                                <p>
                                  <Controller
                                    name={item.viewName}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value}
                                        defaultValue={false}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          handleCheckboxChange(
                                            field.name,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                  <span>View</span>
                                </p>
                                <p>
                                  <Controller
                                    name={item.editName}
                                    control={control}
                                    render={({ field }) => (
                                      <Checkbox
                                        {...field}
                                        checked={field.value}
                                        defaultValue={false}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          handleCheckboxChange(
                                            field.name,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    )}
                                  />
                                  <span>Edit</span>
                                </p>
                              </TableCell>
                              {/* <TableCell>
                              <div>
                                <Controller
                                  name={item.editName}
                                  control={control}
                                  render={({ field }) => (
                                    <Checkbox
                                      {...field}
                                      checked={field.value}
                                      defaultValue={false}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        handleCheckboxChange(
                                          field.name,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  )}
                                />
                                Edit
                              </div>
                            </TableCell> */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Collapse>

                {/* Campaign */}
                <ListItemButton onClick={() => toggleLeftSection("Campaign")}>
                  <ListItemIcon>
                    {showLeftSection === "Campaign" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Campaign" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "Campaign"}
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <p>
                      <Controller
                        name="broadcastCampaign"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>Broadcast campaign</span>
                    </p>
                    <p>
                      <Controller
                        name="campaignAnalytics"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View campaign analytics</span>
                    </p>
                  </div>
                </Collapse>

                {/* OrderFollowup */}
                <ListItemButton
                  onClick={() => toggleLeftSection("OrderFollowup")}
                >
                  <ListItemIcon>
                    {showLeftSection === "OrderFollowup" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary=" Order followup" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "OrderFollowup"}
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <p>
                      <Controller
                        name="viewOrderFollowup"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View</span>
                    </p>
                    <p>
                      <Controller
                        name="editOrderFollowup"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>Edit</span>
                    </p>
                  </div>
                </Collapse>

                {/* Appointment */}
                <ListItemButton
                  onClick={() => toggleLeftSection("Appointment")}
                >
                  <ListItemIcon>
                    {showLeftSection === "Appointment" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Appointment" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "Appointment"}
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <p>
                      <Controller
                        name="viewAppointment"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View</span>
                    </p>
                    <p>
                      <Controller
                        name="editAppointment"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>Edit</span>
                    </p>
                  </div>
                </Collapse>

                {/* User */}
                <ListItemButton onClick={() => toggleLeftSection("User")}>
                  <ListItemIcon>
                    {showLeftSection === "User" ? (
                      <ExpandMore />
                    ) : (
                      <KeyboardArrowRightIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="User" />
                </ListItemButton>

                <Collapse
                  in={showLeftSection === "User"}
                  timeout="auto"
                  unmountOnExit
                >
                  <div
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    <p>
                      <Controller
                        name="viewUser"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>View</span>
                    </p>
                    <p>
                      <Controller
                        name="editUser"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            defaultValue={false}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCheckboxChange(
                                field.name,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      />
                      <span>Edit</span>
                    </p>
                  </div>
                </Collapse>
              </List>
              <div>
                {editData ? (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    onClick={handleSubmit(editRole)}
                  >
                    Update Role
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={btnCss}
                    onClick={handleSubmit(onSubmit)}
                  >
                    ADD Role
                  </Button>
                )}
              </div>
            </div>
          </div>
        </FormProvider>
      </Paper>
    </Modal>
  );
}

export default AddRoles;
