import React, { useState } from "react";
import closeIcon from "../../../Assets/icons/closeIcon.png";
import sendIcon from "../../../Assets/icons/sendIcon.png";
import "../../../App.css";
import justLogo from "../../../Assets/justLogo.png";
import { AuthProvider, useAuth } from "../../../Context/Auth";

const WidgetPreview = ({
  business_name,
  logo_url,
  phone_number,
  first_message,
}) => {
  const [showWidget, setShowWidget] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const auth = useAuth();
  const brandLoading = auth.brandLoading;
  const brandDetails = auth.brandDetails;
  const showModal = () => {
    setShowWidget(true);
  };

  const hideModal = () => {
    setShowWidget(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendButtonClick = () => {
    const message = inputValue.replace(/ /g, "%20");
    const phoneNo = "917418816363";
    const websiteURL = `https://wa.me/${phoneNo}?text=${message}`;

    if (message) {
      window.open(websiteURL, "_blank");
    }
  };

  return (
    <>
      {showWidget && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // position: "fixed",
            // right: "20px",
            // bottom: "70px",
            maxWidth: "360px",
            backgroundColor: "#e5ddd5",
            borderRadius: "10px",
            height: "500px",
            zIndex: "99999",
            fontFamily: "Arial, Helvetica, sans-serif",
            animation: "slide 0.3s ease-out forwards",
          }}
        >
          {/* Widget Header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#075e54",
              color: "#ffff",
              borderRadius: "10px 10px 0 0",
              padding: "10px 5px 10px 10px",
              maxHeight: "60px",
            }}
          >
            {/* Logo */}
            <span>
              <img
                src={
                  logo_url
                    ? logo_url
                    : brandDetails?.brand_logo
                    ? brandDetails?.brand_logo
                    : justLogo
                }
                style={{ width: "35px" }}
                alt="Logo"
              />
            </span>
            {/* Heading */}
            <span style={{ padding: "10px" }}>
              <h2 style={{ margin: "0", padding: "0" }}>
                {business_name ? business_name : "Brand Name"}
              </h2>
              <p
                style={{
                  margin: "0",
                  padding: "0",
                  color: "#dff3ed",
                  fontSize: "12px",
                }}
              >
                Usually replies in a few minutes
              </p>
            </span>
            {/* Close Button */}
            <span
              style={{
                color: "#aaa",
                float: "right",
                fontSize: "30px",
                fontWeight: "bold",
                cursor: "pointer",
                position: "relative",
                margin: "0 10px",
              }}
              // onClick={hideModal}
            >
              <img src={closeIcon} alt="Close" style={{ width: "20px" }} />
            </span>
          </div>

          {/* Message */}
          <div
            className="message"
            style={{
              margin: "-280px 0 0 10px",
              alignItems: "center",
              left: "30px",
              width: "250px",
              padding: "10px 10px",
              backgroundColor: "#ffff",
              borderRadius: "0 10px 10px 10px",
              display: "flex",
            }}
          >
            {first_message ? (
              <p style={{ fontSize: "13px", whiteSpace: "pre-line" }}>
                {first_message}
              </p>
            ) : (
              <p style={{ fontSize: "13px", whiteSpace: "pre-line" }}>
                Hi, Welcome to {business_name ? business_name : "Brand Name"}.
                {"\n"}
                How can we help you?
              </p>
            )}
            <div
              style={{
                content: "",
                position: "absolute",
                top: "0",
                left: "-14px",
                borderTop: "0 solid transparent",
                borderBottom: "15px solid transparent",
                borderRight: "15px solid #ffff",
              }}
            />
          </div>
          <div>
            {/* Input Button */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                margin: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Send a Message"
                style={{
                  borderRadius: "10px",
                  width: "100%",
                  maxWidth: "300px",
                  padding: "10px 15px",
                  backgroundColor: "#ffffff",
                  border: "none",
                  minHeight: "50px",
                  outline: "none",
                  color: "#999999",
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
              <button
                style={{
                  border: "none",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                  backgroundColor: "#075e54",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSendButtonClick}
              >
                <img
                  src={sendIcon}
                  alt="Send"
                  style={{ width: "20px", margin: "auto" }}
                />
              </button>
            </div>

            {/* Footer */}
            <p
              style={{
                margin: "5px 0",
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Powered by&nbsp;
              <span style={{ color: "#d01f7b", cursor: "pointer" }}>
                PINAIPPU
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetPreview;
