import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const deleteCampaign = (campaignId) => {
  return apiFetch(
    "DELETE",
    `whatsapp-business/campaign/${campaignId}/`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return true;
      } else {
        notify("error", "Campaign Not Deleted");
        return false;
      }
    })
    .catch((error) => {
      console.error("Failed to delete campaign", error);
      notify("error", "Failed to delete campaign");
      return false;
    });
};

export const loadCampaigns = (param) => {
  const url = param
    ? `whatsapp-business/campaign/?${param}`
    : "whatsapp-business/campaign/?page=1";
  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        console.error(res);
        notify("error", "Something went wrong with Campaigns");
        throw new Error("Failed to fetch campaigns");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      notify("error", "Something went wrong with Campaigns");
      throw new Error("Failed to fetch campaigns");
    });
};

export const createCampaign = (url, body, editData) => {
  const postOrEdit = editData ? "PUT" : "POST";
  
  if("file" in body && body.file)
  {
    const formData = new FormData();
    return fetch(body.file)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], body.fileName);
        formData.append("campaign_file", file);
        formData.append("fileName",body.fileName);
        formData.append("send_now",body.send_now);
        formData.append("name",body?.name);
        formData.append("template",body?.template);
        if("schedule_time" in body)
        {
          formData.append("scheduled_time",body.scheduled_time);
        }
        console.log(Object.fromEntries(formData));
      return formData;
      })
      .then((formData) => {
        return apiFetch(postOrEdit, url, formData, true)
        .then((res) => {
          if (res.ok) {
            console.log("createCamp", res);
            return res.json();
          }
          else{
            return res;
          }
        });
      });
  } 
  else{
  
  console.log(body);
  return apiFetch(postOrEdit, url, body, true).then((res) => {
    if (res.ok) {
      return res.json();
    }
    else{
      return res;
    }
  });}
};

export const loadTemplates = (text) => {
  const url = text
    ? `whatsapp-business/templates/autocomplete/?text=${text}`
    : "whatsapp-business/templates/autocomplete/";
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("Something went wrong with templates");
    }
  });
};

export const campaignAnalytics = (campaignId) => {
  const url = `whatsapp-business/campaign/${campaignId}/analytics/`;
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      throw new Error("Something went wrong with campaign analytics");
    }
  });
}

export const downloadSampleFile = (templateId) => {
  const url = `whatsapp-business/templates/${templateId}/generate-sample/`;
  return apiFetch("GET", url, null, true).then((res) => {
    if (res.ok) {
      
      return res.blob();
    } else {
      throw new Error("Something went wrong while downloading the sample file");
    }
  });
};


