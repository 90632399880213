import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  Autocomplete,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { isMobile } from "./Common";

const CustomFilter = ({
  attributes,
  onUpdateFilter,
  resetFilter,
  resetFilterFunction,
  resetAddFilter,
  resetAddFilterFunction,
}) => {
  const mobileScreen = isMobile();
  const methods = useForm({ mode: "onBlur" });
  const { control } = methods;

  const [dropdown, setDropdown] = useState([
    {
      id: 0,
      data: {
        selectedAttribute: "",
        selectedType: "",
        selectedValue: "",
        attrType: "",
      },
    },
  ]);

  const [dropdownCount, setDropdownCount] = useState(1);

  const optionsForTypes = {
    text: [
      { value: "icontains", label: "Contains" },
      { value: "istartswith", label: "Starts With" },
      { value: "iendswith", label: "Ends With" },
      { value: "iexact", label: "Exact Match" },
    ],
    number: [
      { value: "lt", label: "Less Than" },
      { value: "gt", label: "Greater Than" },
      { value: "exact", label: "Equal To" },
    ],
    date: [
      { value: "lt", label: "Before" },
      { value: "gt", label: "After" },
      { value: "exact", label: "On" },
    ],
    dropdown: [
      { value: "in", label: "In" },
      { value: "not-in", label: "Not In" },
    ],
  };

  const handleAttributeChange = (index, value) => {
    setDropdown((prevDropdown) => {
      const updatedDropdown = [...prevDropdown];
      const selectedAttribute = attributes.find((attr) => attr.name === value);
      updatedDropdown[index].data.selectedAttribute = value;
      updatedDropdown[index].data.attrType = selectedAttribute?.type || "";
      return updatedDropdown;
    });
  };

  const handleTypeChange = (index, option) => {
    setDropdown((prevDropdown) => {
      const updatedDropdown = [...prevDropdown];
      updatedDropdown[index].data.selectedType = option?.value || "";
      return updatedDropdown;
    });
  };

  const handleValueChange = (index, event) => {
    setDropdown((prevDropdown) => {
      const updatedDropdown = [...prevDropdown];
      updatedDropdown[index].data.selectedValue =
        updatedDropdown[index].data.attrType === "number" &&
        (event.target.value === null || event.target.value === "")
          ? 0
          : event.target.value;
      return updatedDropdown;
    });
    onUpdateFilter(dropdown);
  };

  const handleRemoveIconClick = (index) => {
    const updatedDropdown = dropdown.filter((_, i) => i !== index);
    setDropdown(updatedDropdown);
    onUpdateFilter(updatedDropdown);
  };

  useEffect(() => {
    if (resetAddFilter && dropdown.length < 5) {
      setDropdown((prevDropdowns) => [
        ...prevDropdowns,
        {
          id: dropdownCount,
          data: { selectedAttribute: "", selectedType: "", selectedValue: "" },
        },
      ]);
      setDropdownCount((prevCount) => prevCount + 1);
      resetAddFilterFunction();
    } else if (resetAddFilter) {
      // Optionally, you can show a message or take another action
      console.log("Maximum of 5 filters allowed.");
      resetAddFilterFunction(); // Reset the trigger to prevent the add operation from being retried
    }
  }, [resetAddFilter, resetAddFilterFunction, dropdown.length, dropdownCount]);

  useEffect(() => {
    if (resetFilter) {
      setDropdown([
        {
          id: 0,
          data: {
            selectedAttribute: "",
            selectedType: "",
            selectedValue: "",
            attrType: "",
          },
        },
      ]);
      resetFilterFunction();
    }
  }, [resetFilter, resetFilterFunction]);

  const renderInputField = (item, index) => {
    const selectedAttribute = attributes.find(
      (attr) => attr.name === item.data.selectedAttribute
    );

    if (!selectedAttribute) return null;

    const { type, options } = selectedAttribute;

    switch (type) {
      case "date":
        return (
          <Controller
            key={index}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                size="small"
                fullWidth
                variant="outlined"
                value={dropdown[index].data.selectedValue}
                onChange={(event) => handleValueChange(index, event)}
              />
            )}
            name={`value-${index}`}
            control={control}
          />
        );

      case "dropdown":
        return (
          <Controller
            key={index}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                options={options}
                size="small"
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Value"
                    value={dropdown[index].data.selectedValue}
                    onChange={(event) => handleValueChange(index, event)}
                  />
                )}
                onChange={(event, newValue) => {
                  handleValueChange(index, { target: { value: newValue } });
                }}
              />
            )}
            name={`value-${index}`}
            control={control}
          />
        );

      case "number":
        return (
          <Controller
            key={index}
            render={({ field }) => (
              <TextField
                type="number"
                {...field}
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Value"
                value={
                  dropdown[index].data.selectedValue === 0
                    ? ""
                    : dropdown[index].data.selectedValue
                }
                onChange={(event) => handleValueChange(index, event)}
              />
            )}
            name={`value-${index}`}
            control={control}
          />
        );

      default:
        return (
          <Controller
            key={index}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Value"
                value={dropdown[index].data.selectedValue}
                onChange={(event) => handleValueChange(index, event)}
              />
            )}
            name={`value-${index}`}
            control={control}
          />
        );
    }
  };

  return (
    <div sx={{ display: "flex", flexDirection: "column" }}>
      {dropdown.map((item, index) => (
        <div
          key={item.id}
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
            backgroundColor: mobileScreen ? "#ebebeb" : "",
            padding: mobileScreen ? "10px" : "",
            borderRadius: mobileScreen ? "4px" : "",
          }}
        >
          <IconButton
            onClick={() => handleRemoveIconClick(index)}
            disabled={dropdown.length === 1}
          >
            <RemoveCircleIcon
              style={{
                cursor: dropdown.length === 1 ? "not-allowed" : "pointer",
                fontSize: "20px",
                color: dropdown.length === 1 ? "grey" : "red",
              }}
            />
          </IconButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{
                minWidth: "200px",
                maxWidth: mobileScreen ? "300px" : "200px",
                width: "100%",
              }}
            >
              <Autocomplete
                value={item.data.selectedAttribute}
                onChange={(event, newValue) =>
                  handleAttributeChange(index, newValue)
                }
                options={attributes.map((attribute) => attribute.name)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Custom Field Name"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{
                minWidth: "200px",
                maxWidth: mobileScreen ? "300px" : "200px",
                width: "100%",
              }}
            >
              <Autocomplete
                value={
                  item.data.selectedType
                    ? optionsForTypes[item.data.attrType]?.find(
                        (option) => option.value === item.data.selectedType
                      )
                    : null
                }
                onChange={(event, newValue) =>
                  handleTypeChange(index, newValue)
                }
                options={optionsForTypes[item.data.attrType] || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              style={{
                minWidth: "200px",
                maxWidth: mobileScreen ? "300px" : "200px",
                width: "100%",
              }}
            >
              {renderInputField(item, index)}
            </FormControl>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomFilter;
