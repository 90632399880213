import { useState, useEffect } from "react";
import profile from "../../../../Assets/profile.png";
import {
  Icon,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import "./UserProfile.css";
import { Autocomplete, TextField } from "@mui/material";
import { useAuth } from "../../../../Context/Auth";
import { updateAssignee } from "../../../../API/ChatLayer";
import { updateStatus } from "../../../../API/ChatLayer";
import { loadContacts as apiLoadContacts } from "../../../../API/ContactLayer";
import { toast } from "react-toastify";
import { UpdateAttribute } from "../../../../API/ContactLayer";
import { Button } from "@mui/material";
import { apiFetch } from "../../../../API/CommonApi";
import EditIcon from "@mui/icons-material/Edit";
import { notify } from "../../../../Components/Common";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UseWindowSize from "../../../UseWindowSize";
import { isMobile } from "../../../Common";

export default function UserProfile({
  assigneeOptions,
  teamOptions,
  renderFlag,
  setRenderFlag,
}) {
  const auth = useAuth();
  const options = ["Open", "Resolved"];
  const [customAttribute, setCustomAttribute] = useState([]);
  const [editedCellValue, setEditedCellValue] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const mobileScreen = isMobile();
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);

  const handleEditClick = () => {
    setShowButton(true);
    setIsEditMode(true);
  };

  const handleInputUpdate = (id, value) => {
    setEditedCellValue((prevEditedCellValue) => ({
      ...prevEditedCellValue,
      [id]: value,
    }));
  };

  const handleCellClick = (attribute) => {
    // event.stopPropagation();
    setDropdownOptions([]);

    if (isEditMode) {
      if (attribute.type !== "dropdown") {
        setEditedCellValue((prevEditedCellValue) => ({
          ...prevEditedCellValue,
          [attribute.id]: attribute.value,
        }));
      }
    }

    if (attribute.type === "dropdown") {
      apiFetch("GET", "whatsapp-business/contacts/custom_fields/", null, true)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            notify("error", "something went wrong");
            console.log("error res", res);
          }
        })
        .then((res) => {
          res?.data?.map((item) => {
            if (item.name === attribute.custom_field) {
              setDropdownOptions(item.options);
            }
          });
        });
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setShowButton(false);
    setEditedCellValue({});
    setDropdownOptions([]);
  };

  const handleSave = () => {
    data.custom_attribute.map((item) => {
      if (item.id in editedCellValue) {
        item.value = editedCellValue[item.id];
      }
    });
    if (flag) {
      customAttribute.map((item) => {
        if (item.id in editedCellValue) {
          item.value = editedCellValue[item.id];
        }
      });
    }
    let updatedAttributes;
    if (flag) {
      updatedAttributes = Object.entries(customAttribute).map(
        ([id, value]) => ({
          custom_field: value.id,
          value: value.value ? value.value : null,
        })
      );
    } else {
      updatedAttributes = Object.entries(editedCellValue).map(
        ([id, value]) => ({
          id: id,
          value: value,
        })
      );
    }

    UpdateAttribute(data, updatedAttributes)
      .then((res) => {
        setRenderFlag(!renderFlag);
        setIsEditMode(false);
        setEditedCellValue({});
        setDropdownOptions([]);
        toast.success("Attributes updated");
      })
      .catch((error) => {
        toast.error("Failed to update attributes");
      });
  };

  const handleValueUpdate = (id, value) => {
    setEditedCellValue((prevEditedCellValue) => ({
      ...prevEditedCellValue,
      [id]: value,
    }));
  };

  const handleAssigneeChange = (value) => {
    auth.selectedChat.assignee = value;
    const conversationId = auth.selectedChat?.id;
    const data = {
      assignee: value ? value.id : null,
    };
    updateAssignee(auth.wpDetails, conversationId, data)
      .then((res) => {
        setRenderFlag(!renderFlag);
        toast.success("Assignee updated");
      })
      .catch((error) => {
        toast.error("Failed to update assignee");
      });
  };

  const handleTeamChange = (value) => {
    auth.selectedChat.team = value.id;
    const conversationId = auth.selectedChat?.id;
    const data = {
      team: value ? value.id : null,
    };
    updateAssignee(auth.wpDetails, conversationId, data)
      .then((res) => {
        setRenderFlag(!renderFlag);
        toast.success("Team updated");
      })
      .catch((error) => {
        toast.error("Failed to update Team");
      });
  };

  const handleStatusChange = (value) => {
    auth.selectedChat.status = value;
    const conversationId = auth.selectedChat?.id;
    const data = {
      status: value.toLowerCase(),
    };
    updateStatus(auth.wpDetails, conversationId, data)
      .then((res) => {
        setRenderFlag(!renderFlag);
        toast.success("Status updated");
      })
      .catch((error) => {
        toast.error("Failed to update status");
      });
  };

  const loadCustomAttribute = () => {
    apiFetch("GET", "whatsapp-business/contacts/custom_fields/", null, true)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          notify("error", "something went wrong");
          console.log("error res", res);
        }
      })
      .then((res) => {
        setCustomAttribute(res?.data);
      });
  };

  const loadContacts = () => {
    let phone_number = auth.selectedChat?.contact?.phone_number;
    let param = `search=${phone_number}`;
    apiLoadContacts(param)
      .then((res) => {
        setData(res?.results?.[0]);
        if (res?.results?.[0]?.custom_attribute.length != 0) {
          setFlag(false);
          setCustomAttribute(res?.results?.[0]?.custom_attribute);
        } else {
          setFlag(true);
          loadCustomAttribute();
        }
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };

  useEffect(() => {
    loadContacts();
  }, [auth.selectedChat]);

  return (
    <>
      <div
        className={
          mobileScreen ? "rightMContainer" : "rightContainer container2"
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px 5px",
            w: "74px",
          }}
        >
          <span style={{ marginRight: "10px" }}>
            {/* <KeyboardArrowDownIcon /> */}
          </span>
          <p>
            {mobileScreen && (
              <IconButton
                onClick={() => {
                  auth.setConversationspage("chatContent");
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            Overview
          </p>
        </div>
        <div className="profileCtr">
          <img src={profile} className="profileImg" />
          <p style={{ marginTop: 0 }}>{auth.selectedChat?.contact?.name}</p>
          <p
            style={{
              color: "#46464680",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "19px",
            }}
          >
            {auth.selectedChat?.contact?.phone_number}
          </p>
        </div>
        <div style={{ maxHeight: "100%", overflow: "auto" }}>
          <div>
            {auth.selectedChat != null && assigneeOptions.length != 0 ? (
              <Autocomplete
                value={auth.selectedChat?.assignee}
                onChange={(event, value) => handleAssigneeChange(value)}
                options={assigneeOptions}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.trim().toLowerCase();
                  if (inputValue.length < 1) {
                    return options;
                  }
                  return options.filter((option) =>
                    `${option.first_name} ${option.last_name}`
                      .toLowerCase()
                      .includes(inputValue)
                  );
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <div style={{ margin: "20px" }}>
                    <InputLabel
                      sx={{
                        color: "#303535",
                        margin: "5px 0",
                        paddingLeft: "10px",
                      }}
                    >
                      Assignee
                    </InputLabel>
                    <TextField
                      {...params}
                      placeholder="Assignee"
                      disableUnderline
                      size="small"
                      sx={{
                        width: "300px",
                        borderRadius: "5px",
                        padding: "0 10px 10px 10px",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: { padding: 0 },
                      }}
                    />
                  </div>
                )}
              />
            ) : null}
          </div>
          <>
            {auth.selectedChat != null && auth.selectedChat?.status != null ? (
              <Autocomplete
                value={auth.selectedChat?.status}
                options={options}
                onChange={(event, value) => {
                  handleStatusChange(value);
                }}
                disableClearable
                renderInput={(params) => (
                  <div style={{ margin: "20px" }}>
                    <InputLabel
                      sx={{
                        color: "#303535",
                        margin: "5px 0",
                        paddingLeft: "10px",
                      }}
                    >
                      Status
                    </InputLabel>
                    <TextField
                      {...params}
                      disableUnderline
                      size="small"
                      placeholder="Status"
                      sx={{
                        width: "300px",
                        borderRadius: "5px",
                        padding: "0 10px 10px 10px",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: { padding: 0 },
                      }}
                    />
                  </div>
                )}
              />
            ) : null}
          </>
          <div>
            {auth.selectedChat != null && teamOptions.length > 0 ? (
              <Autocomplete
                value={teamOptions.find((option) => option.id === auth.selectedChat.team)}
                onChange={(event, value) => handleTeamChange(value)}
                options={teamOptions}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.trim().toLowerCase();
                  if (inputValue.length < 1) {
                    return options;
                  }
                  return options.filter((option) =>
                    option.name.toLowerCase().includes(inputValue)
                  );
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <div style={{ margin: "20px" }}>
                    <InputLabel
                      sx={{
                        color: "#303535",
                        margin: "5px 0",
                        paddingLeft: "10px",
                      }}
                    >
                      Assigned Team
                    </InputLabel>
                    <TextField
                      {...params}
                      placeholder="Assigned Team"
                      disableUnderline
                      size="small"
                      sx={{
                        width: "300px",
                        borderRadius: "5px",
                        padding: "0 10px 10px 10px",
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: { padding: 0 },
                      }}
                    />
                  </div>
                )}
              />
             ) : null} 
          </div>
          <div style={{ margin: "20px" }}>
            <InputLabel
              sx={{ color: "#303535", margin: "5px 0", paddingLeft: "10px" }}
            >
              Custom Attributes
              <IconButton
                size="small"
                sx={{
                  color: "#de4467",
                }}
                onClick={handleEditClick}
              >
                <EditIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </InputLabel>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Custom Field
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customAttribute?.map((attribute) => (
                    <TableRow key={attribute.id}>
                      <TableCell>
                        {attribute.custom_field
                          ? attribute.custom_field
                          : attribute.name}
                      </TableCell>
                      <TableCell
                        className="cellValue"
                        onClick={() => {
                          handleCellClick(attribute);
                        }}
                      >
                        {isEditMode ? (
                          attribute.type === "dropdown" ? (
                            <Autocomplete
                              value={
                                attribute.id in editedCellValue
                                  ? editedCellValue[attribute.id]
                                  : attribute.value
                              }
                              onChange={(event, newValue) => {
                                handleValueUpdate(attribute.id, newValue);
                              }}
                              options={dropdownOptions}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          ) : (
                            <input
                              className="cellValue"
                              type={attribute.type}
                              value={
                                attribute.id in editedCellValue
                                  ? editedCellValue[attribute.id]
                                  : attribute.value
                              }
                              style={{
                                width: "100%",
                                minWidth: "100px",
                                height: "40px",
                              }}
                              onChange={(e) =>
                                handleInputUpdate(attribute.id, e.target.value)
                              }
                            />
                          )
                        ) : (
                          attribute.value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isEditMode && showButton && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    minWidth: "unset",
                    padding: "5px 10px",
                    top: "10px",
                  }}
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    minWidth: "unset",
                    padding: "5px 10px",
                    top: "10px",
                    marginLeft: "10px",
                  }}
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        </div>
        <span></span>
      </div>
    </>
  );
}
