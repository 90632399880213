import React from "react";

import Weekdays from "./AvailabilityComponents/Weekdays";
import DatesGroup from "./AvailabilityComponents/DatesGroup";

const SetAvailability = () => {
  return (
    <div
      style={{
        // padding: "10px",
        display: "grid",
        placeItems: "center",
      }}
    >
      <h2>Set Availability</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: "20px",
          gap: "20px",
          maxWidth: "100vw",
        }}
      >
        <Weekdays />
        <DatesGroup />
      </div>
    </div>
  );
};

export default SetAvailability;
