import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Autocomplete,
  Modal,
  Paper,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../Context/Auth";
import {
  loadComments as apiLoadComments,
  postComment,
} from "../../API/AppointmentLayer";
import { formatDate, isMobile } from "../Common";
const SidePanel = ({ toggleSidePanel, selectedRow }) => {
  const auth = useAuth();
  const user_details = auth?.user?.user;
  const [comment, setComment] = useState(null);
  const [comments, setComments] = useState(null);
  const mobileScreen = isMobile();

  const closePanel = () => {
    toggleSidePanel();
    setComments(null);
    setComment(null);
  };
  const loadComments = (id) => {
    apiLoadComments(id)
      .then((res) => {
        setComments(res.results);
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setComment(value);
  };

  const handlePostComment = () => {
    if (comment) {
      const body = {
        comment_text: comment,
        appointment: selectedRow?.id,
        contact: null,
        user: user_details?.pk,
      };
      postComment(selectedRow?.id, body).then(() => {
        loadComments(selectedRow?.id);
        setComment("");
      });
    }
  };

  useEffect(() => {
    if (selectedRow) {
      loadComments(selectedRow?.id);
      setComment("");
    }
  }, [selectedRow]);
  return (
    selectedRow &&
    (mobileScreen ? (
      <Modal open={selectedRow} onClose={closePanel}>
        <div
          style={{
            width: "100%",
            height: "100%",
            padding: "10px",
            background: "#ffffff",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>{selectedRow?.name}</h2>
            <IconButton
              sx={{ color: "#de4467" }}
              onClick={closePanel}
              //   disabled={selectedValue.value !== "upcoming"}
            >
              <CloseIcon className="Icon" />
            </IconButton>
          </div>
          <OutlinedInput
            // disabled={isSendDisabled && !isPrivateNote}
            // ref={inputRef}
            sx={{
              width: "100%",
              height: "auto",
              bottom: 0,
              marginBottom: "5px",
              paddingRight: "90px",
            }}
            placeholder={"Type your Comment here"}
            multiline
            minRows={3}
            value={comment}
            onChange={handleInputChange}
            // onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                  }}
                >
                  <Button
                    disabled={!comment?.length > 0}
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      background:
                        "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                      borderRadius: " 5px",
                    }}
                    onClick={() => {
                      handlePostComment(comment);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </InputAdornment>
            }
          />
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              height: "100%",
            }}
          >
            <h3>Comments:</h3>
            <div>
              {comments?.map((x) => (
                <div>
                  <p className={x.contact ? "contactComment" : "userComment"}>
                    {x.comment_text}
                  </p>
                  <div className="commentTimeStamp">
                    <p>
                      By:{" "}
                      {x?.contact_details
                        ? x?.contact_details?.name
                        : `${x?.user_details?.first_name} ${x?.user_details?.last_name}`}
                    </p>
                    <p>Created at: {formatDate(x.created_at)}</p>
                  </div>
                </div>
              ))}
            </div>
          </Paper>
        </div>
      </Modal>
    ) : (
      <Drawer anchor="right" open={selectedRow} onClose={closePanel}>
        <div style={{ minWidth: "500px", padding: "20px", height: "95%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>{selectedRow?.name}</h2>
            <IconButton
              sx={{ color: "#de4467" }}
              onClick={closePanel}
              //   disabled={selectedValue.value !== "upcoming"}
            >
              <CloseIcon className="Icon" />
            </IconButton>
          </div>
          <OutlinedInput
            // disabled={isSendDisabled && !isPrivateNote}
            // ref={inputRef}
            sx={{
              width: "100%",
              height: "auto",
              bottom: 0,
              marginBottom: "5px",
              paddingRight: "90px",
            }}
            placeholder={"Type your Comment here"}
            multiline
            minRows={3}
            value={comment}
            onChange={handleInputChange}
            // onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                  }}
                >
                  <Button
                    disabled={!comment?.length > 0}
                    variant="contained"
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      background:
                        "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
                      borderRadius: " 5px",
                    }}
                    onClick={() => {
                      handlePostComment(comment);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </InputAdornment>
            }
          />
          <Paper
            elevation={2}
            sx={{
              padding: "10px",
              height: "100%",
            }}
          >
            <h3>Comments:</h3>
            <div>
              {comments?.map((x) => (
                <div>
                  <p className={x.contact ? "contactComment" : "userComment"}>
                    {x.comment_text}
                  </p>
                  <div className="commentTimeStamp">
                    <p>
                      By:{" "}
                      {x?.contact_details
                        ? x?.contact_details?.name
                        : `${x?.user_details?.first_name} ${x?.user_details?.last_name}`}
                    </p>
                    <p>Created at: {formatDate(x.created_at)}</p>
                  </div>
                </div>
              ))}
            </div>
          </Paper>
        </div>
      </Drawer>
    ))
  );
};
export default SidePanel;
