import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { debounce } from "lodash";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { btnCss, choosebtn, TextfieldCss, ClsBtn } from "../../../../Styles";
import { notify } from "../../../Common";
import {
  setAvailability,
  getAvailability,
} from "../../../../API/AppointmentLayer";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
function DateModel({ handleClose, open, loadTimings, editData }) {
  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      timings: [
        {
          start_time: "",
          end_time: "",
        },
      ],
    },
  });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const watchAllFields = watch();
  const { errors } = formState;
  const {
    fields: timings,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "timings",
  });
  const HandleOnClose = () => {
    reset();
    loadTimings();
    handleClose();
  };

  useEffect(() => {
    reset();
  }, [open]);
  const onSubmit = (data) => {
    const body = {
      date: data.date,
      timings: data.timings,
    };
    setAvailability(true, body)
      .then((res) => {
        if (res.ok) {
          loadTimings();
          notify("success", "saved");
          handleClose();
          reset();
        } else {
          res?.text().then((text) => {
            notify("error", text);
          });
        }
      })
      .catch((error) => {
        notify("error", "Something went wrong");
        console.log(error);
      });
  };

  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        style={{
          margin: "20px 40px",
          width: "100%",
          //   minHeight: "300px",
          maxWidth: "400px",
          padding: "20px",
          // height: "100%",
        }}
      >
        <FormProvider {...methods} enctype="multipart/form-data">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Add a date override</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Date
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="date"
                      fullWidth
                      sx={{ maxWidth: "412px", marginBottom: "10px" }}
                      error={Boolean(errors.date)}
                      helperText={errors?.date && "date is required"}
                    />
                  )}
                  rules={{ required: true }}
                  name="date"
                  control={control}
                />
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "420px",

                  //   borderBottom: "1px solid #b5b5b5",
                  padding: "10px 0",
                }}
              >
                <div>
                  {timings.map((item, fieldIndex) => (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        gap: "5px",
                        margin: "10px 0",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name={`timings[${fieldIndex}].start_time`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="time"
                            fullWidth
                            size="small"
                            // error={Boolean(
                            //   errors?.timings[fieldIndex]?.end_time
                            // )}
                            // helperText={
                            //   errors?.timings[fieldIndex]?.end_time &&
                            //   "Time required"
                            // }
                          />
                        )}
                      />
                      <span>-</span>
                      <Controller
                        name={`timings[${fieldIndex}].end_time`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            variant="outlined"
                            type="time"
                            fullWidth
                            size="small"
                            // error={Boolean(
                            //   errors?.timings[fieldIndex]?.end_time
                            // )}
                            // helperText={
                            //   errors?.timings[fieldIndex]?.end_time &&
                            //   "Time required"
                            // }
                          />
                        )}
                      />

                      <IconButton
                        aria-label="delete"
                        onClick={() => remove(fieldIndex)}
                        // style={{ marginLeft: "10px" }}
                        disabled={watchAllFields.timings?.length < 2}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="add"
                        onClick={() =>
                          append({
                            start_time: "",
                            end_time: "",
                          })
                        }
                        color="primary"
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  type="submit"
                  disabled={!watchAllFields.timings[0].start_time}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Paper>
    </Modal>
  );
}

export default DateModel;
