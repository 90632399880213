import React, { useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Checkbox,
  Paper,
  IconButton,
  Box,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { btnCss, ClsBtn } from "../../../Styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { addTeams } from "../../../API/UserManagementLayer";
import { loadUser } from "../../../API/UserManagementLayer";
import { notify } from "../../Common";
import { updateTeam } from "../../../API/UserManagementLayer";
import { set, update } from "lodash";

function AddTeams({ open, handleClose, loadTeams, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;

  const { errors } = formState;
  const [additionalFields, setAdditionalFields] = useState([{}]);
  const [users, setUsers] = useState([]);
  const watchAllFields = watch();

  const addAdditionalField = () => {
    setAdditionalFields([...additionalFields, {}]);
  };

  const removeAdditionalField = (indexToRemove) => {
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(indexToRemove, 1);
      return updatedFields;
    });
    setValue(`users[${indexToRemove}]`, undefined);
  };

  const handleAdditionalFieldChange = (newValue, index) => {
    const newFields = [...additionalFields];
    newFields[index] = newValue?.first_name;
    setAdditionalFields(newFields);
  };

  const onSubmit = (data) => {
    data = {
      name: data.name,
      users: data.users.map((user) => ({
        user_id: user.id,
        auto_assignment: user.allow_auto_assignment || false,
      })),
    };
    addTeams(data)
      .then((res) => {
        notify("success", "Team Added Successfully");
        loadTeams();
        reset();
        setAdditionalFields([""]);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editTeam = (data) => {
    data = {
      id: editData.id,
      name: data.name,
      users: data.users
        .filter((user) => user !== undefined)
        .map((user) => ({
          user_id: user.id,
          auto_assignment: user.allow_auto_assignment || false,
        })),
    };
    updateTeam(data)
      .then((res) => {
        notify("success", "Team Updated Successfully");
        loadTeams();
        reset();
        setAdditionalFields([""]);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleOnClose = () => {
    reset();
    setAdditionalFields([""]);
    handleClose();
  };

  const handleLoadUser = () => {
    loadUser()
      .then((res) => {
        setUsers(res.results);
        for (let i = 2; i <= res.total_pages; i++) {
          loadUser(`page=${i}`)
            .then((res) => {
              setUsers((prev) => [...prev, ...res.results]);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleLoadUser();
  }, []);

  useEffect(() => {
    if (editData) {
      console.log("editData", editData);
      setValue("name", editData.name);
      setAdditionalFields(editData.users.map((user) => user.first_name));
      setValue("users", editData.users);
      editData.users.forEach((user, index) => {
        setValue(`users[${index}].allow_auto_assignment`, user.auto_assignment);
      });
    }
  }, [editData, open]);
  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        className="modal"
        // style={{
        //   margin: "20px 40px",
        //   minWidth: "550px",
        //   maxWidth: "550px",
        //   //   minHeight: "300px",
        //   padding: "20px",
        //   maxHeight: "80vh", // Set a maximum height for the content
        //   overflowY: "auto"
        // }}
      >
        <FormProvider {...methods}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>{editData ? "Edit Team " : "Add Team"}</h2>
            <CloseIcon
              style={ClsBtn}
              className="Icon2"
              onClick={HandleOnClose}
            />
          </div>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
              Team Name
            </InputLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  sx={{ maxWidth: "550px", marginBottom: "10px" }}
                  error={Boolean(errors.job_id)}
                  helperText={errors?.job_id && "Team name is required"}
                />
              )}
              rules={{ required: true }}
              name="name"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Users
              </InputLabel>
              <IconButton
                onClick={addAdditionalField}
                disabled={additionalFields[0] == "" ? true : false}
                style={{
                  color: additionalFields[0] == "" ? "#CCCCCC" : "#25d366",
                }}
              >
                <AddCircleIcon style={{ height: "20px", width: "20px" }} />
              </IconButton>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Allow Auto Assignment</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalFields.map((field, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Controller
                          name={`users[${index}]`}
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <Autocomplete
                              size="small"
                              fullWidth
                              sx={{
                                minWidth: "150px",
                                maxWidth: "415px",
                                marginBottom: "10px",
                              }}
                              options={users}
                              getOptionLabel={(option) => option?.first_name}
                              value={field.value}
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                                handleAdditionalFieldChange(newValue, index);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select User"
                                  helperText={
                                    additionalFields.length === 1 &&
                                    !field.value
                                      ? "Please enter at least one User"
                                      : null
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Controller
                          name={`users[${index}][allow_auto_assignment]`}
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              sx={{ marginBottom: "40px" }}
                              checked={field.value || false}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => removeAdditionalField(index)}
                          disabled={additionalFields.length === 1}
                          style={{
                            color:
                              additionalFields.length === 1
                                ? "#CCCCCC"
                                : "#df1d5a",
                          }}
                        >
                          <RemoveCircleIcon
                            style={{
                              height: "20px",
                              width: "20px",
                              marginBottom: "40px",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            {editData ? (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                onClick={handleSubmit(editTeam)}
              >
                Update Team
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={btnCss}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Add Team
              </Button>
            )}
          </Grid>
        </FormProvider>
      </Paper>
    </Modal>
  );
}
export default AddTeams;
