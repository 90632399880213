import React from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import { ReactComponent as SignUPBG } from "../../Assets/SignUPBG.svg";
// import "./SignUp.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import RequestDemoForm from "./RequestDemoForm";

const BoxCss = {
  display: "flex",
  minHeight: "88vh",
  alignItems: "center",
  flexDirection: "column",
};
const outerCtr = {
  width: "50%",
  backgroundColor: "rgba(253, 200, 134, 0.1)",
  paddingTop: "7%",
};
const outerCtr2 = {
  width: "50%",
  height: "auto",
  backgroundColor: "#ffff",
  paddingTop: "4%",
};
const miniFooterCss = {
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
  marginTop: "1%",
};
export const RequestDemo = () => {
  const methods = useForm();
  const { handleSubmit, reset, control, setValue, watch, formState } = methods;
  const { errors } = formState;

  const navigate = useNavigate();
  const [phoneNo, setPhoneNo] = useState();
  const [errorMsg, setError] = useState();
  // const [submit, onsubmit] = useState(false);
  const onSubmit = (data) => {
    // console.log("on submit", phoneNo);
    if (phoneNo.length > 11) {
      setError(" ");
      navigate("ftu", {
        state: {
          contactNo: `${phoneNo}`,
        },
      });
    } else if (phoneNo == undefined || phoneNo.length < 11) {
      setError("In valid input");
    }
  };

  return (
    <>
      <Grid container>
        <Grid item sx={outerCtr}>
          {/* <img
            src={PINAIPPUlogoII}
            style={{ width: "100px", margin: "30px 0 0 10%" }}
            alt="Pinaippu"
          /> */}
          <Box sx={BoxCss}>
            <Grid item>
              <div>
                <SignUPBG />
              </div>
            </Grid>
            <Grid item>
              <p className="heading">
                Discover how Pinaippu can boost your sales!
              </p>
              <p className="Fpara">
                All you need is one active WhatsApp number to get started with
                Pinaippu’s exciting free trial. Explore new ways to create long
                term values with Pinaippu! Let’s see what all it has to offer
                you.
              </p>
              <ul className="bullets">
                <li>Free WhatsApp Business API verification</li>
                <li>Send trial messages to your registered number</li>
                <li>360° contact management view</li>
                <li>
                  Experiment with broadcast campaigns in real-time (limited).
                </li>
              </ul>
            </Grid>
          </Box>
        </Grid>
        <Grid item sx={outerCtr2}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              style={{ margin: "0 15px 5% 0", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              « Back
            </p>
          </div>
          <Box>
            <Grid item xs={12} style={BoxCss}>
              <RequestDemoForm />
            </Grid>
          </Box>
          <div style={miniFooterCss}>
            <p>©2022 Pinaippu</p>
            <Link className="no-underline" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
