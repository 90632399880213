export const btnWICss = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "14px",
  padding: "0 15px",
  height: "35px",
  textAlign: "center",
  position: "absolute",
  top: "75px",
  right: "40px",
};
export const btnCss = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  borderRadius: " 5px",
  fontWeight: "500",
  fontSize: "12px",
  lineHeight: "14px",
  // width: "124px",
  padding: "0 15px",
  height: "35px",
  textAlign: "center",
};
export const btnDemoCss = {
  borderRadius: "20px",
  background: "rgba(137, 40, 135, 0.22)",
};
export const TextfieldCss = {
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#464646",
  maxHeight: "35px",
  width: "100%",
  maxWidth: "400px",
  background: "#FFFAFB",
  padding: "dence",
  zIndex: 5,
};
export const pending = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#f8662b",
  borderRadius: "60px",
  backgroundColor: "#FFE4D9",
  textAlign: "center",
  padding: "5px",
  minWidth: "110px",
};
export const in_progress = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#7E56DA",
  borderRadius: "60px",
  backgroundColor: "#FBE3FF",
  textAlign: "center",
  padding: "5px",
  minWidth: "110px",
};
export const ready_for_pickup = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#278435",
  borderRadius: "60px",
  backgroundColor: "#F1FFF3",
  textAlign: "center",
  padding: "6px",
  minWidth: "110px",
};
export const delivered = {
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "17px",
  letterSpacing: "0.03em",
  color: "#F1FFF3",
  borderRadius: "60px",
  backgroundColor: "#278435",
  textAlign: "center",
  padding: "5px",
  minWidth: "110px",
};
export const dot = {
  width: "6px",
  height: "6px",
  position: "relative",
  top: "-2px",
  right: "10px",
};
// for contacts Attributes Close action
export const ClsBtn = {
  width: "24px",
  color: "#de4467",
  marginLeft: "-3px",
  marginRight: "8px",
  cursor: "pointer",
  borderRadius: "50%",
  padding: "1px",
  "::hover": {
    backgroundColor: "#ffff",
  },
};

export const activeChip = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#f2f2f2",
  borderRadius: "20px",
  padding: "5px 15px 5px 5px",
  width: "max-content",
  gap: "5px",
  color: "#278435",
};
export const errorChip = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "#f2f2f2",
  borderRadius: "20px",
  padding: "5px 15px 5px 5px",
  width: "max-content",
  gap: "5px",
  color: "#f8662b",
};

const apiData = [
  {
    id: 17,
    name: "dob",
    type: "datefield",
    created_at: "2023-05-08T12:17:36.431228Z",
    updated_at: "2023-05-08T12:17:36.431245Z",
  },
  {
    id: 18,
    name: "test_head",
    type: "text",
    created_at: "2023-05-08T12:17:47.715322Z",
    updated_at: "2023-05-08T12:17:47.715339Z",
  },
];

const formData = {
  dob: "2023-05-30",
  test_head: "testqwqw",
};

const custom_attribute = [
  {
    id: 13,
    custom_field: "dob",
    value: "2023-05-30",
    type: "datefield",
  },
  {
    id: 14,
    custom_field: "test_head",
    value: "testqwqw",
    type: "text",
  },
];
export const profilebtn = {
  background: "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  cursor: "pointer",
  color: "#ffff",
  borderRadius: "25px",
  padding: "10px",
};
