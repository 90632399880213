import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadServices = (param) => {
  const url = param ? `service/orders/?${param}` : "service/orders/?page=1";
  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Failed to fetch services");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const deleteService = (serviceId) => {
  return apiFetch("DELETE", `service/orders/${serviceId}/`, null, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete service");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const createService = (body) => {
  // console.log("data", data);
  console.log("body", body);
  return apiFetch("POST", "service/orders/", body, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to create service");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const loadAutoCompleteContacts = (text) => {
  return apiFetch(
    "GET",
    `whatsapp-business/contacts/autocomplete/?text=${text}`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Something went wrong with contacts");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const loadAutoCompleteProducts = (text) => {
  return apiFetch(
    "GET",
    `service/products/autocomplete?text=${text}`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Something went wrong with products");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const editService = (serviceId, body) => {
  return apiFetch("PUT", `service/orders/${serviceId}/`, body, true);
};
