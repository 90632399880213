import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Autocomplete,
  Chip,
  Switch,
  FormControlLabel,
  Drawer,
  TimeField,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import {
  btnCss,
  TextfieldCss,
  ClsBtn,
  activeChip,
  errorChip,
} from "../../../Styles";
import { debounce } from "lodash";
import { isMobile } from "../../Common";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { createNode, editNode } from "../../../API/AutomationLayer";
import { useAuth } from "../../../Context/Auth";
import { loadTemplates as apiLoadTemplates } from "../../../API/CampaignLayer";
import { apiFetch } from "../../../API/CommonApi";
import { loadTeams as apiLoadTeams } from "../../../API/UserManagementLayer";

const frmElement = { marginBottom: "10px" };

const AddCard = ({
  editCardData,
  openAddCard,
  handleCloseDrawer,
  chatBot_id,
  loadNodes,
  saveWithoutClosing,
  nodes,
  edges,
}) => {
  const methods = useForm({ mode: "onBlur" });
  const { handleSubmit, reset, control, formState, setValue, watch } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "options",
  });
  const mobile = isMobile();
  const { errors } = formState;
  const watchAllFields = watch();
  const context = useAuth();
  const compareOptions = (array2) => {
    const array1 = editCardData?.data?.options?.map((item) => {
      return { option: item?.option_text };
    });
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      // Assuming the objects have the same properties and values
      const objKeys = Object.keys(obj1);

      for (const key of objKeys) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    return true;
  };
  const [assigneeOptions, setAssigneeOptions] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState(null);
  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error", error);
        // notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };
  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    } else {
      loadTemplates();
    }
  }, 600);

  const fetchAssigneeOptions = () => {
    apiFetch("GET", "/users", null, true)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAssigneeOptions(data.results);
      })
      .catch((error) => {
        console.error("Error fetching assignee options:", error);
      });
  };

  const loadTeams = () => {
    apiLoadTeams()
      .then((res) => {
        setTeamOptions(res.results);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const onSubmit = (data) => {
    console.log("data", data);
    const getOptionId = (Hid) => {
      if (editCardData) {
        // console.log("editCardData", editCardData.data);
        const Toption = editCardData.data.options?.find(
          (opt) => opt.handle_id === Hid
        );
        return Toption ? Toption.id : null;
      } else return null;
    };
    let tempOps = [];
    if (data.options) {
      tempOps = data.options.map((x, index) => {
        const optionID = getOptionId(
          `${data.name.replace(/ /g, "_")}_option_${index}`
        );
        return {
          id: optionID ? optionID : index,
          option_text: x.option,
          handle_id: `${data.name.replace(/ /g, "_")}_option_${index}`,
        };
      });
    }
    const defaultHandles = {
      outId: `${data.name}_out`,
      inId: `${data.name}_in`,
    };
    const formData = new FormData();
    editCardData
      ? formData.append("name", editCardData?.data.name)
      : formData.append("name", data.name);
    formData.append("message", data.message);
    formData.append("variable", data.variable);
    tempOps.map((opt, index) => {
      for (const key in opt) {
        if (opt.hasOwnProperty(key)) {
          formData.append(`options[${index}]${key}`, opt[key]);
        }
      }
    });
    formData.append("default_handles", JSON.stringify(defaultHandles));
    const TPosition = editCardData ? editCardData.position : { x: 1115, y: 17 };
    formData.append("position", JSON.stringify(TPosition));
    data.file_type && formData.append("header_type", data.file_type.value);
    data.file && formData.append("header_file", data.file, data.file.name);
    formData.append("action", data.action?.value ? data.action?.value : "");
    if (data.action?.value === "assign_conversation") {
      data?.assignee && formData.append("assignee", data.assignee.id);
    } else if (data.action?.value === "team_conversation") {
      data?.team && formData.append("team", data.team.id);
    } else if (data.action?.value === "send_template") {
      data?.send_template &&
        formData.append("send_template", JSON.stringify(data.send_template.id));
    } else if (data.action?.value === "schdeule_appointment") {
      data?.full_day_event &&
        formData.append("full_day_event", data.full_day_event);
      data?.appointment_duration &&
        formData.append("appointment_duration", data.appointment_duration);
      data?.appointment_name &&
        formData.append("appointment_name", data.appointment_name);
    }
    // console.log("formData", Object.fromEntries(formData));
    if (editCardData) {
      // if options updated this will run
      const isOptionsUpdated = compareOptions(data.options);
      if (!isOptionsUpdated) {
        const optHanleIds = editCardData?.data?.options?.map(
          (obj) => obj.handle_id
        );
        const filteredEdges = edges.filter(
          (obj1) => !optHanleIds.includes(obj1.sourceHandle)
        );
        saveWithoutClosing(nodes, filteredEdges);
        setTimeout(() => {
          editNode(formData, chatBot_id, editCardData.id).then((res) => {
            if (res.ok) {
              loadNodes(`${chatBot_id}`);
              handleOnClose();
            }
          });
        }, 500);
      } else {
        editNode(formData, chatBot_id, editCardData.id).then((res) => {
          if (res.ok) {
            loadNodes(`${chatBot_id}`);
            handleOnClose();
          }
        });
      }
    } else {
      createNode(formData, chatBot_id).then((res) => {
        if (res.ok) {
          loadNodes(`${chatBot_id}`);
          handleOnClose();
        }
      });
    }
  };
  const handleOnClose = () => {
    editCardData && context.setEditCardData(null);
    handleCloseDrawer();
    reset();
  };
  const typesOfCard = [
    {
      name: "Default",
      value: "default",
      id: 1,
    },
    {
      name: "Input",
      value: "input",
      id: 2,
    },
    {
      name: "Output",
      value: "output",
      id: 3,
    },
  ];
  const typesOfFiles = [
    {
      name: "Image",
      value: "image",
      id: 1,
    },
    {
      name: "Document",
      value: "document",
      id: 2,
    },
    {
      name: "Video",
      value: "video",
      id: 3,
    },
  ];
  const CHATBOT_CARD_ACTIONS = [
    { value: "mark_conversation_open", name: "Mark Conversation as Open" },
    { value: "assign_conversation", name: "Assign Conversation to Agent" },
    { value: "team_conversation", name: "Assign Conversation to Team" },
    { value: "fetch_order_status", name: "Fetch Order Status" },
    { value: "schdeule_appointment", name: "Schedule Appointment" },
    { value: "send_template", name: "Send Template" },
    { value: "send_catalog", name: "Send Catalog" },
  ];
  useEffect(() => {
    reset({
      type_of_card: typesOfCard[0],
      options: [],
    });
    fetchAssigneeOptions();
    loadTeams();
    // setValue("type_of_card", typesOfCard[0]);
    // setValue("options", []);
  }, [openAddCard]);
  useEffect(() => {
    // fetchAssigneeOptions();
    console.log("edit card data", editCardData);
    if (editCardData) {
      const TOptions = editCardData?.data?.options?.map((item) => {
        return { option: item?.option_text };
      });
      const file_type = typesOfFiles.find(
        (x) => x.value === editCardData?.data?.header_type
      );
      const tAction = CHATBOT_CARD_ACTIONS.find(
        (x) => x.value === editCardData?.data?.action
      );
      const tAssignee = assigneeOptions.find(
        (x) => x.id === editCardData?.data?.assignee
      );
      const tTeam = teamOptions.find((x) => x.id === editCardData?.data?.team);
      const prefillData = {
        name: editCardData?.data?.name,
        action: tAction,
        ...(editCardData?.data?.action === "schdeule_appointment" && {
          appointment_name: editCardData?.data?.appointment_name,
          appointment_duration: editCardData?.data?.appointment_duration,
          full_day_event: editCardData?.data?.full_day_event,
        }),
        ...(editCardData?.data?.action === "assign_conversation" && {
          assignee: tAssignee,
        }),
        ...(editCardData?.data?.action === "team_conversation" && {
          team: tTeam,
        }),
        ...(editCardData?.data?.action === "send_template" && {
          send_template: editCardData?.data?.send_template,
        }),
        ...(editCardData?.data?.variable && {
          variable: editCardData?.data?.variable,
        }),
        ...(editCardData?.data?.header_type !== null && {
          file_type: file_type,
        }),
        message: editCardData?.data?.message,
        ...(TOptions && { options: TOptions }),
      };
      reset(prefillData);
    }

    // console.log("editCardData", editCardData);
    // editCardData?.data?.variable &&
    //   setValue("variable", editCardData?.data?.variable);
    // const file_type = typesOfFiles.find(
    //   (x) => x.value === editCardData?.data?.header_type
    // );
    // editCardData?.data?.header_type !== null &&
    //   setValue("file_type", file_type);
    // setValue("message", editCardData?.data?.message);

    // console.log("TOptions", TOptions);
    // TOptions && setValue("options", TOptions);
    // setValue("name", editCardData?.data?.name);
  }, [editCardData]);
  // useEffect(() => {
  //   console.log("watchAllFields", watchAllFields);
  // }, [watchAllFields]);
  return (
    <Drawer
      open={openAddCard}
      onClose={handleOnClose}
      className="mCtr"
      anchor="right"
    >
      <div style={{ padding: "20px", width: mobile ? "100vw" : "450px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
          }}
        >
          <h2>Add Card</h2>
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <CloseIcon
              style={ClsBtn}
              className="Icon2"
              onClick={handleOnClose}
            />
          </span>
        </div>
        <div style={{ padding: "20px 10px 10px 10px" }}>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                minHeight: "84vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                {editCardData ? (
                  ""
                ) : (
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Name*
                    </InputLabel>
                    <Controller
                      rules={{ required: true }}
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // type="date"
                          size="small"
                          fullWidth
                          // sx={{ marginBottom: "30px" }}
                          variant="outlined"
                          placeholder="Name"
                          error={Boolean(errors?.name)}
                          helperText={errors?.name && "Name is required"}
                        />
                      )}
                    />
                  </div>
                )}
                <div style={frmElement}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Variable
                  </InputLabel>
                  <Controller
                    // rules={{ required: true }}
                    name="variable"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        // type="date"
                        size="small"
                        fullWidth
                        // sx={{ marginBottom: "30px" }}
                        variant="outlined"
                        placeholder="Variable"
                        error={Boolean(errors?.variable)}
                        helperText={errors?.variable && "Variable is required"}
                      />
                    )}
                  />
                </div>{" "}
                <div style={frmElement}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Action
                  </InputLabel>
                  <Controller
                    name="action"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete
                        value={value}
                        options={CHATBOT_CARD_ACTIONS}
                        getOptionLabel={(option) => (option ? option.name : "")}
                        onChange={(e, data) => {
                          onChange(data);
                        }}
                        suppressDefaultStyles={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            // sx={{ width: "220px" }}
                            error={Boolean(errors?.action)}
                            placeholder="Action"
                            helperText={errors?.action && "Action is required"}
                          />
                        )}
                      />
                    )}
                  />
                </div>
                {watchAllFields.action?.value === "assign_conversation" && (
                  <div style={frmElement}>
                    {/* assigneeOptions */}
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Assignee
                    </InputLabel>
                    <Controller
                      name="assignee"
                      control={control}
                      rules={{
                        required:
                          watchAllFields.action?.value ===
                          "assign_conversation",
                      }}
                      // defaultValue=""
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={assigneeOptions ? assigneeOptions : []}
                          loading={loadingTemplates}
                          getOptionLabel={(option) => option?.first_name}
                          onInputChange={(e, newInputValue) => {
                            debouncedTemplates(newInputValue);
                          }}
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.assignee)}
                              // onFocus={() => fetchAssigneeOptions()}
                              helperText={
                                errors.assignee && "assignee is required"
                              }
                              placeholder="Select Assignee"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
                {watchAllFields.action?.value === "team_conversation" && (
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Team
                    </InputLabel>
                    <Controller
                      name="team"
                      control={control}
                      rules={{
                        required:
                          watchAllFields.action?.value === "team_conversation",
                      }}
                      // defaultValue=""
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={teamOptions ? teamOptions : []}
                          loading={loadingTemplates}
                          getOptionLabel={(option) => option?.name}
                          onInputChange={(e, newInputValue) => {
                            debouncedTemplates(newInputValue);
                          }}
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.team)}
                              helperText={errors.team && "team is required"}
                              placeholder="Select Team"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
                {watchAllFields.action?.value === "send_template" && (
                  <div style={frmElement}>
                    <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                      Send Template
                    </InputLabel>
                    <Controller
                      name="send_template"
                      control={control}
                      rules={{
                        required:
                          watchAllFields.action?.value === "send_template",
                      }}
                      // defaultValue=""
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          options={templates ? templates : []}
                          loading={loadingTemplates}
                          getOptionLabel={(option) => option?.name}
                          onInputChange={(e, newInputValue) => {
                            debouncedTemplates(newInputValue);
                          }}
                          onChange={(e, data) => {
                            onChange(data);
                          }}
                          loadingText="Loading..."
                          noOptionsText="No options"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              error={Boolean(errors.send_template)}
                              onFocus={() => loadTemplates()}
                              helperText={
                                errors.send_template && "Template is required"
                              }
                              placeholder="Select Template"
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                )}
                {watchAllFields.action?.value === "schdeule_appointment" && (
                  <>
                    <div style={frmElement}>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Appointment Name
                      </InputLabel>
                      <Controller
                        rules={{
                          required:
                            watchAllFields.action === "schdeule_appointment",
                        }}
                        name="appointment_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            // type="date"
                            size="small"
                            fullWidth
                            // sx={{ marginBottom: "30px" }}
                            variant="outlined"
                            placeholder="Name"
                            error={Boolean(errors?.appointment_name)}
                            helperText={
                              errors?.appointment_name &&
                              "Appointment Name is required"
                            }
                          />
                        )}
                      />
                    </div>
                    <div style={frmElement}>
                      <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                        Appointment Duration
                      </InputLabel>
                      <Controller
                        rules={{
                          required:
                            watchAllFields.action === "schdeule_appointment",
                        }}
                        name="appointment_duration"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            size="small"
                            fullWidth
                            // sx={{ marginBottom: "30px" }}
                            variant="outlined"
                            placeholder="Minutes"
                            error={Boolean(errors?.start_time)}
                            helperText={
                              errors?.start_time && "Start Time is required"
                            }
                          />
                        )}
                      />
                    </div>
                    <div style={frmElement}>
                      <Controller
                        name="full_day_event"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={onChange}
                                name="Checkbox"
                              />
                            }
                            label="Full day event"
                          />
                        )}
                      />
                    </div>
                  </>
                )}
                <div style={frmElement}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Upload file
                  </InputLabel>
                  {mobile ? (
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                    >
                      <Controller
                        name="file_type"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            options={typesOfFiles}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            onChange={(e, data) => {
                              onChange(data);
                            }}
                            disableClearable
                            suppressDefaultStyles={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                // sx={{ width: mobile ? "280px" : "220px" }}
                                error={Boolean(errors?.file_type)}
                                placeholder="File type"
                                helperText={
                                  errors?.file_type && "File type is required"
                                }
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        // rules={{ required: true }}
                        name="file"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              disabled={!watchAllFields.file_type}
                            >
                              Select File
                              <input
                                accept={
                                  watchAllFields.file_type?.value === "image"
                                    ? "image/jpeg,image/jpg,image/png"
                                    : watchAllFields?.file_type?.value ===
                                      "document"
                                    ? ".pdf,.xlsx"
                                    : watchAllFields?.file_type?.value ===
                                      "video"
                                    ? "video/mp4"
                                    : ""
                                }
                                type="file"
                                hidden
                                onChange={(e) => onChange(e.target.files[0])}
                              />
                            </Button>
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Controller
                        name="file_type"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            value={value}
                            options={typesOfFiles}
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            onChange={(e, data) => {
                              onChange(data);
                            }}
                            disableClearable
                            suppressDefaultStyles={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                sx={{ width: "255px" }}
                                error={Boolean(errors?.file_type)}
                                placeholder="File type"
                                helperText={
                                  errors?.file_type && "File type is required"
                                }
                              />
                            )}
                          />
                        )}
                      />
                      <Controller
                        // rules={{ required: true }}
                        name="file"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <Button
                              variant="contained"
                              component="label"
                              disabled={!watchAllFields.file_type}
                            >
                              Select File
                              <input
                                accept={
                                  watchAllFields.file_type?.value === "image"
                                    ? "image/jpeg,image/jpg,image/png"
                                    : watchAllFields?.file_type?.value ===
                                      "document"
                                    ? ".pdf,.xlsx"
                                    : watchAllFields?.file_type?.value ===
                                      "video"
                                    ? "video/mp4"
                                    : ""
                                }
                                type="file"
                                hidden
                                onChange={(e) => onChange(e.target.files[0])}
                              />
                            </Button>
                          </>
                        )}
                      />
                    </div>
                  )}
                  {editCardData ? (
                    watchAllFields?.file ? (
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "auto",
                          padding: "5px 0 0 10px ",
                        }}
                      >
                        selected: {watchAllFields?.file.name}
                      </p>
                    ) : (
                      editCardData?.data?.header_file && (
                        <p>
                          Selected:{" "}
                          <a
                            style={{ textDecoration: "none" }}
                            href={editCardData?.data?.header_file}
                            target="_blank"
                          >
                            {editCardData?.data?.header_file.slice(0, 29)}...
                          </a>
                        </p>
                      )
                    )
                  ) : (
                    watchAllFields?.file && (
                      <p
                        style={{
                          fontSize: "12px",
                          margin: "auto",
                          padding: "5px 0 0 10px ",
                        }}
                      >
                        selected: {watchAllFields?.file.name}
                      </p>
                    )
                  )}
                </div>
                <div style={frmElement}>
                  <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                    Message*
                  </InputLabel>
                  <Controller
                    rules={{ required: true }}
                    name="message"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        // type="test"
                        multiline
                        minRows={4}
                        size="small"
                        fullWidth
                        variant="outlined"
                        placeholder="Message"
                        error={Boolean(errors?.message)}
                        helperText={errors?.message && "message is required"}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    ...frmElement,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>Options*</h3>
                  <IconButton
                    aria-label="add"
                    onClick={() => append({ option: "" })}
                    color="primary"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </div>
                {/* {console.log("fields", fields)} */}
                {fields.map((field, index) => (
                  <div
                    key={field.id}
                    style={{ ...frmElement, display: "flex" }}
                  >
                    <Controller
                      name={`options[${index}].option`}
                      control={control}
                      //   rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          fullWidth
                          variant="outlined"
                          placeholder={`Option: ${index + 1}`}
                          error={Boolean(errors?.options?.[index]?.option)}
                          helperText={
                            errors?.options?.[index]?.option &&
                            "This Field is required"
                          }
                          inputProps={{
                            maxLength: 20,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {watchAllFields?.options[index]?.option?.length}
                                /20
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => remove(index)}
                      style={{ marginLeft: "10px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div>

              <div style={frmElement}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  type="submit"
                  name="send_to_draft"
                >
                  Save Card
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </Drawer>
  );
};

export default AddCard;
