import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
} from "@mui/material";
import { debounce } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { btnCss, choosebtn, TextfieldCss } from "../../../Styles";
import { notify } from "../../Common";
import { apiFetch } from "../../../API/CommonApi";
import { createProduct as apiCreateProduct } from "../../../API/ProductLayer";
import { editProduct as apiEditProduct } from "../../../API/ProductLayer";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import { ClsBtn } from "../../../Styles";
import CloseIcon from "@mui/icons-material/Close";

function AddProduct({ handleClose, open, loadProducts, editData }) {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const [editId, setEditID] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const HandleOnClose = () => {
    reset();
    setImage(null);
    handleClose();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
        setImageName(file.name);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImageName(null);
    }
  };

  const createProduct = (data) => {
    SetApiLoading(true);
    console.log("hey", data);
    if (data.hasOwnProperty("image")) {
      const formData = new FormData();
      formData.append(
        "name",
        data.name ? data.name.toLowerCase().replace(/ /g, "_") : ""
      );
      formData.append("file", data.image);
      formData.append("fileName", data.imageName);
      apiCreateProduct(Object.fromEntries(formData))
        .then((res) => {
          if (res.ok) {
            notify("success", "Product Created");
            handleClose();
            loadProducts(null);
            reset();
            SetApiLoading(false);
            setImage(null);
          } else {
            res?.text().then((text) => {
              console.log("text", text);
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    }
  };

  const editProduct = (data) => {
    SetApiLoading(true);
    console.log("before sending", data);
    if (data) {
      const formData = new FormData();
      formData.append(
        "name",
        data.name ? data.name.toLowerCase().replace(/ /g, "_") : ""
      );
      if (
        data.hasOwnProperty("image") &&
        typeof data.image === "string" &&
        data.image !== ""
      ) {
        formData.append("file", data.image);

        const filenameParts = data.image.split("/").pop().split("?");
        if (filenameParts.length > 0) {
          const filename = filenameParts[0];
          formData.append("fileName", filename);
        } else {
          // Handle case when the filename cannot be extracted
          formData.append("fileName", "");
        }
      }
      apiEditProduct(editId, Object.fromEntries(formData))
        .then((res) => {
          if (res.ok) {
            notify("success", "Product Updated");
            handleClose();
            loadProducts(null);
            reset();
            SetApiLoading(false);
            setImage(null);
            return res.json();
          } else {
            res?.text().then((text) => {
              console.log("text", text);
              SetApiLoading(false);
              notify("error", text);
            });
          }
        })
        .catch((error) => {
          SetApiLoading(false);
          notify("error", "Something went wrong");
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (editData) {
      const name = editData.name
        .replace(/_/g, " ")
        .replace(/\b\w/g, (c) => c.toUpperCase());
      setValue("name", name);
      //setValue("image",image);
      setImage(editData.image); // Set the existing image URL or base64 data
      setImageName(editData.imageName);
      setEditID(editData.id);
    }
  }, [editData]);
  return (
    <Modal
      open={open}
      onClose={HandleOnClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className="mCtr"
    >
      <Paper
        elevation={2}
        style={{
          // margin: "20px 40px",
          width: "100%",
          //   minHeight: "300px",
          maxWidth: "400px",
          padding: "20px",
          // height: "100%",
        }}
      >
        <FormProvider {...methods} enctype="multipart/form-data">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Product</h2>
            <CloseIcon style={ClsBtn} className="Icon2" onClick={handleClose} />
          </div>
          <Grid container>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Name
              </InputLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "412px", marginBottom: "10px" }}
                    error={Boolean(errors.job_id)}
                    helperText={errors?.job_id && "Product name is required"}
                  />
                )}
                rules={{ required: true }}
                name="name"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                Image
              </InputLabel>
              <label htmlFor="upload-file" style={{ display: "inline-block" }}>
                <Button variant="contained" component="span">
                  Choose File
                </Button>
              </label>
              <input
                id="upload-file"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                name="image"
              />
              {image && (
                <Avatar
                  name="image"
                  src={image}
                  alt="Product Preview"
                  style={{ marginTop: "10px" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "10px" }}>
              {editData ? (
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  onClick={handleSubmit((data) => {
                    const updatedData = {
                      ...data,
                      name: data.name.toLowerCase().replace(/ /g, "_"),
                      image: [image],
                      imageName: imageName,
                    };
                    editProduct(updatedData);
                  })}
                >
                  Update Product
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  sx={btnCss}
                  onClick={handleSubmit((data) => {
                    const updatedData = {
                      ...data,
                      name: data.name.toLowerCase().replace(/ /g, "_"),
                      image: [image],
                      imageName: imageName,
                    };
                    createProduct(updatedData);
                  })}
                >
                  ADD Product
                </Button>
              )}
            </Grid>
          </Grid>
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </Paper>
    </Modal>
  );
}

export default AddProduct;
