import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";

export const loadAttributes = (param) => {
  const url = param
    ? `whatsapp-business/custom-fields/?${param}`
    : "whatsapp-business/custom-fields/?page=1";

  return apiFetch("GET", url, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        console.error(res);
        notify("error", "Something went wrong with Attributes");
      }
    });
};

export const deleteAttribute = (id) => {
  return apiFetch(
    "DELETE",
    `/whatsapp-business/custom-fields/${id}/`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete attribute");
      }
    });
};

export const createAttribute = (data) => {
  const body = {
    name: data.name.toLowerCase().replace(/ /g, "_"),
    type: data.type.value,
    options: data.values || null,
  };
  console.log(body);
  return apiFetch("POST", "whatsapp-business/custom-fields/", body, true)
    .then((res) => {
      if (res.ok) {

        return res.json();
      } else {
        return res.text().then((text) => {
          throw new Error(text);
        });
      }
    });
};


export const editAttribute = (id, data) => {

  const body = {
    id: id,
    name: data.name.toLowerCase().replace(/ /g, "_"),
    type: data.type.value,
    options:  data.values || null,
  };

  return apiFetch("PUT", `whatsapp-business/custom-fields/${id}/`, body, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        return res.text().then((text) => {
          throw new Error(text);
        });
      }
    });
};