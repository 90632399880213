import { useState, useEffect, useRef } from "react";
import {
  InputAdornment,
  IconButton,
  FilledInput,
  Button,
  Typography,
  TextField,
  OutlinedInput,
  Fab,
  Zoom,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Menu,
  MenuItem,
  Popper,
  Autocomplete,
  Papper,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import Message from "./Message";
import { useAuth } from "../../../../Context/Auth";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { fetchMoreData as apiFetchMoreData } from "../../../../API/ChatLayer";
import { getChatList as apiGetChatList } from "../../../../API/ChatLayer";
import { sendMessage as apiSendMessage } from "../../../../API/ChatLayer";
import AddIcon from "@mui/icons-material/Add";
import FileUpload from "./FileUpload";
import SendContacts from "./SendContacts";
import SendMessageTemplate from "../../../TemplateMessage/SendTemplate";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { isMobile } from "../../../Common";
import { apiFetch } from "../../../../API/CommonApi";
import { set } from "lodash";
import { loadTemplates as apiPublishedTemplates } from "../../../../API/CampaignLayer";
import { loadTemplates as apiAllTemplates } from "../../../../API/TemplateLayer";
import { debounce } from "lodash";
import { SendTemplates as apiSendTemplates } from "../../../../API/ChatLayer";

export default function ChatContent() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { timerCount } = useAuth();
  const selectedChat = auth.selectedChat;
  const wpDetails = auth.wpDetails?.[0];
  const phone_number = selectedChat?.contact?.phone_number;
  const accountDetails = wpDetails?.phone_numbers;
  // const [screenWidth, screenHeight] = UseWindowSize();

  const [chatMsgs, setChatMsg] = useState();
  const [message, setMessage] = useState([]);
  const [isPrivateNote, setIsPrivateNote] = useState(false);
  const [moreFetchData, setMoreFetchData] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [rotate, setRotate] = useState(false);
  const [isSendDisabled, setIsSendDisabled] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const mobileScreen = isMobile();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const menuItemsList = ["send_template"];
  const [template, setTemplate] = useState(null);
  // const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    const apiFunc = isSendDisabled ? apiAllTemplates : apiPublishedTemplates;

    apiFunc(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Something went wrong with templates", error);
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };

  const debouncedLoadTemplates = debounce((searchKey) => {
    loadTemplates(searchKey);
  }, 600);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    setRotate(!rotate);
  };

  useEffect(() => {
    setChatMsg(null);
    setMoreFetchData(null);
    const functionTemp = () => {
      getChatList().then((res) => {
        auth.setChatData(res);
        setChatMsg(res?.results);
        setMoreFetchData(res);
      });
    };
    functionTemp();
  }, [auth.selectedChat]);

  const getUsers = () => {
    apiFetch("GET", "users/", null, true)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((res) => {
        const usersWithIdAndName = res.results.map((user) => ({
          id: user.id,
          name: user.first_name,
        }));
        setSelectedUser(usersWithIdAndName);
        const uniqueNames = Array.from(
          new Set(res.results.map((user) => user.first_name))
        );
        setCompanyUsers(uniqueNames);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getUsers();
    loadTemplates();
  }, []);

  const fetchMoredata = () => {
    if (accountDetails) {
      apiFetchMoreData(wpDetails, moreFetchData, phone_number)
        .then((res) => {
          setMoreFetchData(res);
          setChatMsg((prevState) => [...prevState, ...res.results]);
        })
        .catch((error) => {
          console.error("Failed to fetch chat list", error);
        });
    }
  };
  useEffect(() => {
    setRotate(false);
  }, [auth.selectedChat?.id]);

  useEffect(() => {
    if (phone_number) {
      getChatList().then((res) => {
        if (setMoreFetchData.count !== res.count) {
          let preArray = Boolean(chatMsgs) ? [...chatMsgs] : [];
          const oldFirststMsg = preArray[0];
          const filteredData = res?.results.filter(
            (newMsg) => newMsg.id > oldFirststMsg?.id
          );
          if (Boolean(filteredData.length)) {
            const latestArray = [...filteredData, ...preArray];

            auth.setChatData(res);
            setChatMsg(latestArray);
          }

          setMoreFetchData(res);
        }
      });
    }
  }, [auth.socketMsg, timerCount]);

  const getChatList = async () => {
    try {
      if (wpDetails && phone_number) {
        return apiGetChatList(wpDetails, phone_number);
      }
    } catch (error) {
      console.error("Failed to fetch chat list", error);
      throw error;
    }
  };

  const fetchMSGData = () => {
    return getChatList(wpDetails, phone_number).then((res) => {
      auth.setChatData(res);
      setChatMsg(res?.results);
      setMoreFetchData(res);
    });
  };

  const SendMessage = (message) => {
    const extractMentionedUsers = (message) => {
      const regex = /@([A-Za-z0-9_]+)/g;
      const matches = message.match(regex);
      if (matches) {
        return matches.map((match) => match.slice(1));
      }
      return [];
    };

    const mentionedUsers = extractMentionedUsers(message);
    const mentionedUserIds = mentionedUsers.map(
      (name) => selectedUser.find((user) => user.name === name)?.id
    );
    const uniqueMentionedUserIds = mentionedUserIds.filter(
      (userId, index, array) => array.indexOf(userId) === index
    );

    if (message !== null) {
      return apiSendMessage(
        selectedChat,
        wpDetails,
        message,
        isPrivateNote,
        uniqueMentionedUserIds
      )
        .then((res) => {
          return getChatList(wpDetails, phone_number).then((res) => {
            auth.setChatData(res);
            setChatMsg(res?.results);
            setMoreFetchData(res);
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  function titleCaseWithRegex(str) {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b(\w)/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
  }
  useEffect(() => {
    // this is to disable tha inputfield according after 24hrs of last msg
    if (auth.conversations && chatMsgs) {
      auth.conversations?.map((contact) => {
        if (contact?.id === selectedChat?.id) {
          const currentTime = new Date();
          const twentyFourHours = 24 * 60 * 60 * 1000;
          if (contact?.latest_received_time === null) {
            const lastReceivedMsg = chatMsgs.find(
              (msg) => msg?.direction === "received"
            );
            if (!lastReceivedMsg) {
              setIsSendDisabled(true);
            }
            const msgTime = new Date(lastReceivedMsg?.sent_time);
            const timeDifference = currentTime.getTime() - msgTime.getTime();
            if (timeDifference < twentyFourHours) {
              setIsSendDisabled(false);
            } else {
              setIsSendDisabled(true);
            }
          } else {
            const lastIncomingMessageTime = new Date(
              contact?.latest_received_time
            );
            const timeDifference =
              currentTime.getTime() - lastIncomingMessageTime.getTime();
            if (timeDifference < twentyFourHours) {
              setIsSendDisabled(false);
            } else {
              setIsSendDisabled(true);
            }
          }
        }
      });
    }
  }, [chatMsgs]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const atIndices = [];
    let match;
    const regex = /@/g;

    while ((match = regex.exec(value)) !== null) {
      atIndices.push(match.index);
    }

    if (atIndices.length > 0) {
      const lastIndex = atIndices[atIndices.length - 1];
      const prefix = value.slice(lastIndex + 1).toLowerCase();
      const matchingNames = companyUsers.filter((name) =>
        name.toLowerCase().includes(prefix)
      );
      setSuggestions(matchingNames);
    } else {
      setSuggestions([]);
    }
    const slashRegex = /\/$/;
    // if (slashRegex.test(value)) {
    //   setMenuAnchorEl(e.currentTarget);
    // } else {
    //   setMenuAnchorEl(null);
    // }
    if (value === "/") {
      setMenuAnchorEl(e.currentTarget);
    } else {
      setMenuAnchorEl(null);
    }

    const slashRegex2 = /\/[^\/]+\/$/;
    if (slashRegex2.test(value)) {
      setPopperEl(e.currentTarget);
    } else {
      setPopperEl(null);
    }
    setMessage(e.target.value);
  };

  function handleKeyDown(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
    if (event.key === "Escape") {
      setShowEmojiPicker(false);
    }
  }
  const handleSendMessage = () => {
    // to send message
    if (message.includes("send_template")) {
      const tempSelectedChat = selectedChat.contact?.phone_number;
      apiSendTemplates(tempSelectedChat, wpDetails, template).then((res) => {
        setMessage("");
      });
    } else {
      SendMessage(message).then((res) => {
        setMessage("");
        setIsPrivateNote(false);
      });
    }
  };

  const bubble = (direction) => {
    if (direction == "sent") {
      return "sender";
    } else {
      return "received";
    }
  };

  const handleSuggestionClick = (name) => {
    setMessage((prevMessage) => {
      const atIndex = prevMessage.lastIndexOf("@");
      if (atIndex >= 0) {
        return prevMessage.substring(0, atIndex + 1) + name + " ";
      } else {
        return prevMessage + "@" + name + " ";
      }
    });
    setSuggestions([]);
    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const handleEmojiSelect = (emojiData) => {
    setMessage(message + emojiData?.emoji);
  };
  useEffect(() => {
    if (showEmojiPicker && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [showEmojiPicker]);
  // const scrollableDivRef = useRef(null);
  // useEffect(() => {
  //   // Scroll to the top when a new message is added
  // }, [chatMsgs]); // Ensure the effect runs when chatMsgs is updated

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (containerRef.current) {
  //       // Capturing the scroll position correctly, taking into account inverse scrolling
  //       setSavedScrollPosition(
  //         containerRef.current.scrollHeight - containerRef.current.scrollTop
  //       );
  //     }
  //   };

  //   const div = containerRef.current;
  //   if (div) {
  //     div.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (div) {
  //       div.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const div = containerRef.current;
  //   if (div) {
  //     requestAnimationFrame(() => {
  //       // Restoring the scroll position taking inverse scrolling into account
  //       div.scrollTop = div.scrollHeight - savedScrollPosition;
  //     });
  //   }
  // }, [chatMsgs]);

  return (
    <>
      {selectedChat ? (
        <div className="midleContainer container2">
          <div>
            <div className="userHeadder">
              <p className="userHeading">
                {mobileScreen && (
                  <IconButton
                    onClick={() => {
                      auth.setConversationspage("chatList");
                      auth.setSelectedChat(null);
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                {selectedChat?.contact?.name}
              </p>
              {mobileScreen && (
                <IconButton
                  onClick={() => {
                    auth.setConversationspage("profile");
                  }}
                >
                  <AccountCircleIcon />
                </IconButton>
              )}
            </div>
          </div>
          <div
            className="chatContent"
            ref={containerRef}
            id="scrollableDivChatContent"
          >
            <InfiniteScroll
              dataLength={chatMsgs?.length || 10}
              next={fetchMoredata}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              hasMore={chatMsgs?.length < moreFetchData?.count}
              loader={
                <h4 style={{ textAlign: "center", padding: "10px" }}>
                  Loading...
                </h4>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {/* <b>Yay! You have seen it all</b> */}
                  <b>--*--</b>
                </p>
              }
              scrollableTarget="scrollableDivChatContent"
            >
              {chatMsgs?.map((data) => (
                <Message data={data} bubble={bubble(data?.direction)} />
              ))}
              {/* <DayDivider data="Today" /> */}
            </InfiniteScroll>
          </div>
          <div className="suggestions-container">
            {isPrivateNote && suggestions.length > 0 && (
              <div className="suggestions-box">
                {suggestions.map((name, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => handleSuggestionClick(name)}
                  >
                    {name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <OutlinedInput
            disabled={isSendDisabled && !isPrivateNote}
            ref={inputRef}
            sx={{
              minWidth: mobileScreen ? "98%" : "630px",
              height: "auto",
              bottom: 0,
              margin: "5px",
              paddingRight: "90px",
            }}
            placeholder={
              isSendDisabled && !isPrivateNote
                ? "Cannot send message as the last message was sent more than 24 hours ago"
                : "Type your message here"
            }
            multiline
            minRows={3}
            value={message}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <div className="inputDiv">
                  <button
                    disabled={isSendDisabled}
                    className={`rotate-button `}
                    style={{
                      position: "absolute",

                      right: "50px",
                      bottom: "35px",
                    }}
                    onClick={toggleEmojiPicker}
                  >
                    <InsertEmoticonIcon />
                  </button>
                  <div className="toggleAction">
                    {rotate && !isSendDisabled && (
                      <Zoom in={true} timeout={500}>
                        <div>
                          <Tooltip title="Add File" placement="right">
                            <div style={{ display: "inline-block" }}>
                              <FileUpload
                                fetchMSGData={fetchMSGData}
                                selectedChat={selectedChat}
                                wpDetails={wpDetails}
                                phone_number={phone_number}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </Zoom>
                    )}
                    {rotate && !isSendDisabled && (
                      <Zoom in={true} timeout={500}>
                        <div>
                          <Tooltip title="Add Contact" placement="right">
                            <div style={{ display: "inline-block" }}>
                              <SendContacts
                                fetchMSGData={fetchMSGData}
                                selectedChat={selectedChat}
                                wpDetails={wpDetails}
                                phone_number={phone_number}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </Zoom>
                    )}
                    {rotate && (
                      <Zoom in={true} timeout={500}>
                        <div>
                          <Tooltip title="Send Template" placement="right">
                            <div style={{ display: "inline-block" }}>
                              <Fab
                                sx={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  margin: "10px 0",
                                }}
                                onClick={openDialog}
                              >
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                  style={{ color: "#000000", fontSize: "20px" }}
                                />
                              </Fab>
                              <SendMessageTemplate
                                isOpen={isOpen}
                                closeDialog={closeDialog}
                                fetchMSGData={fetchMSGData}
                                selectedChat={selectedChat}
                                phone_number={phone_number}
                                isSendDisabled={isSendDisabled}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </Zoom>
                    )}
                    <button
                      className={`rotate-button ${rotate ? "rotate" : ""}`}
                      onClick={handleClick}
                    >
                      <AddIcon className="icon" />
                    </button>
                  </div>
                  <div
                    style={{
                      position: "absolute",

                      right: "10px",
                      bottom: "5px",
                    }}
                  >
                    <span style={{ marginRight: "15px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // disabled={isSendDisabled}
                            checked={isPrivateNote}
                            onChange={() => setIsPrivateNote(!isPrivateNote)}
                          />
                        }
                        label="Add as Private Note"
                      />
                    </span>
                    <Button
                      disabled={
                        (isSendDisabled || message.length <= 0) &&
                        (!isPrivateNote || message.length <= 0)
                      }
                      variant="contained"
                      sx={{
                        // mt: "50px",
                        w: "74px",
                        h: "26px",
                        p: "0px",
                        marginBottom: "0",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        background:
                          (!isSendDisabled && message.length > 0) ||
                          (isPrivateNote && message.length > 0)
                            ? "linear-gradient(272.65deg, #383194 0%, #da1e7a 100%)"
                            : "grey",
                        borderRadius: " 5px",
                      }}
                      onClick={() => {
                        handleSendMessage(message);
                      }}
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </InputAdornment>
            }
          />
        </div>
      ) : (
        ""
      )}
      {showEmojiPicker && (
        <div
          className="emojiPicker"
          onKeyDown={(event) => {
            if (event.key === "Escape") {
              setShowEmojiPicker(false);
            }
          }}
        >
          <EmojiPicker onEmojiClick={handleEmojiSelect} />
        </div>
      )}
      <Menu
        // anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
        PaperProps={{
          sx: {
            width: 600, // Set the width
            maxHeight: 300, // Set maximum height for scrollable behavior
            overflowY: "auto", // Make it scrollable
            // backgroundColor: "lightblue", // Background color
            marginTop: "-110px",
            marginLeft: "370px",
          },
        }}
      >
        {menuItemsList.map((x, index) => (
          <MenuItem
            onClick={() => {
              setMessage(message + "send_template");
              setMenuAnchorEl(null);
            }}
          >
            <div>
              <h3>{titleCaseWithRegex(x)}</h3>
              <p style={{ fontSize: "14px" }}>
                <strong>Shortcut:</strong> /{x}/
                {x === "send_template" ? "tempate_name" : ""}
              </p>
            </div>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        open={Boolean(popperEl)}
        // anchorEl={popperEl}
        style={{ zIndex: 1 }}
        onClose={() => setPopperEl(null)}
        PaperProps={{
          sx: {
            width: 600, // Set the width
            height: 300, // Set maximum height for scrollable behavior
            overflowY: "auto", // Make it scrollable
            // backgroundColor: "lightblue", // Background color
            marginTop: "-110px",
            marginLeft: "370px",
            padding: "5px",
          },
        }}
      >
        <Autocomplete
          freeSolo
          options={templates}
          loading={loadingTemplates}
          getOptionLabel={(option) => option?.name || ""}
          onInputChange={(event, newInputValue) => {
            debouncedLoadTemplates(newInputValue);
          }}
          onChange={(e, data) => {
            setMessage(message + data?.name);
            setTemplate(data);
            setPopperEl(null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select a template"
              loadingText="Loading..."
              noOptionsText="No options"
              autoFocus
            />
          )}
        />
      </Menu>
    </>
  );
}
