import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";
const url = "chatbot-flows";

export const loadMessageAutomation = (param) =>{
  return apiFetch("GET", `message-automations/?${param}`, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        notify("error", "Failed to Load Message Automation");
        throw new Error("Failed to load Message Automatios");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
}

export const createMessageAutomation = (body) => {
  return apiFetch("POST", "message-automations/", body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Message Automation Created");
        return res;
      } else {
        notify("error", "Failed to create Automation");
        throw new Error("Failed to create Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const deleteMessageAutomation = (automationId) => {
  return apiFetch("DELETE", `message-automations/${automationId}/`, null, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const editMessageAutomation = (id, body) => {
  return apiFetch("PUT", `message-automations/${id}/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Automation Updated");
        return res;
      } else {
        console.error("error", res);
        notify("error", "Failed to Edit Automation");
        throw new Error("Failed to Edit Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const createAutomation = (body) => {
  return apiFetch("POST", "/chatbot-flows/", body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Automation Created");
        return res;
      } else {
        notify("error", "Failed to create Automation");
        throw new Error("Failed to create Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};



export const editAutomation = (id, body) => {
  return apiFetch("PUT", `/chatbot-flows/${id}/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Automation Updated");
        return res;
      } else {
        console.error("error", res);
        notify("error", "Failed to Edit Automation");
        throw new Error("Failed to Edit Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const loadAutomations = (param) => {
  return apiFetch("GET", `/chatbot-flows/?${param}`, null, true)
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        notify("error", "Failed to Load Automation");
        throw new Error("Failed to load Automatios");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const deleteAutomation = (automationId) => {
  return apiFetch("DELETE", `/chatbot-flows/${automationId}/`, null, true)
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};

export const loadNodes = (param) => {
  return apiFetch(
    "GET",
    `/chatbot-flows/${param}/get-nodes-and-edges`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        notify("error", "Failed to Loading Nodes");
        throw new Error("Failed to loading Nodes");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
// /chatbot-flows/7/get-nodes-and-edges
export const createNode = (body, chatbot_id) => {
  return apiFetch("POST", `/chatbot-flows/${chatbot_id}/nodes/`, body, true)
    .then((res) => {
      if (res.ok) {
        notify("success", "Card Created");
        return res;
      } else {
        notify("error", "Failed to create Card");
        throw new Error("Failed to create Card");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const editNode = (body, chatbot_id, node_id) => {
  return apiFetch(
    "PUT",
    `/chatbot-flows/${chatbot_id}/nodes/${node_id}/`,
    body,
    true
  )
    .then((res) => {
      if (res.ok) {
        notify("success", "Card Edited");
        return res;
      } else {
        notify("error", "Failed to Edit Card");
        throw new Error("Failed to Edit Card");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const deleteNode = (chatBot_id, nodeId) => {
  return apiFetch(
    "DELETE",
    `/chatbot-flows/${chatBot_id}/nodes/${nodeId}/`,
    null,
    true
  )
    .then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw new Error("Failed to delete Automation");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
export const setConnectionsAndPositions = (chatBot_id, body) => {
  return apiFetch(
    "POST",
    `/chatbot-flows/${chatBot_id}/set-connections-and-positions/`,
    body,
    true
  )
    .then((res) => {
      if (res.ok) {
        // notify("success", "Flow Published");
        return res;
      } else {
        notify("error", " Failed to Published Flow");
        throw new Error(" Failed to Published Flow");
      }
    })
    .catch((error) => {
      console.error("Error", error);
      throw error;
    });
};
