import React, { useState, useEffect, useLayoutEffect } from "react";

import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Autocomplete,
  createFilterOptions,
  Select,
  Paper,
  Avatar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { loadTemplates as apiLoadTemplates } from "../../../API/CampaignLayer";
import { debounce } from "lodash";
import { notify } from "../../Common";
import { btnCss, choosebtn, TextfieldCss } from "../../../Styles";
import { ReactComponent as Spinner_2 } from "../../../Assets/Spinner_2.svg";
import {
  getAppintmentMessages as apiGetAppintmentMessages,
  setAppintmentMessages,
} from "../../../API/AppointmentLayer";
const AppointmentMsgs = () => {
  const methods = useForm({ mode: "onBlur" });
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState,
    resetField,
  } = methods;
  const { errors } = formState;
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [ApiLoading, SetApiLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const getAppintmentMessages = () => {
    apiGetAppintmentMessages().then((res) => {
      setEditData(res);
    });
  };

  const loadTemplates = (text) => {
    setLoadingTemplates(true);
    apiLoadTemplates(text)
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        console.error("Error", error);
        notify("error", "Something went wrong with templates");
      })
      .finally(() => {
        setLoadingTemplates(false);
      });
  };

  const debouncedTemplates = debounce((searchKey) => {
    if (searchKey) {
      loadTemplates(searchKey);
    } else {
      loadTemplates();
    }
  }, 600);
  const onSubmit = (data) => {
    const body = {
      appointment_creation: data.appointment_creation.id,
      appointment_cancel: data.appointment_cancel.id,
      appointment_reminder: data.appointment_reminder.id,
      appointment_reminder_mins: data.appointment_reminder_mins,
    };
    // const body = {
    //   appointment_creation: data.appointment_creation,
    //   appointment_cancel: data.appointment_cancel,
    //   appointment_reminder: data.appointment_reminder,
    //   appointment_reminder_mins: data.appointment_reminder_mins,
    // };

    setAppintmentMessages(body).then((res) => {
      if (res.ok) {
        notify("successs", "Saved");
      }
      console.log("res", res);
    });
  };
  useLayoutEffect(() => {
    getAppintmentMessages();
  }, []);
  useEffect(() => {
    setValue("appointment_cancel", editData?.appointment_cancel || "");
    setValue("appointment_creation", editData?.appointment_creation || "");
    setValue("appointment_reminder", editData?.appointment_reminder || "");
    setValue(
      "appointment_reminder_mins",
      editData?.appointment_reminder_mins || ""
    );
  }, [editData, setValue]);
  return (
    <div
      style={{
        marginTop: "20px",

        display: "grid",
        placeItems: "center",
      }}
    >
      <Paper
        elevation={2}
        style={{
          margin: "20px 0",
          width: "100%",
          padding: "15px",
          display: "grid",
          placeItems: "center",
          overflowX: "auto",
          maxWidth: "500px",
        }}
      >
        <FormProvider>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: "500px", width: "100%" }}
          >
            <h2 style={{ display: "grid", placeItems: "center" }}>
              Remainder Messages
            </h2>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Appointment Creation Message
                </InputLabel>
                <Controller
                  name="appointment_creation"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      options={templates ? templates : []}
                      loading={loadingTemplates}
                      getOptionLabel={(option) => option?.name}
                      onInputChange={(e, newInputValue) => {
                        debouncedTemplates(newInputValue);
                      }}
                      onChange={(e, data) => {
                        onChange(data);
                      }}
                      loadingText="Loading..."
                      noOptionsText="No options"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(errors.appointment_creation)}
                          onFocus={() => loadTemplates()}
                          helperText={
                            errors.appointment_creation &&
                            "Template is required"
                          }
                          placeholder="Select Template"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Appointment Cancel Message
                </InputLabel>
                <Controller
                  name="appointment_cancel"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      options={templates ? templates : []}
                      loading={loadingTemplates}
                      getOptionLabel={(option) => option?.name}
                      onInputChange={(e, newInputValue) => {
                        debouncedTemplates(newInputValue);
                      }}
                      onChange={(e, data) => {
                        onChange(data);
                      }}
                      loadingText="Loading..."
                      noOptionsText="No options"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(errors.appointment_cancel)}
                          onFocus={() => loadTemplates()}
                          helperText={
                            errors.appointment_cancel &&
                            "Appointment Cancel Message is required"
                          }
                          placeholder="Select Template"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Appointment Reminder Message
                </InputLabel>
                <Controller
                  name="appointment_reminder"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      value={value}
                      options={templates ? templates : []}
                      loading={loadingTemplates}
                      getOptionLabel={(option) => option?.name}
                      onInputChange={(e, newInputValue) => {
                        debouncedTemplates(newInputValue);
                      }}
                      onChange={(e, data) => {
                        onChange(data);
                      }}
                      loadingText="Loading..."
                      noOptionsText="No options"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(errors.appointment_reminder)}
                          onFocus={() => loadTemplates()}
                          helperText={
                            errors.appointment_reminder &&
                            "Appointment Reminder Message is required"
                          }
                          placeholder="Select Template"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ color: "#303535", margin: "5px 0" }}>
                  Appointment Reminder Minutes
                </InputLabel>
                <Controller
                  name="appointment_reminder_mins"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      type="number"
                      fullWidth
                      error={Boolean(errors.appointment_reminder_mins)}
                      onFocus={() => loadTemplates()}
                      helperText={
                        errors.appointment_reminder_mins &&
                        "Appointment Reminder Minutes Message is required"
                      }
                      placeholder="Type number of minutes"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <Button variant="contained" fullWidth sx={btnCss} type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {ApiLoading && (
          <div className="overlay">
            <Spinner_2 style={{ width: "80px" }} />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default AppointmentMsgs;
