import React from "react";

function ScheduleCamaign() {
  return (
    <div className="mainCtr">
      <h3 className="title"> Schedule Campaign</h3>
      <p className="subTitle">Let’s see an overview of your customers</p>
    </div>
  );
}

export default ScheduleCamaign;
