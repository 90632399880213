import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import ownchat from "../../Assets/ownchat.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', ownchat: '₹3760/mo' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', ownchat: 'No' },
    { feature: 'Number of agent', pinaippu: '10', ownchat: '5' },
    { feature: 'Broadcast message', pinaippu: 'Unlimited', ownchat: '100K' },    
    { feature: 'Smart Audience Segmentation', pinaippu: 'Yes', ownchat: 'No' },
    { feature: 'Campaign management', pinaippu: 'Advanced ', ownchat: 'Standard' },
    { feature: 'Drip marketing( Sequence-based )', pinaippu: 'Yes', ownchat: 'No' },
    { feature: 'Follow-up Reminders for Re-engagement', pinaippu: 'Yes', ownchat: 'No' },
    { feature: 'Chatbot Flows', pinaippu: 'Unlimited', ownchat: 'Limited' },
    { feature: 'WhatsApp Store', pinaippu: 'Unlimited Products', ownchat: 'Upto 100' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', ownchat: 'Ticketing, Email, Call' },
  ];

const ComparisonBlog8 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Ownchat | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Choose Ownchat over Respond.io for a cost-effective WhatsApp Business API solution
                    with Click-to-WhatsApp Ads support, advanced marketing features, and comprehensive
                    customer support. Explore the Best WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px" }}>Pinaippu Vs Ownchat</p>
              <img src={ownchat} style={{ width: "55px", height: "60px" }} alt="Ownchat" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Ownchat', field: 'ownchat' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Ownchat</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Offers standard and advanced plans to cater to different business sizes.</li>
                        <li>Unlimited tags and custom fields.</li>
                        <li>Standard campaign management.</li>
                        <li>Higher broadcast message limit (100K).</li>
                        <li>Suitable for mid-sized companies.</li>
                        <li>Integration with Shopify and Google Sheets.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Higher monthly cost compared to Pinaippu.</li>
                        <li>Fewer agents available for communication (5).</li>
                        <li>No smart audience segmentation.</li>
                        <li>Limited chatbot flows.</li>
                        <li>Does not offer drip marketing or follow-up reminders for re-engagement.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Pricing and Cost Considerations</h3></span><br />
                Pinaippu.io distinguishes itself by offering a significantly lower monthly cost compared to Ownchat. 
                For cost-conscious small or medium-sized businesses, this notable price difference serves as a compelling factor, potentially influencing their choice of messaging platform due to its cost-effectiveness.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Agent Capacity and Messaging Flexibility</h3></span><br />
                Pinaippu.io allows for up to 10 agents in contrast to Ownchat's Advanced plan, which restricts the number to 5 agents. 
                For businesses managing larger teams handling customer interactions, Pinaippu.io offers increased flexibility, allowing more team members to engage with customers effectively.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Messaging Capabilities and Advanced Features</h3></span><br />
               In terms of messaging capabilities, Pinaippu.io offers unlimited broadcast messages, catering well to businesses with extensive messaging needs. Conversely, Ownchat's Advanced plan confines broadcast messages to a limit of 100K. 
               Furthermore, Pinaippu.io encompasses advanced features such as smart audience segmentation, drip marketing, and follow-up reminders for re-engagement—crucial tools for businesses seeking to engage their audience more effectively.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Marketing Tools and Chatbot Capabilities</h3></span><br />
                Pinaippu.io excels in providing the option for Click-to-WhatsApp Ads, a potent tool for marketing and customer acquisition. 
                In contrast, Ownchat lacks this specific feature, potentially limiting marketing strategies. Moreover, for businesses heavily reliant on chatbots for customer interactions, Pinaippu.io's advantage lies in offering unlimited chatbot flows, while Ownchat presents limited chatbot capabilities, potentially impacting the range and complexity of interactions with customers.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog8;
