import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";
import { useAuth } from "../Context/Auth";
import { useNavigate } from "react-router-dom";
export const useIntegrationsLayer = () => {
  const { userDetails, setUserDetails, setZohoBooksDetails } = useAuth();
  const navigate = useNavigate();
  const updateUserDetails = (newKey, newValue) => {
    // Get the existing user details from localStorage
    const storedUserDetails = localStorage.getItem("userDetails");

    // Parse the stored details
    let userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : {};

    // Update the user details with the new key-value pair
    userDetails[newKey] = newValue;

    // Save the updated details back to localStorage
    localStorage.setItem("userDetails", JSON.stringify(userDetails));

    // Update the state if needed
    setUserDetails(userDetails);
  };

  const getZohoHookUrl = (body) => {
    return apiFetch("POST", "integrations/zohobooks/setup/", body, true)
      .then((res) => {
        if (res?.ok) {
          notify("success", "WebHook URL Generated");
          getZohoHookDetails();
          navigate("/integrations/zohobooks");
        } else {
          notify("error", "Failed to Generate WebHook URL");
        }
      })
      .catch((error) => {
        console.error("Error", error);
        throw error;
      });
  };

  const getZohoHookDetails = () => {
    const url = "integrations/zohobooks/";
    return apiFetch("GET", url, null, true).then((res) => {
      if (res.ok) {
        return res.json().then((Tres) => {
          setZohoBooksDetails(Tres);
        });
      } else {
        console.error(res);
        notify("error", "Something went wrong with Generate Webhook");
      }
    });
  };

  return {
    getZohoHookUrl,
    getZohoHookDetails,
  };
};
