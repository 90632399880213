import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  TextField,
  Fab,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Paper,
  InputAdornment,
} from "@mui/material";
import { loadContacts as apiLoadContacts } from "../../../../API/ContactLayer";
import { notify } from "../../../Common";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { sendContacts } from "../../../../API/ChatLayer";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useEffect } from "react";

function SendContacts({ fetchMSGData, selectedChat, wpDetails }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [selectedContacts, setSelectedRows] = useState([]);

  const handleSendContacts = () => {
    if (selectedContacts.length > 0) {
      const ids = selectedContacts.map((obj) => obj.id);
      return sendContacts(selectedChat, wpDetails, ids).then((res) => {
        return fetchMSGData()
          .then((res) => {
            closeDialog();
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
    } else {
      setErrorMessage(true);
      console.log("please SELECT Contacts");
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setErrorMessage(false);
    setSelectedRows([]);
  };
  const loadContacts = (param) => {
    apiLoadContacts(param)
      .then((res) => {
        setContacts(res?.results);
      })
      .catch((error) => {
        notify("error", error.message);
      });
  };
  const handleRowSelect = (row) => {
    if (selectedContacts.some((selectedRow) => selectedRow.id === row.id)) {
      // If row is already selected, remove it from selected rows
      setSelectedRows(
        selectedContacts.filter((selectedRow) => selectedRow.id !== row.id)
      );
    } else {
      // If row is not selected, add it to selected rows
      setSelectedRows([...selectedContacts, row]);
    }
  };
  // Function to handle select/deselect all rows
  const handleSelectAll = () => {
    if (selectedContacts.length === contacts.length) {
      // If all rows are already selected, deselect all rows
      setSelectedRows([]);
    } else {
      // If not all rows are selected, select all rows
      setSelectedRows([...contacts]);
    }
  };
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      loadContacts(`search=${searchKey}`);
    }
  }, 600);
  useEffect(() => {
    loadContacts();
  }, []);
  return (
    <div>
      <Fab
        sx={{
          width: "45px",
          height: "45px",
          borderRadius: "50%",
          margin: "10px 0",
        }}
        onClick={openDialog}
      >
        <ContactsIcon />
      </Fab>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Select Contacts</DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "400px",
              height: "455px",
            }}
          >
            <>
              <TextField
                id="campaignName"
                variant="outlined"
                fullWidth
                sx={{ marginBottom: "10px" }}
                size="small"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                  if (!e.target.value) {
                    loadContacts();
                  }
                }}
              />
              <TableContainer
                component={Paper}
                style={{
                  overflowY: "auto",
                  height: "400px",
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Checkbox
                          size="small"
                          checked={
                            selectedContacts?.length === contacts?.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell align="left">name</TableCell>
                      <TableCell align="right">
                        Phone Number&nbsp;&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {contacts ? (
                    <TableBody>
                      {contacts.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">
                            <Checkbox
                              checked={selectedContacts?.some(
                                (selectedRow) => selectedRow.id === row.id
                              )}
                              onChange={() => handleRowSelect(row)}
                            />
                          </TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="right">
                            {row.phone_number}&nbsp;&nbsp;
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <p>Loading...</p>
                  )}
                </Table>
              </TableContainer>
            </>
            {selectedContacts.length <= 0 && errorMessage ? (
              <p
                style={{
                  textAlign: "center",
                  color: "red",
                  margin: "5px",
                }}
              >
                Please Select Contact
              </p>
            ) : (
              ""
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "0 20px 20px 0" }}>
            <Button
              disabled={selectedContacts.length <= 0}
              onClick={handleSendContacts}
            >
              Send
            </Button>
            <Button onClick={closeDialog}>Cancel</Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SendContacts;
