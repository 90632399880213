import React from "react";
import { useAuth } from "../Context/Auth";
import { useNavigate } from "react-router-dom";
function RequireAuth({ children }) {
  const navigate = useNavigate();
  const auth = useAuth();

  // React.useEffect(() => {
  //   const accessToken = JSON.parse(localStorage.getItem("userTokens"))?.access;
  //   testLogin(accessToken).then((res) => {
  //     console.log("session not expired");
  //     if (!res.ok) {
  //       console.log("session expired");
  //       notify("error", "session expired");
  //       localStorage.removeItem("userTokens");
  //       return navigate("/");
  //     }
  //   });
  // });

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userTokens"));
    if (auth.user || data?.access_token) {
    } else {
      return navigate("/");
    }
  }, []);
  return children;
}

export default RequireAuth;
