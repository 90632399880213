import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import wati from "../../Assets/wati.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', wati: '₹16999/mo' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Yes', wati: 'No' },
    { feature: 'Additional users', pinaippu: '$5 per month', wati: '$30 per month' },
    { feature: 'Green Tick Application', pinaippu: 'Free', wati: '₹ 3750' },    
    { feature: 'Smart Audience Segmentation', pinaippu: 'Yes', wati: 'No' },
    { feature: 'Chatbot Flows', pinaippu: 'Unlimited', wati: '200' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', wati: 'Ticketing' },
    { feature: 'Zoom Call/ Gmeet Support', pinaippu: 'Yes', wati: 'No' },
  ];

const ComparisonBlog9 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Wati | WhatsApp Marketing Automation</title>
                <meta
                    name="description"
                    content="Pinaippu.io is the ideal WhatsApp Business API alternative to Wati, offering affordable pricing,
                    unlimited messaging, and transparent plans. Explore the Best WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px" }}>Pinaippu Vs Wati</p>
              <img src={wati} style={{ width: "70px", height: "80px" }} alt="Wati" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Wati', field: 'wati' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Wati</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Offers different pricing tiers to accommodate various business sizes.</li>
                        <li>Includes various automation features and integrations.</li>
                        <li>Provides support and dedicated customer success manager options.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Significantly higher pricing compared to Pinaippu.</li>
                        <li>Additional user pricing is relatively expensive.</li>
                        <li>Smart audience segmentation is not available.</li>
                        <li>Limited to 200 chatbot flows, which might be restrictive for some businesses.</li>
                        <li>Support is limited to ticketing only.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Diverse and Accessible Pricing Options</h3></span><br />
               Pinaippu's service offers three distinct pricing tiers that accommodate businesses of various sizes and financial capacities. 
               These tiers are designed to cater to both startups and medium-sized enterprises, providing accessible solutions for diverse business scales and budgets.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Pricing Transparency and Messaging Freedom</h3></span><br />
               One of Pinaippu's standout attributes is its transparent pricing model. The platform clearly states the monthly pricing for each tier, simplifying cost understanding for businesses.
                Moreover, regardless of the selected tier, Pinaippu provides the advantage of unlimited messaging, enabling businesses to communicate freely with their customers without concerns of message limits, fostering uninhibited communication.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Scalability and Essential Features</h3></span><br />
                Pinaippu's scalability is evident in its offering of "Advanced" or "Elite" plans, suitable for businesses experiencing growth.
                 These upgraded plans offer additional agents, campaigns, and contacts, essential for expanding customer engagement needs. 
                Furthermore, the inclusion of a shared team inbox improves team collaboration, ultimately enhancing customer support efficiency.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Reliable WhatsApp API Support and Clarity in Features</h3></span><br />
                Pinaippu's reliability is underscored by its support of the official WhatsApp API, ensuring dependable and compliant communication through WhatsApp. 
                Additionally, the platform offers onboarding assistance, aiding businesses in effectively commencing their integration with the WhatsApp API. 
                The service's commitment to providing clear and comprehensive information about the included users, campaigns, and contacts within each plan empowers businesses to make informed decisions without ambiguity.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog9;
