// SwitchComponent.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import "./CustomSwitchStyle.css";

const CustomSwitch = ({ tabs, defaultTab, onTabChange }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  return (
    <div className="CustomSwitch-container">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`${selectedTab === tab ? "CSActive" : "CStab"}`}
          onClick={() => handleTabClick(tab)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

CustomSwitch.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func,
};

export default CustomSwitch;
