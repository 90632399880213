import React from "react";
import "./EmptyPage.css";
import grayscaleLogo from "../../../../Assets/grayscaleLogo.png";

const EmptyPage = () => {
  return (
    <div className="empty-page">
      <img
        src={grayscaleLogo}
        style={{ width: "100px", marginBottom: "15px" }}
        alt="Pinaippu"
      />

      <h3>No chat selected</h3>
      <p>Please select a chat from the list to start a conversation.</p>
    </div>
  );
};

export default EmptyPage;
