import { useContext, createContext, useState, useEffect } from "react";
import { notify, extractPhoneNumberFromUrl } from "../Components/Common";
import { checkwhatsAppBusinessAcc as WPCheck } from "../API/AuthLayer";
import { useNavigate, createSearchParams } from "react-router-dom";
import { getBrandDetails } from "../API/CommonApi";
import { arraysAreEqual, playNotificationSound } from "../Components/Common";
import { useNotification } from "../Hooks/useNotification";
import { useMediaQuery, useTheme } from "@mui/material";
const AuthContext = createContext(null);
export const AuthProvider = ({ children }) => {
  const { checkNotificationPermission, setNotification } = useNotification();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [wpDetails, setWpDetails] = useState(null);
  const [chatList, setChatList] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editCardData, setEditCardData] = useState(null);
  const [brandLoading, setBrandLoading] = useState(false);
  const [brandDetails, setBrandDetails] = useState(null);
  const [conversationsPage, setConversationspage] = useState(null);
  const [conversations, setConversations] = useState(null);
  const [moreConversations, setMoreConversations] = useState(null);
  const [contactFlag, setContactFlag] = useState("open");
  const [dropDownFlag, setDropDownFlag] = useState("all_chats");
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [chatSearchKey, setChatSearchKey] = useState("");
  const [socket, setSocket] = useState(null);
  const [socketMsg, setSocketMsg] = useState([]);
  const [prevConversations, setPrevConversations] = useState([]);
  const [timerCount, setTimerCount] = useState(0);
  const [zohoBooksDetails, setZohoBooksDetails] = useState(null);
  const navigate = useNavigate();
  //  query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const [phoneNumber, setPhoneNumber] = useState(
    extractPhoneNumberFromUrl(urlParams)
  );
  // const phoneNumber = extractPhoneNumberFromUrl(urlParams);

  useEffect(() => {
    const tempUsDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (tempUsDetails && !userDetails) {
      setUserDetails(tempUsDetails);
    }
    const tempisNativeApp = urlParams.get("isNativeApp");
    setIsNativeApp(tempisNativeApp);

    checkNotificationPermission();
    initializeUser();
  }, []);
  const whatsappCheck = () => {
    const tempUser = JSON.parse(localStorage.getItem("userTokens"));
    if (tempUser) {
      WPCheck()
        .then((res) => {
          if (typeof res === "object" && res?.length !== 0) {
            setWpDetails(res);
          } else {
            notify("error", "whatsapp account is not associated ");
            navigate("/whatsapp-signup");
            setWpDetails(null);
          }

          setUser(tempUser);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    whatsappCheck();
    // const intervalId = setInterval(() => {
    //   setTimerCount((pre) => pre + 1);
    //   // }, 10 * 1000);
    // }, 5 * 60 * 1000);

    // return () => clearInterval(intervalId);
  }, []);

  const initializeUser = () => {
    // Get data from localStorage
    const storedUser = localStorage.getItem("userTokens");
    const storedUserDetails = localStorage.getItem("userDetails");

    // Parse the data and set it in state
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    if (storedUserDetails) {
      setUserDetails(JSON.parse(storedUserDetails));
    }
  };
  const login = (user) => {
    setUser(user);
    setUserDetails(user?.user);
    localStorage.setItem("userTokens", JSON.stringify(user));
    localStorage.setItem("userDetails", JSON.stringify(user.user));

    whatsappCheck();
  };
  const logOut = () => {
    setUser(null);
    setUserDetails(null);
  };

  useEffect(() => {
    setBrandLoading(true);
    const currentDomain = window.location.hostname;
    getBrandDetails(currentDomain).then((res) => {
      setBrandDetails(res);
      setBrandLoading(false);
    });
  }, []);

  const RedirectBasedOnDomain = () => {
    const allowedDomain = "pinaippu.io";
    const currentDomain = window.location.hostname;

    if (currentDomain === "localhost") {
      console.log("continue");
    } else if (!currentDomain.endsWith(allowedDomain)) {
      navigate("/login");
    }
  };

  //  For Notifications
  const updateNotificationCount = (conversations) => {
    const unreadConversations = conversations?.filter(
      (conv) => conv.total_unread_messages >= 1
    );
    const totalUnreadCount = unreadConversations?.length;
    const isDataChanged = !arraysAreEqual(conversations, prevConversations);

    if (isDataChanged) {
      if (totalUnreadCount > 0) {
        document.title = `(${totalUnreadCount}) PINAIPPU`;
      } else {
        document.title = `PINAIPPU`;
      }
      setPrevConversations(conversations);
    }
  };
  useEffect(() => {
    updateNotificationCount(conversations);
  }, [conversations]);

  const backend = process.env.REACT_APP_WS_URL;

  function updatePreviousObjects(preObjects, latestObject) {
    const previousObjects = [...preObjects];

    const existingObjectIndex = previousObjects.findIndex(
      (obj) => obj.id === latestObject.id
    );

    if (existingObjectIndex !== -1) {
      previousObjects.splice(existingObjectIndex, 1);
    }

    previousObjects.unshift(latestObject);

    return previousObjects;
  }
  // web socket
  // useEffect(() => {
  //   if (wpDetails?.[0].phone_numbers?.[0]?.phone_number_id !== undefined) {
  //     const accessToken = JSON.parse(
  //       localStorage.getItem("userTokens")
  //     )?.access_token;

  //     const socketURL = `${backend}ws/chat/${wpDetails?.[0].phone_numbers?.[0]?.phone_number_id}/`;
  //     const socket = new WebSocket(socketURL);

  //     socket.onopen = () => {
  //       console.log("WebSocket connected");
  //       const authMessage = { type: "authentication", token: accessToken };
  //       socket.send(JSON.stringify(authMessage));
  //     };

  //     socket.onmessage = (event) => {
  //       const messageData = JSON.parse(event.data);
  //       setSocketMsg(messageData);
  //     };

  //     socket.onclose = (event) => {
  //       console.log("connection died");
  //       if (event.wasClean) {
  //         console.log(
  //           `WebSocket closed cleanly, code=${event.code}, reason=${event.reason}`
  //         );
  //       } else {
  //         console.error("WebSocket connection died");
  //       }
  //     };

  //     socket.onerror = (error) => {
  //       console.log(`WebSocket error: ${error}`);
  //     };
  //     setSocket(socket);
  //     return () => {
  //       socket.close();
  //     };
  //   }
  // }, [wpDetails?.[0].phone_numbers?.[0]?.phone_number_id]);

  useEffect(() => {
    let socket;
    let intervalId;
    let pingIntervalId;
    let reconnectAttempts = 0;

    const connectSocket = () => {
      const phoneNumberId = wpDetails?.[0].phone_numbers?.[0]?.phone_number_id;
      if (!phoneNumberId) return;

      const accessToken = JSON.parse(
        localStorage.getItem("userTokens")
      )?.access_token;
      const socketURL = `${backend}ws/chat/${phoneNumberId}/`;
      socket = new WebSocket(socketURL);

      socket.onopen = () => {
        console.log("WebSocket connected");
        reconnectAttempts = 0;

        // Send authentication message
        const authMessage = { type: "authentication", token: accessToken };
        socket.send(JSON.stringify(authMessage));

        // Start the ping interval
        pingIntervalId = setInterval(() => {
          if (socket.readyState === WebSocket.OPEN) {
            console.log("Sending ping to keep WebSocket alive");
            socket.send(JSON.stringify({ type: "ping" }));
          } else {
            console.error("WebSocket is not open, attempting to reconnect...");
            retryConnection(); // Attempt to reconnect if the connection is lost
          }
        }, 5 * 60 * 1000); // Ping every 5 minutes 5 * 60 * 1000
      };

      socket.onmessage = (event) => {
        const messageData = JSON.parse(event.data);
        setSocketMsg(messageData);
      };

      socket.onclose = (event) => {
        console.log("WebSocket connection closed");
        if (!event.wasClean) {
          console.error("WebSocket connection died, attempting to reconnect");
          retryConnection(); // Retry connection if it wasn't clean
        }
        clearInterval(pingIntervalId); // Stop the ping interval
      };

      socket.onerror = (error) => {
        console.log(`WebSocket error: ${error}`);
        retryConnection(); // Retry connection on error
        clearInterval(pingIntervalId); // Stop the ping interval
      };

      setSocket(socket);
    };

    const retryConnection = () => {
      reconnectAttempts += 1;
      if (navigator.onLine) {
        setTimeout(() => {
          console.log(`Attempting to reconnect, attempt #${reconnectAttempts}`);
          connectSocket();
        }, 10000); // Retry connection every 5 seconds
      } else {
        notify("warn", "Please check your internet connection");
        // Retry connection every 5 seconds if the internet is disconnected
        intervalId = setInterval(() => {
          if (navigator.onLine) {
            clearInterval(intervalId); // Clear the interval once internet is back
            connectSocket();
          } else {
            console.log("Still no internet connection, retrying...");
          }
        }, 5000);
      }
    };

    connectSocket(); // Initial connection

    return () => {
      if (socket) {
        socket.close();
      }
      clearInterval(intervalId); // Cleanup interval on component unmount
      clearInterval(pingIntervalId); // Cleanup ping interval on component unmount
    };
  }, [wpDetails?.[0]?.phone_numbers?.[0]?.phone_number_id]);

  useEffect(() => {
    if (
      (socketMsg?.event?.status === contactFlag || contactFlag === "all") &&
      conversations
    ) {
      const message = socketMsg.event;

      setNotification(socketMsg?.event);
      const updated = updatePreviousObjects(conversations, socketMsg?.event);
      // console.log("socketMsg?.event", socketMsg?.event);
      setConversations(updated);
    }
  }, [socketMsg]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logOut,
        wpDetails,
        setWpDetails,
        setChatData,
        chatData,
        selectedChat,
        setSelectedChat,
        chatList,
        setChatList,
        isLoading,
        editCardData,
        setEditCardData,
        brandDetails,
        brandLoading,
        RedirectBasedOnDomain,
        conversationsPage,
        setConversationspage,
        conversations,
        setConversations,
        moreConversations,
        setMoreConversations,
        contactFlag,
        setContactFlag,
        dropDownFlag,
        setDropDownFlag,
        socketMsg,
        isNativeApp,
        userDetails,
        setUserDetails,
        phoneNumber,
        chatSearchKey,
        setChatSearchKey,
        timerCount,
        isMobile,
        zohoBooksDetails,
        setZohoBooksDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
