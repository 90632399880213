import React, { useState } from "react";
import EditProfile from "./EditProfile";
import ResetPassword from "./ResetPassword";
const Profile = () => {
  return (
    <div style={{ marginTop: "60px", minHeight: "90vh" }}>
      <div className="pageInNav">
        <div>
          <p className="pageH">Profile</p>
        </div>
        <div className="inNavCtr"></div>
      </div>

      <div
        style={{
          margin: "20px 5px 5px 5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <EditProfile />
        <ResetPassword />
      </div>
    </div>
  );
};

export default Profile;
