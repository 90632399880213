import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', interakt: '₹3499/mo' },
    { feature: 'Click-to-WhatsApp Ads', pinaippu: 'Create, run, track Ads', interakt: 'Only trackings' },
    { feature: 'WhatsApp Conversation charges', pinaippu: 'Standard WhatsApp Business conversation charges', interakt: 'Standard WhatsApp Businessconversation charges +markup charges' },
    { feature: 'Contacts Management', pinaippu: 'Advanced', interakt: 'Basic' },    
    { feature: 'Assignment Rules', pinaippu: 'Yes', interakt: 'No' },
    { feature: 'Chatbot Flow', pinaippu: 'Advanced keyword-based bot', interakt: 'Basic shopping bot' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', interakt: 'Ticketing' },
    { feature: 'Zoom Call/ Gmeet Support', pinaippu: 'Yes', interakt: 'No' },
  ];

const ComparisonBlog1 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
              <title>Best Alternative to Interakt | WhatsApp marketing automation</title>
              <meta name="description" content="Choose Pinaippu.io over Interkart for your WhatsApp Business API needs. Affordable,
                advanced, and supported - the smart choice for effective WhatsApp communication.
                Explore the Best WhatsApp Business API solution now!" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
                <p className='brand-title' style={{ margin: "3px 20px", fontSize: "45px", textAlign: "center"}}>Pinaippu Vs Interakt</p>
              <img src={interakt} style={{ width: "70px", height: "64px" }} alt="Interakt" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                    columns={[
                        { title: 'Comparison', field: 'feature' },
                        { title: 'Pinaippu', field: 'pinaippu' },
                        { title: 'Interakt', field: 'interakt' },
                    ]}
                    data={tableData}
                    options={{
                        paging: false,
                        search: false,
                        toolbar: false,
                        headerStyle: {
                            backgroundColor: "#f3eeef",
                            border: "1px solid #dddbdb",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "32px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            textTransform: "capitalize",
                            lineHeight: "normal",
                            borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                            color: "rgba(121, 6, 78, 0.9)",
                        },
                        cellStyle: {
                            border: "1px solid #dddbdb",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "130%",
                        },
                    }}
                    style={{
                        width: "950px",
                    }}
                />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Interakt</p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            
            <div className='challenges' style={{ display: 'flex' }}>
                <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Interkart offers transparent pricing plans with defined conversation limits, making it easy for businesses to choose.</li>
                        <li>The Starter Plan includes free WhatsApp API approval and setup, simplifying the onboarding process.</li>
                        <li>Interkart provides chat automation features, including out-of-office (OOO) and welcome messages, streamlining customer interactions.</li>
                        <li>Businesses can send bulk WhatsApp notifications and access campaign analytics, facilitating marketing efforts.</li>
                        <li>Interkart's Shared Team Inbox enhances team collaboration by allowing quick replies, agent assignments, labels, and more.</li>
                        <li>Users can access developer APIs, enabling custom integrations and greater control over messaging.</li>
                    </ul>
                </div>
                <div className='challenges-cons' style={{ marginLeft: '20px' }}>
                    <p>Cons:</p>
                    <ul>
                        <li>Interkart offers basic contact management, which may not be suitable for businesses with complex contact management needs.</li>
                        <li>Interkart does not support assignment rules, which can be a limitation for businesses that require automated assignment of chats to specific agents or teams.</li>
                        <li>Costs can vary based on the number of conversations, especially Marketing conversations, which are charged separately. This variability can make it challenging for businesses to predict and manage their messaging expenses.</li>
                        <li>Interkart specifies rate limits for API calls. These rate limits may be limiting for businesses with high-volume messaging needs, potentially affecting the efficiency of communication.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px',marginBottom: '20px'}}><span><h3>Pricing and Budget Considerations</h3></span><br />
                Pinaippu presents a significantly lower monthly pricing at ₹1699/mo in comparison to Interkart's ₹3499/mo. 
                This considerable price disparity makes Pinaippu a more affordable option, especially for budget-conscious businesses, potentially being a pivotal factor in their decision-making process.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>Advertising Capabilities and Contacts Management</h3></span><br />
                Pinaippu not only enables businesses to track but also create and execute Click-to-WhatsApp Ads, offering a comprehensive advertising solution on the WhatsApp platform. 
                Additionally, the platform provides advanced contact management features, facilitating efficient organization and management of contact lists, streamlining customer interactions.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3>Automation Tools and Support Options</h3></span><br />
                Through its support for assignment rules, Pinaippu offers automated chat distribution to specific agents or teams, a functionality that significantly improves response times and customer satisfaction. Moreover, Pinaippu's advanced keyword-based chatbot enables more sophisticated automation, surpassing the basic shopping bot provided by Interkart.
                 The inclusion of various support channels such as ticketing, email, and calls further ensures businesses can obtain assistance tailored to their specific needs.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Video Communication Integration</h3></span><br />
                  Pinaippu's support for video conferencing integration with Zoom Call and Gmeet stands as a crucial asset, particularly for businesses requiring video communication with their customers. 
                  This feature enhances the platform's versatility, offering an additional communication channel for businesses aiming to engage customers through video interactions.</p>
            </div>
          </div>


          </div>
              <IconButton
                  sx={btnDemoCss}
                  style={{
                      position:"fixed",
                      bottom:"20px",
                      right:"20px",
                      color: "#892887",
                      fontFamily: "Inter",
                      fontSize: "14px",
                  }}
                  onClick={() => {
                  window.open(CalendlyURL, "_blank");
                  }}
              >
                  Book A Demo
              </IconButton>
          <div style={{margin :"10px"}}></div>
        </div>
    );
};

export default ComparisonBlog1;
