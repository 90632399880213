import React from 'react';
import { NavLink } from 'react-router-dom';
import { CalendlyURL } from '../../API/CommonApi';
import { AppBar, Toolbar, Typography ,Grid} from "@material-ui/core";
import { btnDemoCss } from '../../Styles';
import justLogo from "../../Assets/justLogo.png";
import blog_side_img from "../../Assets/blog_side_img.png";
import comparison_img from "../../Assets/comparison_img.png";
import interakt from "../../Assets/interakt.png";
import MaterialTable from "material-table";
import { Helmet } from 'react-helmet';
import "./ComparisonBlog.css";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {  IconButton } from '@mui/material';
import gallabox from "../../Assets/gallabox.png";

const theme = createTheme({
    palette: {
      primary: {
        light: "linear-gradient(272.65deg, #383194 0%, #DA1E7A 100%)",
        main: "#ffffff",
        dark: "#ab344f",
        contrastText: "#fff",
      },
    },
  });
  const tableData = [
    { feature: 'Pricing', pinaippu: '₹1699/mo', gallabox: '₹7999/mo' },
    { feature: 'No of users', pinaippu: '10 users', gallabox: '6 users' },
    { feature: 'Additional users', pinaippu: '$3 per month', gallabox: '$15 per month' },
    { feature: 'Bots', pinaippu: 'Unlimited', gallabox: 'Up to 20' },    
    { feature: 'Marketing campaigns', pinaippu: 'Advanced', gallabox: 'Standard' },
    { feature: 'Chatbot type', pinaippu: 'Keyword based + Advanced', gallabox: 'Advanced' },
    { feature: 'Custom Fields', pinaippu: 'Unlimited', gallabox: '10' },
    { feature: 'Support', pinaippu: 'Ticketing, Email, Call', gallabox: 'Ticketing, Email, Call' }, 
  ];

const ComparisonBlog3 = () => {
    return (
    <div className='comparison-body'>
        <Helmet>
                <title>Best Alternative to Gallabox | WhatsApp marketing automation</title>
                <meta
                    name="description"
                    content="Choose Pinaippu.io over Gallabox for a cost-effective WhatsApp Business API solution
                    with more features, greater user support, and unlimited custom fields. Explore the Best
                    WhatsApp Business API solution now!"
                />
        </Helmet>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <>
                <img src={justLogo} style={{ width: "35px" }} alt="Pinaippu" />
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: " 700",
                    marginLeft: "3px",
                    color: "#000",
                  }}
                >
                  I N A I P P U
                </p>
              </>
              <div style={{ marginLeft: 'auto' }}>
                <ul className="Navmenu" style={{ marginRight: "20px" }}>
                  <li>
                    <NavLink className="normalNav" to="/signup">
                      Sign Up
                    </NavLink>{" "}
                  </li> 
                  <li>
                    <NavLink className="normalNav" to="/pricing">
                      Pricing
                    </NavLink>{" "}
                  </li>
                  <li>
                    <a
                      target="_blank_"
                      href={CalendlyURL}
                      className="normalNav"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <div className="scrollable-div" style={{paddingTop:"100px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} >
                <div style={{paddingLeft:'60px',paddingTop:"80px"}}>
                    <p style = {{color:'#9F177A',paddingBottom:"50px", fontSize:'55px'}}><b>Pinaippu.io</b></p>
                    <p style={{paddingBottom:'45px', fontSize:'40px'}}>The WhatsApp way to connect with customers</p>
                    <p style={{fontSize:'27px'}}>Build your customer relationship at ease with our end-to-end Whatsapp marketing platform.</p>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <img src={blog_side_img} alt="WhatsApp" style={{height:'auto',width:"auto",float:'right'}}/>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <img src={comparison_img} alt="Centered Image" style={{ height:'500px', width:'auto' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={justLogo} style={{ width: "45px", height: "45px" }} alt="Pinaippu" />
              <p className='brand-title' style={{ margin: "0 20px", fontSize: "45px", textAlign: "center" }}>Pinaippu Vs Gallabox</p>
              <img src={gallabox} style={{ width: "60px", height: "60px" }} alt="Gallabox" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ margin: "50px", maxWidth: "2000px" }}>
                <MaterialTable
                columns={[
                    { title: 'Comparison', field: 'feature' },
                    { title: 'Pinaippu', field: 'pinaippu' },
                    { title: 'Gallabox', field: 'gallabox' },
                ]}
                data={tableData}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                        backgroundColor: "#f3eeef",
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize:"32px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        textTransform: "capitalize",
                        lineHeight: "normal",
                        borderBottom: "1px solid rgba(121, 6, 78, 0.90)",
                        color:"rgba(121, 6, 78, 0.9)",
                    },
                    cellStyle: {
                        border: "1px solid #dddbdb",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight:"130%",
                    },
                }}
                style={{
                    width:"950px",
                }}
                />
            </div>  
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p className='brand-title' style={{ fontSize: "45px" }}>Gallabox</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              
              <div className='challenges' style={{ display: 'flex' }}>
                  <div className='challenges-pros' style={{ marginRight: '20px' }}>
                    <p>Pros:</p>
                    <ul>
                        <li>Gallabox allows you to use multiple WhatsApp numbers, which can be beneficial for businesses managing different brands or services.</li>
                        <li>The platform supports bulk broadcast campaigns, making it easier to send messages to a large audience at once.</li>
                        <li>Businesses can create drip marketing sequences, allowing for automated, scheduled messaging to nurture leads and customers.</li>
                        <li>Gallabox offers features like WhatsApp Shop and Payment Links, making it suitable for businesses involved in e-commerce.</li>
                        <li>The platform offers integrations with various third-party tools and services, providing flexibility in connecting with other business applications.</li>
                    </ul>
                </div>
                <div className='challenges-cons'>
                    <p>Cons:</p>
                    <ul>
                        <li>Gallabox&#39;s pricing can be relatively higher compared to other WhatsApp API solutions,potentially making it less budget-friendly for small businesses.</li>
                        <li>The platform allows a maximum of 6 users on all plans, which may not be suitable for larger organizations with extensive team requirements.</li>
                        <li>The broad range of features may make Gallabox more complex for businesses seeking a straightforward WhatsApp API solution.</li>
                        <li>Gallabox allows only 10 custom fields, which may not be sufficient for businesses with complex contact management needs.</li>
                    </ul>
                </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center',paddingTop:'40px' }}>
            <p className='brand-title' style={{ fontSize: "45px" }}>Why Pinaippu?</p>
          </div>
          <div class="container">
            <div class="box central-box"></div>
            <div class="box top-left">
              <p style={{marginTop:'7px'}}><span><h3>Affordability and Budget Impact</h3></span><br />Pinaippu stands out for its significantly more budget-friendly pricing compared to Gallabox. This cost-effectiveness plays a pivotal role in the long-term financial planning of businesses. 
              Opting for Pinaippu can make a substantial difference in a company's annual budget, offering potential savings that can be allocated to other essential areas of development.</p>
            </div>
            <div class="box top-right">
              <p style={{marginTop:'7px'}}><span><h3>User Capacity and Team Accommodation</h3></span><br />In terms of user capacity, Pinaippu surpasses Gallabox by offering a plan that accommodates up to 10 users, whereas Gallabox limits users to 6.
               This advantage makes Pinaippu more suitable for larger teams, providing the necessary flexibility for businesses with a growing workforce.</p>
            </div>
            <div class="box bottom-left">
              <p style={{marginTop:'7px'}}><span><h3> Cost-Efficiency in Scaling Teams</h3></span><br />For businesses requiring additional users, Pinaippu presents a cost-effective solution with a lower additional user cost of $3 per month compared to Gallabox's $15 per month. 
              This substantial difference in cost makes Pinaippu a more financially viable option for businesses aiming to scale their teams without incurring significant additional expenses.</p>
            </div>
            <div class="box bottom-right">
              <p style={{marginTop:'7px'}}><span><h3>Enhanced Features for Marketing and Management</h3></span><br />Pinaippu offers a range of advanced features, such as unlimited bots, advanced marketing campaign capabilities, and unlimited custom fields. 
              These attributes provide greater flexibility and potential for more sophisticated marketing automation, extensive contact management, and customization, catering to the diverse and evolving needs of businesses seeking robust and adaptable solutions.</p>
            </div>
          </div>


          </div>
        <IconButton
            sx={btnDemoCss}
            style={{
                position:"fixed",
                bottom:"20px",
                right:"20px",
                color: "#892887",
                fontFamily: "Inter",
                fontSize: "14px",
            }}
            onClick={() => {
            window.open(CalendlyURL, "_blank");
            }}
        >
            Book A Demo
        </IconButton>
        <div style={{margin :"10px"}}></div>
    </div>
    );
};

export default ComparisonBlog3;
