import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { apiFetch } from "../../../API/CommonApi";
import { notify, formatDate } from "../../Common";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, Link } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Analytics from "./Analytics";
import { debounce, set } from "lodash";
import { useAuth } from "../../../Context/Auth";

import {
  Button,
  TextField,
  InputLabel,
  Grid,
  Box,
  Menu,
  InputAdornment,
  MenuItem,
  TablePagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import "../../Contacts/Contacts.css";
import "../Campaign.css";
import AddIcon from "@mui/icons-material/Add";
import NewCampaign from "../Modal/NewCampaign";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { deleteCampaign as apiDeleteCampaign } from "../../../API/CampaignLayer";
import { loadCampaigns as apiLoadCampaigns } from "../../../API/CampaignLayer";
import { campaignAnalytics } from "../../../API/CampaignLayer";
import {
  btnCss,
  btnWICss,
  pending,
  in_progress,
  ready_for_pickup,
  delivered,
  dot,
} from "../../../Styles";

function History() {
  const [Campaigns, setCampaigns] = useState(null);
  const [tempRowData, setRowData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const deleteDrop = Boolean(anchorEl);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [searchKey, setSearch] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageRead, setMessageRead] = useState();
  const [messageSent, setMessageSent] = useState();
  const [messageDelivered, setMessageDelivered] = useState();
  const [messageReplied, setMessageReplied] = useState();
  const [totalMessage, setTotalMessage] = useState();
  const [campaignName, setCampaignName] = useState();
  const [allowAdd, setAllowAdd] = useState(true);
  const [btnMsg, setBtnMsg] = useState(null);
  const auth = useAuth();
  const userPermissions = auth?.user?.user?.permission;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const check_time = (time) => {
    const scheduledTime = new Date(time);
    const currentTime = new Date();
    const isCrossed = scheduledTime < currentTime;

    return isCrossed;
  };
  const columns = [
    { title: "Campaign Name", field: "name" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        if (rowData.campaign_run.status === "failed") {
          return (
            <p style={pending}>
              <FiberManualRecordIcon style={dot} />
              Failed
            </p>
          );
        }
        if (rowData.campaign_run.status === "draft") {
          return (
            <p style={in_progress}>
              <FiberManualRecordIcon style={dot} />
              Draft
            </p>
          );
        }
        if (rowData.campaign_run.status === "scheduled") {
          return (
            <p style={ready_for_pickup}>
              <FiberManualRecordIcon style={dot} />
              Scheduled
            </p>
          );
        }
        if (rowData.campaign_run.status === "in_progress") {
          return (
            <p style={in_progress}>
              <FiberManualRecordIcon style={dot} />
              In Progress
            </p>
          );
        }
        if (rowData.campaign_run.status === "completed") {
          return (
            <p style={delivered}>
              <FiberManualRecordIcon style={dot} />
              Completed
            </p>
          );
        } else {
          return (
            <p style={pending}>
              <FiberManualRecordIcon style={dot} />
              Failed
            </p>
          );
        }
      },
    },
    {
      title: "Scheduled Time",
      field: "scheduled_time",
      render: (rowData) => (
        <p>
          {rowData.scheduled_time ? formatDate(rowData.scheduled_time) : ""}
        </p>
      ),
    },
    {
      title: "Created at",
      field: "created_at",
      render: (rowData) => <p>{formatDate(rowData.created_at)}</p>,
    },
    {
      title: "Edit/ Delete",
      field: "editelete",
      render: (rowData) => (
        <div className="column-5">
          <Analytics rowData={rowData} setRowData={setRowData} />
          <IconButton
            sx={{
              color: "#de4467",
            }}
            onClick={() => {
              setRowData(rowData);
              handleClickOpen();
            }}
            disabled={rowData.send_now || check_time(rowData.scheduled_time)}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
            sx={{
              color: "#de4467",
            }}
            onClick={(event) => {
              setRowData(rowData);
              setAnchorEl(event.currentTarget);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton> */}

          {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={deleteDrop}
            onClose={() => {
              setRowData(null);
              setAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p style={{ paddingBottom: "5px" }}>Confirmation:</p>
              <Button
                variant="contained"
                sx={{
                  margin: "5px",
                }}
                size="small"
                onClick={() => {
                  delCampaign(tempRowData);
                }}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  margint: "5px",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  setRowData(null);
                }}
              >
                Cancel
              </Button>
            </div>
          </Menu> */}
        </div>
      ),
    },
  ];

  const delCampaign = (rowData) => {
    apiDeleteCampaign(rowData.id).then((isDeleted) => {
      if (isDeleted) {
        setAnchorEl(null);
        loadCampaigns();
        notify("warn", "Campaign Deleted");
      }
    });
  };
  const debouncedSearch = debounce((searchKey) => {
    if (searchKey) {
      setSearch(searchKey);
      loadCampaigns(`search=${searchKey}`);
    } else {
      setSearch(null);
    }
  }, 600);
  const loadCampaigns = (param) => {
    apiLoadCampaigns(param).then((res) => {
      setCampaigns(res);
    });
  };

  useEffect(() => {
    if (
      Campaigns?.count >= userPermissions?.max_campaign_count &&
      userPermissions?.max_campaign_count > 0
    ) {
      setAllowAdd(false);
      setBtnMsg(
        "You have reached the maximum limit of Campaigns for your plan. Please upgrade to add more Campaigns."
      );
    } else {
      setAllowAdd(true);
      setBtnMsg(null);
    }
    // loadCampaigns();
  }, [Campaigns]);
  useEffect(() => {
    loadCampaigns();
  }, [open]);

  useEffect(() => {
    if (page && searchKey) {
      loadCampaigns(`page=${page}&search=${searchKey}`);
    }
    if (page) {
      loadCampaigns(`page=${page}`);
    }
    if (searchKey) {
      loadCampaigns(`search=${searchKey}`);
    }
  }, [page]);
  return (
    <div className="mainCtr">
      {/* <h3 className="title"> History</h3> */}
      {/* <p className="subTitle">Let’s see an History of your campaigns</p> */}
      {/* <p className="subTitle">Let’s see your campaigns</p> */}
      <div className="customToolbar">
        <TextField
          style={{
            fontWeight: 400,
            fontSize: "13px",
            lineHeight: "16px",
            color: "#464646",
            maxHeight: "35px",
            maxWidth: "315px",
            width: "100%",
            background: "#FFFAFB",
            padding: "dence",
          }}
          // onKeyDown={handleKeyDown}
          size="small"
          variant="outlined"
          placeholder="Search  "
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            debouncedSearch(e.target.value);
            if (!e.target.value) {
              loadCampaigns();
            }
          }}
        />
      </div>

      <div>
        <MaterialTable
          sx={{
            border: "1px solid red",
            borderRadius: "10px",
          }}
          columns={columns}
          data={Campaigns?.results}
          components={{
            Toolbar: () => <></>,
            Pagination: (props) => (
              <TablePagination
                {...props}
                rowsPerPage={10}
                rowsPerPageOptions={[10]}
                count={Campaigns?.count}
                page={page - 1}
                onPageChange={(e, page) => setPage(page + 1)}
                labelDisplayedRows={() => null}
              />
            ),
          }}
          options={{
            selection: false,
            showTitle: false,
            search: false,
            toolbar: false,
            pageSize: 10,
            headerStyle: {
              border: "1px solid #EDDCDC",
              maxHeight: "50px0",
              zIndex: 2,
              backgroundColor: "#FBFBFB",
            },
          }}
        />
      </div>
      <div style={{ position: "absolute", top: "75px", right: "2%" }}>
        <Tooltip title={btnMsg}>
          <span>
            <Button
              variant="contained"
              sx={btnCss}
              // disabled={!allowAdd}
              onClick={() => {
                setRowData(null);
                handleClickOpen();
              }}
            >
              <AddIcon /> New Campaign
            </Button>
          </span>
        </Tooltip>
      </div>
      <NewCampaign
        open={open}
        setOpen={setOpen}
        editData={tempRowData}
        setRowData={setRowData}
      />
    </div>
  );
}

export default History;
