import React, { useEffect } from "react";
import { useState } from "react";
import {
  InputAdornment,
  IconButton,
  FilledInput,
  Button,
  Tab,
  Tabs,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import "../../DashBoard.css";
import Conversations from "./Conversations";
import { useAuth } from "../../../../Context/Auth";
import { debounce } from "lodash";
// import UseWindowSize from "../../../UseWindowSize";
import { useChatLayer } from "../../../../API/useChatLayer";
import { isMobile, extractPhoneNumberFromUrl } from "../../../Common";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

export default function ChatList() {
  const urlParams = new URLSearchParams(window.location.search);
  // Extract phone number from the query parameters
  // const phoneNumber = extractPhoneNumberFromUrl(urlParams);
  const { getContactChatList } = useChatLayer();
  const {
    conversations,
    setContactFlag,
    setDropDownFlag,
    dropDownFlag,
    contactFlag,
    chatSearchKey,
    setChatSearchKey,
  } = useAuth();
  const emptyRender = Boolean(conversations);

  const [isOpen, setIsOpen] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const mobileScreen = isMobile();

  const toggleDropdown = (e) => {
    setFilterAnchorEl(e.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleChatsClick = (flag) => {
    setContactFlag("open");
    setDropDownFlag(flag);
    setIsOpen(false);
  };

  const debouncedSearch = debounce(() => {
    if (chatSearchKey) {
      getContactChatList();
    }
  }, 500);

  const handleTabChange = (event, newValue) => {
    setContactFlag(newValue);
  };
  useEffect(() => {
    if (chatSearchKey) {
      debouncedSearch();
    } else {
      getContactChatList();
    }
  }, [chatSearchKey]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".dropdown-container")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  // useEffect(() => {
  //   if (phoneNumber) {
  //     setChatSearchKey(phoneNumber);
  //   }
  // }, []);
  return (
    <>
      <div className={mobileScreen ? "MchatList" : "chatList container2"}>
        <p>
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <FilledInput
              disableUnderline
              size="small"
              sx={{
                width: mobileScreen ? "100%" : "300px",
                borderRadius: "5px",
                padding: "5px",
              }}
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {chatSearchKey && (
                    <IconButton
                      onClick={() => {
                        setChatSearchKey("");
                      }}
                    >
                      <ClearRoundedIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              value={chatSearchKey}
              onChange={(e) => {
                setChatSearchKey(e.target.value);
              }}
            />
            <div className="dropdown-container">
              <IconButton className="dropdown-button" onClick={toggleDropdown}>
                <FilterListOutlinedIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={filterAnchorEl}
                open={isOpen}
                onClose={toggleDropdown}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleChatsClick("all_chats")}>
                  All Chats
                </MenuItem>
                <MenuItem onClick={() => handleChatsClick("is_unread")}>
                  Unread Messages
                </MenuItem>
                <MenuItem onClick={() => handleChatsClick("assigned")}>
                  Chats Assigned to Me
                </MenuItem>
              </Menu>
              {/* {isOpen && (
                <div className="dropdown-content">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      handleChatsClick(true);
                    }}
                  >
                    All Chats
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      handleChatsClick(false);
                    }}
                  >
                    Chats Assigned to Me
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </p>
        <div className="dropdown-filter">
          {dropDownFlag == "assigned"
            ? "Chats Assigned to Me"
            : dropDownFlag == "is_unread"
            ? "Unread Messages"
            : "All Chats"}
        </div>
        <div>
          <Tabs
            value={contactFlag}
            onChange={handleTabChange}
            variant="fullWidth"
            aria-label="Contact Tabs"
          >
            <Tab
              style={{ minWidth: 0, padding: "6px 12px", borderRadius: "5px" }}
              label="Open"
              value="open"
            />
            <Tab
              style={{
                minWidth: 0,
                padding: "6px 12px",
                marginLeft: "10px",
                borderRadius: "5px",
              }}
              label="Resolved"
              value="resolved"
            />
            <Tab
              style={{
                minWidth: 0,
                padding: "6px 12px",
                marginLeft: "10px",
                borderRadius: "5px",
              }}
              label="All"
              value="all"
            />
          </Tabs>
        </div>
        {!emptyRender ? (
          <>
            <h4 style={{ textAlign: "center", marginTop: "250px" }}>
              {/* {dropDownFlag==assigned
                ? `No ${
                    contactFlag != "all" ? contactFlag : ""
                  } Chats `
                : `No ${
                    contactFlag != "all" ? contactFlag : ""
                  } Chats Assigned to You`} */}
              {dropDownFlag == "assigned" ? "No Chats Assigned to You" : ""}
              {dropDownFlag == "is_unread" ? "No Unread Messages" : ""}
              {dropDownFlag == "all_chats" ? "No Messages" : ""}
            </h4>
          </>
        ) : conversations?.length > 0 ? (
          <Conversations />
        ) : (
          <h3 style={{ textAlign: "center", marginTop: "100px" }}>
            No Messages
          </h3>
        )}
      </div>
    </>
  );
}
