import { apiFetch } from "./CommonApi";
import { notify } from "../Components/Common";


export const addRole = (role) => {

    return apiFetch(
        "POST",
        `roles/`,
        role,
        true
    )
        .then((res) => {
        if (res.ok) {
            return res.json();
        } else {
            throw new Error("Failed to add role");
        }
        })
        .catch((error) => {
        console.error("Failed to add role", error);
        throw error;
        });
}

export const getRoles = (params) => {

    const url = params
    ? `roles/?${params}`
    : `roles/?page=1`;
    return apiFetch(
        "GET",
        url,
        null,
        true
    )
        .then((res) => {
        if (res.ok) {
            return res.json();
        } else {
            throw new Error("Failed to get roles");
        }
        })
        .catch((error) => {
        console.error("Failed to get roles", error);
        throw error;
        });
}

export const deleteRole = (attrData) => {

    return apiFetch("DELETE", `roles/${attrData}/`, null, true)
        .then((res) => {
        if (res.ok) {
            return res;
        } else {
            throw new Error("Role not deleted");
        }
        })
        .catch((error) => {
        console.error("Error", error);
        throw error;
        });
        
}

export const updateRole = (role) => {
        return apiFetch(
            "PUT",
            `roles/${role.id}/`,
            role,
            true
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to update role");
            }
            })
            .catch((error) => {
            console.error("Failed to update role", error);
            throw error;
            });
    }

export const addUser = (user) => {

    return apiFetch(
        "POST",
        `users/`,
        user,
        true
    )
        .then((res) => {
        if (res.ok) {
            return res.json();
        } else {
            throw new Error("Failed to add user");
        }
        })
        .catch((error) => {
        console.error("Failed to add user", error);
        throw error;
        });
}


export const loadUser = (params) => {

    const url = params
    ? `users/?${params}`
    : `users/?page=1`;
    return apiFetch(
        "GET",
        url,
        null,
        true
    )
        .then((res) => {
        if (res.ok) {
            return res.json();
        } else {
            throw new Error("Failed to get users");
        }
        })
        .catch((error) => {
        console.error("Failed to get users", error);
        throw error;
        });

}

export const updateUser = (user) => {
        return apiFetch(
            "PUT",
            `users/${user.id}/`,
            user,
            true
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to update user");
            }
            })
            .catch((error) => {
            console.error("Failed to update user", error);
            throw error;
            });
}

export const deleteUser = (attrData) => {
    
        return apiFetch("DELETE", `users/${attrData}/`, null, true)
            .then((res) => {
            if (res.ok) {
                return res;
            } else {
                throw new Error("User not deleted");
            }
            })
            .catch((error) => {
            console.error("Error", error);
            throw error;
            });
            
}

export const addTeams = (team) => {
    console.log("addeam",team)
        return apiFetch(
            "POST",
            `teams/`,
            team,
            true
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to add team");
            }
            })
            .catch((error) => {
            console.error("Failed to add team", error);
            throw error;
            });
}

export const loadTeams = (params) => {
    
        const url = params
        ? `teams/?${params}`
        : `teams/?page=1`;
        return apiFetch(
            "GET",
            url,
            null,
            true
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to get teams");
            }
            })
            .catch((error) => {
            console.error("Failed to get teams", error);
            throw error;
            });
}


export const deleteTeam = (attrData) => {
        
        return apiFetch("DELETE", `teams/${attrData}/`, null, true)
            .then((res) => {
            if (res.ok) {
               return res;
            } else {
                throw new Error("Team not deleted");
            }
            })
            .catch((error) => {
            console.error("Error", error);
            throw error;
            });
            
}

export const updateTeam = (team) => {
    console.log("team",team);
        return apiFetch(
            "PUT",
            `teams/${team.id}/`,
            team,
            true
        )
            .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                throw new Error("Failed to update team");
            }
            })
            .catch((error) => {
            console.error("Failed to update team", error);
            throw error;
            });
}


